import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Divider from '@material-ui/core/Divider';
import AlertComponent from '../layout/AlertComponent';
import TextField from '@material-ui/core/TextField';
import clsx from 'clsx';
import {
  makeStyles,
  createMuiTheme,
  ThemeProvider,
} from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';

import Grid from '@material-ui/core/Grid';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#00acee',
    },
    secondary: {
      main: '#a8dda8',
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    // display: 'flex',
    // flexWrap: 'wrap',
    justifyContent: 'center',
    marginTop: theme.spacing(3),
  },
  margin: {
    // margin: theme.spacing(1),
  },
  marginDate: {
    marginBottom: '-3px',
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: '100%',
  },
  dateOfBirthErrorClass: {
    borderColor: 'red !important',
  },
}));

const Step4Family = ({
  onChange,
  nextStep,
  formData: {
    fathersName,
    fathersOccupation,
    fathersPhone,
    fathersNID,
    mothersName,
    mothersOccupation,
    mothersPhone,
    mothersNID,
    localGuardiansName,
    localGuardiansOccupation,
    localGuardiansPhone,
    localGuardiansNID,
    emergencyPhone,
    emergencyName,
  },
  prevStep,
  steps,
  currentStepMobile,
}) => {
  const classes = useStyles();

  const [errorTexts, setErrorTexts] = useState({
    fathersNameError: false,
    fathersNameText: "Please enter your father's name*",
    fathersPhoneError: false,
    fathersPhoneText: "Please enter your father's phone number*",
    fathersOccupationError: false,
    fathersOccupationText: "Please enter your father's occupation*",
    fathersNIDError: false,
    fathersNIDText: "Please enter your father's National ID Card Number*",

    mothersNameError: false,
    mothersNameText: "Please enter your mother's name*",
    mothersPhoneError: false,
    mothersPhoneText: "Please enter your mother's phone number*",
    mothersOccupationError: false,
    mothersOccupationText: "Please enter your mother's occupation*",
    mothersNIDError: false,
    mothersNIDText: "Please enter your mother's National ID Card Number*",

    localGuardiansNameError: false,
    localGuardiansNameText: "Please enter your local guardian's name*",
    localGuardiansPhoneError: false,
    localGuardiansPhoneText: "Please enter your local guardian's phone number*",
    localGuardiansOccupationError: false,
    localGuardiansOccupationText:
      "Please enter your local guardian's occupation*",
    localGuardiansNIDError: false,
    localGuardiansNIDText:
      "Please enter your local guardian's National ID Card Number*",

    emergencyNameError: false,
    emergencyNameText: "Please enter your local guardian's occupation*",
    emergencyPhoneError: false,
    emergencyPhoneText: "Please enter your local guardian's occupation*",
  });

  const {
    fathersNameError,
    fathersNameText,
    fathersPhoneError,
    fathersPhoneText,
    fathersOccupationError,
    fathersOccupationText,
    fathersNIDError,
    fathersNIDText,

    mothersNameError,
    mothersNameText,
    mothersPhoneError,
    mothersPhoneText,
    mothersOccupationError,
    mothersOccupationText,
    mothersNIDError,
    mothersNIDText,

    localGuardiansNameError,
    localGuardiansNameText,
    localGuardiansPhoneError,
    localGuardiansPhoneText,
    localGuardiansOccupationError,
    localGuardiansOccupationText,
    localGuardiansNIDError,
    localGuardiansNIDText,

    emergencyNameError,
    emergencyNameText,
    emergencyPhoneError,
    emergencyPhoneText,
  } = errorTexts;

  const validate = () => {
    let errors = {};

    if (fathersName === '') {
      errors.fathersNameError = true;
      errors.fathersNameText = "You must enter your father's name";
    }

    if (fathersPhone === '') {
      errors.fathersPhoneError = true;
      errors.fathersPhoneText = "You must enter your father's phone number";
    }

    if (fathersOccupation === '') {
      errors.fathersOccupationError = true;
      errors.fathersOccupationText = "You must enter your father's occupation";
    }

    if (fathersNID === '') {
      errors.fathersNIDError = true;
      errors.fathersNIDText = "You must enter your father's nid number";
    }

    if (mothersName === '') {
      errors.mothersNameError = true;
      errors.mothersNameText = "You must enter your mother's name";
    }

    if (mothersPhone === '') {
      errors.mothersPhoneError = true;
      errors.mothersPhoneText = "You must enter your mother's phone number";
    }

    if (mothersOccupation === '') {
      errors.mothersOccupationError = true;
      errors.mothersOccupationText = "You must enter your mother's occupation";
    }

    if (mothersNID === '') {
      errors.mothersNIDError = true;
      errors.mothersNIDText = "You must enter your mother's nid number";
    }

    if (localGuardiansName === '') {
      errors.localGuardiansNameError = true;
      errors.localGuardiansNameText =
        "You must enter your local guardian's name";
    }

    if (localGuardiansPhone === '') {
      errors.localGuardiansPhoneError = true;
      errors.localGuardiansPhoneText =
        "You must enter your local guardian's phone number";
    }

    if (localGuardiansOccupation === '') {
      errors.localGuardiansOccupationError = true;
      errors.localGuardiansOccupationText =
        "You must enter your local guardian's occupation";
    }

    if (localGuardiansNID === '') {
      errors.localGuardiansNIDError = true;
      errors.localGuardiansNIDText =
        "You must enter local guardian's nid number";
    }

    if (emergencyName === '') {
      errors.emergencyNameError = true;
      errors.emergencyNameText = 'You must enter your emergency contact name';
    }

    if (emergencyPhone === '') {
      errors.emergencyPhoneError = true;
      errors.emergencyPhoneText =
        'You must enter your emergency contact phone number';
    }

    setErrorTexts(errors);

    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const isValid = validate();

    Object.entries(isValid).length === 0 && nextStep(e);
  };

  return (
    <ThemeProvider theme={theme}>
      <div className="container">
        <h1 className="large text-primary">Family</h1>
        <div className="hide-sm">{steps}</div>
        <div className="hide-lg">{currentStepMobile}</div>
        <p className="lead">
          <i className="fas fa-home"></i> Please provide your family details
        </p>
        <Divider />
        <form className={classes.root} onSubmit={(e) => handleSubmit(e)}>
          <Typography variant="button" component="h2" gutterBottom>
            Father's Information
          </Typography>{' '}
          <br />
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                error={fathersNameError}
                type="text"
                name="fathersName"
                label="Father's Name"
                value={fathersName}
                helperText={fathersNameText}
                variant="outlined"
                onChange={(e) => onChange(e)}
                className={clsx(classes.margin, classes.textField)}
              ></TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                error={fathersOccupationError}
                type="text"
                name="fathersOccupation"
                label="Father's Occupation"
                value={fathersOccupation}
                helperText={fathersOccupationText}
                variant="outlined"
                onChange={(e) => onChange(e)}
                className={clsx(classes.margin, classes.textField)}
              ></TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                error={fathersPhoneError}
                type="number"
                name="fathersPhone"
                label="Father's Phone Number"
                value={fathersPhone}
                helperText={fathersPhoneText}
                variant="outlined"
                onChange={(e) => onChange(e)}
                className={clsx(classes.margin, classes.textField)}
              ></TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                error={fathersNIDError}
                type="number"
                name="fathersNID"
                label="Father's NID Number"
                value={fathersNID}
                helperText={fathersNIDText}
                variant="outlined"
                onChange={(e) => onChange(e)}
                className={clsx(classes.margin, classes.textField)}
              ></TextField>
            </Grid>
            <br />
          </Grid>
          <br />
          <Divider />
          <br />
          <Typography variant="button" component="h2" gutterBottom>
            Mother's Information
          </Typography>{' '}
          <br />
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                error={mothersNameError}
                type="text"
                name="mothersName"
                label="Mother's Name"
                value={mothersName}
                helperText={mothersNameText}
                variant="outlined"
                onChange={(e) => onChange(e)}
                className={clsx(classes.margin, classes.textField)}
              ></TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                error={mothersOccupationError}
                type="text"
                name="mothersOccupation"
                label="Mother's Occupation"
                value={mothersOccupation}
                helperText={mothersOccupationText}
                variant="outlined"
                onChange={(e) => onChange(e)}
                className={clsx(classes.margin, classes.textField)}
              ></TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                error={mothersPhoneError}
                type="number"
                name="mothersPhone"
                label="Mother's Phone Number"
                value={mothersPhone}
                helperText={mothersPhoneText}
                variant="outlined"
                onChange={(e) => onChange(e)}
                className={clsx(classes.margin, classes.textField)}
              ></TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                error={mothersNIDError}
                type="number"
                name="mothersNID"
                label="Mother's NID Number"
                value={mothersNID}
                helperText={mothersNIDText}
                variant="outlined"
                onChange={(e) => onChange(e)}
                className={clsx(classes.margin, classes.textField)}
              ></TextField>
            </Grid>
          </Grid>
          <br />
          <Divider />
          <br />
          <Typography variant="button" component="h2" gutterBottom>
            Local Guardian's Information
          </Typography>{' '}
          <br />
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                error={localGuardiansNameError}
                type="text"
                name="localGuardiansName"
                label="Local Guardian's Name"
                value={localGuardiansName}
                helperText={localGuardiansNameText}
                variant="outlined"
                onChange={(e) => onChange(e)}
                className={clsx(classes.margin, classes.textField)}
              ></TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                error={localGuardiansOccupationError}
                type="text"
                name="localGuardiansOccupation"
                label="Local Guardian's Occupation"
                value={localGuardiansOccupation}
                helperText={localGuardiansOccupationText}
                variant="outlined"
                onChange={(e) => onChange(e)}
                className={clsx(classes.margin, classes.textField)}
              ></TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                error={localGuardiansPhoneError}
                type="number"
                name="localGuardiansPhone"
                label="Local Guardian's Phone Number"
                value={localGuardiansPhone}
                helperText={localGuardiansPhoneText}
                variant="outlined"
                onChange={(e) => onChange(e)}
                className={clsx(classes.margin, classes.textField)}
              ></TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                error={localGuardiansNIDError}
                type="number"
                name="localGuardiansNID"
                label="Local Guardian's NID Number"
                value={localGuardiansNID}
                helperText={localGuardiansNIDText}
                variant="outlined"
                onChange={(e) => onChange(e)}
                className={clsx(classes.margin, classes.textField)}
              ></TextField>
            </Grid>
          </Grid>
          <br />
          <Divider />
          <br />
          <Typography variant="button" component="h2" gutterBottom>
            Emergency Contact's Information
          </Typography>{' '}
          <br />
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                error={emergencyNameError}
                type="text"
                name="emergencyName"
                label="Emergency Contact's Name"
                value={emergencyName}
                helperText={emergencyNameText}
                variant="outlined"
                onChange={(e) => onChange(e)}
                className={clsx(classes.margin, classes.textField)}
              ></TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                error={emergencyPhoneError}
                type="number"
                name="emergencyPhone"
                label="Emergency Contact's Phone Number"
                value={emergencyPhone}
                helperText={emergencyPhoneText}
                variant="outlined"
                onChange={(e) => onChange(e)}
                className={clsx(classes.margin, classes.textField)}
              ></TextField>
            </Grid>
          </Grid>
          <br />
          <AlertComponent />
          <div className="form-group">
            <button className="btn btn-light my-1" onClick={(e) => prevStep(e)}>
              <i className="fas fa-arrow-left text-primary"></i> &nbsp; Back
            </button>
            <button type="submit" className="btn btn-primary">
              Next &nbsp; <i className="fas fa-arrow-right"></i>
            </button>
          </div>
        </form>
      </div>
    </ThemeProvider>
  );
};

Step4Family.propTypes = {
  onChange: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  prevStep: PropTypes.func.isRequired,
  formData: PropTypes.object.isRequired,
};

export default Step4Family;
