import {
  DEPARTMENT_CREATE_FAIL,
  DEPARTMENT_CREATE_REQUEST,
  DEPARTMENT_CREATE_RESET,
  DEPARTMENT_CREATE_SUCCESS,
  DEPARTMENT_DELETE_FAIL,
  DEPARTMENT_DELETE_REQUEST,
  DEPARTMENT_DELETE_RESET,
  DEPARTMENT_DELETE_SUCCESS,
  DEPARTMENT_DETAILS_FAIL,
  DEPARTMENT_DETAILS_REQUEST,
  DEPARTMENT_DETAILS_SUCCESS,
  DEPARTMENT_LIST_FAIL,
  DEPARTMENT_LIST_REMOVE_ITEM,
  DEPARTMENT_LIST_REQUEST,
  DEPARTMENT_LIST_SUCCESS,
  DEPARTMENT_UPDATE_FAIL,
  DEPARTMENT_UPDATE_REQUEST,
  DEPARTMENT_UPDATE_RESET,
  DEPARTMENT_UPDATE_SUCCESS,
} from '../constants/departmentConstants';

// Get Departments
export const departmentListReducer = (state = { departments: [] }, action) => {
  switch (action.type) {
    case DEPARTMENT_LIST_REQUEST:
      return { loading: true, departments: [] };
    case DEPARTMENT_LIST_SUCCESS:
      return {
        loading: false,
        departments: action.payload.departments,
        count: action.payload.count,
      };
    case DEPARTMENT_LIST_FAIL:
      return { loading: false, error: action.payload };
    case DEPARTMENT_LIST_REMOVE_ITEM:
      return {
        ...state,
        departments: state.departments.filter(
          (department) => department._id !== action.payload
        ),
        count: state.count - 1,
      };
    default:
      return state;
  }
};

// Get Single Department
export const departmentDetailsReducer = (
  state = { exam: { loading: false } },
  action
) => {
  switch (action.type) {
    case DEPARTMENT_DETAILS_REQUEST:
      return { loading: true };
    case DEPARTMENT_DETAILS_SUCCESS:
      return {
        loading: false,
        department: action.payload,
      };
    case DEPARTMENT_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// Create Department
export const departmentCreateReducer = (state = { loading: false }, action) => {
  switch (action.type) {
    case DEPARTMENT_CREATE_REQUEST:
      return { loading: true };
    case DEPARTMENT_CREATE_SUCCESS:
      return {
        loading: false,
        department: action.payload,
        success: true,
      };
    case DEPARTMENT_CREATE_FAIL:
      return { loading: false, error: action.payload, success: false };
    case DEPARTMENT_CREATE_RESET:
      return { loading: false, success: false };
    default:
      return state;
  }
};

// Update Department
export const departmentUpdateReducer = (state = { loading: false }, action) => {
  switch (action.type) {
    case DEPARTMENT_UPDATE_REQUEST:
      return { loading: true };
    case DEPARTMENT_UPDATE_SUCCESS:
      return {
        loading: false,
        department: action.payload,
        success: true,
      };
    case DEPARTMENT_UPDATE_FAIL:
      return { loading: false, error: action.payload, success: false };
    case DEPARTMENT_UPDATE_RESET:
      return { loading: false, success: false };
    default:
      return state;
  }
};

// Delete Department
export const departmentDeleteReducer = (
  state = { loading: false, success: false },
  action
) => {
  switch (action.type) {
    case DEPARTMENT_DELETE_REQUEST:
      return { loading: true };
    case DEPARTMENT_DELETE_SUCCESS:
      return { loading: false, success: true };
    case DEPARTMENT_DELETE_FAIL:
      return { loading: false, success: false, error: action.payload };
    case DEPARTMENT_DELETE_RESET:
      return { loading: false, success: false };
    default:
      return state;
  }
};
