import React, { useEffect } from 'react';
import Spinner from '../layout/Spinner';
import { getNotice, deleteNotice } from '../../actions/notice';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import ReactQuill from 'react-quill';
import {
  Button,
  createMuiTheme,
  Grid,
  makeStyles,
  ThemeProvider,
} from '@material-ui/core';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#00acee',
    },
  },
});

const useStyles = makeStyles((theme) => ({
  btn: {
    color: '#FFF',
    marginRight: '10px',
  },
  btnSecondary: {
    color: '#000',
    marginRight: '10px',
  },
}));

const Notice = ({ match, history }) => {
  const dispatch = useDispatch();

  const noticeDelete = useSelector((state) => state.noticeDelete);
  const { loading: loadingDelete, success: successDelete } = noticeDelete;

  const noticeDetails = useSelector((state) => state.noticeDetails);
  const { loading, notice, error } = noticeDetails;

  const auth = useSelector((state) => state.auth);
  const { loading: authLoading, role } = auth;

  useEffect(() => {
    if (successDelete) {
      history.push('/notices');
    } else {
      if (!notice || !notice.title || notice._id !== match.params.id) {
        dispatch(getNotice(match.params.id));
      }
    }
  }, [dispatch, match.params.id, history, successDelete, notice]);

  // let noticeDateRaw = new Date();
  // let noticeDate = '';
  // if (notice !== null) {
  //   noticeDateRaw = new Date(notice.date);
  //   noticeDate = `${noticeDateRaw.getDate()}/${
  //     noticeDateRaw.getMonth() + 1
  //   }/${noticeDateRaw.getFullYear()}`;
  // }

  const onDelete = (id) => {
    if (window.confirm('Are you sure?')) {
      dispatch(deleteNotice(id));
    }
  };

  const classes = useStyles();

  return loading ? (
    <div className="container">
      <Spinner />
    </div>
  ) : (
    <ThemeProvider theme={theme}>
      <div className="container">
        <div className="post bg-white p-1 my-1">
          <div>
            <h1 className="text-primary">{notice?.title}</h1>
            <p className="post-date">Posted on {notice?.date}</p>
          </div>
          <div>
            <ReactQuill theme={null} value={notice?.bodyText} readOnly={true} />
          </div>
        </div>

        <Link to="/dashboard">
          <Button
            variant="contained"
            color="default"
            disableElevation
            className={classes.btnSecondary}
          >
            Dashboard
          </Button>
        </Link>
        <Link to="/notices">
          <Button
            variant="contained"
            color="primary"
            disableElevation
            className={classes.btn}
          >
            All Notice
          </Button>
        </Link>
        {!authLoading && role.includes('ADMIN') && (
          <Link to={`/notices/edit/${notice?._id}`}>
            <Button
              variant="contained"
              color="primary"
              disableElevation
              className={classes.btn}
            >
              Edit &nbsp; <i className="far fa-edit"></i>
            </Button>
          </Link>
        )}
        {!authLoading && role.includes('ADMIN') && (
          <Button
            variant="contained"
            color="secondary"
            disableElevation
            onClick={() => onDelete(notice._id)}
            className={classes.btn}
          >
            Delete
            {loadingDelete ? (
              <>
                &nbsp;<i className="fas fa-circle-notch fa-spin"></i>
              </>
            ) : (
              <>
                &nbsp;<i className="fas fa-trash"></i>
              </>
            )}
          </Button>
        )}
      </div>
    </ThemeProvider>
  );
};

export default Notice;
