import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Divider from '@material-ui/core/Divider';
import AlertComponent from '../layout/AlertComponent';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { countries } from './countries/countries';
import clsx from 'clsx';
import {
  makeStyles,
  createMuiTheme,
  ThemeProvider,
} from '@material-ui/core/styles';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Typography from '@material-ui/core/Typography';

import Grid from '@material-ui/core/Grid';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#00acee',
    },
    secondary: {
      main: '#a8dda8',
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    // display: 'flex',
    // flexWrap: 'wrap',
    justifyContent: 'center',
    marginTop: theme.spacing(3),
  },
  margin: {
    // margin: theme.spacing(1),
  },
  marginDate: {
    marginBottom: '-3px',
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: '100%',
  },
  dateOfBirthErrorClass: {
    borderColor: 'red !important',
  },
}));

const Step3Address = ({
  onChange,
  nextStep,
  formData: {
    presentAddressType,
    presentAptNumber,
    presentRoadNameAndNumber,
    presentAreaAndPostalCode,
    presentCity,
    presentCountry,
    presentVillage,
    presentPostOffice,
    presentPoliceStation,
    presentDistrict,
    permanentAddressType,
    permanentAptNumber,
    permanentRoadNameAndNumber,
    permanentAreaAndPostalCode,
    permanentCity,
    permanentCountry,
    permanentVillage,
    permanentPostOffice,
    permanentPoliceStation,
    permanentDistrict,
    isAddressSame,
  },
  prevStep,
  steps,
  currentStepMobile,
}) => {
  const classes = useStyles();

  const [errorTexts, setErrorTexts] = useState({
    presentAptNumberError: false,
    presentAptNumberText: 'Please enter your apartment number*',
    presentRoadNameAndNumberError: false,
    presentRoadNameAndNumberText: 'Please enter your road name and number*',
    presentAreaAndPostalCodeError: false,
    presentAreaAndPostalCodeText: 'Please enter your road name and number*',
    presentCityError: false,
    presentCityText: 'Please enter the name of your city*',
    presentCountryError: false,
    presentCountryText: 'Please select your country*',
    presentVillageError: false,
    presentVillageText: 'Please enter the name of your village*',
    presentPostOfficeError: false,
    presentPostOfficeText: 'Please enter your post office*',
    presentPoliceStationError: false,
    presentPoliceStationText: 'Please enter your police station*',
    presentDistrictError: false,
    presentDistrictText: 'Please enter the name of your district*',

    permanentAptNumberError: false,
    permanentAptNumberText: 'Please enter your apartment number*',
    permanentRoadNameAndNumberError: false,
    permanentRoadNameAndNumberText: 'Please enter your road name and number*',
    permanentAreaAndPostalCodeError: false,
    permanentAreaAndPostalCodeText: 'Please enter your road name and number*',
    permanentCityError: false,
    permanentCityText: 'Please enter the name of your city*',
    permanentCountryError: false,
    permanentCountryText: 'Please select your country*',
    permanentVillageError: false,
    permanentVillageText: 'Please enter the name of your village*',
    permanentPostOfficeError: false,
    permanentPostOfficeText: 'Please enter your post office*',
    permanentPoliceStationError: false,
    permanentPoliceStationText: 'Please enter your police station*',
    permanentDistrictError: false,
    permanentDistrictText: 'Please enter the name of your district*',
  });

  const {
    presentAptNumberError,
    presentAptNumberText,
    presentRoadNameAndNumberError,
    presentRoadNameAndNumberText,
    presentAreaAndPostalCodeError,
    presentAreaAndPostalCodeText,
    presentCityError,
    presentCityText,
    presentCountryError,
    presentCountryText,
    presentVillageError,
    presentVillageText,
    presentPostOfficeError,
    presentPostOfficeText,
    presentPoliceStationError,
    presentPoliceStationText,
    presentDistrictError,
    presentDistrictText,

    permanentAptNumberError,
    permanentAptNumberText,
    permanentRoadNameAndNumberError,
    permanentRoadNameAndNumberText,
    permanentAreaAndPostalCodeError,
    permanentAreaAndPostalCodeText,
    permanentCityError,
    permanentCityText,
    permanentCountryError,
    permanentCountryText,
    permanentVillageError,
    permanentVillageText,
    permanentPostOfficeError,
    permanentPostOfficeText,
    permanentPoliceStationError,
    permanentPoliceStationText,
    permanentDistrictError,
    permanentDistrictText,
  } = errorTexts;

  const validate = () => {
    let errors = {};

    if (presentAddressType === 'CITY') {
      if (presentAptNumber === '') {
        errors.presentAptNumberError = true;
        errors.presentAptNumberText = 'You must enter your apartment number';
      }

      if (presentRoadNameAndNumber === '') {
        errors.presentRoadNameAndNumberError = true;
        errors.presentRoadNameAndNumberText =
          'You must enter your road name and number';
      }

      if (presentRoadNameAndNumber === '') {
        errors.presentRoadNameAndNumberError = true;
        errors.presentRoadNameAndNumberText =
          'You must enter your road name and number';
      }

      if (presentAreaAndPostalCode === '') {
        errors.presentAreaAndPostalCodeError = true;
        errors.presentAreaAndPostalCodeText =
          'You must enter your area & postal code';
      }

      if (presentCity === '') {
        errors.presentCityError = true;
        errors.presentCityText = 'You must enter your city';
      }

      if (presentCountry === '') {
        errors.presentCountryError = true;
        errors.presentCountryText = 'You must select your country';
      }
    }

    if (presentAddressType === 'VILLAGE') {
      if (presentVillage === '') {
        errors.presentVillageError = true;
        errors.presentVillageText = 'You must enter the name of your village';
      }

      if (presentPostOffice === '') {
        errors.presentPostOfficeError = true;
        errors.presentPostOfficeText = 'You must enter your post office';
      }

      if (presentPoliceStation === '') {
        errors.presentPoliceStationError = true;
        errors.presentPoliceStationText = 'You must enter your police station';
      }

      if (presentDistrict === '') {
        errors.presentDistrictError = true;
        errors.presentDistrictText = 'You must enter the name of your district';
      }

      if (presentCountry === '') {
        errors.presentCountryError = true;
        errors.presentCountryText = 'You must enter the name of your country';
      }
    }

    if (isAddressSame === 'false') {
      if (permanentAddressType === 'CITY') {
        if (permanentAptNumber === '') {
          errors.permanentAptNumberError = true;
          errors.permanentAptNumberText =
            'You must enter your apartment number';
        }

        if (permanentRoadNameAndNumber === '') {
          errors.permanentRoadNameAndNumberError = true;
          errors.permanentRoadNameAndNumberText =
            'You must enter your road name and number';
        }

        if (permanentRoadNameAndNumber === '') {
          errors.permanentRoadNameAndNumberError = true;
          errors.permanentRoadNameAndNumberText =
            'You must enter your road name and number';
        }

        if (permanentAreaAndPostalCode === '') {
          errors.permanentAreaAndPostalCodeError = true;
          errors.permanentAreaAndPostalCodeText =
            'You must enter your area & postal code';
        }

        if (permanentCity === '') {
          errors.permanentCityError = true;
          errors.permanentCityText = 'You must enter your city';
        }

        if (permanentCountry === '') {
          errors.permanentCountryError = true;
          errors.permanentCountryText = 'You must select your country';
        }
      }

      if (permanentAddressType === 'VILLAGE') {
        if (permanentVillage === '') {
          errors.permanentVillageError = true;
          errors.permanentVillageText =
            'You must enter the name of your village';
        }

        if (permanentPostOffice === '') {
          errors.permanentPostOfficeError = true;
          errors.permanentPostOfficeText = 'You must enter your post office';
        }

        if (permanentPoliceStation === '') {
          errors.permanentPoliceStationError = true;
          errors.permanentPoliceStationText =
            'You must enter your police station';
        }

        if (permanentDistrict === '') {
          errors.permanentDistrictError = true;
          errors.permanentDistrictText =
            'You must enter the name of your district';
        }

        if (permanentCountry === '') {
          errors.permanentCountryError = true;
          errors.permanentCountryText =
            'You must enter the name of your country';
        }
      }
    }

    setErrorTexts(errors);

    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const isValid = validate();

    Object.entries(isValid).length === 0 && nextStep(e);
  };

  return (
    <ThemeProvider theme={theme}>
      <div className="container">
        <h1 className="large text-primary">Address</h1>
        <div className="hide-sm">{steps}</div>
        <div className="hide-lg">{currentStepMobile}</div>
        <p className="lead">
          <i className="fas fa-home"></i> Please provide your address
        </p>
        <Divider /> <br />
        <Typography variant="button" component="h2" gutterBottom>
          Present Address
        </Typography>
        <form className={classes.root} onSubmit={(e) => handleSubmit(e)}>
          <Grid container spacing={3}>
            <Grid item sm={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend">
                  Where is your present address?
                </FormLabel>
                <RadioGroup
                  aria-label="presentAddressType"
                  name="presentAddressType"
                  value={presentAddressType}
                  onChange={(e) => onChange(e)}
                >
                  <FormControlLabel
                    value="CITY"
                    control={<Radio />}
                    label="City"
                  />
                  <FormControlLabel
                    value="VILLAGE"
                    control={<Radio />}
                    label="Village"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            {presentAddressType === 'CITY' ? (
              <>
                <Grid item xs={12} sm={6}>
                  <TextField
                    error={presentAptNumberError}
                    type="text"
                    name="presentAptNumber"
                    label="Apartment Number"
                    value={presentAptNumber}
                    helperText={presentAptNumberText}
                    variant="outlined"
                    onChange={(e) => onChange(e)}
                    className={clsx(classes.margin, classes.textField)}
                  ></TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    error={presentRoadNameAndNumberError}
                    type="text"
                    name="presentRoadNameAndNumber"
                    label="Road Name & Number"
                    value={presentRoadNameAndNumber}
                    helperText={presentRoadNameAndNumberText}
                    variant="outlined"
                    onChange={(e) => onChange(e)}
                    className={clsx(classes.margin, classes.textField)}
                  ></TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    error={presentAreaAndPostalCodeError}
                    type="text"
                    name="presentAreaAndPostalCode"
                    label="Area & Postal Code"
                    value={presentAreaAndPostalCode}
                    helperText={presentAreaAndPostalCodeText}
                    variant="outlined"
                    onChange={(e) => onChange(e)}
                    className={clsx(classes.margin, classes.textField)}
                  ></TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    error={presentCityError}
                    type="text"
                    name="presentCity"
                    label="City"
                    value={presentCity}
                    helperText={presentCityText}
                    variant="outlined"
                    onChange={(e) => onChange(e)}
                    className={clsx(classes.margin, classes.textField)}
                  ></TextField>
                </Grid>
                {/* <Grid item xs={12} sm={6}>
                  <TextField
                    error={presentCountryError}
                    type="text"
                    name="presentCountry"
                    label="Country"
                    value={presentCountry}
                    helperText={presentCountryText}
                    variant="outlined"
                    onChange={(e) => onChange(e)}
                    className={clsx(classes.margin, classes.textField)}
                  ></TextField>
                </Grid> */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    error={presentCountryError}
                    select
                    name="presentCountry"
                    label="Country"
                    value={presentCountry}
                    helperText={presentCountryText}
                    variant="outlined"
                    onChange={(e) => onChange(e)}
                    className={clsx(classes.margin, classes.textField)}
                  >
                    <MenuItem value={''}> Select</MenuItem>
                    {countries.map((country) => (
                      <MenuItem
                        key={country.num_code}
                        value={country.en_short_name}
                      >
                        {country.en_short_name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={12} sm={6}>
                  <TextField
                    error={presentVillageError}
                    type="text"
                    name="presentVillage"
                    label="Village"
                    value={presentVillage}
                    helperText={presentVillageText}
                    variant="outlined"
                    onChange={(e) => onChange(e)}
                    className={clsx(classes.margin, classes.textField)}
                  ></TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    error={presentPostOfficeError}
                    type="text"
                    name="presentPostOffice"
                    label="Post Office"
                    value={presentPostOffice}
                    helperText={presentPostOfficeText}
                    variant="outlined"
                    onChange={(e) => onChange(e)}
                    className={clsx(classes.margin, classes.textField)}
                  ></TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    error={presentPoliceStationError}
                    type="text"
                    name="presentPoliceStation"
                    label="Police Station"
                    value={presentPoliceStation}
                    helperText={presentPoliceStationText}
                    variant="outlined"
                    onChange={(e) => onChange(e)}
                    className={clsx(classes.margin, classes.textField)}
                  ></TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    error={presentDistrictError}
                    type="text"
                    name="presentDistrict"
                    label="District"
                    value={presentDistrict}
                    helperText={presentDistrictText}
                    variant="outlined"
                    onChange={(e) => onChange(e)}
                    className={clsx(classes.margin, classes.textField)}
                  ></TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    error={presentCountryError}
                    select
                    name="presentCountry"
                    label="Country"
                    value={presentCountry}
                    helperText={presentCountryText}
                    variant="outlined"
                    onChange={(e) => onChange(e)}
                    className={clsx(classes.margin, classes.textField)}
                  >
                    <MenuItem value={''}> Select</MenuItem>
                    {countries.map((country) => (
                      <MenuItem
                        key={country.num_code}
                        value={country.en_short_name}
                      >
                        {country.en_short_name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </>
            )}
            <br />
            <Divider />
          </Grid>
          <br />
          <Divider />
          <br />

          <Typography variant="button" component="h2" gutterBottom>
            Permanent Address
          </Typography>
          <br />
          <Grid container spacing={3}>
            <Grid item sm={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend">
                  Is your permanent address same as your present address?
                </FormLabel>
                <RadioGroup
                  aria-label="isAddressSame"
                  name="isAddressSame"
                  value={isAddressSame}
                  onChange={(e) => onChange(e)}
                >
                  <FormControlLabel
                    value="false"
                    control={<Radio />}
                    label="No"
                  />
                  <FormControlLabel
                    value="true"
                    control={<Radio />}
                    label="Yes"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            {isAddressSame === 'false' ? (
              <Grid item sm={12}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">
                    Where is your permanent address?
                  </FormLabel>
                  <RadioGroup
                    aria-label="permanentAddressType"
                    name="permanentAddressType"
                    value={permanentAddressType}
                    onChange={(e) => onChange(e)}
                  >
                    <FormControlLabel
                      value="CITY"
                      control={<Radio />}
                      label="City"
                    />
                    <FormControlLabel
                      value="VILLAGE"
                      control={<Radio />}
                      label="Village"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            ) : null}
            {isAddressSame === 'false' ? (
              permanentAddressType === 'CITY' ? (
                <>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      error={permanentAptNumberError}
                      type="text"
                      name="permanentAptNumber"
                      label="Apartment Number"
                      value={permanentAptNumber}
                      helperText={permanentAptNumberText}
                      variant="outlined"
                      onChange={(e) => onChange(e)}
                      className={clsx(classes.margin, classes.textField)}
                    ></TextField>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      error={permanentRoadNameAndNumberError}
                      type="text"
                      name="permanentRoadNameAndNumber"
                      label="Road Name & Number"
                      value={permanentRoadNameAndNumber}
                      helperText={permanentRoadNameAndNumberText}
                      variant="outlined"
                      onChange={(e) => onChange(e)}
                      className={clsx(classes.margin, classes.textField)}
                    ></TextField>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      error={permanentAreaAndPostalCodeError}
                      type="text"
                      name="permanentAreaAndPostalCode"
                      label="Area & Postal Code"
                      value={permanentAreaAndPostalCode}
                      helperText={permanentAreaAndPostalCodeText}
                      variant="outlined"
                      onChange={(e) => onChange(e)}
                      className={clsx(classes.margin, classes.textField)}
                    ></TextField>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      error={permanentCityError}
                      type="text"
                      name="permanentCity"
                      label="City"
                      value={permanentCity}
                      helperText={permanentCityText}
                      variant="outlined"
                      onChange={(e) => onChange(e)}
                      className={clsx(classes.margin, classes.textField)}
                    ></TextField>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      error={permanentCountryError}
                      select
                      name="permanentCountry"
                      label="Country"
                      value={permanentCountry}
                      helperText={permanentCountryText}
                      variant="outlined"
                      onChange={(e) => onChange(e)}
                      className={clsx(classes.margin, classes.textField)}
                    >
                      <MenuItem value={''}> Select</MenuItem>
                      {countries.map((country) => (
                        <MenuItem
                          key={country.num_code}
                          value={country.en_short_name}
                        >
                          {country.en_short_name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      error={permanentVillageError}
                      type="text"
                      name="permanentVillage"
                      label="Village"
                      value={permanentVillage}
                      helperText={permanentVillageText}
                      variant="outlined"
                      onChange={(e) => onChange(e)}
                      className={clsx(classes.margin, classes.textField)}
                    ></TextField>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      error={permanentPostOfficeError}
                      type="text"
                      name="permanentPostOffice"
                      label="Post Office"
                      value={permanentPostOffice}
                      helperText={permanentPostOfficeText}
                      variant="outlined"
                      onChange={(e) => onChange(e)}
                      className={clsx(classes.margin, classes.textField)}
                    ></TextField>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      error={permanentPoliceStationError}
                      type="text"
                      name="permanentPoliceStation"
                      label="Police Station"
                      value={permanentPoliceStation}
                      helperText={permanentPoliceStationText}
                      variant="outlined"
                      onChange={(e) => onChange(e)}
                      className={clsx(classes.margin, classes.textField)}
                    ></TextField>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      error={permanentDistrictError}
                      type="text"
                      name="permanentDistrict"
                      label="District"
                      value={permanentDistrict}
                      helperText={permanentDistrictText}
                      variant="outlined"
                      onChange={(e) => onChange(e)}
                      className={clsx(classes.margin, classes.textField)}
                    ></TextField>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      error={permanentCountryError}
                      select
                      name="permanentCountry"
                      label="Country"
                      value={permanentCountry}
                      helperText={permanentCountryText}
                      variant="outlined"
                      onChange={(e) => onChange(e)}
                      className={clsx(classes.margin, classes.textField)}
                    >
                      <MenuItem value={''}> Select</MenuItem>
                      {countries.map((country) => (
                        <MenuItem
                          key={country.num_code}
                          value={country.en_short_name}
                        >
                          {country.en_short_name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </>
              )
            ) : null}

            <br />
            <Divider />
          </Grid>
          <br />
          <Divider />
          <AlertComponent />
          <div className="form-group">
            <button className="btn btn-light my-1" onClick={(e) => prevStep(e)}>
              <i className="fas fa-arrow-left text-primary"></i> &nbsp; Back
            </button>
            <button type="submit" className="btn btn-primary">
              Next &nbsp; <i className="fas fa-arrow-right"></i>
            </button>
          </div>
        </form>
      </div>
    </ThemeProvider>
  );
};

Step3Address.propTypes = {
  onChange: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  prevStep: PropTypes.func.isRequired,
  formData: PropTypes.object.isRequired,
};

export default Step3Address;
