import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getExamStudents, getAvailableExams } from '../../actions/exam';
import { addMarks } from '../../actions/profileActions';
import Spinner from './Spinner';

import {
  withStyles,
  makeStyles,
  ThemeProvider,
  createMuiTheme,
} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import PersonIcon from '@material-ui/icons/Person';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import TextField from '@material-ui/core/TextField';
import exam from '../../reducers/exam';
import {
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
} from '@material-ui/core';
import { getExam } from '../../actions/examActions';
import { format } from 'date-fns';
import AlertComponent from './AlertComponent';
import DownloadXlsxMultiple from './utils/DownloadXlsxMultiple';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#00acee',
    },
    // secondary: {
    //   main: '#a8dda8',
    // },
  },
});

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#00acee',
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    // '&:nth-of-type(odd)': {
    //   backgroundColor: theme.palette.action.hover,
    // },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
  // addMarks: {
  //   cursor: 'pointer',
  //   fontWeight: 'bold',
  //   color: '#00acee',
  //   backgroundColor: '#e6f7fd',
  // },
  textFieldNumber: {
    width: '10ch',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  chipContainer: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    // paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    '& .MuiChip-root': {
      margin: theme.spacing(0.5),
      color: '#FFF',
    },
  },
});

const ExamStudents = ({
  match,
  auth,
  profiles,
  getExamStudents,
  addMarks,
  getAvailableExams,
  history,
}) => {
  const dispatch = useDispatch();

  const profileAddMark = useSelector((state) => state.profileAddMark);
  const { loading: loadingAddMark, profile, success } = profileAddMark;

  const examStudentList = useSelector((state) => state.examStudentList);
  const { loading: loadingStudentList, students } = examStudentList;

  useEffect(() => {
    getExamStudents(match.params.id);
  }, [match.params.id, dispatch, success, getExamStudents]);

  useEffect(() => {
    dispatch(getExam(match.params.id));
  }, []);

  const [formData, setFormData] = useState({
    written: null,
    viva: null,
    remarks: '',
  });

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onMarkAdd = (e, id) => {
    e.preventDefault();
    console.log(id);
    addMarks(formData, id, match.params.id, handleClose);
    // setTimeout(function () {
    //   window.location.reload();
    // }, 3000);
    setFormData({
      ...formData,
      written: '',
      viva: '',
      remarks: '',
    });
  };

  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [markProfile, setMarkProfile] = useState(null);

  const examDetails = useSelector((state) => state.examDetails);
  const { loading: loadingExamDetails, exam } = examDetails;

  const handleClickOpen = (profile) => {
    setOpen(true);
    setMarkProfile(profile);
  };
  const handleClose = () => {
    setOpen(false);
    setMarkProfile(null);
  };

  const onChipClick = (id) => {
    history.push(`/edit-exam/${id}`);
  };

  const modal = (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle onClose={handleClose}>
        Add Marks
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Typography gutterBottom>
          Applicant ID: {markProfile?.marks.applicantId}
        </Typography>
        <Typography gutterBottom>Name: {markProfile?.user.name}</Typography>
        <TextField
          autoFocus
          margin="dense"
          id="written"
          name="written"
          label="Written"
          type="number"
          fullWidth
          variant="outlined"
          onChange={(e) => onChange(e)}
        />
        <TextField
          autoFocus
          margin="dense"
          id="viva"
          name="viva"
          label="Viva"
          type="number"
          fullWidth
          variant="outlined"
          onChange={(e) => onChange(e)}
        />
        <AlertComponent />
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={(e) => onMarkAdd(e, markProfile.user._id)}
          color="primary"
        >
          Submit{' '}
          {loadingAddMark ? (
            <>
              &nbsp;<i className="fas fa-circle-notch fa-spin"></i>
            </>
          ) : (
            <>
              &nbsp;<i className="fas fa-check"></i>
            </>
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <ThemeProvider theme={theme}>
      <Fragment>
        {modal}

        <div className="container">
          <h1 className="large text-primary">Applicants in Exam</h1>
          <Divider />
          <Link to={`/exams`}>
            <Button
              variant="contained"
              color="default"
              disableElevation
              size="small"
              style={{
                marginTop: '10px',
                marginBottom: '5px',
              }}
            >
              <i className="fas fa-arrow-left"></i> &nbsp; Back
            </Button>
          </Link>
          <AlertComponent />
          {loadingExamDetails && <Spinner />}
          {exam && (
            <div className={classes.chipContainer}>
              <Chip
                label={`Program: ${exam?.program?.programName}`}
                color="primary"
              />
              <Chip
                label={`Department: ${exam?.program?.department.departmentName}`}
                color="primary"
              />
              <Chip label={`Trimester: ${exam?.trimester}`} color="primary" />
              <Chip label={`Year: ${exam?.year}`} color="primary" />
              <Chip label={`Applicants: ${students?.length}`} color="primary" />

              {students && students.length > 0 ? (
                <DownloadXlsxMultiple profiles={students} type="chip" />
              ) : null}
              <Chip
                label={`Exam Details`}
                color="primary"
                onClick={() => onChipClick(exam._id)}
                onDelete={() => onChipClick(exam._id)}
                deleteIcon={
                  <i
                    className="fas fa-arrow-right"
                    style={{ color: '#FFF', paddingTop: '3px' }}
                  ></i>
                }
              />
            </div>
          )}

          <TableContainer component={Paper} className={classes.my}>
            <Table
              className={classes.table}
              aria-label="user table"
              size="small"
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell align="left">Applicant ID</StyledTableCell>
                  <StyledTableCell align="left">Name</StyledTableCell>
                  <StyledTableCell align="left">SSC</StyledTableCell>
                  <StyledTableCell align="left">HSC</StyledTableCell>
                  <StyledTableCell align="center">Written</StyledTableCell>
                  <StyledTableCell align="center">Viva</StyledTableCell>
                  <StyledTableCell align="center">Total</StyledTableCell>
                  <StyledTableCell align="center">Add Marks</StyledTableCell>
                  <StyledTableCell align="center">View/Edit</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loadingStudentList && <small>Loading...</small>}
                {students && students.length > 0 ? (
                  students.map((profile) => (
                    <StyledTableRow key={profile._id} hover>
                      <StyledTableCell align="left">
                        {profile.marks.applicantId}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {profile.user.name}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {profile.ssc.sscConverted.toFixed(2)}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {profile.hsc.hscConverted.toFixed(2)}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {profile.marks.written?.toFixed(2)}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {profile.marks.viva?.toFixed(2)}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {profile.marks.total?.toFixed(2)}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <IconButton
                          aria-label="add-marks"
                          onClick={() => handleClickOpen(profile)}
                          // className={classes.btn}
                          color="secondary"
                        >
                          <AddIcon color="primary" />
                        </IconButton>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Link
                          to={`/student/${profile.user._id}?redirect=/exam/students/${match.params.id}`}
                        >
                          <IconButton aria-label="delete-exam" color="primary">
                            <ArrowForwardIcon />
                          </IconButton>
                        </Link>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))
                ) : (
                  <small>No students applied yet</small>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </Fragment>
    </ThemeProvider>
  );
};

ExamStudents.propTypes = {
  auth: PropTypes.object.isRequired,
  profiles: PropTypes.array.isRequired,
  getExamStudents: PropTypes.func.isRequired,
  getAvailableExams: PropTypes.func.isRequired,
  addMarks: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  profiles: state.profile.profiles,
  exam: state.exam,
});

export default connect(mapStateToProps, {
  getExamStudents,
  addMarks,
  getAvailableExams,
})(ExamStudents);
