import {
  ADMIN_PROFILE_PDF_FAIL,
  ADMIN_PROFILE_PDF_REQUEST,
  ADMIN_PROFILE_PDF_SUCCESS,
  ADMIN_PROFILE_PDF_RESET,
  ADMIN_PROFILE_PDF_MULTI_REQUEST,
  ADMIN_PROFILE_PDF_MULTI_SUCCESS,
  ADMIN_PROFILE_PDF_MULTI_RESET,
  ADMIN_PROFILE_PDF_MULTI_FAIL,
} from '../constants/pdfConstants';

export const profilePDFReducer = (state = { loading: false }, action) => {
  switch (action.type) {
    case ADMIN_PROFILE_PDF_REQUEST:
      return { loading: true };
    case ADMIN_PROFILE_PDF_SUCCESS:
      return {
        loading: false,
        profilePDF: action.payload,
      };
    case ADMIN_PROFILE_PDF_RESET:
      return {
        loading: false,
      };
    case ADMIN_PROFILE_PDF_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const profilePDFMultiReducer = (state = { loading: false }, action) => {
  switch (action.type) {
    case ADMIN_PROFILE_PDF_MULTI_REQUEST:
      return { loading: true };
    case ADMIN_PROFILE_PDF_MULTI_SUCCESS:
      return {
        loading: false,
        profilePDF: action.payload,
      };
    case ADMIN_PROFILE_PDF_MULTI_RESET:
      return {
        loading: false,
      };
    case ADMIN_PROFILE_PDF_MULTI_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
