import {
  APPLICANT_CREATE_FAIL,
  APPLICANT_CREATE_REQUEST,
  APPLICANT_CREATE_RESET,
  APPLICANT_CREATE_SUCCESS,
  APPLICANT_DELETE_FAIL,
  APPLICANT_DELETE_REQUEST,
  APPLICANT_DELETE_RESET,
  APPLICANT_DELETE_SUCCESS,
  APPLICANT_DETAILS_FAIL,
  APPLICANT_DETAILS_REQUEST,
  APPLICANT_DETAILS_SUCCESS,
  APPLICANT_LIST_FAIL,
  APPLICANT_LIST_REMOVE_ITEM,
  APPLICANT_LIST_REQUEST,
  APPLICANT_LIST_SUCCESS,
  APPLICANT_UPDATE_FAIL,
  APPLICANT_UPDATE_REQUEST,
  APPLICANT_UPDATE_RESET,
  APPLICANT_UPDATE_SUCCESS,
} from '../constants/applicantConstants';

// Get Applicants
export const applicantListReducer = (state = { applicants: [] }, action) => {
  switch (action.type) {
    case APPLICANT_LIST_REQUEST:
      return { loading: true, applicants: [] };
    case APPLICANT_LIST_SUCCESS:
      return {
        loading: false,
        applicants: action.payload.applicants,
        institutionStats: action.payload.institutionStats,
        count: action.payload.count,
      };
    case APPLICANT_LIST_FAIL:
      return { loading: false, error: action.payload };
    case APPLICANT_LIST_REMOVE_ITEM:
      return {
        ...state,
        applicants: state.applicants.filter(
          (applicant) => applicant._id !== action.payload
        ),
        count: state.count - 1,
      };
    default:
      return state;
  }
};

// Get Single Department
export const departmentDetailsReducer = (
  state = { exam: { loading: false } },
  action
) => {
  switch (action.type) {
    case APPLICANT_DETAILS_REQUEST:
      return { loading: true };
    case APPLICANT_DETAILS_SUCCESS:
      return {
        loading: false,
        department: action.payload,
      };
    case APPLICANT_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// Create Department
export const departmentCreateReducer = (state = { loading: false }, action) => {
  switch (action.type) {
    case APPLICANT_CREATE_REQUEST:
      return { loading: true };
    case APPLICANT_CREATE_SUCCESS:
      return {
        loading: false,
        department: action.payload,
        success: true,
      };
    case APPLICANT_CREATE_FAIL:
      return { loading: false, error: action.payload, success: false };
    case APPLICANT_CREATE_RESET:
      return { loading: false, success: false };
    default:
      return state;
  }
};

// Update Department
export const departmentUpdateReducer = (state = { loading: false }, action) => {
  switch (action.type) {
    case APPLICANT_UPDATE_REQUEST:
      return { loading: true };
    case APPLICANT_UPDATE_SUCCESS:
      return {
        loading: false,
        department: action.payload,
        success: true,
      };
    case APPLICANT_UPDATE_FAIL:
      return { loading: false, error: action.payload, success: false };
    case APPLICANT_UPDATE_RESET:
      return { loading: false, success: false };
    default:
      return state;
  }
};

// Delete Department
export const departmentDeleteReducer = (
  state = { loading: false, success: false },
  action
) => {
  switch (action.type) {
    case APPLICANT_DELETE_REQUEST:
      return { loading: true };
    case APPLICANT_DELETE_SUCCESS:
      return { loading: false, success: true };
    case APPLICANT_DELETE_FAIL:
      return { loading: false, success: false, error: action.payload };
    case APPLICANT_DELETE_RESET:
      return { loading: false, success: false };
    default:
      return state;
  }
};
