import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Logo from '../../img/logo.png';
import { Fragment } from 'react';
import Spinner from './Spinner';

const Landing = ({ isAuthenticated, role, loading }) => {
  if (isAuthenticated && role === 'STUDENT') {
    return <Redirect to="/dashboard" />;
  } else if (isAuthenticated && role.includes('ADMIN')) {
    return <Redirect to="/admin-dashboard" />;
  }

  return loading ? (
    <Fragment>
      <div className="container">
        <Spinner />
      </div>
    </Fragment>
  ) : (
    <div>
      <section className="landing">
        <div className="dark-overlay">
          <div className="landing-inner">
            <img src={Logo} className="logo-200" alt="Logo" />
            <h1 className="x-large">Notre Dame University Bangladesh</h1>
            <p className="lead">
              Register to apply to NDUB or log in to your existing account
            </p>
            <div className="buttons">
              <Link to="/register" className="btn btn-primary">
                Register
              </Link>
              <Link to="/login" className="btn btn-light">
                Login
              </Link>
            </div>
            <br />
            <small>
              Having trouble? See this &nbsp;
              <a
                href="https://www.admission.ndub.edu.bd/tutorial/ndub-admission-tutorial.pdf"
                target="_blank"
                rel="noopener"
              >
                Admission Tutorial
              </a>{' '}
              &nbsp; | &nbsp; Still need help? &nbsp;
              <Link to="/contact">Contact Us</Link>
            </small>
          </div>
        </div>
      </section>
    </div>
  );
};

Landing.propTypes = {
  isAuthenticated: PropTypes.bool,
  role: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  // user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  role: state.auth.role,
  loading: state.auth.loading,
});

export default connect(mapStateToProps)(Landing);
