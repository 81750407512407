import {
  NOTICE_CREATE_FAIL,
  NOTICE_CREATE_REQUEST,
  NOTICE_CREATE_RESET,
  NOTICE_CREATE_SUCCESS,
  NOTICE_DELETE_FAIL,
  NOTICE_DELETE_REQUEST,
  NOTICE_DELETE_RESET,
  NOTICE_DELETE_SUCCESS,
  NOTICE_DETAILS_FAIL,
  NOTICE_DETAILS_REQUEST,
  NOTICE_DETAILS_SUCCESS,
  NOTICE_LIST_FAIL,
  NOTICE_LIST_REQUEST,
  NOTICE_LIST_SUCCESS,
  NOTICE_UPDATE_FAIL,
  NOTICE_UPDATE_REQUEST,
  NOTICE_UPDATE_RESET,
  NOTICE_UPDATE_SUCCESS,
} from '../constants/noticeConstants';

// Get Notices
export const noticeListReducer = (state = { notices: [] }, action) => {
  switch (action.type) {
    case NOTICE_LIST_REQUEST:
      return { loading: true, notices: [] };
    case NOTICE_LIST_SUCCESS:
      return {
        loading: false,
        notices: action.payload.notices,
        pages: action.payload.pages,
        page: action.payload.page,
      };
    case NOTICE_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// Get Single Notice
export const noticeDetailsReducer = (
  state = { notice: { loading: false } },
  action
) => {
  switch (action.type) {
    case NOTICE_DETAILS_REQUEST:
      return { loading: true };
    case NOTICE_DETAILS_SUCCESS:
      return {
        loading: false,
        notice: action.payload,
      };
    case NOTICE_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// Create Notice
export const noticeCreateReducer = (state = { notice: {} }, action) => {
  switch (action.type) {
    case NOTICE_CREATE_REQUEST:
      return { loading: true, notice: {} };
    case NOTICE_CREATE_SUCCESS:
      return {
        loading: false,
        success: true,
        notice: action.payload.notice,
      };
    case NOTICE_CREATE_FAIL:
      return { loading: false, success: false, error: action.payload };
    case NOTICE_CREATE_RESET:
      return { loading: false, success: false };
    default:
      return state;
  }
};

// Update Notice
export const noticeUpdateReducer = (state = { loading: false }, action) => {
  switch (action.type) {
    case NOTICE_UPDATE_REQUEST:
      return { loading: true };
    case NOTICE_UPDATE_SUCCESS:
      return {
        loading: false,
        notice: action.payload.notice,
        success: true,
      };
    case NOTICE_UPDATE_FAIL:
      return { loading: false, error: action.payload, success: false };
    case NOTICE_UPDATE_RESET:
      return { loading: false, success: false };
    default:
      return state;
  }
};

// Delete Notice
export const noticeDeleteReducer = (
  state = { loading: false, success: false },
  action
) => {
  switch (action.type) {
    case NOTICE_DELETE_REQUEST:
      return { loading: true };
    case NOTICE_DELETE_SUCCESS:
      return { loading: false, success: true };
    case NOTICE_DELETE_FAIL:
      return { loading: false, success: false, error: action.payload };
    case NOTICE_DELETE_RESET:
      return { loading: false, success: false };
    default:
      return state;
  }
};
