import {
  ThemeProvider,
  createMuiTheme,
  makeStyles,
} from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#66deff',
      main: '#00acee',
      dark: '#007dbb',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ffea53',
      main: '#fcb813',
      dark: '#c48800',
      contrastText: '#0c2340',
    },
  },
});

//   const useStyles = makeStyles((theme) => ({
//     root: {
//     //   justifyContent: 'center',
//     //   marginTop: theme.spacing(3),
//     },
//   }));

export default theme;
