import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Grid,
  MenuItem,
  TextField,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { createUser } from '../../actions/userActions';
import AlertComponent from '../layout/AlertComponent';

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
    marginTop: theme.spacing(3),
  },
  table: {
    minWidth: 700,
  },
  textField: {
    width: '100%',
  },
  btn: {
    color: '#FFF',
    marginRight: '10px',
  },
  btnSecondary: {
    color: '#00acee',
    marginRight: '10px',
  },
  paginationSection: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  my: {
    marginBottom: '10px',
  },
}));

const AddUserModal = ({ open, handleClose }) => {
  const [role, setRole] = useState('ADMIN');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState('password');

  const dispatch = useDispatch();

  const userCreate = useSelector((state) => state.userCreate);
  const { loading, msg, user } = userCreate;

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(createUser(role, email, phone, name, password));
  };

  const togglePass = (e) => {
    showPassword === 'password'
      ? setShowPassword('text')
      : setShowPassword('password');
  };

  const classes = useStyles();

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        Add User
        {/* <IconButton
          aria-label="close"
          //   className={classes.closeButton}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton> */}
      </DialogTitle>
      <DialogContent dividers>
        {/* <Typography gutterBottom>
          Only JPG/JPEG Images are allowed. Please choose the allowed image
          format.
        </Typography> */}
        <form onSubmit={(e) => onSubmit(e)} style={{ marginBottom: '20px' }}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} sm={6}>
              <TextField
                type="text"
                name="name"
                label="Name"
                value={name}
                helperText={'Enter Name'}
                variant="outlined"
                onChange={(e) => setName(e.target.value)}
                className={classes.textField}
              ></TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                type="text"
                name="phone"
                label="Phone"
                value={phone}
                helperText={'Enter Phone'}
                variant="outlined"
                onChange={(e) => setPhone(e.target.value)}
                className={classes.textField}
              ></TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                type="text"
                name="email"
                label="Email"
                value={email}
                helperText={'Enter Email'}
                variant="outlined"
                onChange={(e) => setEmail(e.target.value)}
                className={classes.textField}
              ></TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                select
                name="role"
                label="Role"
                value={role}
                helperText={'Select role'}
                variant="outlined"
                onChange={(e) => setRole(e.target.value)}
                className={classes.textField}
              >
                <MenuItem value={'ADMIN'}>Admin</MenuItem>
                <MenuItem value={'SUPERADMIN'}>Super Admin</MenuItem>
                <MenuItem value={'STUDENT'}>Student</MenuItem>
              </TextField>
            </Grid>

            <Grid item xs={12}>
              <TextField
                type={showPassword}
                name="password"
                label="Password"
                value={password}
                helperText="Use 6 or more characters with a mix of letters, numbers & symbols."
                variant="outlined"
                required
                onChange={(e) => setPassword(e.target.value)}
                className={classes.textField}
              ></TextField>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showPassword === 'password' ? false : true}
                    onChange={togglePass}
                    name="checkedB"
                    color="primary"
                  />
                }
                label="Show Password"
              />
            </Grid>
          </Grid>

          <br />
          <div className="form-group">
            <Button
              variant="contained"
              color="primary"
              disableElevation
              className={classes.btn}
              type="submit"
            >
              Add &nbsp;
              {loading ? (
                <i className="fas fa-circle-notch fa-spin"></i>
              ) : (
                <i className="fas fa-add"></i>
              )}
            </Button>
          </div>
        </form>
        <AlertComponent />
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddUserModal;
