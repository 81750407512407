export const DEPARTMENT_LIST_REQUEST = 'DEPARTMENT_LIST_REQUEST';
export const DEPARTMENT_LIST_SUCCESS = 'DEPARTMENT_LIST_SUCCESS';
export const DEPARTMENT_LIST_FAIL = 'DEPARTMENT_LIST_FAIL';
export const DEPARTMENT_LIST_REMOVE_ITEM = 'DEPARTMENT_LIST_REMOVE_ITEM';

export const DEPARTMENT_DETAILS_REQUEST = 'DEPARTMENT_DETAILS_REQUEST';
export const DEPARTMENT_DETAILS_SUCCESS = 'DEPARTMENT_DETAILS_SUCCESS';
export const DEPARTMENT_DETAILS_FAIL = 'DEPARTMENT_DETAILS_FAIL';

export const DEPARTMENT_DELETE_REQUEST = 'DEPARTMENT_DELETE_REQUEST';
export const DEPARTMENT_DELETE_SUCCESS = 'DEPARTMENT_DELETE_SUCCESS';
export const DEPARTMENT_DELETE_FAIL = 'DEPARTMENT_DELETE_FAIL';
export const DEPARTMENT_DELETE_RESET = 'DEPARTMENT_DELETE_RESET';

export const DEPARTMENT_CREATE_REQUEST = 'DEPARTMENT_CREATE_REQUEST';
export const DEPARTMENT_CREATE_SUCCESS = 'DEPARTMENT_CREATE_SUCCESS';
export const DEPARTMENT_CREATE_FAIL = 'DEPARTMENT_CREATE_FAIL';
export const DEPARTMENT_CREATE_RESET = 'DEPARTMENT_CREATE_RESET';

export const DEPARTMENT_UPDATE_REQUEST = 'DEPARTMENT_UPDATE_REQUEST';
export const DEPARTMENT_UPDATE_SUCCESS = 'DEPARTMENT_UPDATE_SUCCESS';
export const DEPARTMENT_UPDATE_FAIL = 'DEPARTMENT_UPDATE_FAIL';
export const DEPARTMENT_UPDATE_RESET = 'DEPARTMENT_UPDATE_RESET';
