import axios from 'axios';
import {
  PROFILE_CREATE_FAIL,
  PROFILE_CREATE_REQUEST,
  PROFILE_CREATE_SUCCESS,
  PROFILE_MARK_ADD_FAIL,
  PROFILE_MARK_ADD_REQUEST,
  PROFILE_MARK_ADD_SUCCESS,
  PROFILE_PICTURE_UPLOAD_FAIL,
  PROFILE_PICTURE_UPLOAD_REQUEST,
  PROFILE_PICTURE_UPLOAD_SUCCESS,
  PROFILE_SIGNATURE_UPLOAD_FAIL,
  PROFILE_SIGNATURE_UPLOAD_REQUEST,
  PROFILE_SIGNATURE_UPLOAD_SUCCESS,
} from '../constants/profileConstants';
import { setAlert } from './alert';
import { GET_PROFILE } from './types';
import * as Sentry from '@sentry/react';

// Create or update profile
export const createProfile =
  (formData, history, edit = false) =>
  async (dispatch) => {
    try {
      dispatch({
        type: PROFILE_CREATE_REQUEST,
      });
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      const res = await axios.post('/api/profile', formData, config);

      dispatch({
        type: PROFILE_CREATE_SUCCESS,
        payload: res.data,
      });

      dispatch(
        setAlert(edit ? 'Profile Updated' : 'Profile Created', 'success')
      );

      if (!edit) {
        history.push('/dashboard');
      }
    } catch (err) {
      Sentry.captureException(err);

      const errors = err.response.data.errors;

      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
      }
      dispatch({
        type: PROFILE_CREATE_FAIL,
        payload: { msg: err.response.statusText, status: err.response.status },
      });
    }
  };

// Add or Update Marks
export const addMarks =
  (formData, user_id, exam_id, handleClose) => async (dispatch) => {
    try {
      dispatch({
        type: PROFILE_MARK_ADD_REQUEST,
      });

      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      const res = await axios.put(
        `/api/profile/marks/user/${user_id}`,
        formData,
        config
      );

      dispatch({
        type: PROFILE_MARK_ADD_SUCCESS,
        payload: res.data,
      });

      dispatch(handleClose);

      dispatch(setAlert('Marks Updated', 'success'));
    } catch (err) {
      Sentry.captureException(err);
      const errors = err.response.data.errors;
      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
      }
      dispatch({
        type: PROFILE_MARK_ADD_FAIL,
        payload: { msg: err.response.statusText, status: err.response.status },
      });
    }
  };

// Upload Profile Picture
export const upProPic = (profilePic, refreshPage) => async (dispatch) => {
  try {
    dispatch({
      type: PROFILE_PICTURE_UPLOAD_REQUEST,
    });

    let formData = new FormData();

    formData.append('profilePic', profilePic);

    const res = await axios.post('/api/profile/upload/profile-pic', formData);

    dispatch({
      type: PROFILE_PICTURE_UPLOAD_SUCCESS,
      payload: res.data,
    });

    dispatch({
      type: GET_PROFILE,
      payload: res.data,
    });

    dispatch(setAlert('Profile picture uploaded successfully'));

    dispatch(refreshPage());
  } catch (err) {
    Sentry.captureException(err);
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
    }

    dispatch({
      type: PROFILE_PICTURE_UPLOAD_FAIL,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Upload Signature Picture
export const upSignature = (signatureFile, refreshPage) => async (dispatch) => {
  try {
    dispatch({
      type: PROFILE_SIGNATURE_UPLOAD_REQUEST,
    });

    let formData = new FormData();

    formData.append('signature', signatureFile.signature);

    const res = await axios.post('/api/profile/upload/signature', formData);

    dispatch({
      type: PROFILE_SIGNATURE_UPLOAD_SUCCESS,
      payload: res.data,
    });

    dispatch({
      type: GET_PROFILE,
      payload: res.data,
    });

    dispatch(setAlert('Signature uploaded successfully'));

    dispatch(refreshPage());
  } catch (err) {
    Sentry.captureException(err);
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
    }

    dispatch({
      type: PROFILE_SIGNATURE_UPLOAD_FAIL,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};
