import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionActions from '@material-ui/core/AccordionActions';
import Chip from '@material-ui/core/Chip';

import ClearIcon from '@material-ui/icons/Clear';
import { Button, IconButton } from '@material-ui/core';

import { format } from 'date-fns';

import {
  makeStyles,
  createMuiTheme,
  ThemeProvider,
} from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#00acee',
    },
    // secondary: {
    //   main: '#FFF',
    // },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '1100px',
    margin: 'auto',
    padding: '0 2rem',
    marginTop: '6rem',
    marginBottom: '2rem',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  accordionContainer: {
    maxWidth: '50%',
    margin: 'auto',
    // overflow: 'hidden',
    padding: '20px',
    marginTop: '6rem',
    marginBottom: '2rem',
  },
  headerColor: {
    backgroundColor: ' #00acee',
    color: 'white',
  },
  headerColorSelected: {
    backgroundColor: ' #FCB813',
    color: 'white',
  },
  iconColor: {
    color: 'white',
  },
  btn: {
    color: '#FFF',
    // marginRight: '10px',
  },
  btnSecondary: {
    // color: '#00acee',
    marginRight: '10px',
  },
}));

const ProgramItem = ({ exam, onProgramSelect, examId, nextStep }) => {
  const classes = useStyles();

  const onClick = (e, exam_id, title) => {
    e.preventDefault();
    onProgramSelect(
      exam_id,
      title,
      exam.program.department.departmentName,
      exam.examDate,
      exam.program.programName,
      exam.program.programType,
      exam.trimester,
      exam.year
    );
  };

  const onSelect = (e) => {
    e.preventDefault();
    if (examId !== '') {
      nextStep();
    }
  };

  const clearSelect = (e) => {
    e.preventDefault();
    onProgramSelect('', '', '', '', '', '', '', '', '');
  };

  // function tConvert(time) {
  //   // Check correct time format and split into components
  //   time = time
  //     .toString()
  //     .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

  //   if (time.length > 1) {
  //     // If time format correct
  //     time = time.slice(1); // Remove full string match value
  //     time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
  //     time[0] = +time[0] % 12 || 12; // Adjust hours
  //   }
  //   return time.join(''); // return adjusted time or original string
  // }

  return (
    <ThemeProvider theme={theme}>
      <Accordion key={exam._id}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon className={classes.iconColor} />}
          className={
            examId === exam._id
              ? classes.headerColorSelected
              : classes.headerColor
          }
        >
          <Typography className={classes.heading}>
            {exam.title} - {exam.program.programType.toUpperCase()}{' '}
          </Typography>
        </AccordionSummary>{' '}
        <br />
        <AccordionDetails>
          <a href={`${exam.program.programUrl}`} target="_blank">
            <span style={{ textDecoration: 'underline' }}>
              {' '}
              Read Program Details
            </span>{' '}
            <i className="fa-solid fa-arrow-up-right-from-square"></i>
          </a>
        </AccordionDetails>
        <div className={classes.chipContainer} style={{ padding: '10px' }}>
          <Chip
            label={`Date: ${format(new Date(exam.examDate), 'PPp')} `}
            color="primary"
            style={{ color: '#FFF', margin: '5px' }}
          />
          <Chip
            label={`Course: ${exam.program.programName}`}
            color="primary"
            style={{ color: '#FFF', margin: '5px' }}
          />
          <Chip
            label={`Department: ${exam.program.department.departmentName}`}
            color="primary"
            style={{ color: '#FFF', margin: '5px' }}
          />
          <Chip
            label={`Trimester: ${exam.trimester}`}
            color="primary"
            style={{ color: '#FFF', margin: '5px' }}
          />
          <Chip
            label={`Year: ${exam.year}`}
            color="primary"
            style={{ color: '#FFF', margin: '5px' }}
          />
        </div>
        <AccordionActions>
          <Fragment>
            {examId === '' ? (
              <Button
                variant="contained"
                color="primary"
                disableElevation
                className={classes.btn}
                onClick={(e) => onClick(e, exam._id, exam.title)}
                size="small"
              >
                Select &nbsp; <i className="fas fa-check"></i>
              </Button>
            ) : examId !== exam._id ? (
              <Button
                variant="contained"
                color="primary"
                disableElevation
                className={classes.btn}
                onClick={(e) => onClick(e, exam._id, exam.title)}
                size="small"
              >
                Select &nbsp; <i className="fas fa-check"></i>
              </Button>
            ) : null}

            {examId === exam._id ? (
              <Fragment>
                <small>Are you sure?</small> &nbsp;
                <Button
                  variant="contained"
                  color="secondary"
                  disableElevation
                  className={classes.btn}
                  onClick={(e) => clearSelect(e)}
                  size="small"
                >
                  Cancel &nbsp; <i className="fas fa-times"></i>
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  disableElevation
                  className={classes.btn}
                  onClick={(e) => onSelect(e)}
                  size="small"
                >
                  Next &nbsp; <i className="fas fa-arrow-right"></i>
                </Button>
              </Fragment>
            ) : null}
          </Fragment>
        </AccordionActions>
      </Accordion>
    </ThemeProvider>
  );
};

ProgramItem.propTypes = {
  exam: PropTypes.object.isRequired,

  onProgramSelect: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
};

export default ProgramItem;
