export const EXAM_LIST_REQUEST = 'EXAM_LIST_REQUEST';
export const EXAM_LIST_SUCCESS = 'EXAM_LIST_SUCCESS';
export const EXAM_LIST_FAIL = 'EXAM_LIST_FAIL';
export const EXAM_LIST_REMOVE_ITEM = 'EXAM_LIST_REMOVE_ITEM';

export const EXAM_STUDENT_LIST_REQUEST = 'EXAM_STUDENT_LIST_REQUEST';
export const EXAM_STUDENT_LIST_SUCCESS = 'EXAM_STUDENT_LIST_SUCCESS';
export const EXAM_STUDENT_LIST_FAIL = 'EXAM_STUDENT_LIST_FAIL';
export const EXAM_STUDENT_LIST_REMOVE_ITEM = 'EXAM_STUDENT_LIST_REMOVE_ITEM';

export const EXAM_DETAILS_REQUEST = 'EXAM_DETAILS_REQUEST';
export const EXAM_DETAILS_SUCCESS = 'EXAM_DETAILS_SUCCESS';
export const EXAM_DETAILS_FAIL = 'EXAM_DETAILS_FAIL';

export const EXAM_DELETE_REQUEST = 'EXAM_DELETE_REQUEST';
export const EXAM_DELETE_SUCCESS = 'EXAM_DELETE_SUCCESS';
export const EXAM_DELETE_FAIL = 'EXAM_DELETE_FAIL';
export const EXAM_DELETE_RESET = 'EXAM_DELETE_RESET';

export const EXAM_CREATE_REQUEST = 'EXAM_CREATE_REQUEST';
export const EXAM_CREATE_SUCCESS = 'EXAM_CREATE_SUCCESS';
export const EXAM_CREATE_FAIL = 'EXAM_CREATE_FAIL';
export const EXAM_CREATE_RESET = 'EXAM_CREATE_RESET';

export const EXAM_UPDATE_REQUEST = 'EXAM_UPDATE_REQUEST';
export const EXAM_UPDATE_SUCCESS = 'EXAM_UPDATE_SUCCESS';
export const EXAM_UPDATE_FAIL = 'EXAM_UPDATE_FAIL';
export const EXAM_UPDATE_RESET = 'EXAM_UPDATE_RESET';
