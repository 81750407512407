import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from '../../layout/Spinner';
import Divider from '@material-ui/core/Divider';
import {
  searchProfile,
  getSingleStudentsProfile,
} from '../../../actions/profile';
import { Link } from 'react-router-dom';
// import AlertComponent from '../layout/AlertComponent';
// import DownloadXlsxMultiple from './utils/DownloadXlsxMultiple';

import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#00acee',
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    // '&:nth-of-type(odd)': {
    //   backgroundColor: theme.palette.action.hover,
    // },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
  // addMarks: {
  //   cursor: 'pointer',
  //   fontWeight: 'bold',
  //   color: '#00acee',
  //   backgroundColor: '#e6f7fd',
  // },
});

const PendingPayments = ({
  auth: { loading, isAuthenticated },
  profiles,
  searchProfile,
}) => {
  const [formData, setFormData] = useState({
    paymentStatus: 'PENDING',
  });

  useEffect(() => {
    searchProfile(formData);
    // eslint-disable-next-line
  }, [searchProfile]);

  // const pendingProfiles = profiles.filter(
  //   (profile) => profile.user.paymentInfo.status === 'PENDING'
  // );

  const classes = useStyles();

  return loading ? (
    <Fragment>
      <div className="container">
        <Spinner />
      </div>
    </Fragment>
  ) : (
    <Fragment>
      <div className="container">
        <h1 className="large text-primary">Students</h1>
        <Divider /> <br />
        <p className="lead">
          View & Edit Exams <br />
        </p>
        <small>To search by Applicant ID please toggle the switch below.</small>
      </div>
      <div className="table-container">
        <TableContainer component={Paper}>
          <Table
            className={classes.table}
            // aria-label="customized table"
            // size="small"
          >
            <TableHead>
              <TableRow>
                <StyledTableCell align="right">Applicant ID</StyledTableCell>
                <StyledTableCell align="left">Name</StyledTableCell>
                <StyledTableCell align="left">Phone</StyledTableCell>
                <StyledTableCell align="left">Course</StyledTableCell>
                <StyledTableCell align="left">Trimester</StyledTableCell>
                <StyledTableCell align="right">Year</StyledTableCell>
                <StyledTableCell align="right">Batch</StyledTableCell>
                <StyledTableCell align="left">Paid From</StyledTableCell>
                <StyledTableCell align="left">Trx ID</StyledTableCell>
                <StyledTableCell align="center">View Details</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {profiles && profiles.length > 0 ? (
                profiles.map((profile) => (
                  <StyledTableRow key={profile._id} hover>
                    <StyledTableCell align="right">
                      {profile.marks.applicantId}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      {profile.user.name}
                    </StyledTableCell>

                    <StyledTableCell align="right">
                      {profile.user.phone}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {profile.admissionInfo.course}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {profile.admissionInfo.trimester}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {profile.admissionInfo.year}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {profile.admissionInfo.batch}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {profile.user.paymentInfo.sentFrom}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {profile.user.paymentInfo.transactionId}
                    </StyledTableCell>

                    <StyledTableCell
                      align="center"
                      // className={classes.addMarks}
                    >
                      <Link
                        to={`/payment/${profile.user._id}`}
                        className="btn btn-primary"
                      >
                        View
                      </Link>
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              ) : (
                <StyledTableRow>
                  No entries, please check again after a while.
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </Fragment>
  );
};

PendingPayments.propTypes = {
  auth: PropTypes.object.isRequired,
  profiles: PropTypes.array.isRequired,
  searchProfile: PropTypes.func.isRequired,
  getSingleStudentsProfile: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  exam: state.exam.exam,
  profiles: state.profile.profiles,
});

export default connect(mapStateToProps, {
  searchProfile,
  getSingleStudentsProfile,
})(PendingPayments);
