import { combineReducers } from 'redux';
import alert from './alert';
import auth from './auth';
import profile from './profile';
import exam from './exam';
import notice from './notice';
import {
  noticeListReducer,
  noticeCreateReducer,
  noticeUpdateReducer,
  noticeDetailsReducer,
  noticeDeleteReducer,
} from './noticesReducer';
import {
  userListReducer,
  userDetailsReducer,
  userCreateReducer,
  userUpdateReducer,
  userDeleteReducer,
  userRegisterStep1Reducer,
  userVerifyPhoneReducer,
  userResetStep1Reducer,
  userResetStep2Reducer,
} from './userReducers';
import {
  examDetailsReducer,
  examListReducer,
  examUpdateReducer,
  examDeleteReducer,
  examStudentListReducer,
} from './examReducers';
import {
  departmentDetailsReducer,
  departmentListReducer,
  departmentCreateReducer,
  departmentUpdateReducer,
  departmentDeleteReducer,
} from './departmentReducers';
import { applicantListReducer } from './applicantReducers';
import {
  programDetailsReducer,
  programListReducer,
  programCreateReducer,
  programUpdateReducer,
  programDeleteReducer,
} from './programReducers';
import { admitAdminReducer } from './admitReducers';
import {
  profilePDFMultiReducer,
  profilePDFReducer,
} from './profilePDFReducers';
import {
  profileCreateReducer,
  profileAddMarkReducer,
  profileUpProPicReducer,
  profileSignatureReducer,
} from './profileReducers';
import { mailSendReducer } from './mailReducers';
import { smsMultiSendReducer, smsSendReducer } from './smsReducers';

export default combineReducers({
  alert,
  auth,
  profile,
  applicantList: applicantListReducer,
  profileCreate: profileCreateReducer,
  profileAddMark: profileAddMarkReducer,
  profileUpProPic: profileUpProPicReducer,
  profileSignature: profileSignatureReducer,
  exam,
  examList: examListReducer,
  examStudentList: examStudentListReducer,
  examDetails: examDetailsReducer,
  examUpdate: examUpdateReducer,
  examDelete: examDeleteReducer,
  departmentList: departmentListReducer,
  departmentCreate: departmentCreateReducer,
  departmentUpdate: departmentUpdateReducer,
  departmentDetails: departmentDetailsReducer,
  departmentDelete: departmentDeleteReducer,
  programList: programListReducer,
  programCreate: programCreateReducer,
  programUpdate: programUpdateReducer,
  programDetails: programDetailsReducer,
  programDelete: programDeleteReducer,
  notice,
  noticeList: noticeListReducer,
  noticeDetails: noticeDetailsReducer,
  noticeCreate: noticeCreateReducer,
  noticeUpdate: noticeUpdateReducer,
  noticeDelete: noticeDeleteReducer,
  userList: userListReducer,
  userDetails: userDetailsReducer,
  userCreate: userCreateReducer,
  userUpdate: userUpdateReducer,
  userDelete: userDeleteReducer,

  userRegisterStep1: userRegisterStep1Reducer,
  userVerifyPhone: userVerifyPhoneReducer,

  userResetStep1: userResetStep1Reducer,
  userResetStep2: userResetStep2Reducer,

  admitAdminDetails: admitAdminReducer,
  profilePDFDetails: profilePDFReducer,
  profilePDFMulti: profilePDFMultiReducer,

  mailSend: mailSendReducer,
  smsSend: smsSendReducer,
  smsMultiSend: smsMultiSendReducer,
});
