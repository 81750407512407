import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import Divider from '@material-ui/core/Divider';
import { addNotice } from '../../actions/notice';
import AlertComponent from '../layout/AlertComponent';
import Quill from 'quill';
import 'quill/dist/quill.snow.css';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {
  Button,
  createMuiTheme,
  Grid,
  makeStyles,
  TextField,
  ThemeProvider,
} from '@material-ui/core';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#00acee',
    },
  },
});

const useStyles = makeStyles((theme) => ({
  btn: {
    color: '#FFF',
    marginRight: '10px',
  },
  btnSecondary: {
    color: '#000',
    marginRight: '10px',
  },
  textField: {
    width: '100%',
  },
  my20: {
    marginBottom: '20px',
  },
}));

const CreateNotice = ({ addNotice, history, isAuthenticated, role }) => {
  const [formData, setFormData] = useState({
    title: '',
    bodyText: '',
  });
  const { title, bodyText } = formData;

  const noticeCreate = useSelector((state) => state.noticeCreate);
  const { loading, success, notice, error } = noticeCreate;

  useEffect(() => {
    if (success) {
      history.push('/notices');
    }
  }, [success, history]);

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();
    addNotice(formData, history);
  };

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
      ],
      ['link', 'image'],
      ['clean'],
    ],
  };

  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
  ];

  const handleChange = (value) => {
    setFormData({ ...formData, bodyText: value });
  };

  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <div className="container">
        <h1 className="large text-primary">Create New Notice</h1>
        <p className="lead">
          <i className="far fa-newspaper"></i> &nbsp; Please fill up the form
          and create a new notice.
        </p>
        <Divider /> <br />
        <form onSubmit={(e) => onSubmit(e)}>
          <Grid>
            <Grid item xs={12} className={classes.my20}>
              <TextField
                // error={phoneError}
                type="text"
                name="title"
                label="Notice Title"
                value={title}
                helperText={'Main title of the notice'}
                variant="outlined"
                onChange={(e) => onChange(e)}
                className={classes.textField}
              ></TextField>
            </Grid>
            <Grid item xs={12} className={classes.my20}>
              <ReactQuill
                theme="snow"
                value={bodyText}
                onChange={handleChange}
                modules={modules}
                formats={formats}
              />
              <small className="form-text">Notice Description.</small>
            </Grid>
          </Grid>

          <AlertComponent />
          <div className="form-group">
            {/* <input type="submit" className="btn btn-primary" value="Submit" /> */}
            <Link to={`/notices`}>
              <Button
                variant="contained"
                color="default"
                disableElevation
                className={classes.btnSecondary}
              >
                <i className="fas fa-arrow-left"></i> &nbsp; Back
              </Button>
            </Link>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disableElevation
              className={classes.btn}
            >
              Submit &nbsp;{' '}
              {loading ? (
                <i className="fas fa-circle-notch fa-spin"></i>
              ) : (
                <i className="fas fa-check"></i>
              )}
            </Button>
          </div>
        </form>
      </div>
    </ThemeProvider>
  );
};

CreateNotice.propTypes = {
  addNotice: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  role: PropTypes.string,
};

const mapStateToProps = (state) => ({
  notice: state.notice,
  isAuthenticated: state.auth.isAuthenticated,
  role: state.auth.role,
});

export default connect(mapStateToProps, { addNotice })(
  withRouter(CreateNotice)
);
