import React, { useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch, useSelector } from 'react-redux';
import { listNotices } from '../../actions/notice';
import { getCurrentProfile, downloadAdmit } from '../../actions/profile';
import Spinner from '../layout/Spinner';
import { Link, Redirect } from 'react-router-dom';
import Divider from '@material-ui/core/Divider';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { CreateProfile, Step2Upload, Step3Payment } from './DashboardActions';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepConnector from '@material-ui/core/StepConnector';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
    alignItems: 'center',
    marginBottom: '5px',
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(8),
    height: theme.spacing(8),
  },
}));

const StepBlueConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  active: {
    '& $line': {
      borderColor: '#00acee',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#00acee',
    },
  },
  line: {
    borderColor: '#eaeaf0',
    borderTopWidth: 2,
    borderRadius: 1,
  },
})(StepConnector);

const Dashboard = ({
  downloadAdmit,
  getCurrentProfile,
  auth: { user, role },
  profile: { profile, loading, profileAdmit },
}) => {
  const dispatch = useDispatch();

  const noticeList = useSelector((state) => state.noticeList);
  const { loading: loadingNotices, notices } = noticeList;

  useEffect(() => {
    if (!profile || profile.user._id !== user._id) {
      getCurrentProfile();
    }
    if (notices.length <= 0) {
      dispatch(listNotices());
    }
    // eslint-disable-next-line
  }, []);

  const classes = useStyles();

  // Redirect if logged in as admin
  if (role && role.includes('ADMIN')) {
    return <Redirect to="/admin-dashboard" />;
  }

  return loading && profile === null ? (
    <Fragment>
      <div className="container">
        <Spinner />
      </div>
    </Fragment>
  ) : (
    <Fragment>
      <div className="container">
        {profile ? (
          <div className={classes.root}>
            <Avatar
              alt={user && user.name}
              src={'/uploads/profile-pic/' + profile.profilePic}
              className={classes.large}
            ></Avatar>
            <p className="lead">
              Welcome {user && user.name} <br />
            </p>
          </div>
        ) : null}
        {/* <h1 className="large text-primary">Dashboard</h1> */}
        {!loadingNotices ? (
          <Link to={`/notices/${notices?.[0]?._id}`}>
            <Alert severity="info">{`${notices?.[0]?.title}. Read more...`}</Alert>
          </Link>
        ) : (
          <Alert
            severity="info"
            action={<i className="fas fa-circle-notch fa-spin"></i>}
          >
            Loading...
          </Alert>
        )}
        {profile && user ? (
          <Stepper alternativeLabel connector={<StepBlueConnector />}>
            <Step
              completed={profile ? true : false}
              active={profile ? false : true}
            >
              <StepLabel>Select Program & Create Profile</StepLabel>
            </Step>
            <Step
              completed={profile.profilePic && profile.signature ? true : false}
              active={profile.profilePic && profile.signature ? false : true}
            >
              <StepLabel>Upload Image </StepLabel>
            </Step>
            <Step
              completed={user.paymentInfo.status === 'PAID' ? true : false}
              active={profile.profilePic && profile.signature ? true : false}
            >
              <StepLabel>Make Payment </StepLabel>
            </Step>
            <Step
              completed={false}
              active={user.paymentInfo.status === 'PAID' ? true : false}
            >
              <StepLabel>Download Admit</StepLabel>
            </Step>
          </Stepper>
        ) : (
          <Stepper alternativeLabel connector={<StepBlueConnector />}>
            <Step completed={false} active={true}>
              <StepLabel>Select Program & Create Profile</StepLabel>
            </Step>
            <Step>
              <StepLabel>Upload Image </StepLabel>
            </Step>
            <Step>
              <StepLabel>Make Payment</StepLabel>
            </Step>
            <Step>
              <StepLabel>Download Admit</StepLabel>
            </Step>
          </Stepper>
        )}
        <Divider style={{ marginBottom: '30px' }} />

        {profile !== null ? (
          <Fragment>
            {profile.profilePic === '' || profile.signature === '' ? (
              <Step2Upload />
            ) : null}
            {profile.profilePic && profile.signature ? (
              <Step3Payment />
            ) : (
              <Step2Upload />
            )}
          </Fragment>
        ) : (
          <Fragment>
            <p className="lead mt-20">
              Welcome {user && user.name} <br />
            </p>

            <CreateProfile />
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};

Dashboard.propTypes = {
  downloadAdmit: PropTypes.func.isRequired,

  getCurrentProfile: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  profile: state.profile,
});

export default connect(mapStateToProps, {
  downloadAdmit,
  getCurrentProfile,
})(Dashboard);
