import axios from 'axios';
import * as Sentry from '@sentry/react';
import {
  EXAM_DETAILS_FAIL,
  EXAM_DETAILS_REQUEST,
  EXAM_DETAILS_SUCCESS,
  EXAM_UPDATE_REQUEST,
  EXAM_UPDATE_SUCCESS,
  EXAM_UPDATE_FAIL,
  EXAM_LIST_REQUEST,
  EXAM_LIST_SUCCESS,
  EXAM_LIST_FAIL,
  EXAM_DELETE_REQUEST,
  EXAM_DELETE_SUCCESS,
  EXAM_DELETE_FAIL,
  EXAM_LIST_REMOVE_ITEM,
} from '../constants/examConstants';
import { setAlert } from './alert';

// Get all or search exams
export const listExams =
  (program, trimester, year, courseType, conducted = false) =>
  async (dispatch) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      let newYear = new Date(year);
      newYear = newYear.getFullYear();

      const searchParameters = {
        program,
        trimester,
        newYear,
        courseType,
        conducted,
      };
      dispatch({ type: EXAM_LIST_REQUEST });

      const { data } = await axios.post(
        `/api/exams/get-exams`,
        searchParameters,
        config
      );
      console.log(data);

      dispatch({
        type: EXAM_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      Sentry.captureException(error);
      dispatch({
        type: EXAM_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

// Get single exam
export const getExam = (exam_id) => async (dispatch) => {
  try {
    dispatch({ type: EXAM_DETAILS_REQUEST });
    const { data } = await axios.get(`/api/exams/exam/${exam_id}`);
    //   data.date = format(new Date(data?.date), 'PPPPp');
    dispatch({
      type: EXAM_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    Sentry.captureException(error);
    console.log(error.response);
    dispatch({
      type: EXAM_DETAILS_FAIL,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};

// Update Exam
export const updateExam = (formData, history) => async (dispatch) => {
  try {
    dispatch({ type: EXAM_UPDATE_REQUEST });
    const { data } = await axios.post(`/api/exams/`, formData);
    //   data.date = format(new Date(data?.date), 'PPPPp');
    dispatch({
      type: EXAM_UPDATE_SUCCESS,
      payload: data,
    });
    dispatch({
      type: EXAM_DETAILS_SUCCESS,
      payload: data,
    });
    dispatch(setAlert('Exam Updated', 'success'));
  } catch (error) {
    Sentry.captureException(error);
    console.log(error.response);
    dispatch({
      type: EXAM_UPDATE_FAIL,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};

// Delete a exam
export const deleteExam = (exam_id) => async (dispatch) => {
  try {
    dispatch({ type: EXAM_DELETE_REQUEST });
    const { data } = await axios.delete(`/api/exams/exam/${exam_id}`);
    console.log(data);
    dispatch({ type: EXAM_DELETE_SUCCESS });
    dispatch({
      type: EXAM_LIST_REMOVE_ITEM,
      payload: exam_id,
    });
    dispatch(setAlert('Exam Deleted', 'success'));
  } catch (error) {
    Sentry.captureException(error);
    const errors = error.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
    }
    dispatch({
      type: EXAM_DELETE_FAIL,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};
