import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Divider from '@material-ui/core/Divider';
import AlertComponent from '../../components/layout/AlertComponent';
import { useDispatch, useSelector } from 'react-redux';
import { listDepartments } from '../../actions/departmentActions';

import {
  Grid,
  makeStyles,
  Button,
  TextField,
  createMuiTheme,
  ThemeProvider,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Switch,
} from '@material-ui/core';

import clsx from 'clsx';
import Spinner from '../../components/layout/Spinner';
import { getProgram, updateProgram } from '../../actions/programActions';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#00acee',
    },
    secondary: {
      main: '#a8dda8',
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
    marginTop: theme.spacing(3),
  },
  textField: {
    width: '100%',
  },
  btnStyle: {
    color: '#FFF',
  },
  btn: {
    color: '#FFF',
    marginRight: '10px',
  },
  btnSecondary: {
    // color: '#00acee',
    marginRight: '10px',
  },
  my20: {
    marginBottom: '20px',
  },
}));

const ProgramEditScreen = ({ history, match }) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const programDetails = useSelector((state) => state.programDetails);
  const { loading: loadingDetails, program } = programDetails;

  const programUpdate = useSelector((state) => state.programUpdate);
  const {
    loading: loadingUpdate,
    program: updatedProgram,
    success: successUpdate,
  } = programUpdate;

  const auth = useSelector((state) => state.auth);
  const { loading: authLoading, role } = auth;

  const departmentList = useSelector((state) => state.departmentList);
  const { loading: loadingList, departments, error } = departmentList;

  const [programName, setProgramName] = useState('');
  const [programShortCode, setProgramShortCode] = useState('');
  const [credit, setCredit] = useState('');
  const [programType, setProgramType] = useState('');
  const [department, setDepartment] = useState('');
  const [programUrl, setProgramUrl] = useState('');
  const [description, setDescription] = useState('');
  const [edit, setEdit] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(
      updateProgram(
        {
          programName,
          programShortCode,
          credit,
          programType,
          department,
          programUrl,
          description,
        },
        program._id
      )
    );
  };

  useEffect(() => {
    dispatch(listDepartments());
  }, [listDepartments, dispatch]);

  useEffect(() => {
    if (successUpdate) {
      setTimeout(() => {
        history.push(`/programs`);
      }, 1000);
    } else {
      if (!program || !program.programName || program._id !== match.params.id) {
        dispatch(getProgram(match.params.id));
      } else {
        if (programName.length === 0) {
          setProgramName(program.programName);
          setProgramShortCode(program.programShortCode);
          setCredit(program.credit);
          setProgramType(program.programType);
          setProgramUrl(program.programUrl);
          setDescription(program.description);
          setDepartment(program.department._id);
        }
      }
    }
  }, [dispatch, program, match, successUpdate]);

  return (
    <ThemeProvider theme={theme}>
      <div className="container">
        <h1 className="large text-primary">
          {edit ? 'Editing the Program' : 'Viewing the Program'}
        </h1>

        <Divider style={{ marginBottom: '10px', display: 'block' }} />
        <small>To edit, please toggle the switch</small>
        <FormGroup row style={{ marginBottom: '10px', display: 'block' }}>
          <FormControlLabel
            control={
              <Switch
                checked={edit}
                onChange={() => setEdit(!edit)}
                name="edit"
                color="primary"
              />
            }
            label="Edit"
            style={{ marginBottom: '10px' }}
          />
        </FormGroup>
        <form onSubmit={(e) => onSubmit(e)}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <TextField
                InputProps={{
                  readOnly: !edit,
                }}
                type="text"
                name="programName"
                label="Name of the program*"
                value={loadingDetails ? 'Loading...' : programName}
                helperText={'Enter the name of the program'}
                variant="outlined"
                onChange={(e) => setProgramName(e.target.value)}
                className={clsx(classes.textField)}
              ></TextField>
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                InputProps={{
                  readOnly: !edit,
                }}
                type="text"
                name="programShortCode"
                label="Short Code*"
                value={loadingDetails ? 'Loading...' : programShortCode}
                helperText={'Enter the short code of the program'}
                variant="outlined"
                onChange={(e) => setProgramShortCode(e.target.value)}
                className={clsx(classes.textField)}
              ></TextField>
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                InputProps={{
                  readOnly: !edit,
                }}
                type="number"
                name="credit"
                label="Credit*"
                value={loadingDetails ? 'Loading...' : credit}
                helperText={'Enter the credit of the program'}
                variant="outlined"
                onChange={(e) => setCredit(e.target.value)}
                className={clsx(classes.textField)}
              ></TextField>
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                InputProps={{
                  readOnly: !edit,
                }}
                select
                name="department"
                label="Program Type*"
                value={loadingDetails ? 'Loading...' : programType}
                helperText={'Select Program Type'}
                variant="outlined"
                onChange={(e) => setProgramType(e.target.value)}
                className={clsx(classes.textField)}
              >
                <MenuItem value={''}>* Program Type</MenuItem>
                <MenuItem value={'undergraduate'}>Undergraduate</MenuItem>
                <MenuItem value={'graduate'}>Graduate</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                InputProps={{
                  readOnly: !edit,
                }}
                select
                name="department"
                label="Department*"
                value={loadingDetails ? '' : department}
                helperText={'Select Department'}
                variant="outlined"
                onChange={(e) => setDepartment(e.target.value)}
                className={clsx(classes.textField)}
              >
                <MenuItem value={''}>* Select Department</MenuItem>
                {departments.map((department) => (
                  <MenuItem value={department._id} key={department._id}>
                    {department.departmentName}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                InputProps={{
                  readOnly: !edit,
                }}
                type="text"
                name="programUrl"
                label="Link"
                value={loadingDetails ? 'Loading...' : programUrl}
                helperText={'Enter NDUB website link of the program'}
                variant="outlined"
                onChange={(e) => setProgramUrl(e.target.value)}
                className={clsx(classes.textField)}
              ></TextField>
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                InputProps={{
                  readOnly: !edit,
                }}
                type="text"
                multiline
                rows={5}
                name="description"
                label="Description"
                value={loadingDetails ? 'Loading...' : description}
                helperText={'Enter the description of the program'}
                variant="outlined"
                onChange={(e) => setDescription(e.target.value)}
                className={clsx(classes.textField)}
              ></TextField>
            </Grid>

            <Grid item xs={12}>
              <AlertComponent />
              {!authLoading && (
                <Link to={`/programs`}>
                  <Button
                    variant="contained"
                    color="default"
                    disableElevation
                    className={classes.btnSecondary}
                  >
                    <i className="fas fa-arrow-left"></i> &nbsp; Back
                  </Button>
                </Link>
              )}

              {!authLoading && role.includes('ADMIN') && (
                <Button
                  variant="contained"
                  color="primary"
                  disableElevation
                  className={classes.btn}
                  type="submit"
                >
                  Update
                  {loadingUpdate ? (
                    <>
                      &nbsp;<i className="fas fa-circle-notch fa-spin"></i>
                    </>
                  ) : (
                    <>
                      &nbsp;<i className="fas fa-check"></i>
                    </>
                  )}
                </Button>
              )}
            </Grid>
          </Grid>
        </form>
      </div>
    </ThemeProvider>
  );
};

export default ProgramEditScreen;
