import React from 'react';
import PropTypes from 'prop-types';
import xlsx from 'json-as-xlsx';
import { Button } from '@material-ui/core';

const DownloadXlsx = ({
  profile: {
    personalDetails: { religion, gender, dateOfBirth, nationality },
    guardiansInfo: { fathersName, fathersPhone, mothersName, mothersPhone },
    user: { name, phone, email },
    admissionInfo: { course },
    marks: {
      applicantId,
      examId: { batch, year, trimester, program },
      otherPrograms,
    },
    ssc: { sscInstitutionName, sscGroup, sscPassingYear, sscGpa, sscSubjects },
    hsc: { hscInstitutionName, hscGroup, hscPassingYear, hscGpa, hscSubjects },
    gradDegree: {
      gradDegreeType,
      gradDegreeInstitutionName,
      gradDegreeCourseName,
      gradDegreeMajor,
      gradDegreeAcademicYearFrom,
      gradDegreeAcademicYearTo,
      gradDegreeCGpa,
    } = {},
    presentAddress: { aptNumber: presentAddressString },
    permanentAddress: { aptNumber: permanentAddressString },
    workExperience,
  },
}) => {
  //   json-as-xlsx start
  if (religion) {
    if (gradDegreeType) {
      var columnsGrad = [
        {
          label: 'Applicant ID',
          value: 'applicantId',
        },
        {
          label: 'Applied to',
          value: 'course',
        },
        {
          label: 'Trimester',
          value: 'trimester',
        },
        {
          label: 'Year',
          value: 'year',
        },
        {
          label: 'Batch',
          value: 'batch',
        },
        {
          label: 'Name',
          value: 'name',
        },

        {
          label: 'Religion',
          value: 'religion',
        },
        {
          label: 'Gender',
          value: 'gender',
        },
        {
          label: 'Date of Birth',
          value: 'dateOfBirth',
        },
        {
          label: 'Phone',
          value: 'phone',
        },
        {
          label: 'Nationality',
          value: 'nationality',
        },
        {
          label: 'Email',
          value: 'email',
        },
        {
          label: 'Present Address',
          value: 'presentAddressString',
        },
        {
          label: 'Permanent Address',
          value: 'permanentAddressString',
        },
        {
          label: "Father's Name",
          value: 'fathersName',
        },
        {
          label: "Father's Phone",
          value: 'fathersPhone',
        },
        {
          label: "Mother's Name",
          value: 'mothersName',
        },
        {
          label: "Mother's Phone",
          value: 'mothersPhone',
        },
        {
          label: 'SSC Institution',
          value: 'sscInstitutionName',
        },
        {
          label: 'SSC Group',
          value: 'sscGroup',
        },
        {
          label: 'SSC Year',
          value: 'sscPassingYear',
        },
        {
          label: 'SSC Subjects',
          value: 'sscSubjects',
        },
        {
          label: 'SSC GPA',
          value: 'sscGpa',
        },
        {
          label: 'HSC Institution',
          value: 'hscInstitutionName',
        },
        {
          label: 'HSC Group',
          value: 'hscGroup',
        },
        {
          label: 'HSC Year',
          value: 'hscPassingYear',
        },
        {
          label: 'HSC Subjects',
          value: 'hscSubjects',
        },
        {
          label: 'HSC GPA',
          value: 'hscGpa',
        },
        {
          label: 'Graduate Institution',
          value: 'gradDegreeInstitutionName',
        },
        {
          label: 'Degree Type',
          value: 'gradDegreeType',
        },
        {
          label: 'Course Name',
          value: 'gradDegreeCourseName',
        },
        {
          label: 'Major',
          value: 'gradDegreeMajor',
        },
        {
          label: 'Year Start',
          value: 'gradDegreeAcademicYearFrom',
        },
        {
          label: 'Year End',
          value: 'gradDegreeAcademicYearTo',
        },
        {
          label: 'CGPA',
          value: 'gradDegreeCGpa',
        },
        {
          label: 'Experience',
          value: 'workExperience',
        },
        {
          label: 'Other Programs',
          value: 'otherPrograms',
        },
      ];
      var contentGrad = [
        {
          applicantId,
          courseGrad: program?.programName,
          trimester,
          year,
          batch,
          name,
          course,
          religion,
          gender,
          dateOfBirth,
          phone,
          nationality,
          email,
          presentAddressString,
          permanentAddressString,
          fathersName,
          fathersPhone,
          mothersName,
          mothersPhone,
          sscInstitutionName,
          sscGroup,
          sscPassingYear,
          sscSubjects,
          sscGpa,
          hscInstitutionName,
          hscGroup,
          hscPassingYear,
          hscSubjects,
          hscGpa,
          gradDegreeInstitutionName,
          gradDegreeType,
          gradDegreeCourseName,
          gradDegreeMajor,
          gradDegreeAcademicYearFrom,
          gradDegreeAcademicYearTo,
          gradDegreeCGpa,
          workExperience: JSON.stringify(workExperience),
          otherPrograms: JSON.stringify(otherPrograms),
        },
      ];
    } else {
      var columnsUnderGrad = [
        {
          label: 'Applicant ID',
          value: 'applicantId',
        },
        {
          label: 'Applied to',
          value: 'course',
        },
        {
          label: 'Trimester',
          value: 'trimester',
        },
        {
          label: 'Year',
          value: 'year',
        },
        {
          label: 'Batch',
          value: 'batch',
        },
        {
          label: 'Name',
          value: 'name',
        },

        {
          label: 'Religion',
          value: 'religion',
        },
        {
          label: 'Gender',
          value: 'gender',
        },
        {
          label: 'Date of Birth',
          value: 'dateOfBirth',
        },
        {
          label: 'Phone',
          value: 'phone',
        },
        {
          label: 'Nationality',
          value: 'nationality',
        },
        {
          label: 'Email',
          value: 'email',
        },
        {
          label: 'Present Address',
          value: 'presentAddressString',
        },
        {
          label: 'Permanent Address',
          value: 'permanentAddressString',
        },
        {
          label: "Father's Name",
          value: 'fathersName',
        },
        {
          label: "Father's Phone",
          value: 'fathersPhone',
        },
        {
          label: "Mother's Name",
          value: 'mothersName',
        },
        {
          label: "Mother's Phone",
          value: 'mothersPhone',
        },
        {
          label: 'SSC Institution',
          value: 'sscInstitutionName',
        },
        {
          label: 'SSC Group',
          value: 'sscGroup',
        },
        {
          label: 'SSC Year',
          value: 'sscPassingYear',
        },
        {
          label: 'SSC Subjects',
          value: 'sscSubjects',
        },
        {
          label: 'SSC GPA',
          value: 'sscGpa',
        },
        {
          label: 'HSC Institution',
          value: 'hscInstitutionName',
        },
        {
          label: 'HSC Group',
          value: 'hscGroup',
        },
        {
          label: 'HSC Year',
          value: 'hscPassingYear',
        },
        {
          label: 'HSC Subjects',
          value: 'hscSubjects',
        },
        {
          label: 'HSC GPA',
          value: 'hscGpa',
        },
        {
          label: 'Other Programs',
          value: 'otherPrograms',
        },
      ];
      var contentUnderGrad = [
        {
          applicantId,
          courseUnderGrad: program?.programName,
          trimester,
          year,
          batch,
          name,
          course,
          religion,
          gender,
          dateOfBirth,
          phone,
          nationality,
          email,
          presentAddressString,
          permanentAddressString,
          fathersName,
          fathersPhone,
          mothersName,
          mothersPhone,
          sscInstitutionName,
          sscGroup,
          sscPassingYear,
          sscSubjects,
          sscGpa,
          hscInstitutionName,
          hscGroup,
          hscPassingYear,
          hscSubjects,
          hscGpa,
          otherPrograms: JSON.stringify(otherPrograms),
        },
      ];
    }
  }

  var settings = {
    sheetName: name, // The name of the sheet
    fileName: `${name} - ${applicantId}`, // The name of the spreadsheet
    extraLength: 3, // A bigger number means that columns should be wider
    writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
  };

  var download = true; // If true will download the xlsx file, otherwise will return a buffer

  const onDownload = (e) => {
    e.preventDefault();
    if (gradDegreeType) {
      xlsx(columnsGrad, contentGrad, settings, download); // Will download the excel file
    } else xlsx(columnsUnderGrad, contentUnderGrad, settings, download);
  };

  // json-as-xlsx end

  return (
    <Button
      variant="contained"
      color="primary"
      size="small"
      disableElevation
      type="submit"
      style={{ color: '#FFF', marginRight: '10px' }}
      onClick={(e) => onDownload(e)}
    >
      Excel &nbsp; <i className="fas fa-download"></i>
    </Button>
    // <button className="btn btn-primary" onClick={(e) => onDownload(e)}>
    //   <i className="fas fa-download"></i> &nbsp; Download XLSX
    // </button>
  );
};

DownloadXlsx.propTypes = {
  profile: PropTypes.object.isRequired,
};

export default DownloadXlsx;
