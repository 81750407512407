import {
  Button,
  Card,
  CardContent,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { registerUserStep1 } from '../../actions/userActions';
import AlertComponent from '../../components/layout/AlertComponent';
import { checkEmail } from '../../utils/checkEmail';
import { Link, Redirect } from 'react-router-dom';
import { useQuery } from '../../utils/useQuery';
import {
  USER_REGISTER_STEP1_RESET,
  USER_VERIFY_PHONE_RESET,
} from '../../constants/userConstants';
import Logo from '../../img/logo.png';
// import AlertComponent from '../layout/AlertComponent';

const RegisterScreenStep1 = ({ history }) => {
  const dispatch = useDispatch();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState('password');

  const userRegisterStep1 = useSelector((state) => state.userRegisterStep1);
  const { loading: loadingUserRegisterStep1, _id, token } = userRegisterStep1;

  useEffect(() => {
    if (token) {
      history.push(`/register/verify?token=${token}&phone=${phone}`);
      dispatch({ type: USER_REGISTER_STEP1_RESET });
      dispatch({ type: USER_VERIFY_PHONE_RESET });
    }
  }, [token]);

  // registerUserStep1
  const onSubmit = (e) => {
    e.preventDefault();
    if (!checkEmail(email)) {
      let registerEmail = document.getElementById('registerEmail');
      registerEmail.focus();
      window.alert('Please provide a valid email address');
    } else if (name.length === 0) {
      let registerName = document.getElementById('registerName');
      registerName.focus();
      window.alert('Please type in your full name');
    } else if (phone.length === 0) {
      let registerPhone = document.getElementById('registerPhone');
      registerPhone.focus();
      window.alert('Please type in your phone number');
    } else if (password.length < 8) {
      let registerPassword = document.getElementById('registerPassword');
      registerPassword.focus();
      window.alert('Passwords must be at least 8 characters or more');
    } else if (password !== confirmPassword) {
      let registerConfirmPassword = document.getElementById(
        'registerConfirmPassword'
      );
      registerConfirmPassword.focus();
      window.alert('Passwords do not match');
    } else
      dispatch(
        registerUserStep1(
          name.toUpperCase().trim(),
          email,
          phone,
          password,
          confirmPassword
        )
      );
  };

  return (
    <div className="container">
      <Grid
        container
        alignItems="center"
        justify="center"
        style={{ minHeight: '90vh' }}
      >
        <Grid item xs={12} sm={6} md={6}>
          <Card variant="outlined">
            <CardContent>
              <form onSubmit={(e) => onSubmit(e)}>
                <Grid container spacing={2}>
                  <Grid item xs={12} align="center">
                    <img src={Logo} className="logo-100" alt="Logo" />
                    <Typography variant="h3">Register</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      size="small"
                      type="text"
                      name="name"
                      label="Name"
                      id="registerName"
                      value={name}
                      helperText={'Type in your full name'}
                      variant="outlined"
                      onChange={(e) => setName(e.target.value)}
                      fullWidth
                    ></TextField>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      size="small"
                      type="text"
                      name="email"
                      label="Email"
                      id="registerEmail"
                      value={email}
                      helperText={'Enter your email address'}
                      variant="outlined"
                      onChange={(e) => setEmail(e.target.value.trim())}
                      fullWidth
                    ></TextField>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      size="small"
                      type="number"
                      name="phone"
                      label="Phone"
                      id="registerPhone"
                      value={phone}
                      helperText={
                        'Enter your phone number, a verification code will be sent to it.'
                      }
                      variant="outlined"
                      onChange={(e) => setPhone(e.target.value)}
                      fullWidth
                    ></TextField>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      size="small"
                      type={showPassword}
                      name="password"
                      label="Password"
                      id="registerPassword"
                      value={password}
                      helperText={'Enter password'}
                      variant="outlined"
                      onChange={(e) => setPassword(e.target.value)}
                      fullWidth
                    ></TextField>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      size="small"
                      type={showPassword}
                      name="password"
                      label="Confirm Password"
                      id="registerConfirmPassword"
                      value={confirmPassword}
                      helperText={'Enter password again'}
                      variant="outlined"
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      fullWidth
                    ></TextField>
                    <FormControlLabel
                      control={
                        <Checkbox
                          size="small"
                          color="primary"
                          onClick={(e) =>
                            setShowPassword(
                              showPassword === 'password' ? 'text' : 'password'
                            )
                          }
                        />
                      }
                      label="Show Password"
                      labelPlacement="end"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <AlertComponent />
                    <Button
                      variant="contained"
                      color="primary"
                      disableElevation
                      type="submit"
                    >
                      <span>Register</span>
                      {loadingUserRegisterStep1 ? (
                        <>
                          &nbsp;
                          <i className="fas fa-circle-notch fa-spin loadingIndicator"></i>
                        </>
                      ) : null}
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <small>
                      Already have an account? <Link to="/login">Sign In</Link>{' '}
                      | Forgot password? <Link to="/reset">Reset</Link>
                    </small>
                  </Grid>
                </Grid>
              </form>{' '}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default RegisterScreenStep1;
