import axios from 'axios';
import * as Sentry from '@sentry/react';
import {
  NOTICE_CREATE_FAIL,
  NOTICE_CREATE_REQUEST,
  NOTICE_CREATE_SUCCESS,
  NOTICE_DELETE_FAIL,
  NOTICE_DELETE_REQUEST,
  NOTICE_DELETE_SUCCESS,
  NOTICE_DETAILS_FAIL,
  NOTICE_DETAILS_REQUEST,
  NOTICE_DETAILS_SUCCESS,
  NOTICE_LIST_FAIL,
  NOTICE_LIST_REQUEST,
  NOTICE_LIST_SUCCESS,
  NOTICE_UPDATE_FAIL,
  NOTICE_UPDATE_REQUEST,
  NOTICE_UPDATE_SUCCESS,
} from '../constants/noticeConstants';
import { setAlert } from './alert';

import { GET_NOTICES, GET_NOTICE, NOTICE_ERROR, DELETE_NOTICE } from './types';

import { format } from 'date-fns';

// Get all available notices
// export const getNotices = () => async (dispatch) => {
//   try {
//     const res = await axios.get('/api/notices/');
//     dispatch({
//       type: GET_NOTICES,
//       payload: res.data,
//     });
//   } catch (err) {
//     dispatch({
//       type: NOTICE_ERROR,
//       payload: { msg: err.response.statusText, status: err.response.status },
//     });
//   }
// };

// Get all available notices
export const listNotices =
  (keyword = '', pageNumber = '') =>
  async (dispatch) => {
    try {
      dispatch({ type: NOTICE_LIST_REQUEST });

      const { data } = await axios.get(
        `/api/notices?keyword=${keyword}&pageNumber=${pageNumber}`
      );

      dispatch({
        type: NOTICE_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      Sentry.captureException(error);
      dispatch({
        type: NOTICE_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

// Get single notice
export const getNotice = (notice_id) => async (dispatch) => {
  try {
    dispatch({ type: NOTICE_DETAILS_REQUEST });
    const { data } = await axios.get(`/api/notices/${notice_id}`);
    data.date = format(new Date(data?.date), 'PPPPp');
    dispatch({
      type: NOTICE_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (err) {
    console.log(err.response);
    dispatch({
      type: NOTICE_DETAILS_FAIL,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Add new notice or update notice
export const addNotice = (formData) => async (dispatch) => {
  try {
    dispatch({ type: NOTICE_CREATE_REQUEST });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const res = await axios.post('/api/notices/', formData, config);

    dispatch({
      type: NOTICE_CREATE_SUCCESS,
      payload: res.data,
    });

    dispatch(setAlert('Notice Added', 'success'));
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
    }
    dispatch({
      type: NOTICE_CREATE_FAIL,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Edit notice
export const editNotice = (formData, notice_id) => async (dispatch) => {
  try {
    dispatch({ type: NOTICE_UPDATE_REQUEST });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const res = await axios.put(`/api/notices/${notice_id}`, formData, config);

    dispatch({
      type: NOTICE_UPDATE_SUCCESS,
      payload: res.data,
    });

    dispatch(getNotice(notice_id));

    dispatch(setAlert('Notice Updated', 'success'));
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
    }
    dispatch({
      type: NOTICE_UPDATE_FAIL,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Delete a notice
// export const deleteNotice = (notice_id) => async (dispatch) => {
//   try {
//     dispatch({ type: NOTICE_DELETE_REQUEST });

//     await axios.delete(`/api/notices/${notice_id}`);

//     dispatch({
//       type: NOTICE_DELETE_SUCCESS,
//       payload: notice_id,
//     });

//     dispatch(setAlert('Notice Deleted', 'success'));
//   } catch (err) {
//     const errors = err.response.data.errors;
//     if (errors) {
//       errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
//     }
//     dispatch({
//       type: NOTICE_DELETE_FAIL,
//       payload: { msg: err.response.statusText, status: err.response.status },
//     });
//   }
// };

export const deleteNotice = (_id) => async (dispatch, getState) => {
  try {
    dispatch({ type: NOTICE_DELETE_REQUEST });

    const { data } = await axios.delete(`/api/notices/${_id}`);

    dispatch({
      type: NOTICE_DELETE_SUCCESS,
      payload: data,
    });
    dispatch(setAlert('Notice Deleted'));
  } catch (error) {
    Sentry.captureException(error);
    const errors = error.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
    }
    dispatch({
      type: NOTICE_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
