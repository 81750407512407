import axios from 'axios';
import { setAlert } from './alert';
import * as Sentry from '@sentry/react';
import { getExamStudents } from './exam';

import {
  GET_PROFILE,
  PROFILE_ERROR,
  SEARCH_PROFILE,
  SINGLE_STUDENT_PROFILE,
  SINGLE_STUDENT_PROFILE_PDF,
  CLEAR_PROFILE_PDF,
  CLEAR_PROFILE,
  ADD_MARKS,
  CLEAR_PROFILE_ADMIT,
  SINGLE_STUDENT_PROFILE_ADMIT,
  SET_LOADING,
} from './types';

// Get current users profile
export const getCurrentProfile = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/profile/me');

    dispatch({
      type: GET_PROFILE,
      payload: res.data,
    });
  } catch (err) {
    Sentry.captureException(err);
    dispatch({
      type: PROFILE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Get current users profile
export const downloadAdmit = (user_id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/admit/download/`);

    dispatch({
      type: SINGLE_STUDENT_PROFILE_ADMIT,
      payload: res.data,
    });
  } catch (err) {
    Sentry.captureException(err);
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
    }
    dispatch({
      type: CLEAR_PROFILE_ADMIT,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};
// Get current download admit admin
export const downloadAdmitAdmin = (user_id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/admit/download/${user_id}`);

    console.log(res);
    // dispatch({
    //   type: GET_PROFILE,
    //   payload: res.data,
    // });
  } catch (err) {
    Sentry.captureException(err);
    console.log(err);
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
    }
    // dispatch({
    //   type: PROFILE_ERROR,
    //   payload: { msg: err.response.statusText, status: err.response.status },
    // });
  }
};

// Create or update profile
export const createProfile =
  (formData, history, edit = false) =>
  async (dispatch) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      const res = await axios.post('/api/profile', formData, config);

      dispatch({
        type: GET_PROFILE,
        payload: res.data,
      });

      dispatch(
        setAlert(edit ? 'Profile Updated' : 'Profile Created', 'success')
      );

      if (!edit) {
        history.push('/dashboard');
      }
    } catch (err) {
      Sentry.captureException(err);
      const errors = err.response.data.errors;

      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
      }
      dispatch({
        type: PROFILE_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status },
      });
    }
  };

// Upload Profile Picture
export const upProPic =
  (file, history, edit = false) =>
  async (dispatch) => {
    try {
      let formData = new FormData();

      formData.append('profilePic', file.profilePic);

      await axios.post('/api/profile/upload/profile-pic', formData);

      dispatch(
        setAlert('Profile Picture Uploaded. Please wait, the page will reload.')
      );
      setTimeout(function () {
        window.location.reload();
      }, 3000);
    } catch (err) {
      Sentry.captureException(err);
      const errors = err.response.data.errors;

      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
      }

      dispatch({
        type: PROFILE_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status },
      });
    }
  };

// Upload Signature Picture
export const upSignature = (signatureFile) => async (dispatch) => {
  try {
    let formData = new FormData();

    formData.append('signature', signatureFile.signature);

    await axios.post('/api/profile/upload/signature', formData);

    dispatch(
      setAlert('Signature Uploaded. Please wait, the page will reload.')
    );
    setTimeout(function () {
      window.location.reload();
    }, 3000);
  } catch (err) {
    Sentry.captureException(err);
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
    }

    dispatch({
      type: PROFILE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Search Student Profiles and show them
export const searchProfile =
  (formData, selectedDate, paymentDate) => async (dispatch) => {
    dispatch({
      type: CLEAR_PROFILE,
    });
    dispatch({
      type: SET_LOADING,
    });
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      formData.selectedDate = selectedDate;
      formData.paymentCompletionTime = paymentDate;

      console.log(formData);

      const res = await axios.post('/api/profile/students', formData, config);

      dispatch({
        type: SEARCH_PROFILE,
        payload: res.data,
      });

      dispatch(setAlert(`${res.data.length} Students Loaded`, 'success'));
    } catch (err) {
      Sentry.captureException(err);
      const errors = err.response.data.errors;

      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
      }

      dispatch({
        type: PROFILE_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status },
      });
    }
  };

// Get single students profile
export const getSingleStudentsProfile = (user_id) => async (dispatch) => {
  try {
    // dispatch({
    //   type: SINGLE_STUDENT_PROFILE_REQUEST
    // });

    const res = await axios.get(`/api/profile/user/${user_id}`);

    dispatch({
      type: SINGLE_STUDENT_PROFILE,
      payload: res.data,
    });
  } catch (err) {
    Sentry.captureException(err);
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
    }
    dispatch({
      type: CLEAR_PROFILE,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Create or update profile
export const addMarks = (formData, user_id, exam_id) => async (dispatch) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const res = await axios.put(
      `/api/profile/marks/user/${user_id}`,
      formData,
      config
    );

    dispatch({
      type: ADD_MARKS,
      payload: res.data,
    });

    dispatch(getExamStudents(exam_id));

    dispatch(setAlert('Marks Updated', 'success'));
  } catch (err) {
    Sentry.captureException(err);
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
    }
    dispatch({
      type: PROFILE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Calculate Place
export const calculatePlace = (examId) => async (dispatch) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const res = await axios.post('/api/exams/exam/place', examId, config);

    if (res.data === 'No students in this exam') {
      dispatch(setAlert(res.data, 'warning'));
    } else dispatch(setAlert(res.data, 'success'));
  } catch (err) {
    Sentry.captureException(err);
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
    }
    dispatch({
      type: PROFILE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};
