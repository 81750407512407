import axios from 'axios';
import { setAlert } from '../actions/alert';
import {
  ADMIN_PROFILE_PDF_FAIL,
  ADMIN_PROFILE_PDF_MULTI_FAIL,
  ADMIN_PROFILE_PDF_MULTI_REQUEST,
  ADMIN_PROFILE_PDF_MULTI_SUCCESS,
  ADMIN_PROFILE_PDF_REQUEST,
  ADMIN_PROFILE_PDF_SUCCESS,
} from '../constants/pdfConstants';
import * as Sentry from '@sentry/react';

// Get single students profile pdf
export const getSingleStudentsPDF = (user_id) => async (dispatch) => {
  try {
    dispatch({
      type: ADMIN_PROFILE_PDF_REQUEST,
    });
    const res = await axios.get(`/api/student-form/${user_id}`);

    dispatch({
      type: ADMIN_PROFILE_PDF_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    Sentry.captureException(err);
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
    }
    dispatch({
      type: ADMIN_PROFILE_PDF_FAIL,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Get single students profile pdf
export const generateMultiplePDFs =
  (profileIds, trimester, year) => async (dispatch) => {
    try {
      console.log(profileIds);
      dispatch({
        type: ADMIN_PROFILE_PDF_MULTI_REQUEST,
      });
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      const { data } = await axios.post(
        `/api/student-form/multi`,
        { profileIds },
        config
      );

      function download(filename, data) {
        var element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;base64,' + data);
        element.setAttribute('download', filename);

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
      }

      // Start file download.
      download(
        `profile_pdfs_${trimester}_${new Date().toJSON().slice(0, 10)}.zip`,
        data
      );

      dispatch({
        type: ADMIN_PROFILE_PDF_MULTI_SUCCESS,
        payload: data,
      });
    } catch (err) {
      Sentry.captureException(err);
      const errors = err.response.data.errors;
      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
      }
      dispatch({
        type: ADMIN_PROFILE_PDF_MULTI_FAIL,
        payload: { msg: err.response.statusText, status: err.response.status },
      });
    }
  };
