import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Alert from '@material-ui/lab/Alert';
import { Snackbar } from '@material-ui/core';

const AlertComponent = ({ alerts }) => {
  const [open, setOpen] = React.useState(false);
  const [alertMessages, setAlertMessages] = React.useState('');

  useEffect(() => {
    if (alerts !== null && alerts.length > 0) {
      setOpen(true);
      let tempAlertMessages = '';
      let tempAlertMessagesArray = [];
      alerts.forEach((alert, index) => {
        if (tempAlertMessages.indexOf(alert.msg) === -1) {
          tempAlertMessages = tempAlertMessages + alert.msg + ', ';
          tempAlertMessagesArray.push(alert.msg);
        }
      });
      setAlertMessages(
        tempAlertMessages.substring(0, tempAlertMessages.length - 2)
      );
    }
  }, [alerts]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    alerts !== null &&
    alerts.length > 0 && (
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={alerts[0].alertType}
          sx={{ width: '100%' }}
        >
          {alertMessages}
        </Alert>
      </Snackbar>
    )
  );
};

AlertComponent.propTypes = {
  alerts: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  alerts: state.alert,
});

export default connect(mapStateToProps)(AlertComponent);
