export const APPLICANT_LIST_REQUEST = 'APPLICANT_LIST_REQUEST';
export const APPLICANT_LIST_SUCCESS = 'APPLICANT_LIST_SUCCESS';
export const APPLICANT_LIST_FAIL = 'APPLICANT_LIST_FAIL';
export const APPLICANT_LIST_REMOVE_ITEM = 'APPLICANT_LIST_REMOVE_ITEM';

export const APPLICANT_DETAILS_REQUEST = 'APPLICANT_DETAILS_REQUEST';
export const APPLICANT_DETAILS_SUCCESS = 'APPLICANT_DETAILS_SUCCESS';
export const APPLICANT_DETAILS_FAIL = 'APPLICANT_DETAILS_FAIL';

export const APPLICANT_DELETE_REQUEST = 'APPLICANT_DELETE_REQUEST';
export const APPLICANT_DELETE_SUCCESS = 'APPLICANT_DELETE_SUCCESS';
export const APPLICANT_DELETE_FAIL = 'APPLICANT_DELETE_FAIL';
export const APPLICANT_DELETE_RESET = 'APPLICANT_DELETE_RESET';

export const APPLICANT_CREATE_REQUEST = 'APPLICANT_CREATE_REQUEST';
export const APPLICANT_CREATE_SUCCESS = 'APPLICANT_CREATE_SUCCESS';
export const APPLICANT_CREATE_FAIL = 'APPLICANT_CREATE_FAIL';
export const APPLICANT_CREATE_RESET = 'APPLICANT_CREATE_RESET';

export const APPLICANT_UPDATE_REQUEST = 'APPLICANT_UPDATE_REQUEST';
export const APPLICANT_UPDATE_SUCCESS = 'APPLICANT_UPDATE_SUCCESS';
export const APPLICANT_UPDATE_FAIL = 'APPLICANT_UPDATE_FAIL';
export const APPLICANT_UPDATE_RESET = 'APPLICANT_UPDATE_RESET';
