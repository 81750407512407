import axios from 'axios';
import * as Sentry from '@sentry/react';
import {
  APPLICANT_DETAILS_FAIL,
  APPLICANT_DETAILS_REQUEST,
  APPLICANT_DETAILS_SUCCESS,
  APPLICANT_UPDATE_REQUEST,
  APPLICANT_UPDATE_SUCCESS,
  APPLICANT_UPDATE_FAIL,
  APPLICANT_LIST_REQUEST,
  APPLICANT_LIST_SUCCESS,
  APPLICANT_LIST_FAIL,
  APPLICANT_DELETE_REQUEST,
  APPLICANT_DELETE_SUCCESS,
  APPLICANT_DELETE_FAIL,
  APPLICANT_LIST_REMOVE_ITEM,
  APPLICANT_CREATE_REQUEST,
  APPLICANT_CREATE_FAIL,
  APPLICANT_CREATE_SUCCESS,
} from '../constants/applicantConstants';
import { setAlert } from './alert';

// Get/Search all applicants
export const listApplicants = (formData) => async (dispatch) => {
  try {
    dispatch({ type: APPLICANT_LIST_REQUEST });

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const { data } = await axios.post(
      '/api/profile/students',
      formData,
      config
    );

    dispatch({
      type: APPLICANT_LIST_SUCCESS,
      payload: data,
    });
    dispatch(setAlert(`${data.applicants.length} Applicants Found`, 'success'));
  } catch (error) {
    Sentry.captureException(error);
    Sentry.captureException(error);
    dispatch({
      type: APPLICANT_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// Search Student Profiles and show them
// export const searchProfile =
//   (formData, selectedDate, paymentDate) => async (dispatch) => {
//     dispatch({
//       type: CLEAR_PROFILE,
//     });
//     dispatch({
//       type: SET_LOADING,
//     });
//     try {
//       const config = {
//         headers: {
//           'Content-Type': 'application/json',
//         },
//       };

//       formData.selectedDate = selectedDate;
//       formData.paymentCompletionTime = paymentDate;

//       console.log(formData);

//       const res = await axios.post('/api/profile/students', formData, config);

//       dispatch({
//         type: SEARCH_PROFILE,
//         payload: res.data,
//       });

//       dispatch(setAlert(`${res.data.length} Students Loaded`, 'success'));
//     } catch (error) { Sentry.captureException(error);
//       const errors = error.response.data.errors;

//       if (errors) {
//         errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
//       }

//       dispatch({
//         type: PROFILE_ERROR,
//         payload: { msg: error.response.statusText, status: error.response.status },
//       });
//     }
//   };

// Get single department
export const getDepartment = (APPLICANT_id) => async (dispatch) => {
  try {
    dispatch({ type: APPLICANT_DETAILS_REQUEST });
    const { data } = await axios.get(`/api/departments/${APPLICANT_id}`);
    dispatch({
      type: APPLICANT_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    Sentry.captureException(error);
    dispatch({
      type: APPLICANT_DETAILS_FAIL,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};

// Create Department
export const createDepartment = (formData) => async (dispatch) => {
  try {
    dispatch({ type: APPLICANT_CREATE_REQUEST });
    const { data } = await axios.post(`/api/departments/`, formData);

    dispatch({
      type: APPLICANT_CREATE_SUCCESS,
      payload: data,
    });
    dispatch(setAlert('Department Created', 'success'));
  } catch (error) {
    Sentry.captureException(error);
    const errors = error.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
    }
    dispatch({
      type: APPLICANT_CREATE_FAIL,
      payload: { msg: errors, status: error.response.status },
    });
  }
};

// Update Department
export const updateDepartment =
  (formData, APPLICANT_id) => async (dispatch) => {
    try {
      dispatch({ type: APPLICANT_UPDATE_REQUEST });
      const { data } = await axios.put(
        `/api/departments/${APPLICANT_id}`,
        formData
      );

      dispatch({
        type: APPLICANT_UPDATE_SUCCESS,
        payload: data,
      });
      dispatch({
        type: APPLICANT_DETAILS_SUCCESS,
        payload: data,
      });
      dispatch(setAlert('Department Updated', 'success'));
    } catch (error) {
      Sentry.captureException(error);
      const errors = error.response.data.errors;
      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
      }
      dispatch({
        type: APPLICANT_UPDATE_FAIL,
        payload: { msg: errors, status: error.response.status },
      });
    }
  };

// Delete a department
export const deleteDepartment = (APPLICANT_id) => async (dispatch) => {
  try {
    dispatch({ type: APPLICANT_DELETE_REQUEST });
    const { data } = await axios.delete(`/api/departments/${APPLICANT_id}`);
    dispatch({ type: APPLICANT_DELETE_SUCCESS });
    dispatch({
      type: APPLICANT_LIST_REMOVE_ITEM,
      payload: APPLICANT_id,
    });
    dispatch(setAlert('Department Deleted', 'success'));
  } catch (error) {
    Sentry.captureException(error);
    const errors = error.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
    }
    dispatch({
      type: APPLICANT_DELETE_FAIL,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};
