import axios from 'axios';
import * as Sentry from '@sentry/react';
import {
  EXAM_STUDENT_LIST_FAIL,
  EXAM_STUDENT_LIST_REQUEST,
  EXAM_STUDENT_LIST_SUCCESS,
} from '../constants/examConstants';
import { setAlert } from './alert';

import {
  SEARCH_PROFILE,
  PROFILE_ERROR,
  GET_EXAM,
  EXAM_ERROR,
  ADD_EXAM,
  EDIT_EXAM,
} from './types';

// Get all available exams
export const getAvailableExams = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/exams');

    dispatch({
      type: GET_EXAM,
      payload: res.data,
    });
  } catch (error) {
    Sentry.captureException(error);
    dispatch({
      type: EXAM_ERROR,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};

// Get all students in an exam by exam id
export const getExamStudents = (id) => async (dispatch) => {
  try {
    dispatch({
      type: EXAM_STUDENT_LIST_REQUEST,
    });
    const { data } = await axios.get(`/api/exams/exam/students/${id}`);

    console.log(data);

    dispatch({
      type: EXAM_STUDENT_LIST_SUCCESS,
      payload: data,
    });
    dispatch({
      type: SEARCH_PROFILE,
      payload: data,
    });
  } catch (error) {
    Sentry.captureException(error);
    dispatch({
      type: EXAM_STUDENT_LIST_FAIL,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
    dispatch({
      type: PROFILE_ERROR,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};

// Add new exam
export const addExam =
  (formData, history, edit = false) =>
  async (dispatch) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      const res = await axios.post('/api/exams', formData, config);

      dispatch({
        type: ADD_EXAM,
        payload: res.data,
      });
      dispatch(setAlert(edit ? 'Exam Updated' : 'Exam Added', 'success'));
      setTimeout(() => {
        history.push('/exams');
      }, 3000);
    } catch (error) {
      Sentry.captureException(error);
      const errors = error.response.data.errors;

      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
      }
      dispatch({
        type: EXAM_ERROR,
        payload: {
          msg: error.response.statusText,
          status: error.response.status,
        },
      });
    }
  };

// Delete a exam
export const deleteExam = (exam_id) => async (dispatch) => {
  try {
    await axios.delete(`/api/exams/exam/${exam_id}`);

    dispatch(getAvailableExams());
    dispatch(setAlert('Exam Deleted', 'success'));
  } catch (error) {
    Sentry.captureException(error);
    const errors = error.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
    }
    dispatch({
      type: EXAM_ERROR,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};

// Edit a exam
export const editExam = (exam_id) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_EXAM,
      payload: exam_id,
    });
    dispatch(getAvailableExams());
  } catch (error) {
    Sentry.captureException(error);
    const errors = error.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
    }
    dispatch({
      type: EXAM_ERROR,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};
