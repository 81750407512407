import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const ProfileEducationInfo = ({
  profile: {
    ssc: { sscGroup, sscPassingYear, sscGpa, sscConverted },
    hsc: { hscGroup, hscPassingYear, hscGpa, hscConverted },
    presentAddress,
    permanentAddress,
  },
}) => {
  return (
    <div className="profile-edu bg-white p-2">
      <h2 className="text-primary">Educational Info</h2>
      <div>
        <h3 className="text-dark">SSC</h3>
        <p>
          <strong>Group: </strong>
          {sscGroup}
        </p>
        <p>
          <strong>Passing Year: </strong>
          {sscPassingYear}
        </p>
        <p>
          <strong>GPA: </strong>
          {sscGpa}
        </p>
      </div>
      <div>
        <h3 className="text-dark">HSC</h3>
        <p>
          <strong>Group: </strong>
          {hscGroup}
        </p>
        <p>
          <strong>Passing Year: </strong>
          {hscPassingYear}
        </p>
        <p>
          <strong>GPA: </strong>
          {hscGpa}
        </p>
      </div>

      <h2 className="text-primary">Address</h2>
      <div>
        <h3 className="text-dark">Present Address</h3>

        {Object.entries(presentAddress).length === 1 &&
          presentAddress.aptNumber}
        {Object.entries(presentAddress).length > 1 &&
        presentAddress.addressType === 'VILLAGE' ? (
          <Fragment>
            <p>
              <strong>Village: </strong>
              {presentAddress.village}
            </p>
            <p>
              <strong>Post Office: </strong>
              {presentAddress.postOffice}
            </p>
            <p>
              <strong>Police Station: </strong>
              {presentAddress.policeStation}
            </p>
            <p>
              <strong>District: </strong>
              {presentAddress.district}
            </p>
            <p>
              <strong>Country: </strong>
              {presentAddress.country}
            </p>
          </Fragment>
        ) : Object.entries(presentAddress).length > 1 ? (
          <Fragment>
            <p>
              <strong>Apartment Number: </strong>
              {presentAddress.aptNumber}
            </p>
            <p>
              <strong>Road Name & Number: </strong>
              {presentAddress.roadNameAndNumber}
            </p>
            <p>
              <strong>Area & Postal Code: </strong>
              {presentAddress.areaAndPostalCode}
            </p>
            <p>
              <strong>City: </strong>
              {presentAddress.city}
            </p>
            <p>
              <strong>Country: </strong>
              {presentAddress.country}
            </p>
          </Fragment>
        ) : null}
      </div>

      {Object.entries(permanentAddress).length === 1 && (
        <div>
          <h3 className="text-dark">Permanent Address</h3>
          <p>{permanentAddress.aptNumber}</p>
        </div>
      )}

      {Object.entries(permanentAddress).length > 1 &&
      permanentAddress.isAddressSame ? (
        <div>
          <h3 className="text-dark">
            Permanent Address is same as Present Address
          </h3>
        </div>
      ) : Object.entries(permanentAddress).length > 1 &&
        permanentAddress.addressType === 'VILLAGE' ? (
        <Fragment>
          <div>
            <h3 className="text-dark">Permanent Address</h3>

            <p>
              <strong>Village: </strong>
              {permanentAddress.village}
            </p>
            <p>
              <strong>Post Office: </strong>
              {permanentAddress.postOffice}
            </p>
            <p>
              <strong>Police Station: </strong>
              {permanentAddress.policeStation}
            </p>
            <p>
              <strong>District: </strong>
              {permanentAddress.district}
            </p>
            <p>
              <strong>Country: </strong>
              {permanentAddress.country}
            </p>
          </div>
        </Fragment>
      ) : Object.entries(permanentAddress).length > 1 ? (
        <Fragment>
          <div>
            <h3 className="text-dark">Permanent Address</h3>
            <p>
              <strong>Apartment Number: </strong>
              {permanentAddress.aptNumber}
            </p>
            <p>
              <strong>Road Name & Number: </strong>
              {permanentAddress.roadNameAndNumber}
            </p>
            <p>
              <strong>Area & Postal Code: </strong>
              {permanentAddress.areaAndPostalCode}
            </p>
            <p>
              <strong>City: </strong>
              {permanentAddress.city}
            </p>
            <p>
              <strong>Country: </strong>
              {permanentAddress.country}
            </p>
          </div>
        </Fragment>
      ) : null}
    </div>
  );
};

ProfileEducationInfo.propTypes = {
  profile: PropTypes.object.isRequired,
};

export default ProfileEducationInfo;
