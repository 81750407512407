import React from 'react';
import PropTypes from 'prop-types';
import xlsx from 'json-as-xlsx';
import { format } from 'date-fns';
import { Button, Chip } from '@material-ui/core';

const DownloadXlsxMultiple = ({ profiles, type }) => {
  var columns = [
    {
      label: 'Applicant ID',
      value: 'applicantId',
    },
    {
      label: 'Payment',
      value: 'payment',
    },
    {
      label: 'Transaction ID',
      value: 'trxID',
    },
    {
      label: 'Applied to',
      value: 'program',
    },
    {
      label: 'Trimester',
      value: 'trimester',
    },
    {
      label: 'Year',
      value: 'year',
    },
    {
      label: 'Batch',
      value: 'batch',
    },
    {
      label: 'Name',
      value: 'name',
    },

    {
      label: 'Religion',
      value: 'religion',
    },
    {
      label: 'Gender',
      value: 'gender',
    },
    {
      label: 'Date of Birth',
      value: 'dateOfBirth',
    },
    {
      label: 'Phone',
      value: 'phone',
    },
    {
      label: 'Nationality',
      value: 'nationality',
    },
    {
      label: 'Email',
      value: 'email',
    },
    {
      label: 'Present Address',
      value: 'presentAddressString',
    },
    {
      label: 'Permanent Address',
      value: 'permanentAddressString',
    },
    {
      label: "Father's Name",
      value: 'fathersName',
    },
    {
      label: "Father's Phone",
      value: 'fathersPhone',
    },
    {
      label: "Mother's Name",
      value: 'mothersName',
    },
    {
      label: "Mother's Phone",
      value: 'mothersPhone',
    },
    {
      label: 'SSC Institution',
      value: 'sscInstitutionName',
    },
    {
      label: 'SSC Group',
      value: 'sscGroup',
    },
    {
      label: 'SSC Year',
      value: 'sscPassingYear',
    },
    {
      label: 'SSC Subjects',
      value: 'sscSubjects',
    },
    {
      label: 'SSC GPA',
      value: 'sscGpa',
    },
    {
      label: 'HSC Institution',
      value: 'hscInstitutionName',
    },
    {
      label: 'HSC Group',
      value: 'hscGroup',
    },
    {
      label: 'HSC Year',
      value: 'hscPassingYear',
    },
    {
      label: 'HSC Subjects',
      value: 'hscSubjects',
    },
    {
      label: 'HSC GPA',
      value: 'hscGpa',
    },
    {
      label: 'Graduate Institution',
      value: 'gradDegreeInstitutionName',
    },
    {
      label: 'Degree Type',
      value: 'gradDegreeType',
    },
    {
      label: 'Course Name',
      value: 'gradDegreeCourseName',
    },
    {
      label: 'Major',
      value: 'gradDegreeMajor',
    },
    {
      label: 'Year Start',
      value: 'gradDegreeAcademicYearFrom',
    },
    {
      label: 'Year End',
      value: 'gradDegreeAcademicYearTo',
    },
    {
      label: 'CGPA',
      value: 'gradDegreeCGpa',
    },
    {
      label: 'Experience',
      value: 'workExperience',
    },
    {
      label: 'Other Programs',
      value: 'otherPrograms',
    },
  ];

  var content = profiles.map((profile) =>
    profile.gradDegree
      ? {
          applicantId: profile.marks.applicantId,
          payment: profile.user.paymentInfo.status,
          trxID: profile.user.paymentInfo?.bkashInfo?.trxID,
          program: profile.marks.examId.program.programName,
          trimester: profile.marks.examId.trimester,
          year: profile.marks.examId.year,
          batch: profile.marks.examId.batch,
          name: profile.user.name,
          religion: profile.personalDetails.religion,
          gender: profile.personalDetails.gender,
          dateOfBirth: profile.personalDetails.dateOfBirth,
          phone: profile.user.phone,
          nationality: profile.personalDetails.nationality,
          email: profile.user.email,
          presentAddressString: profile.presentAddress.aptNumber,
          permanentAddressString: profile.permanentAddress.aptNumber,
          fathersName: profile.guardiansInfo.fathersName,
          fathersPhone: profile.guardiansInfo.fathersPhone,
          mothersName: profile.guardiansInfo.mothersName,
          mothersPhone: profile.guardiansInfo.mothersPhone,
          sscInstitutionName: profile.ssc.sscInstitutionName,
          sscGroup: profile.ssc.sscGroup,
          sscPassingYear: profile.ssc.sscPassingYear,
          sscSubjects: JSON.stringify(profile.ssc.sscSubjects),
          sscGpa: profile.ssc.sscGpa.toString(),
          hscInstitutionName: profile.hsc.hscInstitutionName,
          hscGroup: profile.hsc.hscGroup,
          hscPassingYear: profile.hsc.hscPassingYear,
          hscSubjects: profile.hsc.hscSubjects,
          hscGpa: profile.hsc.hscGpa.toString(),
          gradDegreeInstitutionName:
            profile.gradDegree.gradDegreeInstitutionName,
          gradDegreeType: profile.gradDegree.gradDegreeType,
          gradDegreeCourseName: profile.gradDegree.gradDegreeCourseName,
          gradDegreeMajor: profile.gradDegree.gradDegreeMajor,
          gradDegreeAcademicYearFrom:
            profile.gradDegree.gradDegreeAcademicYearFrom.toString(),
          gradDegreeAcademicYearTo:
            profile.gradDegree.gradDegreeAcademicYearTo.toString(),
          gradDegreeCGpa: profile.gradDegree.gradDegreeCGpa.toString(),
          workExperience: JSON.stringify(profile.workExperience),
          otherPrograms: JSON.stringify(profile.marks.otherPrograms),
        }
      : {
          applicantId: profile.marks.applicantId,
          payment: profile.user.paymentInfo?.status,
          trxID: profile.user.paymentInfo?.bkashInfo?.trxID,
          program: profile.marks.examId.program.programName,
          trimester: profile.marks.examId.trimester,
          year: profile.marks.examId.year,
          batch: profile.marks.examId.batch,
          name: profile.user.name,
          religion: profile.personalDetails.religion,
          gender: profile.personalDetails.gender,
          dateOfBirth: profile.personalDetails.dateOfBirth,
          phone: profile.user.phone,
          nationality: profile.personalDetails.nationality,
          email: profile.user.email,
          presentAddressString: profile.presentAddress.aptNumber,
          permanentAddressString: profile.permanentAddress.aptNumber,
          fathersName: profile.guardiansInfo.fathersName,
          fathersPhone: profile.guardiansInfo.fathersPhone,
          mothersName: profile.guardiansInfo.mothersName,
          mothersPhone: profile.guardiansInfo.mothersPhone,
          sscInstitutionName: profile.ssc.sscInstitutionName,
          sscGroup: profile.ssc.sscGroup,
          sscPassingYear: profile.ssc.sscPassingYear,
          sscSubjects: JSON.stringify(profile.ssc.sscSubjects),
          sscGpa: profile.ssc.sscGpa.toString(),
          hscInstitutionName: profile.hsc.hscInstitutionName,
          hscGroup: profile.hsc.hscGroup,
          hscPassingYear: profile.hsc.hscPassingYear,
          hscSubjects: profile.hsc.hscSubjects,
          hscGpa: profile.hsc.hscGpa.toString(),
          otherPrograms: JSON.stringify(profile.marks.otherPrograms),
        }
  );

  var settings = {
    sheetName: `${profiles[0].admissionInfo.trimester} ${profiles[0].admissionInfo.year}`, // The name of the sheet
    fileName: `${profiles[0].admissionInfo.trimester} - ${
      profiles[0].admissionInfo.year
    } - ${format(new Date(), 'PPPPp')}`, // The name of the spreadsheet
    extraLength: 3, // A bigger number means that columns should be wider
    writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
  };

  var download = true; // If true will download the xlsx file, otherwise will return a buffer

  const onDownload = (e) => {
    e.preventDefault();
    xlsx(columns, content, settings, download); // Will download the excel file
  };

  if (type === 'chip') {
    return (
      <Chip
        label={`Excel`}
        onDelete={(e) => onDownload(e)}
        deleteIcon={
          <i
            className="fas fa-download"
            style={{ color: '#FFF', paddingTop: '3px' }}
          ></i>
        }
        color="primary"
        onClick={(e) => onDownload(e)}
      />
    );
  }
  return (
    <Button
      variant="contained"
      color="primary"
      size="small"
      disableElevation
      type="submit"
      style={{ color: '#FFF' }}
      onClick={(e) => onDownload(e)}
    >
      Excel &nbsp; <i className="fas fa-download"></i>
    </Button>
  );
};

DownloadXlsxMultiple.propTypes = {
  profiles: PropTypes.array.isRequired,
};

export default DownloadXlsxMultiple;
