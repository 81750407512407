import React from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';

const ProfileAbout = ({
  profile: {
    personalDetails: {
      religion,
      gender,
      bloodGroup,
      dateOfBirth,
      maritalStatus,
      birthCertificateNumber,
      nidNumber,
    },
    profilePic,
    user: { name, phone, email },
  },
}) => {
  return (
    <div className="profile-about bg-light p-2">
      <h2 className="text-primary">
        {name && name.trim().split(' ')[0]}'s Personal Details
      </h2>
      <div className="skills">
        <div className="p-1">
          <strong>Religion: </strong> {religion}
        </div>
        <div className="p-1">
          <strong>Gender: </strong> {gender}
        </div>
        {bloodGroup ? (
          <div className="p-1">
            <strong>Blood Group:</strong> {bloodGroup}
          </div>
        ) : null}
        <div className="p-1">
          <strong>Birthday: </strong>
          {format(new Date(dateOfBirth), 'PP', {
            timeZone: 'Asia/Dhaka',
          })}
        </div>
        {maritalStatus ? (
          <div className="p-1">
            <strong>Marital Status:</strong> {maritalStatus}
          </div>
        ) : null}

        {birthCertificateNumber ? (
          <div className="p-1">
            <strong>Birth Certificate Number:</strong> {birthCertificateNumber}
          </div>
        ) : null}

        <div className="p-1">
          {' '}
          {nidNumber && (
            <p>
              <strong>NID Number:</strong> {nidNumber}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

ProfileAbout.propTypes = {
  profile: PropTypes.object.isRequired,
};

export default ProfileAbout;
