import React, { useState, useEffect } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useDispatch, useSelector } from 'react-redux';

import { getCurrentProfile } from '../../actions/profile';
import { createProfile } from '../../actions/profileActions';

import Step1Program from './Step1Program';
import Step2Personal from './Step2Personal';
import Step3Address from './Step3Address';
import Step4Family from './Step4Family';
import Step5Academic from './Step5Academic';
import Step6ConfirmSubmit from './Step6ConfirmSubmit';

import { v4 as uuid } from 'uuid';

import { makeStyles, withStyles } from '@material-ui/core/styles';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepConnector from '@material-ui/core/StepConnector';

import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
    alignItems: 'center',
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
}));

const StepBlueConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
    color: '#FFF',
  },
  active: {
    '& $line': {
      borderColor: '#00acee',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#00acee',
    },
  },
  line: {
    borderColor: '#eaeaf0',
    borderTopWidth: 2,
    borderRadius: 1,
  },
})(StepConnector);

const CreateProfile = ({
  profile: { profile },

  history,
  getCurrentProfile,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    getCurrentProfile();

    // eslint-disable-next-line
  }, [getCurrentProfile]);
  const [step, setStep] = useState(1);

  // Proceed to the next step
  const nextStep = () => {
    setStep(step + 1);
  };

  // Go back to previous step
  const prevStep = (e) => {
    e.preventDefault();
    setStep(step - 1);
  };

  const steps = (
    <Stepper alternativeLabel connector={<StepBlueConnector />}>
      <Step
        completed={step > 1 ? true : false}
        active={step === 1 ? true : false}
      >
        <StepLabel>Program</StepLabel>
      </Step>
      <Step
        completed={step > 2 ? true : false}
        active={step === 2 ? true : false}
      >
        <StepLabel>Fill Up Information</StepLabel>
      </Step>
      <Step
        completed={step > 3 ? true : false}
        active={step === 3 ? true : false}
      >
        <StepLabel>Confirm & Submit</StepLabel>
      </Step>
    </Stepper>
  );

  const [formData, setFormData] = useState({
    religion: '',
    gender: '',
    bloodGroup: '',
    dateOfBirth: new Date(
      new Date().setFullYear(new Date().getFullYear() - 18)
    ),

    nationality: '',
    fathersName: '',

    fathersPhone: '',

    mothersName: '',

    mothersPhone: '',

    presentAptNumber: '',

    permanentAptNumber: '',

    sscInstitutionName: '',
    sscGroup: '',
    sscPassingYear: '',
    sscGpa: 0,
    sscSubjects: '',
    hscInstitutionName: '',
    hscGroup: '',
    hscPassingYear: '',
    hscGpa: 0,
    hscSubjects: '',

    gradDegreeType: '',
    gradDegreeInstitutionName: '',
    gradDegreeCourseName: '',
    gradDegreeMajor: '',
    gradDegreeAcademicYearFrom: '',
    gradDegreeAcademicYearTo: '',
    gradDegreeCGpa: '',

    mastersDegreeType: '',
    mastersDegreeInstitutionName: '',
    mastersDegreeCourseName: '',
    mastersDegreeMajor: '',
    mastersDegreeAcademicYearFrom: '',
    mastersDegreeAcademicYearTo: '',
    mastersDegreeCGpa: '',

    workExperience: [
      {
        id: uuid(),
        companyName: '',
        position: '',
        from: '',
        to: '',
      },
    ],

    tillDateExists: '',

    examId: '',
    examTitle: '',
    examDepartment: '',

    examDate: '',
    examCourse: '',
    examCourseType: '',
    examTrimester: '',
    examYear: '',

    otherPrograms: [],
    otherProgramsDetails: [],
    showOtherPrograms: false,
  });

  const {
    workExperience,
    otherPrograms,
    otherProgramsDetails,
    showOtherPrograms,
  } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const setDateOfBirth = (date) => {
    setFormData({ ...formData, dateOfBirth: date });
  };

  const handleChangeWorkExperience = (id, e) => {
    const newWorkExperience = formData.workExperience.map((i) => {
      if (id === i.id) {
        i[e.target.name] = e.target.value;
      }
      return i;
    });

    setFormData({
      ...formData,
      workExperience: newWorkExperience,
      tillDateExists: newWorkExperience.some(
        (workExp) => workExp.to === 'Till Date'
      )
        ? newWorkExperience.find((workExp) => workExp.to === 'Till Date').id
        : '',
    });
  };

  const handleAddWorkExperience = () => {
    setFormData({
      ...formData,
      workExperience: [
        ...workExperience,
        { id: uuid(), companyName: '', position: '', from: '', to: '' },
      ],
      tillDateExists: workExperience.some(
        (workExp) => workExp.to === 'Till Date'
      )
        ? workExperience.find((workExp) => workExp.to === 'Till Date').id
        : '',
    });
  };

  const handleRemoveWorkExperience = (id) => {
    const values = [...workExperience];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setFormData({
      ...formData,
      workExperience: values,
      tillDateExists: values.some((workExp) => workExp.to === 'Till Date')
        ? values.find((workExp) => workExp.to === 'Till Date').id
        : '',
    });
  };

  const onProgramSelect = (
    exam_id,
    title,
    department,

    examDate,
    course,
    courseType,
    trimester,
    year
  ) => {
    setFormData({
      ...formData,
      examId: exam_id,
      examTitle: title,
      examDepartment: department,

      examDate,
      examCourse: course,
      examCourseType: courseType,
      examTrimester: trimester,
      examYear: year,
    });
  };

  const onOtherProgramSelect = (e, exam) => {
    if (
      otherPrograms.find((program) => program === exam._id) &&
      otherProgramsDetails.find((program) => program._id === exam._id)
    ) {
      let filtered = otherPrograms.filter(
        (otherProgram) => otherProgram !== exam._id
      );
      let filtered2 = otherProgramsDetails.filter(
        (otherProgram) => otherProgram._id !== exam._id
      );
      setFormData({
        ...formData,
        otherPrograms: filtered,
        otherProgramsDetails: filtered2,
      });
    } else
      setFormData({
        ...formData,
        otherPrograms: [...otherPrograms, exam._id],
        otherProgramsDetails: [...otherProgramsDetails, exam],
      });
  };

  const handleShowOtherPrograms = (e) => {
    setFormData({
      ...formData,
      otherPrograms: [],
      otherProgramsDetails: [],
      showOtherPrograms: !showOtherPrograms,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(createProfile(formData, history));
  };

  const currentStepMobile = (
    <div>
      <Chip
        label={`STEP ${step} OF 6`}
        color="primary"
        style={{ color: 'white', marginBottom: '10px' }}
      />
    </div>
  );

  // Redirect if already profile exists
  if (profile) {
    return <Redirect to="/dashboard" />;
  }

  switch (step) {
    case 1:
      return (
        <Step1Program
          onChange={onChange}
          formData={formData}
          nextStep={nextStep}
          steps={steps}
          onProgramSelect={onProgramSelect}
          currentStepMobile={currentStepMobile}
        />
      );
    case 2:
      return (
        <Step2Personal
          prevStep={prevStep}
          onChange={onChange}
          setDateOfBirth={setDateOfBirth}
          formData={formData}
          nextStep={nextStep}
          steps={steps}
          currentStepMobile={currentStepMobile}
          handleChangeWorkExperience={handleChangeWorkExperience}
          handleAddWorkExperience={handleAddWorkExperience}
          handleRemoveWorkExperience={handleRemoveWorkExperience}
          onOtherProgramSelect={onOtherProgramSelect}
          handleShowOtherPrograms={handleShowOtherPrograms}
        />
      );
    case 3:
      return (
        <Step6ConfirmSubmit
          prevStep={prevStep}
          onChange={onChange}
          formData={formData}
          onSubmit={onSubmit}
          steps={steps}
          currentStepMobile={currentStepMobile}
        />
      );

    default:
      return <h1>Error</h1>;
  }
};

CreateProfile.propTypes = {
  getCurrentProfile: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  profile: state.profile,
  auth: state.auth,
});

export default connect(mapStateToProps, { getCurrentProfile })(
  withRouter(CreateProfile)
);
