import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { deleteNotice } from '../../actions/notice';
import { format } from 'date-fns';

import { connect, useDispatch, useSelector } from 'react-redux';

import ReactQuill from 'react-quill';
import {
  Button,
  createMuiTheme,
  makeStyles,
  ThemeProvider,
} from '@material-ui/core';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#00acee',
    },
  },
});

const useStyles = makeStyles((theme) => ({
  btn: {
    color: '#FFF',
    marginRight: '10px',
  },
  btnSecondary: {
    color: '#000',
    marginRight: '10px',
  },
  editor: {
    height: '100px',
    marginBottom: '10px',
  },
}));

const NoticeItem = ({ auth, notice: { _id, title, bodyText, date } }) => {
  const dispatch = useDispatch();

  // let noticeDateRaw = new Date(date);
  // let noticeDate = `${noticeDateRaw.getDate()}/${
  //   noticeDateRaw.getMonth() + 1
  // }/${noticeDateRaw.getFullYear()}`;

  const onDelete = (id) => {
    if (window.confirm('Are you sure?')) {
      dispatch(deleteNotice(id));
    }
  };

  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <div className="post bg-white p-1 my-1">
        <div>
          <Link to={`/notices/${_id}`}>
            <h1>{`${title.substr(0, 80)}...`}</h1>
          </Link>
          <p className="post-date">
            Posted on {format(new Date(date), 'PPPPp')}
          </p>
        </div>
        <div>
          <ReactQuill
            theme={null}
            value={`${bodyText.substr(0, 80)}...`}
            readOnly={true}
            className={classes.editor}
          />

          {!auth.loading && (
            <Link to={`/notices/${_id}`}>
              <Button
                variant="contained"
                color="primary"
                disableElevation
                className={classes.btn}
              >
                View &nbsp; <i className="fas fa-arrow-right"></i>
              </Button>
            </Link>
          )}
          {!auth.loading && auth.role.includes('ADMIN') && (
            <Link to={`/notices/edit/${_id}`}>
              <Button
                variant="contained"
                color="default"
                disableElevation
                className={classes.btnSecondary}
              >
                Edit &nbsp; <i className="far fa-edit"></i>
              </Button>
            </Link>
          )}
          {!auth.loading && auth.role.includes('ADMIN') && (
            <Button
              variant="contained"
              color="secondary"
              disableElevation
              onClick={() => onDelete(_id)}
              className={classes.btn}
            >
              Delete &nbsp; <i className="fas fa-trash"></i>
            </Button>
          )}
        </div>
      </div>
    </ThemeProvider>
  );
};

NoticeItem.propTypes = {
  notice: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  deleteNotice: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { deleteNotice })(NoticeItem);
