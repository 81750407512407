import React, { useEffect, useState } from 'react';
import {
  IconButton,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { sendMultiSMS } from '../../actions/smsActions';

export default function SmsMultiPopup({ phones, message, disabled }) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  //   const [phoneNumbers, setPhoneNumbers] = useState('');
  const [smsBody, setSmsBody] = useState('');

  const smsMultiSend = useSelector((state) => state.smsMultiSend);
  const { loading: loadingSms, data } = smsMultiSend;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSms = (phones) => {
    dispatch(sendMultiSMS({ phones, message: smsBody }));
  };

  useEffect(() => {
    // setPhoneNumbers(phones);
    setSmsBody(message);
  }, []);

  return (
    <>
      <Button
        aria-label="sms"
        variant="contained"
        color="primary"
        disableElevation
        size="small"
        style={{ color: 'white', marginRight: '10px' }}
        onClick={handleClickOpen}
        disabled={disabled}
      >
        Send SMS &nbsp;<i className="fa-solid fa-envelope"></i>
      </Button>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Send SMS</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            id="name"
            label="Phone Number"
            type="phones"
            size="small"
            fullWidth
            value={phones}
            disabled
          />
          <TextField
            id="subject"
            label="Message"
            margin="dense"
            size="small"
            multiline
            rows={10}
            fullWidth
            value={smsBody}
            onChange={(e) => setSmsBody(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          <Button onClick={() => onSms(phones)} disabled={loadingSms}>
            Send
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
