import React from 'react';
import ReactQuill from 'react-quill';

const Editor = ({ mailBody, setMailBody }) => {
  const [text, setText] = React.useState('');

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ align: ['', 'center', 'right', 'justify'] }],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
      ],

      ['link', 'clean'],
    ],
  };

  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'align',
  ];

  const handleChange = (value) => {
    setMailBody(value);
  };
  return (
    <ReactQuill
      theme="snow"
      value={mailBody}
      onChange={handleChange}
      modules={modules}
      formats={formats}
    />
  );
};

export default Editor;
