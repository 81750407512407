import React, { useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from '../layout/Spinner';
import Divider from '@material-ui/core/Divider';
import { DashboardActionsAdmin } from './DashboardActions';
import { getAvailableExams } from '../../actions/exam';

// import { Link, Redirect } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: '#f4f4f4',
    padding: 20,
  },
  chip: {
    margin: theme.spacing(0.5),
    backgroundColor: '#b3e6fa',
    color: '#333333',
  },
  section1: {
    margin: theme.spacing(3, 2),
  },
  section2: {
    margin: theme.spacing(2),
  },
  section3: {
    margin: theme.spacing(3, 1, 1),
  },
  deleteButton: {
    marginLeft: 10,
  },
}));

const DashboardAdmin = ({
  auth: { user, loading, role, isAuthenticated },
  exam,
  getAvailableExams,
}) => {
  // const classes = useStyles();

  useEffect(() => {
    getAvailableExams();

    // eslint-disable-next-line
  }, []);

  // Redirect if logged in as student
  // if (isAuthenticated && role !== 'ADMIN') {
  //   return <Redirect to="/dashboard" />;
  // }

  return loading ? (
    <Fragment>
      <div className="container">
        <Spinner />
      </div>
    </Fragment>
  ) : (
    <Fragment>
      <div className="container">
        <h1 className="large text-primary">Admin Dashboard</h1>
        <Divider /> <br />
        <p className="lead">
          Welcome {user && user.name} <br />
        </p>
        <DashboardActionsAdmin />
      </div>
    </Fragment>
  );
};

DashboardAdmin.propTypes = {
  auth: PropTypes.object.isRequired,
  exam: PropTypes.array,
  getAvailableExams: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  exam: state.exam.exam,
});

export default connect(mapStateToProps, { getAvailableExams })(DashboardAdmin);
