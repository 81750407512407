export const PROFILE_LIST_REQUEST = 'PROFILE_LIST_REQUEST';
export const PROFILE_LIST_SUCCESS = 'PROFILE_LIST_SUCCESS';
export const PROFILE_LIST_REMOVE_ITEM = 'PROFILE_LIST_REMOVE_ITEM';
export const PROFILE_LIST_FAIL = 'PROFILE_LIST_FAIL';

export const PROFILE_DETAILS_REQUEST = 'PROFILE_DETAILS_REQUEST';
export const PROFILE_DETAILS_SUCCESS = 'PROFILE_DETAILS_SUCCESS';
export const PROFILE_DETAILS_FAIL = 'PROFILE_DETAILS_FAIL';
export const PROFILE_DETAILS_RESET = 'PROFILE_DETAILS_RESET';

export const PROFILE_DELETE_REQUEST = 'PROFILE_DELETE_REQUEST';
export const PROFILE_DELETE_SUCCESS = 'PROFILE_DELETE_SUCCESS';
export const PROFILE_DELETE_FAIL = 'PROFILE_DELETE_FAIL';

export const PROFILE_CREATE_REQUEST = 'PROFILE_CREATE_REQUEST';
export const PROFILE_CREATE_SUCCESS = 'PROFILE_CREATE_SUCCESS';
export const PROFILE_CREATE_FAIL = 'PROFILE_CREATE_FAIL';
export const PROFILE_CREATE_RESET = 'PROFILE_CREATE_RESET';

export const PROFILE_UPDATE_REQUEST = 'PROFILE_UPDATE_REQUEST';
export const PROFILE_UPDATE_SUCCESS = 'PROFILE_UPDATE_SUCCESS';
export const PROFILE_UPDATE_FAIL = 'PROFILE_UPDATE_FAIL';
export const PROFILE_UPDATE_RESET = 'PROFILE_UPDATE_RESET';

export const PROFILE_MARK_ADD_REQUEST = 'PROFILE_MARK_ADD_REQUEST';
export const PROFILE_MARK_ADD_SUCCESS = 'PROFILE_MARK_ADD_SUCCESS';
export const PROFILE_MARK_ADD_FAIL = 'PROFILE_MARK_ADD_FAIL';
export const PROFILE_MARK_ADD_RESET = 'PROFILE_MARK_ADD_RESET';

export const PROFILE_PICTURE_UPLOAD_REQUEST = 'PROFILE_PICTURE_UPLOAD_REQUEST';
export const PROFILE_PICTURE_UPLOAD_SUCCESS = 'PROFILE_PICTURE_UPLOAD_SUCCESS';
export const PROFILE_PICTURE_UPLOAD_FAIL = 'PROFILE_PICTURE_UPLOAD_FAIL';
export const PROFILE_PICTURE_UPLOAD_RESET = 'PROFILE_PICTURE_UPLOAD_RESET';

export const PROFILE_SIGNATURE_UPLOAD_REQUEST =
  'PROFILE_SIGNATURE_UPLOAD_REQUEST';
export const PROFILE_SIGNATURE_UPLOAD_SUCCESS =
  'PROFILE_SIGNATURE_UPLOAD_SUCCESS';
export const PROFILE_SIGNATURE_UPLOAD_FAIL = 'PROFILE_SIGNATURE_UPLOAD_FAIL';
export const PROFILE_SIGNATURE_UPLOAD_RESET = 'PROFILE_SIGNATURE_UPLOAD_RESET';
