export const ADMIN_ADMIT_PDF_REQUEST = 'ADMIN_ADMIT_PDF_REQUEST';
export const ADMIN_ADMIT_PDF_SUCCESS = 'ADMIN_ADMIT_PDF_SUCCESS';
export const ADMIN_ADMIT_PDF_FAIL = 'ADMIN_ADMIT_PDF_FAIL';
export const ADMIN_ADMIT_PDF_RESET = 'ADMIN_ADMIT_PDF_RESET';

export const ADMIN_PROFILE_PDF_REQUEST = 'ADMIN_PROFILE_PDF_REQUEST';
export const ADMIN_PROFILE_PDF_SUCCESS = 'ADMIN_PROFILE_PDF_SUCCESS';
export const ADMIN_PROFILE_PDF_FAIL = 'ADMIN_PROFILE_PDF_FAIL';
export const ADMIN_PROFILE_PDF_RESET = 'ADMIN_PROFILE_PDF_RESET';

export const ADMIN_PROFILE_PDF_MULTI_REQUEST =
  'ADMIN_PROFILE_PDF_MULTI_REQUEST';
export const ADMIN_PROFILE_PDF_MULTI_SUCCESS =
  'ADMIN_PROFILE_PDF_MULTI_SUCCESS';
export const ADMIN_PROFILE_PDF_MULTI_FAIL = 'ADMIN_PROFILE_PDF_MULTI_FAIL';
export const ADMIN_PROFILE_PDF_MULTI_RESET = 'ADMIN_PROFILE_PDF_MULTI_RESET';
