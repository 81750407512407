import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Divider from '@material-ui/core/Divider';
import AlertComponent from '../../components/layout/AlertComponent';
import { useDispatch, useSelector } from 'react-redux';
import {
  getDepartment,
  updateDepartment,
} from '../../actions/departmentActions';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CreateIcon from '@material-ui/icons/Create';
// import CreateIcon from '@mui/icons-material/Create';

import {
  Grid,
  makeStyles,
  Button,
  TextField,
  createMuiTheme,
  ThemeProvider,
  FormLabel,
  IconButton,
  FormGroup,
  FormControlLabel,
  Switch,
  Typography,
} from '@material-ui/core';

import clsx from 'clsx';
import Spinner from '../../components/layout/Spinner';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#00acee',
    },
    secondary: {
      main: '#a8dda8',
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
    marginTop: theme.spacing(3),
  },
  textField: {
    width: '100%',
  },
  btnStyle: {
    color: '#FFF',
  },
  btn: {
    color: '#FFF',
    marginRight: '10px',
  },
  btnSecondary: {
    // color: '#00acee',
    marginRight: '10px',
  },
  my20: {
    marginBottom: '20px',
  },
  tableStyle: {
    borderCollapse: 'collapse',
    width: '100%',
    fontSize: '14px',
  },
  tdStyle: {
    border: '1px solid #dddddd',
    textAlign: 'left',
    padding: '8px',
  },
}));

const DepartmentEditScreen = ({ history, match }) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const departmentDetails = useSelector((state) => state.departmentDetails);
  const { loading: loadingDetails, department } = departmentDetails;

  const departmentUpdate = useSelector((state) => state.departmentUpdate);
  const {
    loading: loadingUpdate,
    department: updatedDepartment,
    success: successUpdate,
  } = departmentUpdate;

  const auth = useSelector((state) => state.auth);
  const { loading: authLoading, role } = auth;

  const [departmentName, setDepartmentName] = useState('');
  const [deptShortCode, setDeptShortCode] = useState('');
  const [deptUrl, setDeptUrl] = useState('');
  const [details, setDetails] = useState('');
  const [edit, setEdit] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(
      updateDepartment(
        { departmentName, deptShortCode, details, deptUrl },
        department._id
      )
    );
  };

  useEffect(() => {
    if (successUpdate) {
      setTimeout(() => {
        history.push(`/departments`);
      }, 1000);
    } else {
      if (
        !department ||
        !department.departmentName ||
        department._id !== match.params.id
      ) {
        dispatch(getDepartment(match.params.id));
      } else {
        if (departmentName.length === 0) {
          setDepartmentName(department.departmentName);
          setDeptShortCode(department.deptShortCode);
          setDeptUrl(department.deptUrl);
          setDetails(department.details);
        }
      }
    }
  }, [dispatch, department, match, successUpdate]);

  return (
    <ThemeProvider theme={theme}>
      <div className="container">
        <h1 className="large text-primary">
          {edit ? 'Editing the Department' : 'Viewing the Department'}
        </h1>
        <Divider style={{ marginBottom: '10px', display: 'block' }} />
        <small>To edit, please toggle the switch</small>
        <FormGroup row style={{ marginBottom: '10px', display: 'block' }}>
          <FormControlLabel
            control={
              <Switch
                checked={edit}
                onChange={() => setEdit(!edit)}
                name="edit"
                color="primary"
              />
            }
            label="Edit"
            style={{ marginBottom: '10px' }}
          />
        </FormGroup>
        <form onSubmit={(e) => onSubmit(e)}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <TextField
                InputProps={{
                  readOnly: !edit,
                }}
                type="text"
                name="departmentName"
                label="Name of the department"
                value={loadingDetails ? 'Loading...' : departmentName}
                helperText={'Please enter the name of the department'}
                variant="outlined"
                onChange={(e) => setDepartmentName(e.target.value)}
                className={clsx(classes.textField)}
              ></TextField>
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                InputProps={{
                  readOnly: !edit,
                }}
                type="text"
                name="shortCode"
                label="Short Code of the department"
                value={loadingDetails ? 'Loading...' : deptShortCode}
                helperText={'Please enter the short code of the department'}
                variant="outlined"
                onChange={(e) => setDeptShortCode(e.target.value)}
                className={clsx(classes.textField)}
              ></TextField>
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                InputProps={{
                  readOnly: !edit,
                }}
                type="text"
                name="deptUrl"
                label="Link"
                value={loadingDetails ? 'Loading...' : deptUrl}
                helperText={
                  'Please enter the NDUB website link of the department'
                }
                variant="outlined"
                onChange={(e) => setDeptUrl(e.target.value)}
                className={clsx(classes.textField)}
              ></TextField>
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                InputProps={{
                  readOnly: !edit,
                }}
                type="text"
                multiline
                rows={5}
                name="details"
                label="Details of the department"
                value={loadingDetails ? 'Loading...' : details}
                helperText={'Please enter the details of the department'}
                variant="outlined"
                onChange={(e) => setDetails(e.target.value)}
                className={clsx(classes.textField)}
              ></TextField>
            </Grid>

            {department?.programs?.length > 0 && (
              <Grid item xs={12} sm={12}>
                <FormLabel style={{ marginBottom: '10px', display: 'block' }}>
                  Programs under {department.departmentName}
                </FormLabel>
                <table className={classes.tableStyle}>
                  <tr>
                    <th
                      className={classes.tdStyle}
                      style={{
                        width: '16px',
                        textAlign: 'center',
                      }}
                    >
                      SL
                    </th>
                    <th className={classes.tdStyle}>Name</th>
                    <th className={classes.tdStyle}>Short Code</th>
                    <th className={classes.tdStyle}>Credit</th>
                    <th className={classes.tdStyle}>Type</th>
                    <th
                      className={classes.tdStyle}
                      style={{
                        width: '16px',
                        textAlign: 'center',
                      }}
                    >
                      URL
                    </th>
                    <th
                      className={classes.tdStyle}
                      style={{
                        width: '16px',
                        textAlign: 'center',
                      }}
                    >
                      Edit
                    </th>
                  </tr>
                  {department.programs.map((program, index) => (
                    <tr key={program._id}>
                      <td
                        className={classes.tdStyle}
                        style={{
                          textAlign: 'center',
                        }}
                      >
                        {index + 1}
                      </td>
                      <td className={classes.tdStyle}>{program.programName}</td>
                      <td className={classes.tdStyle}>
                        {program.programShortCode}
                      </td>
                      <td className={classes.tdStyle}>{program.credit}</td>
                      <td className={classes.tdStyle}>
                        {program.programType.charAt(0).toUpperCase() +
                          program.programType.slice(1)}
                      </td>
                      <td className={classes.tdStyle}>
                        <a href={program.programUrl} target="_blank">
                          <IconButton
                            aria-label="visit-program"
                            color="primary"
                          >
                            <i
                              className="fas fa-external-link-alt"
                              style={{ fontSize: '18px' }}
                            ></i>
                          </IconButton>
                        </a>
                      </td>
                      <td className={classes.tdStyle}>
                        <Link to={`/programs/edit/${program._id}`}>
                          <IconButton aria-label="edit-program" color="primary">
                            <CreateIcon />
                          </IconButton>
                        </Link>
                      </td>
                    </tr>
                  ))}
                </table>
              </Grid>
            )}

            <Grid item xs={12}>
              {!authLoading && (
                <Link to={`/departments`}>
                  <Button
                    variant="contained"
                    color="default"
                    disableElevation
                    className={classes.btnSecondary}
                  >
                    <i className="fas fa-arrow-left"></i> &nbsp; Back
                  </Button>
                </Link>
              )}

              {!authLoading && role.includes('ADMIN') && (
                <Button
                  variant="contained"
                  color="primary"
                  disableElevation
                  className={classes.btn}
                  type="submit"
                >
                  Update
                  {loadingUpdate ? (
                    <>
                      &nbsp;<i className="fas fa-circle-notch fa-spin"></i>
                    </>
                  ) : (
                    <>
                      &nbsp;<i className="fas fa-check"></i>
                    </>
                  )}
                </Button>
              )}
            </Grid>
          </Grid>
          <AlertComponent />
        </form>
      </div>
    </ThemeProvider>
  );
};

export default DepartmentEditScreen;
