import React, { useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Divider from '@material-ui/core/Divider';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { login } from '../../actions/auth';
import AlertComponent from '../layout/AlertComponent';
import Spinner from '../layout/Spinner';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import BreakButton from '../BreakButton';

import Grid from '@material-ui/core/Grid';
import {
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  Typography,
} from '@material-ui/core';
import Logo from '../../img/logo.png';

const Login = ({ login, isAuthenticated, role, user }) => {
  const [formData, setFormData] = useState({
    phone: '',
    password: '',
    showPass: 'password',
    loading: false,
  });

  const { phone, password, showPass } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  // Stop Loading
  const loadingStop = () => {
    setFormData({ ...formData, loading: false });
  };

  const [errorTexts, setErrorTexts] = useState({
    phoneError: false,
    phoneText: 'Enter the phone number you have used to create your account.*',
    passwordError: false,
    passwordText: 'Enter your password.*',
  });

  const { phoneText, phoneError, passwordText, passwordError } = errorTexts;

  const validate = () => {
    let errors = {};

    if (phone === '') {
      errors.phoneError = true;
      errors.phoneText = 'You must enter your phone number';
    }

    if (password === '') {
      errors.passwordError = true;
      errors.passwordText = 'You must enter your password';
    }

    if (password.length < 6) {
      errors.passwordError = true;
      errors.passwordText = 'Password must be 6 or more characters';
    }

    setErrorTexts(errors);

    return errors;
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const isValid = validate();

    if (Object.entries(isValid).length === 0) {
      setFormData({ ...formData, loading: true });
      login(phone, password, loadingStop);
    }
  };

  // Redirect if logged in
  if (isAuthenticated && role === 'STUDENT') {
    return <Redirect to="/dashboard" />;
  } else if (isAuthenticated && role.includes('ADMIN')) {
    return <Redirect to="/admin-dashboard" />;
  }

  const togglePass = (e) => {
    if (showPass === 'password') {
      setFormData({
        ...formData,
        showPass: 'text',
      });
    } else {
      setFormData({
        ...formData,
        showPass: 'password',
      });
    }
  };

  return (
    <div className="container">
      <Grid
        container
        spacing={0}
        alignItems="center"
        justify="center"
        style={{ minHeight: '80vh' }}
      >
        <Grid item xs={12} sm={6} md={6}>
          <Card variant="outlined">
            <CardContent>
              <form onSubmit={(e) => onSubmit(e)}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <img src={Logo} className="logo-100" alt="Logo" />
                    <Typography variant="h3">Log In</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      error={phoneError}
                      type="tel"
                      name="phone"
                      label="Phone Number"
                      value={phone}
                      helperText={phoneText}
                      variant="outlined"
                      onChange={(e) => onChange(e)}
                      fullWidth
                    ></TextField>
                  </Grid>
                  <br />
                  <Grid item xs={12}>
                    <TextField
                      error={passwordError}
                      type={showPass}
                      name="password"
                      label="Password"
                      value={password}
                      helperText={passwordText}
                      variant="outlined"
                      onChange={(e) => onChange(e)}
                      fullWidth
                    ></TextField>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          size="small"
                          color="primary"
                          onClick={(e) => togglePass(e)}
                        />
                      }
                      label="Show Password"
                      labelPlacement="end"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <AlertComponent />
                    <Button
                      variant="contained"
                      color="primary"
                      disableElevation
                      type="submit"
                    >
                      <span>Log In</span>
                      {formData.loading ? (
                        <>
                          &nbsp;
                          <i className="fas fa-circle-notch fa-spin loadingIndicator"></i>
                        </>
                      ) : null}
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <small>
                      Don't have an account?{' '}
                      <Link
                        to="/register"
                        style={{ textDecoration: 'underline' }}
                      >
                        Register
                      </Link>{' '}
                      | Forgot password?{' '}
                      <Link to="/reset" style={{ textDecoration: 'underline' }}>
                        Reset
                      </Link>
                    </small>
                  </Grid>
                  {/* <BreakButton /> */}
                </Grid>
              </form>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

Login.propTypes = {
  login: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  user: PropTypes.object,
  role: PropTypes.string,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  role: state.auth.role,
  user: state.auth.user,
});

export default connect(mapStateToProps, { login })(Login);
