import {
  MAIL_SEND_FAIL,
  MAIL_SEND_REQUEST,
  MAIL_SEND_SUCCESS,
} from '../constants/mailConstants';

// Get Applicants
export const mailSendReducer = (state = {}, action) => {
  switch (action.type) {
    case MAIL_SEND_REQUEST:
      return { loading: true };
    case MAIL_SEND_SUCCESS:
      return {
        loading: false,
        mail: action.payload,
      };
    case MAIL_SEND_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
