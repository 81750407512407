import React, { Fragment, useState, useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Divider from '@material-ui/core/Divider';
import { verifyPayment } from '../../../actions/auth';
import AlertComponent from '../AlertComponent';
import { getSingleStudentsProfile } from '../../../actions/profile';

import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';

import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

const PendingPayment = ({
  verifyPayment,
  match,
  getSingleStudentsProfile,
  profile,
}) => {
  useEffect(() => {
    getSingleStudentsProfile(match.params.id);
  }, [getSingleStudentsProfile, match.params.id]);

  const [formData, setFormData] = useState({
    bankSlipNumber: '',
  });

  const { bankSlipNumber } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();
    verifyPayment(formData, match.params.id);
    setTimeout(() => {
      window.location.reload();
    }, 3000);
  };

  const classes = useStyles();

  const paymentDate = () => {
    let paymentDateRaw = new Date(profile.user.paymentInfo.date);

    var hours = paymentDateRaw.getHours();
    var minutes = paymentDateRaw.getMinutes();
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;

    return `${strTime} - ${paymentDateRaw.getDate()}/${
      paymentDateRaw.getMonth() + 1
    }/${paymentDateRaw.getFullYear()}`;
  };

  const verifiedDate = () => {
    let verifiedDateRaw = new Date(profile.user.paymentInfo.verifiedOn);

    var hours = verifiedDateRaw.getHours();
    var minutes = verifiedDateRaw.getMinutes();
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;

    return `${strTime} - ${verifiedDateRaw.getDate()}/${
      verifiedDateRaw.getMonth() + 1
    }/${verifiedDateRaw.getFullYear()}`;
  };

  // Payment Status Pending
  const statusPending = (
    <Fragment>
      {profile && profile.user.paymentInfo && (
        <div className={classes.root}>
          <Alert severity="warning">
            Payment Status: {profile.user.paymentInfo.status}
          </Alert>
        </div>
      )}{' '}
      <br />
      <div>
        {profile && profile.user.name && (
          <Chip
            label={`Student Name: ${profile.user.name}`}
            variant="outlined"
          />
        )}{' '}
        &nbsp;
        {profile && profile.user.name && (
          <Chip
            label={`Applicant ID: ${profile.marks.applicantId}`}
            variant="outlined"
          />
        )}{' '}
        &nbsp;
        {profile && profile.user.paymentInfo && (
          <Chip
            label={`Payment Method: ${profile.user.paymentInfo.paymentMethod}`}
            variant="outlined"
          />
        )}{' '}
        &nbsp;
        {profile && profile.user.paymentInfo && (
          <Chip
            label={`Bank Slip Number: ${profile.user.paymentInfo.bankSlipNumber}`}
            variant="outlined"
          />
        )}{' '}
        &nbsp;
        {profile && profile.user.paymentInfo && (
          <Chip label={`Submitted at: ${paymentDate()}`} variant="outlined" />
        )}{' '}
      </div>{' '}
      <br />
      <Divider />
      {profile && profile.user.paymentInfo.status === 'PENDING' && (
        <form className="form" onSubmit={(e) => onSubmit(e)}>
          <div className="form-group">
            <input
              type="text"
              placeholder="Bank Slip Number"
              name="bankSlipNumber"
              value={bankSlipNumber}
              onChange={(e) => onChange(e)}
            />
            <small className="form-text">
              Enter the bank slip number and click verify to verify the payment.
            </small>
          </div>
          <AlertComponent />
          <div className="form-group">
            <input type="submit" className="btn btn-primary" value="Verify" />
          </div>
          <AlertComponent />
        </form>
      )}
    </Fragment>
  );

  // Payment Status Paid
  const statusPaid = (
    <Fragment>
      {profile && profile.user.paymentInfo && (
        <div className={classes.root}>
          <Alert severity="success">
            Payment Status: {profile.user.paymentInfo.status}
          </Alert>
        </div>
      )}
      <br />
      <div>
        {profile && profile.user.name && (
          <Chip
            label={`Student Name: ${profile.user.name}`}
            variant="outlined"
          />
        )}{' '}
        &nbsp;
        {profile && profile.user.name && (
          <Chip
            label={`Applicant ID: ${profile.marks.applicantId}`}
            variant="outlined"
          />
        )}{' '}
        &nbsp;
        {profile && profile.user.paymentInfo && (
          <Chip
            label={`Bank Slip Number: ${profile.user.paymentInfo.bankSlipNumber}`}
            variant="outlined"
          />
        )}{' '}
        &nbsp;
        {profile && profile.user.paymentInfo && (
          <Chip label={`Submitted at: ${paymentDate()}`} variant="outlined" />
        )}{' '}
        &nbsp; <br /> <br />
        {profile && profile.user.paymentInfo && (
          <Chip label={`Verified at: ${verifiedDate()}`} variant="outlined" />
        )}{' '}
      </div>{' '}
      <br />
      <p className="lead">
        <i className="fas fa-check-circle text-primary"></i> Payment verified.
      </p>
      <small>The payment was verified.</small>
    </Fragment>
  );

  return (
    <div className="container">
      <Link className="btn btn-light my-1" to="/payment/verify">
        <i className="fas fa-arrow-left"></i> &nbsp; Go Back
      </Link>
      <h1 className="large text-primary">Payment</h1>
      {profile &&
        profile.user.paymentInfo.status === 'PENDING' &&
        statusPending}
      {profile && profile.user.paymentInfo.status === 'PAID' && statusPaid}
    </div>
  );
};

PendingPayment.propTypes = {
  verifyPayment: PropTypes.func.isRequired,
  getSingleStudentsProfile: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  role: PropTypes.string,
};

const mapStateToProps = (state) => ({
  exam: state.exam,
  isAuthenticated: state.auth.isAuthenticated,
  role: state.auth.role,
  user: state.auth.user,
  profile: state.profile.profile,
});

export default connect(mapStateToProps, {
  verifyPayment,
  getSingleStudentsProfile,
})(PendingPayment);
