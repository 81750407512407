import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Spinner from '../layout/Spinner';
import { getSingleStudentsProfile, addMarks } from '../../actions/profile';
import ProfileTop from './student-profile/ProfileTop';
import ProfileAbout from './student-profile/ProfileAbout';
import ProfileGuardiansInfo from './student-profile/ProfileGuardiansInfo';
import ProfileEducationInfo from './student-profile/ProfileEducationInfo';
import ProfileMarks from './student-profile/ProfileMarks';
import DownloadXlsx from './utils/DownloadXlsx';
import AlertComponent from './AlertComponent';
import { useDispatch, useSelector } from 'react-redux';
import { downloadAdmitByAdmin } from '../../actions/admitActions';
import { getSingleStudentsPDF } from '../../actions/profilePDFActions';
import {
  Button,
  createMuiTheme,
  makeStyles,
  ThemeProvider,
} from '@material-ui/core';

const { format } = require('date-fns');

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#00acee',
    },
    secondary: {
      main: '#a8dda8',
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
    marginTop: theme.spacing(3),
  },
  textField: {
    width: '100%',
  },
  btnStyle: {
    color: '#FFF',
  },
  btn: {
    color: '#FFF',
    marginRight: '10px',
  },
  btnSecondary: {
    // color: '#00acee',
    marginRight: '10px',
  },
  my20: {
    marginBottom: '20px',
  },
}));

const Student = ({
  getSingleStudentsProfile,
  addMarks,
  profile: { profile, loading },
  auth,
  match,
  location,
  history,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const admitAdminDetails = useSelector((state) => state.admitAdminDetails);
  const {
    loading: loadingAdmit,
    admitCard,
    error: errorAdmit,
  } = admitAdminDetails;

  const profilePDFDetails = useSelector((state) => state.profilePDFDetails);
  const {
    loading: loadingProfilePDF,
    profilePDF,
    error: errorProfilePDF,
  } = profilePDFDetails;

  const [formData, setFormData] = useState({
    written: '',
    viva: '',
    remarks: '',
  });

  const redirect = location.search ? location.search.split('=')[1] : '/';

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!profile || profile.user._id !== match.params.id) {
      getSingleStudentsProfile(match.params.id);
    }
    if (profile) {
      if (!admitCard || profile.user._id !== match.params.id) {
        dispatch(downloadAdmitByAdmin(match.params.id));
      }
      if (!profilePDF || profile.user._id !== match.params.id) {
        dispatch(getSingleStudentsPDF(match.params.id));
      }
    }
  }, [getSingleStudentsProfile, match.params.id, dispatch, profile]);

  const { written, viva, remarks } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();
    addMarks(formData, match.params.id);
    setTimeout(function () {
      window.location.reload();
    }, 3000);
  };

  return (
    <div className="container">
      {profile === null && loading ? (
        <Spinner />
      ) : (
        <ThemeProvider theme={theme}>
          {profile === null ? (
            <Spinner />
          ) : (
            <>
              <div>
                <Link to={location.search ? redirect : `/students`}>
                  <Button
                    variant="contained"
                    color="default"
                    size="small"
                    disableElevation
                    className={classes.btnSecondary}
                  >
                    <i className="fas fa-arrow-left"></i> &nbsp; Back
                  </Button>
                </Link>
                <DownloadXlsx profile={profile} />
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  disableElevation
                  href={admitCard}
                  className={classes.btn}
                  download={`Admit - ${profile.marks.applicantId} - ${
                    profile.user.name
                  } - ${format(new Date(), 'PPp', {
                    timeZone: 'Asia/Dhaka',
                  })}.pdf`}
                  type="submit"
                >
                  Admit &nbsp; <i className="fas fa-download"></i>
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  disableElevation
                  href={profilePDF}
                  className={classes.btn}
                  download={`Profile PDF - ${profile.marks.applicantId} - ${
                    profile.user.name
                  } - ${format(new Date(), 'PPp', {
                    timeZone: 'Asia/Dhaka',
                  })}.pdf`}
                  type="submit"
                >
                  FORM &nbsp; <i className="fas fa-download"></i>
                </Button>
              </div>
              <div className="profile-grid my-1">
                <ProfileTop profile={profile} />
                <ProfileAbout profile={profile} />
                <ProfileGuardiansInfo profile={profile} />
                <ProfileEducationInfo profile={profile} />
                <ProfileMarks profile={profile} />
                <Fragment>
                  <div className="profile-marks bg-white p-2">
                    <h2 className="text-primary">Add or Edit Marks</h2>

                    <div>
                      <form className="form" onSubmit={(e) => onSubmit(e)}>
                        <div className="form-group">
                          <input
                            type="number"
                            name="written"
                            value={written}
                            min={0}
                            max={40}
                            onChange={(e) => onChange(e)}
                            placeholder="Written"
                            required
                          />
                          <small className="form-text">Written Marks</small>
                        </div>
                        <div className="form-group">
                          <input
                            type="number"
                            name="viva"
                            value={viva}
                            min={0}
                            max={10}
                            onChange={(e) => onChange(e)}
                            placeholder="Viva"
                            required
                          />
                          <small className="form-text">Viva Marks</small>
                        </div>
                        <div className="form-group">
                          <textarea
                            type="text"
                            placeholder="Remarks"
                            name="remarks"
                            value={remarks}
                            onChange={(e) => onChange(e)}
                          ></textarea>
                          <small className="form-text">
                            Remarks of the student. (optional)
                          </small>
                        </div>
                        <AlertComponent />
                        <div className="form-group">
                          <input
                            type="submit"
                            className="btn btn-primary"
                            value="Submit"
                          />
                        </div>
                      </form>
                    </div>
                  </div>
                </Fragment>
              </div>
            </>
          )}
        </ThemeProvider>
      )}
    </div>
  );
};

Student.propTypes = {
  getSingleStudentsProfile: PropTypes.func.isRequired,
  addMarks: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  profile: state.profile,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  getSingleStudentsProfile,
  addMarks,
})(Student);
