import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Chip from '@material-ui/core/Chip';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';

import { makeStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
    alignItems: 'center',
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
}));

const ProfileTop = ({
  profile: {
    profilePic,
    user: { name, phone, email, paymentInfo },
    // admissionInfo: { department, course, batch, year },
    marks: { applicantId, examId },
  },
}) => {
  const classes = useStyles();

  const handleClick = () => {};
  return (
    // <div className="profile-top bg-primary p-2">
    <Paper
      // variant="outlined"
      style={{
        backgroundColor: '#0C2340',
        color: '#FFF',
      }}
      elevation={3}
      color="primary"
      className="profile-top bg-primary p-2"
    >
      <Avatar
        alt={name}
        src={'/uploads/profile-pic/' + profilePic}
        className={classes.large}
      ></Avatar>
      <h1 className="large">{name}</h1>
      <Grid container spacing={2} justify="center">
        <Grid item>
          <Grid container justify="center">
            {phone && (
              <Chip label={phone} size="medium" icon={<PhoneIcon />}></Chip>
            )}
          </Grid>
        </Grid>
        <Grid item>
          <Grid container justify="center">
            <Chip label={email} size="medium" icon={<EmailIcon />}></Chip>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container justify="center">
            <Chip
              label={`Applicant ID: ${
                applicantId
                  ? applicantId
                  : 'Please complete payment to get Applicant ID'
              }`}
              size="medium"
            ></Chip>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container justify="center">
            <Chip
              label={`Department: ${examId.program.department.departmentName}`}
              size="medium"
            ></Chip>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container justify="center">
            <Chip
              label={`Program: ${examId.program.programName}`}
              size="medium"
            ></Chip>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container justify="center">
            <Chip label={`Trimester: ${examId.trimester}`} size="medium"></Chip>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container justify="center">
            <Chip label={`Batch: ${examId.batch}`} size="medium"></Chip>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container justify="center">
            <Chip label={`Year: ${examId.year}`} size="medium"></Chip>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container justify="center">
            <Link to="/payment" style={{ cursor: 'pointer' }}>
              <Chip
                label={`Payment: ${paymentInfo?.status}`}
                size="medium"
                onClick={handleClick}
              ></Chip>
            </Link>
          </Grid>
        </Grid>
        {paymentInfo?.status === 'PAID' && (
          <>
            <Grid item>
              <Grid container justify="center">
                <Chip
                  label={`Method: ${paymentInfo?.paymentMethod}`}
                  size="medium"
                ></Chip>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container justify="center">
                <Chip
                  label={`TrxID: ${paymentInfo?.bkashInfo?.trxID}`}
                  size="medium"
                ></Chip>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </Paper>
  );
};

ProfileTop.propTypes = {
  profile: PropTypes.object.isRequired,
};

export default ProfileTop;
