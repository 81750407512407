import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Divider from '@material-ui/core/Divider';
import AlertComponent from '../layout/AlertComponent';

import {
  makeStyles,
  createMuiTheme,
  ThemeProvider,
} from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';

import Grid from '@material-ui/core/Grid';

import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import Checkbox from '@material-ui/core/Checkbox';

import Chip from '@material-ui/core/Chip';

import FormControlLabel from '@material-ui/core/FormControlLabel';

import { format } from 'date-fns';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { setAlert } from '../../actions/alert';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#00acee',
    },
    secondary: {
      main: '#fd3a69',
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
    marginTop: theme.spacing(3),
  },
  marginDate: {
    marginBottom: '-3px',
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  dateOfBirthErrorClass: {
    borderColor: 'red !important',
  },
  paper: {
    padding: theme.spacing(2),
  },
  chipContainer: {
    padding: '10px',
    '& .MuiChip-root': {
      margin: theme.spacing(0.5),
      color: '#FFF',
      overflow: 'hidden',
    },
  },
  btn: {
    color: '#FFF',
    marginRight: '10px',
  },
  btnSecondary: {
    // color: '#00acee',
    marginRight: '10px',
  },
}));

const Step6ConfirmSubmit = ({
  onSubmit,
  formData: {
    religion,
    gender,
    dateOfBirth,
    nationality,

    presentAptNumber,
    permanentAptNumber,

    fathersName,
    fathersPhone,

    mothersName,
    mothersPhone,

    sscInstitutionName,
    sscGroup,
    sscPassingYear,
    sscGpa,
    sscSubjects,
    hscInstitutionName,
    hscGroup,
    hscPassingYear,
    hscGpa,
    hscSubjects,

    examTitle,
    examDepartment,
    examTime,
    examDate,
    examCourse,
    examTrimester,
    examYear,

    examCourseType,

    gradDegreeType,
    gradDegreeInstitutionName,
    gradDegreeCourseName,
    gradDegreeMajor,
    gradDegreeAcademicYearFrom,
    gradDegreeAcademicYearTo,
    gradDegreeCGpa,

    mastersDegreeType,
    mastersDegreeInstitutionName,
    mastersDegreeCourseName,
    mastersDegreeMajor,
    mastersDegreeAcademicYearFrom,
    mastersDegreeAcademicYearTo,
    mastersDegreeCGpa,

    workExperience,
    otherPrograms,
    otherProgramsDetails,
  },
  prevStep,
  steps,
  currentStepMobile,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const profileCreate = useSelector((state) => state.profileCreate);
  const { loading: loadingCreate } = profileCreate;

  const [confirmAgreement, setConfirmAgreement] = useState(false);
  const handleAgreement = (e) => {
    setConfirmAgreement(!confirmAgreement);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // function tConvert(time) {
  //   // Check correct time format and split into components
  //   time = time
  //     .toString()
  //     .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

  //   if (time.length > 1) {
  //     // If time format correct
  //     time = time.slice(1); // Remove full string match value
  //     time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
  //     time[0] = +time[0] % 12 || 12; // Adjust hours
  //   }
  //   return time.join(''); // return adjusted time or original string
  // }

  const handleSubmit = (e) => {
    e.preventDefault();

    if (confirmAgreement === false) {
      return dispatch(
        setAlert('Please agree by clicking on the checkbox above', 'error')
      );
    }

    onSubmit(e);
  };

  return (
    <ThemeProvider theme={theme}>
      <div className="container">
        <h1 className="large text-primary">Confirm & Submit</h1>
        <div className="hide-sm">{steps}</div>
        <div className="hide-lg">{currentStepMobile}</div>
        <p className="lead">Confirm your submitted information</p>
        <small className="form-text">
          Please confirm your submitted information, go back and if you want to
          make changes. Information cannot be changed or modified after
          submission.
        </small>
        <br /> <br />
        <Divider />
        <form className={classes.root} onSubmit={(e) => handleSubmit(e)}>
          <br />
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Paper elevation={2} className={classes.paper}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography variant="button" component="h2" gutterBottom>
                      Selected Program
                    </Typography>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <List>
                      <ListItem>
                        <ListItemText primary={examTitle} />
                      </ListItem>
                    </List>
                    <div className={classes.chipContainer}>
                      <Chip
                        label={`Date & Time: ${format(
                          new Date(examDate),
                          'PPPPp'
                        )}`}
                        color="primary"
                        style={{ color: '#FFF', margin: '5px' }}
                      />
                      <Chip
                        label={`Department: ${examDepartment}`}
                        color="primary"
                        style={{ color: '#FFF', margin: '5px' }}
                      />
                      <Chip
                        label={`Trimester: ${examTrimester}`}
                        color="primary"
                        style={{ color: '#FFF', margin: '5px' }}
                      />

                      <Chip
                        label={`Year: ${examYear}`}
                        style={{ color: '#FFF', margin: '5px' }}
                        color="primary"
                      />
                    </div>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
              <Paper elevation={2} className={classes.paper}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography variant="button" component="h2" gutterBottom>
                      Personal Info
                    </Typography>
                    <Divider />
                  </Grid>
                  <Grid item xs={6}>
                    <List>
                      <ListItem>
                        <ListItemText primary={religion} secondary="Religion" />
                      </ListItem>
                      <ListItem>
                        <ListItemText primary={gender} secondary="Gender" />
                      </ListItem>
                    </List>
                  </Grid>
                  <Grid item xs={6}>
                    <List>
                      <ListItem>
                        <ListItemText
                          primary={format(dateOfBirth, 'PP')}
                          secondary="Date of birth"
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          primary={nationality}
                          secondary="Nationality"
                        />
                      </ListItem>
                    </List>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
              <Paper elevation={2} className={classes.paper}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography variant="button" component="h2" gutterBottom>
                      Address
                    </Typography>
                    <Divider />
                  </Grid>

                  <Grid item xs={12}>
                    <List>
                      <ListItem>
                        <Typography variant="caption" display="block">
                          Present Address
                        </Typography>
                      </ListItem>
                      <Divider />
                      <ListItem>
                        <ListItemText primary={presentAptNumber} />
                      </ListItem>
                    </List>
                  </Grid>
                  <Grid item xs={12}>
                    <List>
                      <ListItem>
                        <Typography variant="caption" display="block">
                          Permanent Address
                        </Typography>
                      </ListItem>
                      <Divider />
                      <ListItem>
                        <ListItemText primary={permanentAptNumber} />
                      </ListItem>
                    </List>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
              <Paper elevation={2} className={classes.paper}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography variant="button" component="h2" gutterBottom>
                      Guardian's Details
                    </Typography>
                    <Divider />
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <List>
                      <ListItem>
                        <Typography variant="caption" display="block">
                          Father
                        </Typography>
                      </ListItem>
                      <Divider />
                      <ListItem>
                        <ListItemText primary={fathersName} secondary="Name" />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          primary={fathersPhone}
                          secondary="Phone"
                        />
                      </ListItem>
                    </List>
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <List>
                      <ListItem>
                        <Typography variant="caption" display="block">
                          Mother
                        </Typography>
                      </ListItem>
                      <Divider />
                      <ListItem>
                        <ListItemText primary={mothersName} secondary="Name" />
                      </ListItem>

                      <ListItem>
                        <ListItemText
                          primary={mothersPhone}
                          secondary="Phone"
                        />
                      </ListItem>
                    </List>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12} md={12}>
              <Paper elevation={2} className={classes.paper}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography variant="button" component="h2" gutterBottom>
                      Academic Details
                    </Typography>
                    <Divider />
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <List>
                      <ListItem>
                        <Typography variant="caption" display="block">
                          SSC
                        </Typography>
                      </ListItem>
                      <Divider />
                      <ListItem>
                        <ListItemText
                          primary={sscInstitutionName}
                          secondary="Institution Name"
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText primary={sscGroup} secondary="Group" />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          primary={sscPassingYear}
                          secondary="Passing Year"
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText primary={sscGpa} secondary="GPA" />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          primary={sscSubjects}
                          secondary="SSC Subjects"
                        />
                      </ListItem>
                    </List>
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <List>
                      <ListItem>
                        <Typography variant="caption" display="block">
                          HSC
                        </Typography>
                      </ListItem>
                      <Divider />
                      <ListItem>
                        <ListItemText
                          primary={hscInstitutionName}
                          secondary="Institution Name"
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText primary={hscGroup} secondary="Group" />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          primary={hscPassingYear}
                          secondary="Passing Year"
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText primary={hscGpa} secondary="GPA" />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          primary={hscSubjects}
                          secondary="HSC Subjects"
                        />
                      </ListItem>
                    </List>
                  </Grid>

                  {examCourseType === 'graduate' && (
                    <Grid item xs={6} md={6}>
                      <List>
                        <ListItem>
                          <Typography variant="caption" display="block">
                            Graduate
                          </Typography>
                        </ListItem>
                        <Divider />
                        <ListItem>
                          <ListItemText
                            primary={gradDegreeInstitutionName}
                            secondary="Institution Name"
                          />
                        </ListItem>

                        <ListItem>
                          <ListItemText
                            primary={gradDegreeCourseName}
                            secondary="Course Name"
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemText
                            primary={gradDegreeMajor}
                            secondary="Major"
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemText
                            primary={`${gradDegreeAcademicYearFrom} - ${gradDegreeAcademicYearTo}`}
                            secondary="Academic Year"
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemText
                            primary={gradDegreeCGpa}
                            secondary="CGPA"
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemText
                            primary={gradDegreeType}
                            secondary="Degree Type"
                          />
                        </ListItem>
                      </List>
                    </Grid>
                  )}
                  {examCourseType === 'graduate' && (
                    <Grid item xs={6} md={6}>
                      <List>
                        <ListItem>
                          <Typography variant="caption" display="block">
                            Post Graduate
                          </Typography>
                        </ListItem>
                        <Divider />
                        <ListItem>
                          <ListItemText
                            primary={mastersDegreeInstitutionName}
                            secondary="Institution Name"
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemText
                            primary={mastersDegreeCourseName}
                            secondary="Course Name"
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemText
                            primary={mastersDegreeMajor}
                            secondary="Major"
                          />
                        </ListItem>

                        <ListItem>
                          <ListItemText
                            primary={`${mastersDegreeAcademicYearFrom} - ${mastersDegreeAcademicYearTo}`}
                            secondary="Academic Year"
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemText
                            primary={mastersDegreeCGpa}
                            secondary="CGPA"
                          />
                        </ListItem>
                      </List>
                    </Grid>
                  )}
                </Grid>
              </Paper>
            </Grid>

            {examCourseType === 'graduate' &&
              workExperience[0].companyName !== '' && (
                <Grid item xs={12} md={12}>
                  <Paper elevation={2} className={classes.paper}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Typography
                          variant="button"
                          component="h2"
                          gutterBottom
                        >
                          Work Experience
                        </Typography>
                        <Divider />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <Table
                          className={classes.table}
                          aria-label="work experience table"
                          size="small"
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell>Company Name</TableCell>
                              <TableCell align="center">Position</TableCell>
                              <TableCell align="center">
                                Service Years
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {workExperience.map((workExp) => (
                              <TableRow key={workExp.id}>
                                <TableCell component="th" scope="row">
                                  {workExp.companyName}
                                </TableCell>
                                <TableCell align="center">
                                  {workExp.position}
                                </TableCell>
                                <TableCell align="center">{`${workExp.from} - ${workExp.to}`}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              )}

            {otherProgramsDetails.length > 0 && (
              <Grid item xs={12} md={12}>
                <Paper elevation={2} className={classes.paper}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Typography variant="button" component="h2" gutterBottom>
                        Other programs that you have applied to
                      </Typography>
                      <Divider />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Table
                        className={classes.table}
                        aria-label="other programs table"
                        size="small"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell>Program Name</TableCell>
                            <TableCell align="center">Department</TableCell>
                            <TableCell align="center">Exam Date</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {otherProgramsDetails.map((otherProgram) => (
                            <TableRow key={otherProgram.id}>
                              <TableCell component="th" scope="row">
                                {otherProgram.program.programName}
                              </TableCell>
                              <TableCell align="center">
                                {otherProgram.program.department.departmentName}
                              </TableCell>
                              <TableCell align="center">{`${format(
                                new Date(otherProgram.examDate),
                                'PPp'
                              )}`}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            )}
          </Grid>
          <small
            style={{ display: 'block', marginBottom: '15px', marginTop: '5px' }}
          >
            <i>
              Notre Dame University Bangladesh has the right to make changes to
              it's admission policy at any given time.
            </i>
          </small>

          <FormControlLabel
            style={{ marginBottom: '15px' }}
            control={
              <Checkbox color="primary" onClick={(e) => handleAgreement(e)} />
            }
            label="Under the penalty and perjury, I clearly understand and solemnly
            declare that the information contained herein is completely true
            and accurate to the best of my knowledge."
          />

          <AlertComponent />

          <div className="form-group">
            <Button
              variant="contained"
              color="default"
              disableElevation
              className={classes.btnSecondary}
              onClick={(e) => prevStep(e)}
            >
              <i className="fas fa-arrow-left"></i> &nbsp; Back
            </Button>
            <Button
              variant="contained"
              color="primary"
              disableElevation
              className={classes.btn}
              type="submit"
            >
              Submit
              {loadingCreate ? (
                <>
                  &nbsp;<i className="fas fa-circle-notch fa-spin"></i>
                </>
              ) : (
                <>
                  &nbsp;<i className="fas fa-check"></i>
                </>
              )}
            </Button>
          </div>
        </form>
      </div>
    </ThemeProvider>
  );
};

Step6ConfirmSubmit.propTypes = {
  onChange: PropTypes.func.isRequired,
  prevStep: PropTypes.func.isRequired,
  formData: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default Step6ConfirmSubmit;
