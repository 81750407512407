export const PROGRAM_LIST_REQUEST = 'PROGRAM_LIST_REQUEST';
export const PROGRAM_LIST_SUCCESS = 'PROGRAM_LIST_SUCCESS';
export const PROGRAM_LIST_FAIL = 'PROGRAM_LIST_FAIL';
export const PROGRAM_LIST_REMOVE_ITEM = 'PROGRAM_LIST_REMOVE_ITEM';

export const PROGRAM_DETAILS_REQUEST = 'PROGRAM_DETAILS_REQUEST';
export const PROGRAM_DETAILS_SUCCESS = 'PROGRAM_DETAILS_SUCCESS';
export const PROGRAM_DETAILS_FAIL = 'PROGRAM_DETAILS_FAIL';

export const PROGRAM_DELETE_REQUEST = 'PROGRAM_DELETE_REQUEST';
export const PROGRAM_DELETE_SUCCESS = 'PROGRAM_DELETE_SUCCESS';
export const PROGRAM_DELETE_FAIL = 'PROGRAM_DELETE_FAIL';
export const PROGRAM_DELETE_RESET = 'PROGRAM_DELETE_RESET';

export const PROGRAM_CREATE_REQUEST = 'PROGRAM_CREATE_REQUEST';
export const PROGRAM_CREATE_SUCCESS = 'PROGRAM_CREATE_SUCCESS';
export const PROGRAM_CREATE_FAIL = 'PROGRAM_CREATE_FAIL';
export const PROGRAM_CREATE_RESET = 'PROGRAM_CREATE_RESET';

export const PROGRAM_UPDATE_REQUEST = 'PROGRAM_UPDATE_REQUEST';
export const PROGRAM_UPDATE_SUCCESS = 'PROGRAM_UPDATE_SUCCESS';
export const PROGRAM_UPDATE_FAIL = 'PROGRAM_UPDATE_FAIL';
export const PROGRAM_UPDATE_RESET = 'PROGRAM_UPDATE_RESET';
