import React, { useEffect } from 'react';
import Spinner from '../../components/layout/Spinner';
import Divider from '@material-ui/core/Divider';

import { Link } from 'react-router-dom';
import AlertComponent from '../../components/layout/AlertComponent';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import { useDispatch, useSelector } from 'react-redux';
import {
  listDepartments,
  deleteDepartment,
} from '../../actions/departmentActions';
import {
  createMuiTheme,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ThemeProvider,
  withStyles,
} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import {
  DEPARTMENT_CREATE_RESET,
  DEPARTMENT_UPDATE_RESET,
} from '../../constants/departmentConstants';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#00acee',
    },
    // secondary: {
    //   main: '#a8dda8',
    // },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    // maxWidth: 500,
    backgroundColor: '#f4f4f4',
    padding: 20,
  },
  chip: {
    margin: theme.spacing(0.5),
    backgroundColor: '#b3e6fa',
    color: '#333333',
  },
  chipOutlined: {
    borderColor: '#00acee',
    color: '#00acee',
    marginTop: 10,
  },
  section1: {
    margin: theme.spacing(3, 2),
  },
  section2: {
    margin: theme.spacing(2),
  },
  section3: {
    margin: theme.spacing(3, 1, 1),
  },
  deleteButton: {
    marginLeft: 10,
  },
  switch: {
    color: '#00acee !important',
  },
  textField: {
    width: '100%',
  },
  btn: {
    color: '#FFF',
  },
  btnSecondary: {
    // color: '#00acee',
    marginRight: '10px',
  },
}));

const DepartmentScreen = () => {
  const dispatch = useDispatch();

  const classes = useStyles();

  const departmentList = useSelector((state) => state.departmentList);
  const { loading: loadingList, departments, error } = departmentList;

  useEffect(() => {
    dispatch({ type: DEPARTMENT_CREATE_RESET });
    dispatch({ type: DEPARTMENT_UPDATE_RESET });
    dispatch(listDepartments());
    // eslint-disable-next-line
  }, [listDepartments, dispatch]);

  const onDelete = (department_id) => {
    if (window.confirm('Are you sure?')) {
      dispatch(deleteDepartment(department_id));
    }
  };

  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: '#00acee',
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);

  const StyledTableRow = withStyles((theme) => ({
    root: {
      // '&:nth-of-type(odd)': {
      //   backgroundColor: theme.palette.action.hover,
      // },
    },
  }))(TableRow);
  return (
    <ThemeProvider theme={theme}>
      <div className="container">
        <h1 className="large text-primary">Departments</h1>
        <Divider className={classes.my20} />

        <br />
        <Grid container spacing={1}>
          <Grid item xs={12} className={classes.my20}>
            <Link to={`/admin-dashboard`}>
              <Button
                variant="contained"
                size="small"
                color="default"
                disableElevation
                className={classes.btnSecondary}
              >
                <i className="fas fa-arrow-left"></i> &nbsp; Back
              </Button>
            </Link>
            <Link to={`/departments/create`}>
              <Button
                variant="contained"
                size="small"
                color="primary"
                disableElevation
                className={classes.btn}
              >
                Create &nbsp; <i className="fas fa-plus"></i>
              </Button>
            </Link>
          </Grid>
        </Grid>
        <br />
        <AlertComponent />
        {loadingList && <Spinner />}
        <TableContainer component={Paper} className={classes.my}>
          <Table className={classes.table} aria-label="user table" size="small">
            <TableHead>
              <TableRow>
                <StyledTableCell align="left">SL</StyledTableCell>
                <StyledTableCell align="left">Name</StyledTableCell>
                <StyledTableCell align="left">Code</StyledTableCell>
                <StyledTableCell align="left">Details</StyledTableCell>

                <StyledTableCell align="center">Delete</StyledTableCell>

                <StyledTableCell align="center">View/Edit</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {departments && departments.length > 0
                ? departments.map((department, index) => (
                    <StyledTableRow key={department._id} hover>
                      <StyledTableCell align="left">
                        {index + 1}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {department.departmentName}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {department.deptShortCode}
                      </StyledTableCell>

                      <StyledTableCell align="left">
                        {department.details.indexOf(' ') === -1
                          ? department.details.substring(0, 100)
                          : department.details.substring(
                              0,
                              department.details.indexOf(' ', 100)
                            )}
                        ...
                      </StyledTableCell>

                      <StyledTableCell align="center">
                        <IconButton
                          aria-label="delete-department"
                          onClick={() => onDelete(department._id)}
                          // className={classes.btn}
                          color="secondary"
                        >
                          <DeleteForeverIcon color="secondary" />
                        </IconButton>
                      </StyledTableCell>

                      <StyledTableCell align="center">
                        <Link to={`/departments/edit/${department._id}`}>
                          <IconButton
                            aria-label="edit-department"
                            color="primary"
                          >
                            <ArrowForwardIcon />
                          </IconButton>
                        </Link>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))
                : null}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </ThemeProvider>
  );
};

export default DepartmentScreen;
