import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Divider from '@material-ui/core/Divider';
import AlertComponent from '../../components/layout/AlertComponent';
import { useDispatch, useSelector } from 'react-redux';
import {
  getDepartment,
  updateDepartment,
} from '../../actions/departmentActions';

import {
  Grid,
  makeStyles,
  Button,
  TextField,
  createMuiTheme,
  ThemeProvider,
} from '@material-ui/core';

import clsx from 'clsx';
import Spinner from '../../components/layout/Spinner';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#00acee',
    },
    secondary: {
      main: '#a8dda8',
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
    marginTop: theme.spacing(3),
  },
  textField: {
    width: '100%',
  },
  btnStyle: {
    color: '#FFF',
  },
  btn: {
    color: '#FFF',
    marginRight: '10px',
  },
  btnSecondary: {
    // color: '#00acee',
    marginRight: '10px',
  },
  my20: {
    marginBottom: '20px',
  },
}));

const DepartmentViewScreen = ({ history, match }) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const departmentDetails = useSelector((state) => state.departmentDetails);
  const { loading: loadingDetails, department } = departmentDetails;

  const departmentUpdate = useSelector((state) => state.departmentUpdate);
  const {
    loading: loadingUpdate,
    department: updatedDepartment,
    success: successUpdate,
  } = departmentUpdate;

  const auth = useSelector((state) => state.auth);
  const { loading: authLoading, role } = auth;

  const [departmentName, setDepartmentName] = useState('');
  const [deptShortCode, setDeptShortCode] = useState('');
  const [details, setDetails] = useState('');

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(
      updateDepartment(
        { departmentName, deptShortCode, details },
        department._id
      )
    );
  };

  useEffect(() => {
    if (successUpdate) {
      setTimeout(() => {
        history.push(`/departments`);
      }, 1000);
    } else {
      if (
        !department ||
        !department.departmentName ||
        department._id !== match.params.id
      ) {
        dispatch(getDepartment(match.params.id));
      } else {
        if (departmentName.length === 0) {
          setDepartmentName(department.departmentName);
          setDeptShortCode(department.deptShortCode);
          setDetails(department.details);
        }
      }
    }
  }, [dispatch, department, match, successUpdate]);

  return (
    <ThemeProvider theme={theme}>
      <div className="container">
        <h1 className="large text-primary">Edit Exam</h1>
        {/* <p className="lead">
      <i className="fa fa-check"></i> Please fill up the form and create a
      new exam.
    </p>
    <small>* marked are required field</small> */}
        <Divider /> <br />
        <form onSubmit={(e) => onSubmit(e)}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <TextField
                type="text"
                name="departmentName"
                label="Name of the department"
                value={loadingDetails ? 'Loading...' : departmentName}
                helperText={'Please enter the name of the department'}
                variant="outlined"
                onChange={(e) => setDepartmentName(e.target.value)}
                className={clsx(classes.textField)}
                inputProps={{ readOnly: true }}
              ></TextField>
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                type="text"
                name="shortCode"
                label="Short Code of the department"
                value={loadingDetails ? 'Loading...' : deptShortCode}
                helperText={'Please enter the short code of the department'}
                variant="outlined"
                onChange={(e) => setDeptShortCode(e.target.value)}
                className={clsx(classes.textField)}
              ></TextField>
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                type="text"
                multiline
                rows={5}
                name="details"
                label="Details of the department"
                value={loadingDetails ? 'Loading...' : details}
                helperText={'Please enter the details of the department'}
                variant="outlined"
                onChange={(e) => setDetails(e.target.value)}
                className={clsx(classes.textField)}
              ></TextField>
            </Grid>

            <Grid item xs={12}>
              {!authLoading && (
                <Link to={`/departments`}>
                  <Button
                    variant="contained"
                    color="default"
                    disableElevation
                    className={classes.btnSecondary}
                  >
                    <i className="fas fa-arrow-left"></i> &nbsp; Back
                  </Button>
                </Link>
              )}

              {!authLoading && role.includes('ADMIN') && (
                <Button
                  variant="contained"
                  color="primary"
                  disableElevation
                  className={classes.btn}
                  type="submit"
                >
                  Create
                  {loadingUpdate ? (
                    <>
                      &nbsp;<i className="fas fa-circle-notch fa-spin"></i>
                    </>
                  ) : (
                    <>
                      &nbsp;<i className="fas fa-check"></i>
                    </>
                  )}
                </Button>
              )}
            </Grid>
          </Grid>
          <AlertComponent />
        </form>
      </div>
    </ThemeProvider>
  );
};

export default DepartmentViewScreen;
