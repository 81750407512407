import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { useSelector } from 'react-redux';
import ExamIllustration from '../../img/illustrations/exam_illustration.svg';
import TeamMemberIllustration from '../../img/illustrations/team_members_illustration.svg';
import ApplicantsIllustration from '../../img/illustrations/applicants_illustration.svg';
import NoticeBoardIllustration from '../../img/illustrations/notice_board_illustration.svg';
import PaymentsIllustration from '../../img/illustrations/payments_illustration.svg';
import MakePaymentIllustration from '../../img/illustrations/make_payment_illustration.svg';
import UsersIllustration from '../../img/illustrations/team_illustration.svg';
import CreateProfileIllustration from '../../img/illustrations/create_profile_illustration.svg';
import UploadPictureIllustration from '../../img/illustrations/upload_picture_illustration.svg';
import ViewProfileIllustration from '../../img/illustrations/view_profile_illustration.svg';
import DownloadIllustration from '../../img/illustrations/download_illustration.svg';

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
    minHeight: 280,
    // background: '#F4F4F4',
    border: '1px solid #E0E0E0',
  },
  media: {
    height: 140,
  },
  textBlack: {
    color: '#000',
  },
});

export const ActionsSelectProgram = () => {
  return (
    <div className="dash-buttons">
      <Link to="/profile" className="btn btn-light">
        <i className="fas fa-user-circle text-primary"></i> &nbsp; View Profile
      </Link>
      <Link to="/edit-profile" className="btn btn-light">
        <i className="fa fa-pencil-square-o text-primary"></i> &nbsp; Edit
        Profile
      </Link>
      <Link to="/programs" className="btn btn-light">
        <i className="fa fa-arrow-right text-primary"></i> &nbsp; Select Program
      </Link>
    </div>
  );
};

export const CreateProfile = () => {
  const classes = useStyles();
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6} md={3}>
        <Card variant="outlined">
          <Link to="/create-profile" style={{ color: '#000' }}>
            <CardActionArea>
              <CardMedia
                className={classes.media}
                image={CreateProfileIllustration}
                title="Create Profile"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  Create Profile
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  Select your desired program and create profile
                </Typography>
              </CardContent>
            </CardActionArea>
          </Link>
          <CardActions>
            <Link to="/create-profile">
              <Button size="small" style={{ color: '#00acee' }}>
                Create
              </Button>
            </Link>
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  );
};

export const Step2Upload = () => {
  const classes = useStyles();
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6} md={3}>
        <Card variant="outlined">
          <Link to="/profile" style={{ color: '#000' }}>
            <CardActionArea>
              <CardMedia
                className={classes.media}
                image={ViewProfileIllustration}
                title="View Profile"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  View Profile
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  View your profile <br /> <br />
                </Typography>
              </CardContent>
            </CardActionArea>
          </Link>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <Card variant="outlined">
          <Link to="/upload" style={{ color: '#000' }}>
            <CardActionArea>
              <CardMedia
                className={classes.media}
                image={UploadPictureIllustration}
                title="Upload profile picture and signature"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  Upload
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  Upload profile picture and signature
                </Typography>
              </CardContent>
            </CardActionArea>
          </Link>
        </Card>
      </Grid>
    </Grid>
  );
};

export const Step3Payment = () => {
  const auth = useSelector((state) => state.auth);
  const { loading, isAuthenticated, role, user } = auth;
  const classes = useStyles();
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6} md={3}>
        <Card variant="outlined">
          <Link to="/profile" style={{ color: '#000' }}>
            <CardActionArea>
              <CardMedia
                className={classes.media}
                image={ViewProfileIllustration}
                title="View Profile"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  View Profile
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  View your profile <br /> <br />
                </Typography>
              </CardContent>
            </CardActionArea>
          </Link>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <Card variant="outlined">
          <Link to="/upload" style={{ color: '#000' }}>
            <CardActionArea>
              <CardMedia
                className={classes.media}
                image={UploadPictureIllustration}
                title="Upload profile picture and signature"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  Change Image
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  Change profile picture and signature
                </Typography>
              </CardContent>
            </CardActionArea>
          </Link>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <Card variant="outlined">
          <Link to="/payment" style={{ color: '#000' }}>
            <CardActionArea>
              <CardMedia
                className={classes.media}
                image={MakePaymentIllustration}
                title="Make payment"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  {user && user?.paymentInfo?.status === 'PAID'
                    ? 'Payment Complete'
                    : 'Make Payment'}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  {user && user?.paymentInfo?.status === 'PAID'
                    ? `Method: ${user?.paymentInfo?.paymentMethod} | Transaction ID: ${user?.paymentInfo?.bkashInfo?.trxID}`
                    : 'Make your payment online through bKash or Bank'}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Link>
        </Card>
      </Grid>
      {user && user?.paymentInfo?.status === 'PAID' && (
        <Grid item xs={12} sm={6} md={3}>
          <Card variant="outlined">
            <Link to="/download-admit" style={{ color: '#000' }}>
              <CardActionArea>
                <CardMedia
                  className={classes.media}
                  image={DownloadIllustration}
                  title="Download Admit"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    Download Admit
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    You can now download admit card for the admission test
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Link>
          </Card>
        </Grid>
      )}
    </Grid>
  );
};

export const Step4Download = () => {
  return (
    <div className="dash-buttons">
      <Link to="/profile" className="btn btn-light">
        <i className="fas fa-user-circle text-primary"></i> &nbsp; View Profile
      </Link>
      <Link to="/upload" className="btn btn-light">
        <i className="fa fa-pencil-square-o text-primary"></i> &nbsp; Change
        Image
      </Link>
      <Link to="/payment" className="btn btn-light">
        <i className="fa fa-pencil-square-o text-primary"></i> &nbsp; Payment
      </Link>
      <Link to="/payment" className="btn btn-light">
        <i className="fa fa-pencil-square-o text-primary"></i> &nbsp; Download
        Admit
      </Link>
    </div>
  );
};

export const ActionsUploadImage = () => {
  return (
    <div className="dash-buttons">
      <Link to="/profile" className="btn btn-light">
        <i className="fas fa-user-circle text-primary"></i> &nbsp; View Profile
      </Link>
      <Link to="/edit-profile" className="btn btn-light">
        <i className="fa fa-pencil-square-o text-primary"></i> &nbsp; Edit
        Profile
      </Link>
      <Link to="/programs" className="btn btn-light">
        <i className="fa fa-arrow-right text-primary"></i> &nbsp; Select Program
      </Link>
      <Link to="/upload" className="btn btn-light">
        <i className="fa fa-camera text-primary"></i> &nbsp; Upload Picture
      </Link>
      <Link to="/notices" className="btn btn-light">
        <i className="far fa-newspaper text-primary"></i> &nbsp; Notices
      </Link>
    </div>
  );
};

export const DashboardActionsNoPic = () => {
  return (
    <div className="dash-buttons">
      <Link to="/profile" className="btn btn-light">
        <i className="fas fa-user-circle text-primary"></i> &nbsp; View Profile
      </Link>
      <Link to="/edit-profile" className="btn btn-light">
        <i className="fa fa-pencil-square-o text-primary"></i> &nbsp; Edit
        Profile
      </Link>
      <Link to="/programs" className="btn btn-light">
        <i className="fa fa-arrow-right text-primary"></i> &nbsp; Select Program
      </Link>
      <Link to="/upload" className="btn btn-light">
        <i className="fa fa-camera text-primary"></i> &nbsp; Change Picture
      </Link>
      <Link to="/payment" className="btn btn-light">
        <i className="fa fa-arrow-right text-primary"></i> &nbsp; Payment
      </Link>
    </div>
  );
};

export const DashboardActionsAdmin = () => {
  const auth = useSelector((state) => state.auth);
  const { loading, isAuthenticated, role } = auth;
  const classes = useStyles();
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6} md={3}>
        <Card variant="outlined" className={classes.root}>
          <Link to="/exams" style={{ color: '#000' }}>
            <CardActionArea>
              <CardMedia
                className={classes.media}
                image={ExamIllustration}
                title="Exams"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  Exams
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  Create, View, Edit Exams
                </Typography>
              </CardContent>
            </CardActionArea>
          </Link>
          <CardActions>
            <Link to="/create-exam">
              <Button size="small" style={{ color: '#00acee' }}>
                Add
              </Button>
            </Link>
            <Link to="/exams">
              <Button size="small" style={{ color: '#00acee' }}>
                View All
              </Button>
            </Link>
          </CardActions>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <Link to="/students" className="">
          <Card variant="outlined" className={classes.root}>
            <CardActionArea>
              <CardMedia
                className={classes.media}
                image={ApplicantsIllustration}
                title="Applicants"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  Applicants
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  Search, View, Download Applicant Info
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Link>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <Card variant="outlined" className={classes.root}>
          <Link to="/notices" style={{ color: '#000' }}>
            <CardActionArea>
              <CardMedia
                className={classes.media}
                image={NoticeBoardIllustration}
                title="Notices"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  Notice
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  Create, View, Edit Notices
                </Typography>
              </CardContent>
            </CardActionArea>
          </Link>
          <CardActions>
            <Link to="/create-notice">
              <Button size="small" style={{ color: '#00acee' }}>
                Add
              </Button>
            </Link>
            <Link to="/notices">
              <Button size="small" style={{ color: '#00acee' }}>
                View All
              </Button>
            </Link>
          </CardActions>
        </Card>
      </Grid>
      {/* <Grid item xs={12} sm={6} md={3}>
        <Link to="/payment/pending" className="">
          <Card variant="outlined" className={classes.root}>
            <CardActionArea>
              <CardMedia
                className={classes.media}
                image={PaymentsIllustration}
                title="Pending Payments"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  Pending Payments
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  View pending payments
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Link>
      </Grid> */}
      <Grid item xs={12} sm={6} md={3}>
        <Card variant="outlined" className={classes.root}>
          <Link to="/departments" style={{ color: '#000' }}>
            <CardActionArea>
              <CardMedia
                className={classes.media}
                image={NoticeBoardIllustration}
                title="Departments"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  Departments
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  Create, View, Edit Departments
                </Typography>
              </CardContent>
            </CardActionArea>
          </Link>
          <CardActions>
            <Link to="/departments/create">
              <Button size="small" style={{ color: '#00acee' }}>
                Add
              </Button>
            </Link>
            <Link to="/departments">
              <Button size="small" style={{ color: '#00acee' }}>
                View All
              </Button>
            </Link>
          </CardActions>
        </Card>
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <Card variant="outlined" className={classes.root}>
          <Link to="/programs" style={{ color: '#000' }}>
            <CardActionArea>
              <CardMedia
                className={classes.media}
                image={NoticeBoardIllustration}
                title="programs"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  Programs
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  Create, View, Edit Programs
                </Typography>
              </CardContent>
            </CardActionArea>
          </Link>
          <CardActions>
            <Link to="/programs/create">
              <Button size="small" style={{ color: '#00acee' }}>
                Add
              </Button>
            </Link>
            <Link to="/programs">
              <Button size="small" style={{ color: '#00acee' }}>
                View All
              </Button>
            </Link>
          </CardActions>
        </Card>
      </Grid>

      {!loading && role === 'SUPERADMIN' ? (
        <Grid item xs={12} sm={3}>
          <Link to="/users" className="">
            <Card variant="outlined" className={classes.root}>
              <CardActionArea>
                <CardMedia
                  className={classes.media}
                  image={UsersIllustration}
                  title="Users"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    Users
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    View, Add, Edit Admin, Superadmin & Student Users
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Link>
        </Grid>
      ) : null}
    </Grid>
  );
};
