import {
  Button,
  Card,
  CardContent,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  registerUserStep1,
  resetPasswordStep2,
} from '../../actions/userActions';
import AlertComponent from '../../components/layout/AlertComponent';
import { checkEmail } from '../../utils/checkEmail';
import { Link, Redirect } from 'react-router-dom';
import { useQuery } from '../../utils/useQuery';
import { USER_RESET_STEP2_RESET } from '../../constants/userConstants';
import Logo from '../../img/logo.png';
// import AlertComponent from '../layout/AlertComponent';

const ResetScreenStep3 = ({ history }) => {
  const dispatch = useDispatch();
  let query = useQuery();

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState('password');

  const userResetStep2 = useSelector((state) => state.userResetStep2);
  const { loading: loadingUserResetStep2, data, success } = userResetStep2;

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        history.push(`/login`);
      }, '3000');
    }
  }, [success]);

  // reset pass step 2
  const onSubmit = (e) => {
    e.preventDefault();
    if (password.length < 8) {
      let registerPassword = document.getElementById('registerPassword');
      registerPassword.focus();
      window.alert('Passwords must be at least 8 characters or more');
    } else if (password !== confirmPassword) {
      let registerConfirmPassword = document.getElementById(
        'registerConfirmPassword'
      );
      registerConfirmPassword.focus();
      window.alert('Passwords do not match');
    } else
      dispatch(
        resetPasswordStep2(query.get('token'), password, confirmPassword)
      );
  };

  return (
    <div className="container">
      <Grid
        container
        spacing={0}
        alignItems="center"
        justify="center"
        style={{ minHeight: '100vh' }}
      >
        <Grid item xs={12} sm={6} md={6}>
          <Card variant="outlined">
            <CardContent>
              <form onSubmit={(e) => onSubmit(e)}>
                <Grid container spacing={2}>
                  <Grid item xs={12} align="center">
                    <img src={Logo} className="logo-100" alt="Logo" />
                    <Typography variant="h3">Set New Password</Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      size="small"
                      type={showPassword}
                      name="password"
                      label="Password"
                      id="registerPassword"
                      value={password}
                      helperText={'Enter password'}
                      variant="outlined"
                      onChange={(e) => setPassword(e.target.value)}
                      fullWidth
                    ></TextField>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      size="small"
                      type={showPassword}
                      name="password"
                      label="Confirm Password"
                      id="registerConfirmPassword"
                      value={confirmPassword}
                      helperText={'Enter password again'}
                      variant="outlined"
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      fullWidth
                    ></TextField>
                    <FormControlLabel
                      control={
                        <Checkbox
                          size="small"
                          color="primary"
                          onClick={(e) =>
                            setShowPassword(
                              showPassword === 'password' ? 'text' : 'password'
                            )
                          }
                        />
                      }
                      label="Show Password"
                      labelPlacement="end"
                    />
                  </Grid>
                  <Grid item xs={12} variant={'contained'}>
                    <AlertComponent />
                    <Link to={`/reset`}>
                      <Button
                        variant="contained"
                        color="default"
                        disableElevation
                      >
                        Start Over
                      </Button>
                    </Link>{' '}
                    <Button
                      variant="contained"
                      color="primary"
                      disableElevation
                      type="submit"
                    >
                      <span>Reset</span>
                      {loadingUserResetStep2 ? (
                        <>
                          &nbsp;
                          <i className="fas fa-circle-notch fa-spin loadingIndicator"></i>
                        </>
                      ) : null}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default ResetScreenStep3;
