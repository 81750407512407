import {
  EXAM_CREATE_FAIL,
  EXAM_CREATE_REQUEST,
  EXAM_CREATE_RESET,
  EXAM_CREATE_SUCCESS,
  EXAM_DELETE_FAIL,
  EXAM_DELETE_REQUEST,
  EXAM_DELETE_RESET,
  EXAM_DELETE_SUCCESS,
  EXAM_DETAILS_FAIL,
  EXAM_DETAILS_REQUEST,
  EXAM_DETAILS_SUCCESS,
  EXAM_LIST_FAIL,
  EXAM_LIST_REMOVE_ITEM,
  EXAM_LIST_REQUEST,
  EXAM_LIST_SUCCESS,
  EXAM_STUDENT_LIST_FAIL,
  EXAM_STUDENT_LIST_REMOVE_ITEM,
  EXAM_STUDENT_LIST_REQUEST,
  EXAM_STUDENT_LIST_SUCCESS,
  EXAM_UPDATE_FAIL,
  EXAM_UPDATE_REQUEST,
  EXAM_UPDATE_RESET,
  EXAM_UPDATE_SUCCESS,
} from '../constants/examConstants';

// Get Exams
export const examListReducer = (state = { exams: [] }, action) => {
  switch (action.type) {
    case EXAM_LIST_REQUEST:
      return { loading: true, exams: [] };
    case EXAM_LIST_SUCCESS:
      return {
        loading: false,
        exams: action.payload.exams,
        count: action.payload.count,
        studentsInExam: action.payload.studentsInExam,
      };
    case EXAM_LIST_FAIL:
      return { loading: false, error: action.payload };
    case EXAM_LIST_REMOVE_ITEM:
      return {
        ...state,
        exams: state.exams.filter((exam) => exam._id !== action.payload),
        count: state.count - 1,
      };
    default:
      return state;
  }
};

// Get Applicants in an Exams
export const examStudentListReducer = (state = {}, action) => {
  switch (action.type) {
    case EXAM_STUDENT_LIST_REQUEST:
      return { loading: true };
    case EXAM_STUDENT_LIST_SUCCESS:
      return {
        loading: false,
        students: action.payload.students,
        studentsInExam: action.payload.studentsInExam,
        count: action.payload.count,
      };
    case EXAM_STUDENT_LIST_FAIL:
      return { loading: false, error: action.payload };
    case EXAM_STUDENT_LIST_REMOVE_ITEM:
      return {
        ...state,
        exams: state.exams.filter((exam) => exam._id !== action.payload),
        count: state.count - 1,
      };
    default:
      return state;
  }
};

// Get Single Exam
export const examDetailsReducer = (
  state = { exam: { loading: false } },
  action
) => {
  switch (action.type) {
    case EXAM_DETAILS_REQUEST:
      return { loading: true };
    case EXAM_DETAILS_SUCCESS:
      return {
        loading: false,
        exam: action.payload,
      };
    case EXAM_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// Create Notice
export const noticeCreateReducer = (state = { notice: {} }, action) => {
  switch (action.type) {
    case EXAM_CREATE_REQUEST:
      return { loading: true, notice: {} };
    case EXAM_CREATE_SUCCESS:
      return {
        loading: false,
        success: true,
        notice: action.payload.notice,
      };
    case EXAM_CREATE_FAIL:
      return { loading: false, success: false, error: action.payload };
    case EXAM_CREATE_RESET:
      return { loading: false, success: false };
    default:
      return state;
  }
};

// Update Exam
export const examUpdateReducer = (state = { loading: false }, action) => {
  switch (action.type) {
    case EXAM_UPDATE_REQUEST:
      return { loading: true };
    case EXAM_UPDATE_SUCCESS:
      return {
        loading: false,
        exam: action.payload.exam,
        success: true,
      };
    case EXAM_UPDATE_FAIL:
      return { loading: false, error: action.payload, success: false };
    case EXAM_UPDATE_RESET:
      return { loading: false, success: false };
    default:
      return state;
  }
};

// Delete Exam
export const examDeleteReducer = (
  state = { loading: false, success: false },
  action
) => {
  switch (action.type) {
    case EXAM_DELETE_REQUEST:
      return { loading: true };
    case EXAM_DELETE_SUCCESS:
      return { loading: false, success: true };
    case EXAM_DELETE_FAIL:
      return { loading: false, success: false, error: action.payload };
    case EXAM_DELETE_RESET:
      return { loading: false, success: false };
    default:
      return state;
  }
};
