import React, { useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect, useDispatch, useSelector } from 'react-redux';
import Divider from '@material-ui/core/Divider';
import { addExam } from '../../actions/exam';

import { listPrograms } from '../../actions/programActions';

import AlertComponent from '../layout/AlertComponent';

import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import {
  DatePicker,
  DateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import InsertInvitationIcon from '@material-ui/icons/InsertInvitation';

import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import {
  Button,
  Chip,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  MenuItem,
  TextField,
} from '@material-ui/core';
import clsx from 'clsx';
import Spinner from '../layout/Spinner';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#00acee',
    },
    secondary: {
      main: '#a8dda8',
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
    marginTop: theme.spacing(3),
  },
  textField: {
    width: '100%',
  },
  btnStyle: {
    color: '#FFF',
  },
  btnSecondary: {
    marginRight: '10px',
  },
}));

const CreateExam = ({ addExam, history, isAuthenticated, role }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    title: '',
    description: '',
    program: '',
    batch: '',
    trimester: '',
    conducted: false,
  });
  const [examDate, setExamDate] = useState(new Date());
  const [year, setYear] = useState(null);

  const { title, description, program, batch, trimester, conducted } = formData;

  const programList = useSelector((state) => state.programList);
  const { loading: loadingList, programs, error } = programList;

  useEffect(() => {
    dispatch(listPrograms());
    // eslint-disable-next-line
  }, []);

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();
    addExam({ ...formData, examDate, year: year.getFullYear() }, history);
  };

  const minYear = () => {
    const currDate = new Date();
    return currDate.getFullYear();
  };

  return (
    <ThemeProvider theme={theme}>
      <div className="container">
        <h1 className="large text-primary">Create New Exam</h1>
        <p className="lead">
          <i className="fa fa-check"></i> Please fill up the form and create a
          new exam.
        </p>
        <small>* marked are required field</small>
        {loadingList && <Spinner />}
        <Divider /> <br />
        <form className="" onSubmit={(e) => onSubmit(e)}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <TextField
                size="small"
                type="text"
                name="title"
                label="Title of the exam"
                value={title}
                helperText={'Please enter the main title of the exam'}
                variant="outlined"
                onChange={(e) => onChange(e)}
                className={clsx(classes.textField)}
              ></TextField>
            </Grid>

            <Grid item xs={12} sm={12}>
              <TextField
                size="small"
                label="Description"
                multiline
                rows={2}
                variant="outlined"
                className={clsx(classes.textField)}
                type="text"
                name="description"
                helperText={'A small description about the exam'}
                value={description}
                onChange={(e) => onChange(e)}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                size="small"
                select
                name="program"
                label="Program*"
                value={program}
                helperText={'Select Program'}
                variant="outlined"
                onChange={(e) => onChange(e)}
                className={clsx(classes.textField)}
              >
                <MenuItem disabled> Undergraduate</MenuItem>
                {programs && programs.length > 0
                  ? programs?.map((p) =>
                      p.programType === 'undergraduate' ? (
                        <MenuItem value={p._id} key={p._id}>
                          {p.programName} &nbsp;
                          <Chip
                            label={p.department.departmentName}
                            size="small"
                          ></Chip>
                        </MenuItem>
                      ) : null
                    )
                  : null}
                <MenuItem disabled> Graduate</MenuItem>
                {programs && programs.length > 0
                  ? programs?.map((p) =>
                      p.programType === 'graduate' ? (
                        <MenuItem value={p._id} key={p._id}>
                          {p.programName}
                          &nbsp;
                          <Chip
                            label={p.department.departmentName}
                            size="small"
                          ></Chip>
                        </MenuItem>
                      ) : null
                    )
                  : null}
              </TextField>
            </Grid>

            <Grid item xs={12} sm={4}>
              <TextField
                size="small"
                type="text"
                name="batch"
                label="Batch"
                value={batch}
                helperText={'Ongoing batch for this department'}
                variant="outlined"
                onChange={(e) => onChange(e)}
                className={clsx(classes.textField)}
              ></TextField>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                size="small"
                select
                name="trimester"
                label="Trimester"
                value={trimester}
                helperText={'Select trimester'}
                variant="outlined"
                onChange={(e) => onChange(e)}
                className={clsx(classes.textField)}
              >
                <MenuItem value={''}>* Select the trimester</MenuItem>
                <MenuItem value={'Spring'}>Spring</MenuItem>
                <MenuItem value={'Summer'}>Summer</MenuItem>
                <MenuItem value={'Fall'}>Fall</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} sm={3} align="center">
              <MuiPickersUtilsProvider utils={DateFnsUtils} name="year">
                <DatePicker
                  name="year"
                  size="small"
                  inputVariant="outlined"
                  views={['year']}
                  helperText={'Select Exam Year'}
                  label="Year"
                  value={year}
                  onChange={setYear}
                  autoOk={true}
                />
                <i
                  className="fas fa-times"
                  style={{
                    color: 'red',
                    cursor: 'pointer',
                    display: year == null ? 'none' : '',
                    marginLeft: '5px',
                  }}
                  onClick={(e) => setYear(null)}
                ></i>
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                size="small"
                select
                name="conducted"
                label="Conducted?"
                value={conducted}
                helperText={'Has the exam taken place?'}
                variant="outlined"
                onChange={(e) => onChange(e)}
                className={clsx(classes.textField)}
              >
                <MenuItem value={false}>No</MenuItem>
                <MenuItem value={true}>Yes</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} sm={4}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DateTimePicker
                  size="small"
                  className={clsx(classes.textField)}
                  value={examDate}
                  onChange={setExamDate}
                  onError={console.log}
                  disablePast
                  inputVariant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton>
                          <InsertInvitationIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  label="Date of exam."
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>

          <AlertComponent />
          <div className="form-group" style={{ marginTop: '20px' }}>
            <Link to={`/exams`}>
              <Button
                variant="contained"
                color="default"
                disableElevation
                className={classes.btnSecondary}
              >
                <i className="fas fa-arrow-left"></i> &nbsp; Back
              </Button>
            </Link>
            &nbsp;
            <Button
              disableElevation
              type="submit"
              variant="contained"
              color="primary"
              className={clsx(classes.btnStyle)}
            >
              Submit
              <>
                &nbsp;<i className="fas fa-check"></i>
              </>
            </Button>
          </div>
        </form>
      </div>
    </ThemeProvider>
  );
};

CreateExam.propTypes = {
  addExam: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  role: PropTypes.string,
};

const mapStateToProps = (state) => ({
  exam: state.exam,
  isAuthenticated: state.auth.isAuthenticated,
  role: state.auth.role,
});

export default connect(mapStateToProps, { addExam })(withRouter(CreateExam));
