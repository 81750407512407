import {
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { registerUserStep1, verifyCode } from '../../actions/userActions';
import AlertComponent from '../../components/layout/AlertComponent';
import { checkEmail } from '../../utils/checkEmail';
import { useQuery } from '../../utils/useQuery';
// import AlertComponent from '../layout/AlertComponent';
import Logo from '../../img/logo.png';
import {
  USER_REGISTER_STEP1_RESET,
  USER_VERIFY_PHONE_RESET,
} from '../../constants/userConstants';

const RegisterScreenStep2 = ({ history }) => {
  const dispatch = useDispatch();
  const [verificationCode, setVerificationCode] = useState('');

  let query = useQuery();

  const auth = useSelector((state) => state.auth);
  const { isAuthenticated, role } = auth;

  const userVerifyPhone = useSelector((state) => state.userVerifyPhone);
  const {
    loading: loadingUserVerifyPhone,
    _id,
    token,
    success,
  } = userVerifyPhone;

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        history.push(`/dashboard`);
        dispatch({ type: USER_REGISTER_STEP1_RESET });
        dispatch({ type: USER_VERIFY_PHONE_RESET });
      }, '3000');
    }

    if (!query.get('token') || query.get('token').length === 0) {
      console.log('No token found in query params');
      history.push(`/register`);
    }
  }, [success]);

  // Redirect if logged in

  // registerUserStep1
  const onSubmit = (e) => {
    e.preventDefault();
    if (verificationCode.length === 0) {
      let registerVerificationCode = document.getElementById(
        'registerVerificationCode'
      );
      registerVerificationCode.focus();
      window.alert(
        'Please type in the verification code that you received on your phone'
      );
    } else dispatch(verifyCode(query.get('token'), verificationCode, false));
  };

  return (
    <div className="container">
      <Grid
        container
        alignItems="center"
        justify="center"
        style={{ minHeight: '80vh' }}
      >
        <Grid item xs={12} sm={6} md={6}>
          <Card variant="outlined">
            <CardContent>
              <form onSubmit={(e) => onSubmit(e)}>
                <Grid container spacing={2}>
                  <Grid item xs={12} align="center">
                    <img src={Logo} className="logo-100" alt="Logo" />
                    <Typography variant="h3">Verify phone number</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      size="small"
                      type="number"
                      name="verificationCode"
                      label="Verification Code"
                      id="registerVerificationCode"
                      value={verificationCode}
                      helperText={`A verification code was sent to ${query.get(
                        'phone'
                      )}. If the number is incorrect plese start over.`}
                      variant="outlined"
                      onChange={(e) => setVerificationCode(e.target.value)}
                      fullWidth
                    ></TextField>
                  </Grid>
                  <Grid item xs={12} variant={'contained'}>
                    <AlertComponent />
                    <Link to={`/register`}>
                      <Button
                        variant="contained"
                        color="default"
                        disableElevation
                      >
                        Start Over
                      </Button>
                    </Link>{' '}
                    &nbsp;
                    <Button
                      variant="contained"
                      color="primary"
                      disableElevation
                      type="submit"
                    >
                      <span>Register</span>
                      {loadingUserVerifyPhone ? (
                        <>
                          &nbsp;
                          <i className="fas fa-circle-notch fa-spin loadingIndicator"></i>
                        </>
                      ) : null}
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <small>
                      Already have an account? <Link to="/login">Sign In</Link>{' '}
                      | Forgot password? <Link to="/reset">Reset</Link>
                    </small>
                  </Grid>
                </Grid>
              </form>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default RegisterScreenStep2;
