import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Divider from '@material-ui/core/Divider';
import AlertComponent from '../layout/AlertComponent';
import TextField from '@material-ui/core/TextField';
import clsx from 'clsx';
import { v4 as uuid } from 'uuid';
import {
  makeStyles,
  createMuiTheme,
  ThemeProvider,
} from '@material-ui/core/styles';

import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import Icon from '@material-ui/core/Icon';

import MenuItem from '@material-ui/core/MenuItem';

import Typography from '@material-ui/core/Typography';

import Grid from '@material-ui/core/Grid';
import { Fragment } from 'react';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#00acee',
    },
    secondary: {
      main: '#fd3a69',
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    // display: 'flex',
    // flexWrap: 'wrap',
    justifyContent: 'center',
    marginTop: theme.spacing(3),
  },
  margin: {
    // margin: theme.spacing(1),
  },
  marginDate: {
    marginBottom: '-3px',
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: '100%',
  },
  dateOfBirthErrorClass: {
    borderColor: 'red !important',
  },
}));

const Step5Academic = ({
  onChange,
  nextStep,
  formData: {
    sscInstitutionName,
    sscGroup,
    sscPassingYear,
    sscGpa,
    sscSubjects,
    hscInstitutionName,
    hscGroup,
    hscPassingYear,
    hscGpa,
    hscSubjects,

    examCourseType,

    gradDegreeType,
    gradDegreeInstitutionName,
    gradDegreeCourseName,
    gradDegreeMajor,
    gradDegreeAcademicYearFrom,
    gradDegreeAcademicYearTo,
    gradDegreeCGpa,

    mastersDegreeType,
    mastersDegreeInstitutionName,
    mastersDegreeCourseName,
    mastersDegreeMajor,
    mastersDegreeAcademicYearFrom,
    mastersDegreeAcademicYearTo,
    mastersDegreeCGpa,
  },
  prevStep,
  handleChangeSscSubjectsFields,
  handleAddSscFields,
  handleRemoveSscFields,
  handleChangeHscSubjectsFields,
  handleAddHscFields,
  handleRemoveHscFields,
  steps,
  currentStepMobile,
}) => {
  const classes = useStyles();

  const [errorTexts, setErrorTexts] = useState({
    sscInstitutionNameError: false,
    sscInstitutionNameText: 'Please enter your ssc institution name*',
    sscGroupError: false,
    sscGroupText: 'Please select your ssc group*',
    sscPassingYearError: false,
    sscPassingYearText: 'Please enter your ssc passing year*',
    sscGpaError: false,
    sscGpaText: 'SSC GPA (with optional subject)*',

    hscInstitutionNameError: false,
    hscInstitutionNameText: 'Please enter your hsc institution name*',
    hscGroupError: false,
    hscGroupText: 'Please select your hsc group*',
    hscPassingYearError: false,
    hscPassingYearText: 'Please enter your hsc passing year*',
    hscGpaError: false,
    hscGpaText: 'HSC GPA (with optional subject)*',

    gradDegreeTypeError: false,
    gradDegreeTypeText: 'Degree Type*',
    gradDegreeInstitutionNameError: false,
    gradDegreeInstitutionNameText: 'Institution name*',
    gradDegreeCourseNameError: false,
    gradDegreeCourseNameText: 'Course name*',
    gradDegreeAcademicYearFromError: false,
    gradDegreeAcademicYearFromText: 'Staring year (academic)*',
    gradDegreeAcademicYearToError: false,
    gradDegreeAcademicYearToText: 'Ending year (academic)*',
    gradDegreeCGpaError: false,
    gradDegreeCGpaText: 'Please enter your CGPA*',
  });

  const {
    sscInstitutionNameError,
    sscInstitutionNameText,
    sscGroupError,
    sscGroupText,
    sscPassingYearError,
    sscPassingYearText,
    sscGpaError,
    sscGpaText,
    hscInstitutionNameError,
    hscInstitutionNameText,
    hscGroupError,
    hscGroupText,
    hscPassingYearError,
    hscPassingYearText,
    hscGpaError,
    hscGpaText,
    gradDegreeTypeError,
    gradDegreeTypeText,
    gradDegreeInstitutionNameError,
    gradDegreeInstitutionNameText,
    gradDegreeCourseNameError,
    gradDegreeCourseNameText,
    gradDegreeAcademicYearFromError,
    gradDegreeAcademicYearFromText,
    gradDegreeAcademicYearToError,
    gradDegreeAcademicYearToText,
    gradDegreeCGpaError,
    gradDegreeCGpaText,
  } = errorTexts;

  const validate = () => {
    let errors = {};

    if (sscInstitutionName === '') {
      errors.sscInstitutionNameError = true;
      errors.sscInstitutionNameText =
        'You must enter your ssc institution name';
    }

    if (sscGroup === '') {
      errors.sscGroupError = true;
      errors.sscGroupText = 'You must select your ssc group';
    }

    if (sscPassingYear === '') {
      errors.sscPassingYearError = true;
      errors.sscPassingYearText = 'You must enter your ssc passing year';
    }

    if (sscGpa === '') {
      errors.sscGpaError = true;
      errors.sscGpaText = 'You must enter your ssc gpa';
    }

    if (sscGpa < 1 || sscGpa > 5) {
      errors.sscGpaError = true;
      errors.sscGpaText = 'SSC GPA should be in between 1.00 to 5.00';
    }

    if (hscInstitutionName === '') {
      errors.hscInstitutionNameError = true;
      errors.hscInstitutionNameText =
        'You must enter your hsc institution name';
    }

    if (hscGroup === '') {
      errors.hscGroupError = true;
      errors.hscGroupText = 'You must select your hsc group';
    }

    if (hscPassingYear === '') {
      errors.hscPassingYearError = true;
      errors.hscPassingYearText = 'You must enter your hsc passing year';
    }

    if (hscGpa === '') {
      errors.hscGpaError = true;
      errors.hscGpaText = 'You must enter your hsc gpa';
    }

    if (hscGpa < 1 || hscGpa > 5) {
      errors.hscGpaError = true;
      errors.hscGpaText = 'HSC GPA should be in between 1.00 to 5.00';
    }

    if (examCourseType === 'Graduate') {
      if (gradDegreeType === '') {
        errors.gradDegreeTypeError = true;
        errors.gradDegreeTypeText = 'You must select your type';
      }

      if (gradDegreeInstitutionName === '') {
        errors.gradDegreeInstitutionNameError = true;
        errors.gradDegreeInstitutionNameText =
          'You must enter your institution name';
      }

      if (gradDegreeCourseName === '') {
        errors.gradDegreeCourseNameError = true;
        errors.gradDegreeCourseNameText = 'You must enter your course name';
      }

      if (gradDegreeAcademicYearFrom === '') {
        errors.gradDegreeAcademicYearFromError = true;
        errors.gradDegreeAcademicYearFromText =
          'You must enter your academic year';
      }

      if (gradDegreeAcademicYearTo === '') {
        errors.gradDegreeAcademicYearToError = true;
        errors.gradDegreeAcademicYearToText =
          'You must enter your academic year';
      }

      if (gradDegreeCGpa === '') {
        errors.gradDegreeCGpaError = true;
        errors.gradDegreeCGpaText = 'You must enter your grad CGPA';
      }

      if (gradDegreeCGpa < 1 || gradDegreeCGpa > 5) {
        errors.gradDegreeCGpaError = true;
        errors.gradDegreeCGpaText = 'CGPA should be in between 1.00 to 4.00';
      }
    }

    setErrorTexts(errors);

    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const isValid = validate();

    Object.entries(isValid).length === 0 && nextStep(e);
  };

  return (
    <ThemeProvider theme={theme}>
      <div className="container">
        <h1 className="large text-primary">Academic</h1>
        <div className="hide-sm">{steps}</div>
        <div className="hide-lg">{currentStepMobile}</div>
        <p className="lead">
          <i className="fas fa-user-graduate"></i> Please provide your academic
          information
        </p>
        <Divider />
        <form className={classes.root} onSubmit={(e) => handleSubmit(e)}>
          <Typography variant="button" component="h2" gutterBottom>
            SSC Information
          </Typography>
          <br />
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                error={sscInstitutionNameError}
                type="text"
                name="sscInstitutionName"
                label="Institution Name"
                value={sscInstitutionName}
                helperText={sscInstitutionNameText}
                variant="outlined"
                onChange={(e) => onChange(e)}
                className={clsx(classes.margin, classes.textField)}
              ></TextField>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                error={sscGroupError}
                select
                name="sscGroup"
                label="Group"
                value={sscGroup}
                helperText={sscGroupText}
                variant="outlined"
                onChange={(e) => onChange(e)}
                className={clsx(classes.margin, classes.textField)}
              >
                <MenuItem value={''}> Select</MenuItem>
                <MenuItem value={'Science'}>Science</MenuItem>
                <MenuItem value={'Commerce'}>Commerce</MenuItem>
                <MenuItem value={'Humanities'}>Humanities</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                error={sscPassingYearError}
                type="number"
                name="sscPassingYear"
                label="Passing Year"
                value={sscPassingYear}
                helperText={sscPassingYearText}
                variant="outlined"
                onChange={(e) => onChange(e)}
                className={clsx(classes.margin, classes.textField)}
              ></TextField>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                error={sscGpaError}
                type="number"
                name="sscGpa"
                label="Total GPA"
                value={sscGpa}
                helperText={sscGpaText}
                variant="outlined"
                onChange={(e) => onChange(e)}
                className={clsx(classes.margin, classes.textField)}
              ></TextField>
            </Grid>
            <br />
            <Divider />
            <br />
          </Grid>
          <br />
          <br />
          <Typography variant="button" component="h2" gutterBottom>
            Add ssc subjects (optional)
          </Typography>{' '}
          <br />
          {sscSubjects.map((sscSubject) => (
            <Grid
              container
              spacing={3}
              key={sscSubject.id}
              className={clsx(classes.margin)}
            >
              <Grid item xs={12} sm={6}>
                <TextField
                  name="subjectName"
                  label="Subject Name"
                  variant="outlined"
                  value={sscSubject.subjectName}
                  className={clsx(classes.margin, classes.textField)}
                  onChange={(e) =>
                    handleChangeSscSubjectsFields(sscSubject.id, e)
                  }
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  name="gradePoint"
                  select
                  label="Letter Grade & Grade Point"
                  variant="outlined"
                  className={clsx(classes.margin, classes.textField)}
                  value={sscSubject.gradePoint}
                  onChange={(e) =>
                    handleChangeSscSubjectsFields(sscSubject.id, e)
                  }
                >
                  <MenuItem value={''}> Select</MenuItem>
                  <MenuItem value={'5.0'}>A+ (5.0)</MenuItem>
                  <MenuItem value={'4.0'}>A (4.0)</MenuItem>
                  <MenuItem value={'3.5'}>A- (3.5)</MenuItem>
                  <MenuItem value={'3.0'}>B (3.0)</MenuItem>
                  <MenuItem value={'2.0'}>C (2.0)</MenuItem>
                  <MenuItem value={'1.0'}>D (1.0)</MenuItem>
                  <MenuItem value={'0.0'}>F (0.0)</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12} sm={2}>
                <IconButton onClick={handleAddSscFields} color="primary">
                  <AddIcon />
                </IconButton>
                <IconButton
                  disabled={sscSubjects.length === 1}
                  onClick={() => handleRemoveSscFields(sscSubject.id)}
                  color="secondary"
                >
                  <RemoveIcon />
                </IconButton>
              </Grid>
            </Grid>
          ))}
          <br />
          <Divider />
          <br />
          <Typography variant="button" component="h2" gutterBottom>
            HSC Information
          </Typography>
          <br />
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                error={hscInstitutionNameError}
                type="text"
                name="hscInstitutionName"
                label="Institution Name"
                value={hscInstitutionName}
                helperText={hscInstitutionNameText}
                variant="outlined"
                onChange={(e) => onChange(e)}
                className={clsx(classes.margin, classes.textField)}
              ></TextField>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                error={hscGroupError}
                select
                name="hscGroup"
                label="Group"
                value={hscGroup}
                helperText={hscGroupText}
                variant="outlined"
                onChange={(e) => onChange(e)}
                className={clsx(classes.margin, classes.textField)}
              >
                <MenuItem value={''}>Select</MenuItem>
                <MenuItem value={'Science'}>Science</MenuItem>
                <MenuItem value={'Commerce'}>Commerce</MenuItem>
                <MenuItem value={'Humanities'}>Humanities</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                error={hscPassingYearError}
                type="number"
                name="hscPassingYear"
                label="Passing Year"
                value={hscPassingYear}
                helperText={hscPassingYearText}
                variant="outlined"
                onChange={(e) => onChange(e)}
                className={clsx(classes.margin, classes.textField)}
              ></TextField>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                error={hscGpaError}
                type="number"
                name="hscGpa"
                label="Total GPA"
                value={hscGpa}
                helperText={hscGpaText}
                variant="outlined"
                // InputProps={{ inputProps: { min: 0, max: 10 } }}
                onChange={(e) => onChange(e)}
                className={clsx(classes.margin, classes.textField)}
              ></TextField>
            </Grid>
            <br />
            <Divider />
            <br />
          </Grid>
          <br />
          <br />
          <Typography variant="button" component="h2" gutterBottom>
            Add hsc subjects (optional)
          </Typography>{' '}
          <br />
          {hscSubjects.map((hscSubject) => (
            <Grid
              container
              spacing={3}
              key={hscSubject.id}
              className={clsx(classes.margin)}
            >
              <Grid item xs={12} sm={6}>
                <TextField
                  name="subjectName"
                  label="Subject Name"
                  variant="outlined"
                  value={hscSubject.subjectName}
                  className={clsx(classes.margin, classes.textField)}
                  onChange={(e) =>
                    handleChangeHscSubjectsFields(hscSubject.id, e)
                  }
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  name="gradePoint"
                  select
                  label="Letter Grade & Grade Point"
                  variant="outlined"
                  className={clsx(classes.margin, classes.textField)}
                  value={hscSubject.gradePoint}
                  onChange={(e) =>
                    handleChangeHscSubjectsFields(hscSubject.id, e)
                  }
                >
                  <MenuItem value={''}> Select</MenuItem>
                  <MenuItem value={'5.0'}>A+ (5.0)</MenuItem>
                  <MenuItem value={'4.0'}>A (4.0)</MenuItem>
                  <MenuItem value={'3.5'}>A- (3.5)</MenuItem>
                  <MenuItem value={'3.0'}>B (3.0)</MenuItem>
                  <MenuItem value={'2.0'}>C (2.0)</MenuItem>
                  <MenuItem value={'1.0'}>D (1.0)</MenuItem>
                  <MenuItem value={'0.0'}>F (0.0)</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12} sm={2}>
                <IconButton onClick={handleAddHscFields} color="primary">
                  <AddIcon />
                </IconButton>
                <IconButton
                  disabled={hscSubjects.length === 1}
                  onClick={() => handleRemoveHscFields(hscSubject.id)}
                  color="secondary"
                >
                  <RemoveIcon />
                </IconButton>
              </Grid>
            </Grid>
          ))}
          {examCourseType === 'Graduate' ? (
            <Fragment>
              <br />
              <Divider />
              <br />
              <Typography variant="button" component="h2" gutterBottom>
                Undergraduate Information
              </Typography>
              <br />
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    error={gradDegreeInstitutionNameError}
                    type="text"
                    name="gradDegreeInstitutionName"
                    label="Institution Name"
                    value={gradDegreeInstitutionName}
                    helperText={gradDegreeInstitutionNameText}
                    variant="outlined"
                    onChange={(e) => onChange(e)}
                    className={clsx(classes.margin, classes.textField)}
                  ></TextField>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    error={gradDegreeTypeError}
                    select
                    name="gradDegreeType"
                    label="Degree Type"
                    value={gradDegreeType}
                    helperText={gradDegreeTypeText}
                    variant="outlined"
                    onChange={(e) => onChange(e)}
                    className={clsx(classes.margin, classes.textField)}
                  >
                    <MenuItem value={''}>Select</MenuItem>
                    <MenuItem value={'Hons'}>Hons</MenuItem>
                    <MenuItem value={'Degree'}>Degree</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <TextField
                    error={gradDegreeCourseNameError}
                    type="text"
                    name="gradDegreeCourseName"
                    label="Course Name"
                    value={gradDegreeCourseName}
                    helperText={gradDegreeCourseNameText}
                    variant="outlined"
                    onChange={(e) => onChange(e)}
                    className={clsx(classes.margin, classes.textField)}
                  ></TextField>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    type="text"
                    name="gradDegreeMajor"
                    label="Major"
                    value={gradDegreeMajor}
                    helperText="Major name"
                    variant="outlined"
                    onChange={(e) => onChange(e)}
                    className={clsx(classes.margin, classes.textField)}
                  ></TextField>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    error={gradDegreeAcademicYearFromError}
                    type="number"
                    name="gradDegreeAcademicYearFrom"
                    label="Academic Year (Start)"
                    value={gradDegreeAcademicYearFrom}
                    helperText={gradDegreeAcademicYearFromText}
                    variant="outlined"
                    onChange={(e) => onChange(e)}
                    className={clsx(classes.margin, classes.textField)}
                  ></TextField>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    error={gradDegreeAcademicYearToError}
                    type="number"
                    name="gradDegreeAcademicYearTo"
                    label="Academic Year (End)"
                    value={gradDegreeAcademicYearTo}
                    helperText={gradDegreeAcademicYearToText}
                    variant="outlined"
                    onChange={(e) => onChange(e)}
                    className={clsx(classes.margin, classes.textField)}
                  ></TextField>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    error={gradDegreeCGpaError}
                    type="number"
                    name="gradDegreeCGpa"
                    label="CGPA"
                    value={gradDegreeCGpa}
                    helperText={gradDegreeCGpaText}
                    variant="outlined"
                    onChange={(e) => onChange(e)}
                    className={clsx(classes.margin, classes.textField)}
                  ></TextField>
                </Grid>
                <br />
                <Divider />
                <br />
              </Grid>
            </Fragment>
          ) : null}
          {examCourseType === 'Graduate' ? (
            <Fragment>
              <br />
              <Divider />
              <br />
              <Typography variant="button" component="h2" gutterBottom>
                Graduate Information (If you have completed any master's degree,
                for example: MSc, MA, MBA etc, then please enter here. Otherwise
                leave empty.)
              </Typography>
              <br />
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    type="text"
                    name="mastersDegreeInstitutionName"
                    label="Institution Name"
                    value={mastersDegreeInstitutionName}
                    helperText="Institution Name"
                    variant="outlined"
                    onChange={(e) => onChange(e)}
                    className={clsx(classes.margin, classes.textField)}
                  ></TextField>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <TextField
                    type="text"
                    name="mastersDegreeCourseName"
                    label="Course Name"
                    value={mastersDegreeCourseName}
                    helperText="Course Name"
                    variant="outlined"
                    onChange={(e) => onChange(e)}
                    className={clsx(classes.margin, classes.textField)}
                  ></TextField>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    type="text"
                    name="mastersDegreeMajor"
                    label="Major"
                    value={mastersDegreeMajor}
                    helperText="Major name"
                    variant="outlined"
                    onChange={(e) => onChange(e)}
                    className={clsx(classes.margin, classes.textField)}
                  ></TextField>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    type="number"
                    name="mastersDegreeAcademicYearFrom"
                    label="Academic Year (Start)"
                    value={mastersDegreeAcademicYearFrom}
                    helperText="Academic Year"
                    variant="outlined"
                    onChange={(e) => onChange(e)}
                    className={clsx(classes.margin, classes.textField)}
                  ></TextField>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    type="number"
                    name="mastersDegreeAcademicYearTo"
                    label="Academic Year (End)"
                    value={mastersDegreeAcademicYearTo}
                    helperText="Academic Year"
                    variant="outlined"
                    onChange={(e) => onChange(e)}
                    className={clsx(classes.margin, classes.textField)}
                  ></TextField>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    type="number"
                    name="mastersDegreeCGpa"
                    label="CGPA"
                    value={mastersDegreeCGpa}
                    helperText="CGPA"
                    variant="outlined"
                    onChange={(e) => onChange(e)}
                    className={clsx(classes.margin, classes.textField)}
                  ></TextField>
                </Grid>
                <br />
                <Divider />
                <br />
              </Grid>
            </Fragment>
          ) : null}
          <br />
          <AlertComponent />
          <div className="form-group">
            <button className="btn btn-light my-1" onClick={(e) => prevStep(e)}>
              <i className="fas fa-arrow-left text-primary"></i> &nbsp; Back
            </button>
            <button type="submit" className="btn btn-primary">
              Next &nbsp; <i className="fas fa-arrow-right"></i>
            </button>
          </div>
        </form>
      </div>
    </ThemeProvider>
  );
};

Step5Academic.propTypes = {
  onChange: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  prevStep: PropTypes.func.isRequired,
  formData: PropTypes.object.isRequired,
};

export default Step5Academic;
