import React from 'react';
import PropTypes from 'prop-types';

const ProfileGuardiansInfo = ({
  profile: {
    guardiansInfo: {
      fathersName,
      fathersOccupation,
      fathersPhone,
      fathersNID,
      mothersName,
      mothersOccupation,
      mothersPhone,
      mothersNID,
      localGuardiansName,
      localGuardiansOccupation,
      localGuardiansPhone,
      localGuardiansNID,
      emergencyName,
      emergencyPhone,
    },
  },
}) => {
  return (
    <div className="profile-exp bg-white p-2">
      <h2 className="text-primary">Guardian's Info</h2>
      <div>
        <h3 className="text-dark">Father</h3>
        <p>
          <strong>Name: </strong>
          {fathersName}
        </p>
        {fathersOccupation ? (
          <p>
            <strong>Occupation: </strong>
            {fathersOccupation.charAt(0).toUpperCase() +
              fathersOccupation.slice(1)}
          </p>
        ) : null}

        <p>
          <strong>Phone: </strong>
          {fathersPhone}
        </p>
        {fathersNID ? (
          <p>
            <strong>NID: </strong>
            {fathersNID}
          </p>
        ) : null}
      </div>
      <div>
        <h3 className="text-dark">Mother</h3>
        <p>
          <strong>Name: </strong>
          {mothersName}
        </p>
        {mothersOccupation ? (
          <p>
            <strong>Occupation: </strong>
            {mothersOccupation.charAt(0).toUpperCase() +
              mothersOccupation.slice(1)}
          </p>
        ) : null}
        <p>
          <strong>Phone: </strong>
          {mothersPhone}
        </p>
        {mothersNID ? (
          <p>
            <strong>NID: </strong>
            {mothersNID}
          </p>
        ) : null}
      </div>
      {localGuardiansName ? (
        <div>
          <h3 className="text-dark">Local Guardian</h3>
          <p>
            <strong>Name: </strong>
            {localGuardiansName}
          </p>
          <p>
            <strong>Occupation: </strong>
            {localGuardiansOccupation.charAt(0).toUpperCase() +
              localGuardiansOccupation.slice(1)}
          </p>
          <p>
            <strong>Phone: </strong>
            {localGuardiansPhone}
          </p>
          <p>
            <strong>NID: </strong>
            {localGuardiansNID}
          </p>
        </div>
      ) : null}
      {emergencyName ? (
        <div>
          <h3 className="text-dark">Emergency Contact</h3>
          <p>
            <strong>Name: </strong>
            {emergencyName}
          </p>

          <p>
            <strong>Phone: </strong>
            {emergencyPhone}
          </p>
        </div>
      ) : null}
    </div>
  );
};

ProfileGuardiansInfo.propTypes = {
  profile: PropTypes.object.isRequired,
};

export default ProfileGuardiansInfo;
