import React, { useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getCurrentProfile, downloadAdmit } from '../../../actions/profile';
import Divider from '@material-ui/core/Divider';
import AlertComponent from '../AlertComponent';
import Spinner from '../Spinner';
import { Link } from 'react-router-dom';
import { Route, Redirect } from 'react-router-dom';
import { Button } from '@material-ui/core';

const DownloadAdmit = ({
  downloadAdmit,
  auth: { user, role },
  profile: { profile, loading, profileAdmit },
}) => {
  useEffect(() => {
    downloadAdmit();
    getCurrentProfile();
    // eslint-disable-next-line
  }, [downloadAdmit, getCurrentProfile]);

  if (user && user.paymentInfo.status !== 'PAID') {
    return <Redirect to="/payment" />;
  }

  return (
    <div className="container">
      <h1 className="large text-primary">Download Admit Card</h1>
      {/* <p className="lead">Download your admit card for the admission test</p> */}
      <small className="form-text">
        Please print out your admit card after downloading. You must bring it
        with you at the admission test.
      </small>
      <Divider style={{ marginTop: '10px', marginBottom: '20px' }} />

      {profileAdmit !== null ? (
        <Fragment>
          <a
            href={profileAdmit}
            download={`${user?.name.trim()} NDUB ADMISSION ADMIT CARD.pdf`}
            style={{ color: 'white' }}
          >
            <Button
              variant="contained"
              style={{
                color: '#FFF',
                backgroundColor: '#00acee',
                marginBottom: '20px',
                display: 'block',
              }}
              disableElevation
              type="submit"
            >
              <i className="fas fa-download"></i> &nbsp; Download
            </Button>
          </a>
        </Fragment>
      ) : (
        <Spinner />
      )}
      <Link to={`/dashboard`}>
        <Button
          variant="contained"
          color="default"
          disableElevation
          size="small"
          style={{
            marginTop: '20px',
            display: 'block',
          }}
        >
          <i className="fas fa-arrow-left"></i> &nbsp; Back
        </Button>
      </Link>
    </div>
  );
};

DownloadAdmit.propTypes = {
  downloadAdmit: PropTypes.func.isRequired,
  getCurrentProfile: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  profile: state.profile,
});

export default connect(mapStateToProps, {
  downloadAdmit,
  getCurrentProfile,
})(DownloadAdmit);
