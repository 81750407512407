import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Divider from '@material-ui/core/Divider';
import { initiatePayment } from '../../../actions/auth';
import AlertComponent from '../AlertComponent';

import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

const PaymentBank = ({ initiatePayment, user }) => {
  const [formData, setFormData] = useState({
    bankSlipNumber: '',
    paymentMethod: 'BANK',
  });

  const { bankSlipNumber } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();
    initiatePayment(formData);
  };

  const classes = useStyles();

  // Payment Status Unpaid
  const statusUnpaid = (
    <Fragment>
      {user && user.paymentInfo && (
        <div className={classes.root}>
          <Alert severity="error">
            Payment Status: {user.paymentInfo.status}
          </Alert>
        </div>
      )}
      <p className="lead">
        Pay BDT 1000 to the nearest branch of Mercantile Bank & submit the slip
        number below
      </p>

      <Divider />
      <form className="form" onSubmit={(e) => onSubmit(e)}>
        <div className="form-group">
          <input
            type="text"
            placeholder="Bank Slip Number"
            name="bankSlipNumber"
            value={bankSlipNumber}
            onChange={(e) => onChange(e)}
          />
          <small className="form-text">
            - Collect bank payment slip from NDUB Admission office. <br /> -
            Make BDT 1000 payment to Mercantile Bank limited. <br /> - Submit
            your slip number and wait for verification.
          </small>
        </div>
        <AlertComponent />
        <div className="form-group">
          <input type="submit" className="btn btn-primary" value="Submit" />
        </div>
      </form>
    </Fragment>
  );

  // Payment Status Pending
  const statusPending = (
    <Fragment>
      {user && user.paymentInfo && (
        <div className={classes.root}>
          <Alert severity="warning">
            Payment Status: {user.paymentInfo.status}
          </Alert>
        </div>
      )}
      <p className="lead">
        <i className="fas fa-spinner"></i> Please wait while the office verifies
        your payment.
      </p>
      <small>
        It might take upto 24 hours to verify your payment. After the
        verification, your payment status will be updated here. You can give us
        a call at 01711155513 to inquire about the payment. However, if you want
        to edit the transaction id and the number you've sent money from, please
        submit the form again.
      </small>
      <Divider />
      {user && user.paymentInfo.status !== 'PAID' && (
        <form className="form" onSubmit={(e) => onSubmit(e)}>
          <div className="form-group">
            <input
              type="text"
              placeholder="Bank Slip Number"
              name="bankSlipNumber"
              value={bankSlipNumber}
              onChange={(e) => onChange(e)}
            />
            <small className="form-text">The number of the Bank Slip.</small>
          </div>
          <AlertComponent />
          <div className="form-group">
            <input type="submit" className="btn btn-primary" value="Submit" />
          </div>
        </form>
      )}
    </Fragment>
  );

  // Payment Status Paid
  const statusPaid = (
    <Fragment>
      {user && user.paymentInfo && (
        <div className={classes.root}>
          <Alert severity="success">
            Payment Status: {user.paymentInfo.status}
          </Alert>
        </div>
      )}
      <p className="lead">
        <i className="fas fa-check-circle text-primary"></i> Payment successful.
      </p>
      <small>
        Your payment was verified, you can now go back to dashboard and download
        your admit card.
      </small>
    </Fragment>
  );

  return (
    <div className="container">
      <Link className="btn btn-light my-1" to="/payment">
        <i className="fas fa-arrow-left"></i> &nbsp; Go Back
      </Link>
      <h1 className="large text-primary">Payment</h1>
      {user && user.paymentInfo.status === 'UNPAID' && statusUnpaid}
      {user && user.paymentInfo.status === 'PENDING' && statusPending}
      {user && user.paymentInfo.status === 'PAID' && statusPaid}
    </div>
  );
};

PaymentBank.propTypes = {
  initiatePayment: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  role: PropTypes.string,
};

const mapStateToProps = (state) => ({
  exam: state.exam,
  isAuthenticated: state.auth.isAuthenticated,
  role: state.auth.role,
  user: state.auth.user,
});

export default connect(mapStateToProps, { initiatePayment })(PaymentBank);
