import axios from 'axios';
import * as Sentry from '@sentry/react';
import { setAlert } from './alert';
import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  CLEAR_PROFILE,
  GET_PROFILE,
} from './types';
import setAuthToken from '../utils/setAuthToken';

// Load User
export const loadUser = () => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  try {
    const res = await axios.get('/api/auth/');

    dispatch({
      type: USER_LOADED,
      payload: res.data,
    });
  } catch (error) {
    Sentry.captureException(error);
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

// Register User
export const register =
  ({ name, email, password, phone, phoneVerified }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const body = JSON.stringify({
      name,
      email,
      password,
      phone,
      phoneVerified,
    });

    try {
      const res = await axios.post('/api/users', body, config);
      dispatch({
        type: REGISTER_SUCCESS,
        payload: res.data,
      });
      dispatch(loadUser());
    } catch (error) {
      Sentry.captureException(error);
      const errors = error.response.data.errors;

      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
        // errors.forEach((error) =>
        //   error.msg ===
        //   'An user already exists with this phone number. Please use a different phone number to register.'
        //     ? resetPhoneVerification()
        //     : null
        // );
      }

      dispatch({
        type: REGISTER_FAIL,
      });
    }
  };

// Login User
export const login = (phone, password, loadingStop) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const body = JSON.stringify({ phone, password });

  try {
    const res = await axios.post('/api/auth', body, config);
    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data,
    });
    dispatch(loadingStop);
    dispatch(loadUser());
  } catch (error) {
    Sentry.captureException(error);
    const errors = error.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
    }
    dispatch(loadingStop);
    dispatch({
      type: LOGIN_FAIL,
    });
  }
};

// LOGOUT user and clear profile
export const logout = () => (dispatch) => {
  dispatch({ type: CLEAR_PROFILE });
  dispatch({ type: LOGOUT });
};

// Initiate Payment Process by Student
export const initiatePayment = (formData) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  try {
    const res = await axios.post('/api/users/payment', formData, config);

    dispatch({
      type: GET_PROFILE,
      payload: res.data,
    });
    dispatch(setAlert('Bank slip number submitted.', 'success'));
    setTimeout(function () {
      window.location.reload();
    }, 3000);
  } catch (error) {
    Sentry.captureException(error);
    const errors = error.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
    }
  }
};

// Verify Payment by Admin
export const verifyPayment = (formData, user_id) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  try {
    const res = await axios.post(
      `/api/users/payment/verify/${user_id}`,
      formData,
      config
    );

    dispatch({
      type: GET_PROFILE,
      payload: res.data,
    });
    dispatch(setAlert('Submitted', 'success'));
    setTimeout(function () {
      window.location.reload();
    }, 3000);
  } catch (error) {
    Sentry.captureException(error);
    const errors = error.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
    }
  }
};

// Send SMS For Registration: Enter Phone Number and get verification code
export const sendSms = (phone, smsCode, nextStep) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const body = JSON.stringify({ phone, smsCode });

  try {
    const res = await axios.post('/api/auth/send-sms', body, config);
    dispatch(setAlert(res.data, 'success'));
    dispatch(nextStep);
  } catch (error) {
    Sentry.captureException(error);
    if (typeof error.response.data === 'string') {
      dispatch(setAlert(error.response.data, 'warning'));
    }
    if (error) {
      console.log(error);
    }
    if (error.response.data.errors) {
      const errors = error.response.data.errors;
      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
      }
    }
  }
};
