export const NOTICE_LIST_REQUEST = 'NOTICE_LIST_REQUEST';
export const NOTICE_LIST_SUCCESS = 'NOTICE_LIST_SUCCESS';
export const NOTICE_LIST_FAIL = 'NOTICE_LIST_FAIL';

export const NOTICE_DETAILS_REQUEST = 'NOTICE_DETAILS_REQUEST';
export const NOTICE_DETAILS_SUCCESS = 'NOTICE_DETAILS_SUCCESS';
export const NOTICE_DETAILS_FAIL = 'NOTICE_DETAILS_FAIL';

export const NOTICE_DELETE_REQUEST = 'NOTICE_DELETE_REQUEST';
export const NOTICE_DELETE_SUCCESS = 'NOTICE_DELETE_SUCCESS';
export const NOTICE_DELETE_FAIL = 'NOTICE_DELETE_FAIL';
export const NOTICE_DELETE_RESET = 'NOTICE_DELETE_RESET';

export const NOTICE_CREATE_REQUEST = 'NOTICE_CREATE_REQUEST';
export const NOTICE_CREATE_SUCCESS = 'NOTICE_CREATE_SUCCESS';
export const NOTICE_CREATE_FAIL = 'NOTICE_CREATE_FAIL';
export const NOTICE_CREATE_RESET = 'NOTICE_CREATE_RESET';

export const NOTICE_UPDATE_REQUEST = 'NOTICE_UPDATE_REQUEST';
export const NOTICE_UPDATE_SUCCESS = 'NOTICE_UPDATE_SUCCESS';
export const NOTICE_UPDATE_FAIL = 'NOTICE_UPDATE_FAIL';
export const NOTICE_UPDATE_RESET = 'NOTICE_UPDATE_RESET';
