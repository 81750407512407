import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Divider from '@material-ui/core/Divider';

import {
  makeStyles,
  createMuiTheme,
  ThemeProvider,
} from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';

import Grid from '@material-ui/core/Grid';

import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import { format } from 'date-fns';
import {
  Avatar,
  Button,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { listExams } from '../../../actions/examActions';
import AlertComponent from '../AlertComponent';
import { getCurrentProfile } from '../../../actions/profile';
import Spinner from '../Spinner';
import { Link } from 'react-router-dom';

import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#00acee',
    },
    secondary: {
      main: '#fd3a69',
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
    marginTop: theme.spacing(3),
  },
  marginDate: {
    marginBottom: '-3px',
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  dateOfBirthErrorClass: {
    borderColor: 'red !important',
  },
  paper: {
    padding: theme.spacing(2),
  },
  chipContainer: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    '& .MuiChip-root': {
      margin: theme.spacing(0.5),
      color: '#FFF',
      overflow: 'hidden',
    },
  },
  btn: {
    color: '#FFF',
    marginRight: '10px',
  },
  btnSecondary: {
    // color: '#00acee',
    marginRight: '10px',
  },
  large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
}));

const ProfileAll = ({ onSubmit, prevStep, steps, currentStepMobile }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const auth = useSelector((state) => state.auth);
  const { loading: loadingAuth, user, error } = auth;

  const profile = useSelector((state) => state.profile);
  const {
    loading: loadingProfile,
    profile: profileDetails,
    error: errorProfile,
  } = profile;

  const [confirmAgreement, setConfirmAgreement] = useState(false);

  const handleAgreement = (e) => {
    setConfirmAgreement(!confirmAgreement);
  };

  const examList = useSelector((state) => state.examList);
  const { loading: loadingList, exams, error: errorExam } = examList;

  useEffect(() => {
    if (exams.length <= 0) {
      dispatch(listExams());
    }
    // if (!profileDetails || profileDetails.user._id !== user._id) {
    dispatch(getCurrentProfile());
    // }
    // eslint-disable-next-line
  }, [listExams, dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleClick = () => {};

  return (
    <ThemeProvider theme={theme}>
      <div className="container">
        {loadingProfile ? (
          <Spinner />
        ) : (
          profileDetails && (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Paper
                  // variant="outlined"
                  style={{
                    backgroundColor: '#0C2340',
                    color: '#FFF',
                  }}
                  elevation={3}
                  color="primary"
                  className="profile-top bg-primary p-2"
                >
                  <Avatar
                    alt={profileDetails?.user?.name}
                    src={'/uploads/profile-pic/' + profileDetails?.profilePic}
                    className={classes.large}
                  ></Avatar>
                  <h1 className="large">{profileDetails?.user?.name}</h1>
                  <Grid container spacing={2} justify="center">
                    <Grid item>
                      <Grid container justify="center">
                        {profileDetails?.user?.phone && (
                          <Chip
                            label={profileDetails?.user?.phone}
                            size="medium"
                            icon={<PhoneIcon />}
                          ></Chip>
                        )}
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid container justify="center">
                        <Chip
                          label={profileDetails?.user?.email}
                          size="medium"
                          icon={<EmailIcon />}
                        ></Chip>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid container justify="center">
                        <Chip
                          label={`Applicant ID: ${profileDetails?.marks?.applicantId}`}
                          size="medium"
                        ></Chip>
                      </Grid>
                    </Grid>

                    <Grid item>
                      <Grid container justify="center">
                        <Chip
                          label={`Batch: ${profileDetails?.admissionInfo?.batch}`}
                          size="medium"
                        ></Chip>
                      </Grid>
                    </Grid>

                    <Grid item>
                      <Grid container justify="center">
                        <Link to="/payment" style={{ cursor: 'pointer' }}>
                          <Chip
                            label={`Payment: ${profileDetails?.user?.paymentInfo?.status}`}
                            size="medium"
                            onClick={handleClick}
                            style={{ color: '#FFF' }}
                            color={
                              profileDetails?.user?.paymentInfo?.status ===
                              'PAID'
                                ? 'primary'
                                : 'secondary'
                            }
                          ></Chip>
                        </Link>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>{' '}
              </Grid>
              <Grid item xs={12} md={6}>
                <Paper elevation={2} className={classes.paper}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Typography variant="button" component="h2" gutterBottom>
                        Applied to
                      </Typography>
                      <Divider />
                    </Grid>
                    <Grid item xs={12}>
                      <List>
                        <ListItem>
                          <ListItemText
                            primary={profileDetails?.admissionInfo?.course}
                          />
                        </ListItem>
                      </List>
                      <div className={classes.chipContainer}>
                        <Chip
                          label={`Exam Date: ${format(
                            new Date(profileDetails?.marks?.date),
                            'PPPPp'
                          )}`}
                          color="primary"
                        />
                        <Chip
                          label={`Department: ${profileDetails?.admissionInfo?.department}`}
                          color="primary"
                        />
                        <Chip
                          label={`Trimester: ${profileDetails?.admissionInfo?.trimester}`}
                          color="primary"
                        />
                        <Chip
                          label={`Year: ${profileDetails?.admissionInfo?.year}`}
                          color="primary"
                        />
                        <Chip
                          label={`Batch: ${profileDetails?.admissionInfo?.batch}`}
                          color="primary"
                        />
                      </div>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={12} md={6}>
                <Paper elevation={2} className={classes.paper}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Typography variant="button" component="h2" gutterBottom>
                        Personal Info
                      </Typography>
                      <Divider />
                    </Grid>
                    <Grid item xs={6}>
                      <List>
                        <ListItem>
                          <ListItemText
                            primary={profileDetails?.personalDetails?.religion}
                            secondary="Religion"
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemText
                            primary={profileDetails?.personalDetails?.gender}
                            secondary="Gender"
                          />
                        </ListItem>
                      </List>
                    </Grid>
                    <Grid item xs={6}>
                      <List>
                        <ListItem>
                          <ListItemText
                            primary={format(
                              new Date(
                                profileDetails?.personalDetails?.dateOfBirth
                              ),
                              'PP'
                            )}
                            secondary="Date of birth"
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemText
                            primary={
                              profileDetails?.personalDetails?.nationality
                            }
                            secondary="Nationality"
                          />
                        </ListItem>
                      </List>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={12} md={6}>
                <Paper elevation={2} className={classes.paper}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Typography variant="button" component="h2" gutterBottom>
                        Address
                      </Typography>
                      <Divider />
                    </Grid>

                    <Grid item xs={12}>
                      <List>
                        <ListItem>
                          <Typography variant="caption" display="block">
                            Present Address
                          </Typography>
                        </ListItem>
                        <Divider />
                        <ListItem>
                          <ListItemText
                            primary={profileDetails?.presentAddress?.aptNumber}
                          />
                        </ListItem>
                      </List>
                    </Grid>
                    <Grid item xs={12}>
                      <List>
                        <ListItem>
                          <Typography variant="caption" display="block">
                            Permanent Address
                          </Typography>
                        </ListItem>
                        <Divider />
                        <ListItem>
                          <ListItemText
                            primary={
                              profileDetails?.permanentAddress?.aptNumber
                            }
                          />
                        </ListItem>
                      </List>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={12} md={6}>
                <Paper elevation={2} className={classes.paper}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Typography variant="button" component="h2" gutterBottom>
                        Guardian's Details
                      </Typography>
                      <Divider />
                    </Grid>
                    <Grid item xs={6} md={6}>
                      <List>
                        <ListItem>
                          <Typography variant="caption" display="block">
                            Father
                          </Typography>
                        </ListItem>
                        <Divider />
                        <ListItem>
                          <ListItemText
                            primary={profileDetails?.guardiansInfo?.fathersName}
                            secondary="Name"
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemText
                            primary={
                              profileDetails?.guardiansInfo?.fathersPhone
                            }
                            secondary="Phone"
                          />
                        </ListItem>
                      </List>
                    </Grid>
                    <Grid item xs={6} md={6}>
                      <List>
                        <ListItem>
                          <Typography variant="caption" display="block">
                            Mother
                          </Typography>
                        </ListItem>
                        <Divider />
                        <ListItem>
                          <ListItemText
                            primary={profileDetails?.guardiansInfo?.mothersName}
                            secondary="Name"
                          />
                        </ListItem>

                        <ListItem>
                          <ListItemText
                            primary={
                              profileDetails?.guardiansInfo?.mothersPhone
                            }
                            secondary="Phone"
                          />
                        </ListItem>
                      </List>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={12} md={12}>
                <Paper elevation={2} className={classes.paper}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Typography variant="button" component="h2" gutterBottom>
                        Academic Details
                      </Typography>
                      <Divider />
                    </Grid>
                    <Grid item xs={6} md={6}>
                      <List>
                        <ListItem>
                          <Typography variant="caption" display="block">
                            SSC
                          </Typography>
                        </ListItem>
                        <Divider />
                        <ListItem>
                          <ListItemText
                            primary={profileDetails?.ssc?.sscInstitutionName}
                            secondary="Institution Name"
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemText
                            primary={profileDetails?.ssc?.sscGroup}
                            secondary="Group"
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemText
                            primary={profileDetails?.ssc?.sscPassingYear}
                            secondary="Passing Year"
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemText
                            primary={profileDetails?.ssc?.sscGpa}
                            secondary="GPA"
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemText
                            primary={profileDetails?.ssc?.sscSubjects}
                            secondary="SSC Subjects"
                          />
                        </ListItem>
                      </List>
                    </Grid>
                    <Grid item xs={6} md={6}>
                      <List>
                        <ListItem>
                          <Typography variant="caption" display="block">
                            HSC
                          </Typography>
                        </ListItem>
                        <Divider />
                        <ListItem>
                          <ListItemText
                            primary={profileDetails?.hsc?.hscInstitutionName}
                            secondary="Institution Name"
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemText
                            primary={profileDetails?.hsc?.hscGroup}
                            secondary="Group"
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemText
                            primary={profileDetails?.hsc?.hscPassingYear}
                            secondary="Passing Year"
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemText
                            primary={profileDetails?.hsc?.hscGpa}
                            secondary="GPA"
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemText
                            primary={profileDetails?.hsc?.hscSubjects}
                            secondary="HSC Subjects"
                          />
                        </ListItem>
                      </List>
                    </Grid>

                    {profileDetails?.gradDegree && (
                      <Grid item xs={6} md={6}>
                        <List>
                          <ListItem>
                            <Typography variant="caption" display="block">
                              Graduate
                            </Typography>
                          </ListItem>
                          <Divider />
                          <ListItem>
                            <ListItemText
                              primary={
                                profileDetails?.gradDegree
                                  ?.gradDegreeInstitutionName
                              }
                              secondary="Institution Name"
                            />
                          </ListItem>

                          <ListItem>
                            <ListItemText
                              primary={
                                profileDetails?.gradDegree?.gradDegreeCourseName
                              }
                              secondary="Course Name"
                            />
                          </ListItem>
                          <ListItem>
                            <ListItemText
                              primary={
                                profileDetails?.gradDegree?.gradDegreeMajor
                              }
                              secondary="Major"
                            />
                          </ListItem>
                          <ListItem>
                            <ListItemText
                              primary={`${profileDetails?.gradDegree?.gradDegreeAcademicYearFrom} - ${profileDetails?.gradDegree?.gradDegreeAcademicYearTo}`}
                              secondary="Academic Year"
                            />
                          </ListItem>
                          <ListItem>
                            <ListItemText
                              primary={
                                profileDetails?.gradDegree?.gradDegreeCGpa
                              }
                              secondary="CGPA"
                            />
                          </ListItem>
                          <ListItem>
                            <ListItemText
                              primary={
                                profileDetails?.gradDegree?.gradDegreeType
                              }
                              secondary="Degree Type"
                            />
                          </ListItem>
                        </List>
                      </Grid>
                    )}
                    {profileDetails?.mastersDegree && (
                      <Grid item xs={6} md={6}>
                        <List>
                          <ListItem>
                            <Typography variant="caption" display="block">
                              Post Graduate
                            </Typography>
                          </ListItem>
                          <Divider />
                          <ListItem>
                            <ListItemText
                              primary={
                                profileDetails?.mastersDegree
                                  ?.mastersDegreeInstitutionName
                              }
                              secondary="Institution Name"
                            />
                          </ListItem>
                          <ListItem>
                            <ListItemText
                              primary={
                                profileDetails?.mastersDegree
                                  ?.mastersDegreeCourseName
                              }
                              secondary="Course Name"
                            />
                          </ListItem>
                          <ListItem>
                            <ListItemText
                              primary={
                                profileDetails?.mastersDegree
                                  ?.mastersDegreeMajor
                              }
                              secondary="Major"
                            />
                          </ListItem>

                          <ListItem>
                            <ListItemText
                              primary={`${profileDetails?.mastersDegree?.mastersDegreeAcademicYearFrom} - ${profileDetails?.mastersDegree?.mastersDegreeAcademicYearTo}`}
                              secondary="Academic Year"
                            />
                          </ListItem>
                          <ListItem>
                            <ListItemText
                              primary={
                                profileDetails?.mastersDegree?.mastersDegreeCGpa
                              }
                              secondary="CGPA"
                            />
                          </ListItem>
                        </List>
                      </Grid>
                    )}
                  </Grid>
                </Paper>
              </Grid>

              {profileDetails?.workExperience &&
                profileDetails?.workExperience[0].companyName !== '' && (
                  <Grid item xs={12} md={12}>
                    <Paper elevation={2} className={classes.paper}>
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <Typography
                            variant="button"
                            component="h2"
                            gutterBottom
                          >
                            Work Experience
                          </Typography>
                          <Divider />
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <Table
                            className={classes.table}
                            aria-label="work experience table"
                            size="small"
                          >
                            <TableHead>
                              <TableRow>
                                <TableCell>Company Name</TableCell>
                                <TableCell align="center">Position</TableCell>
                                <TableCell align="center">
                                  Service Years
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {profileDetails?.workExperience.map((workExp) => (
                                <TableRow key={workExp.id}>
                                  <TableCell component="th" scope="row">
                                    {workExp.companyName}
                                  </TableCell>
                                  <TableCell align="center">
                                    {workExp.position}
                                  </TableCell>
                                  <TableCell align="center">{`${workExp.from} - ${workExp.to}`}</TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                )}

              {profileDetails?.marks?.otherPrograms.length > 0 && (
                <Grid item xs={12} md={12}>
                  <Paper elevation={2} className={classes.paper}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Typography
                          variant="button"
                          component="h2"
                          gutterBottom
                        >
                          Other programs that you have applied to
                        </Typography>
                        <Divider />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <Table
                          className={classes.table}
                          aria-label="other programs table"
                          size="small"
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell>Program Name</TableCell>
                              <TableCell align="center">Department</TableCell>
                              <TableCell align="center">Exam Date</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {exams
                              .filter((exam) =>
                                profileDetails?.marks?.otherPrograms.includes(
                                  exam._id
                                )
                              )
                              .map((otherProgram) => (
                                <TableRow key={otherProgram.id}>
                                  <TableCell component="th" scope="row">
                                    {otherProgram.course}
                                  </TableCell>
                                  <TableCell align="center">
                                    {otherProgram.department}
                                  </TableCell>
                                  <TableCell align="center">{`${format(
                                    new Date(otherProgram.examDate),
                                    'PPp'
                                  )}`}</TableCell>
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              )}
            </Grid>
          )
        )}

        <AlertComponent />

        <div className="form-group">
          <Button
            variant="contained"
            color="default"
            disableElevation
            className={classes.btnSecondary}
            onClick={(e) => prevStep(e)}
          >
            <i className="fas fa-arrow-left"></i> &nbsp; Back
          </Button>
        </div>
      </div>
    </ThemeProvider>
  );
};

ProfileAll.propTypes = {
  onChange: PropTypes.func.isRequired,
  prevStep: PropTypes.func.isRequired,
  formData: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default ProfileAll;
