import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from '../layout/Spinner';
import Divider from '@material-ui/core/Divider';
import { Link } from 'react-router-dom';
import AlertComponent from '../layout/AlertComponent';
import DownloadXlsxMultiple from './utils/DownloadXlsxMultiple';

import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';

import clsx from 'clsx';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import {
  DatePicker,
  DateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { Button, Checkbox, Chip, IconButton } from '@material-ui/core';
import { listPrograms } from '../../actions/programActions';
import { listApplicants } from '../../actions/applicantActions';
import MailPopup from '../popups/MailPopup';
import SmsMultiPopup from '../popups/SmsMultiPopup';
import { generateMultiplePDFs } from '../../actions/profilePDFActions';
import DownloadInstitutions from './utils/DownloadInstitutions';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#00acee',
    },
  },
});

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#00acee',
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    // '&:nth-of-type(odd)': {
    //   backgroundColor: theme.palette.action.hover,
    // },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  root: {
    // display: 'flex',
    // flexWrap: 'wrap',
    justifyContent: 'center',
    marginTop: theme.spacing(3),
  },
  table: {
    minWidth: 700,
  },
  textField: {
    width: '100%',
  },
  btn: {
    color: '#FFF',
    marginRight: '10px',
  },
  btnSecondary: {
    // color: '#00acee',
    marginRight: '10px',
  },
  tableFontSize: {
    fontSize: '12px',
  },
}));

const Students = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [selectedDate, setSelectedDate] = useState(null);
  const [paymentDate, setPaymentDate] = useState(null);

  const [mailAddresses, setMailAddresses] = useState([]);
  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [profileIds, setProfileIds] = useState([]);

  const [formData, setFormData] = useState({
    program: '',
    batch: '',
    name: '',
    phone: '',
    email: '',
    trimester: '',
    applicantId: '',
    paymentStatus: '',
    selectedDate: '',
  });

  const {
    program,
    batch,
    name,
    phone,
    email,
    trimester,
    applicantId,
    paymentStatus,
  } = formData;

  const [year, setYear] = useState(new Date());
  const [loadCount, setLoadCount] = useState(0);

  const programList = useSelector((state) => state.programList);
  const {
    loading: loadingProgramList,
    programs,
    errorProgramList,
  } = programList;

  const applicantList = useSelector((state) => state.applicantList);
  const {
    loading: loadingApplicantList,
    applicants,
    count,
    institutionStats,
    errorApplicantList,
  } = applicantList;

  const profilePDFMulti = useSelector((state) => state.profilePDFMulti);
  const { loading: loadingProfilePDFMulti, profilePDF } = profilePDFMulti;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(
      listApplicants({
        ...formData,
        year: year?.getFullYear(),
        paymentCompletionTime: paymentDate,
        selectedDate,
      })
    );
  };

  const onMailSelect = (e, mail, phone, profileId) => {
    if (mailAddresses.includes(mail)) {
      setMailAddresses(mailAddresses.filter((m) => m !== mail));
    } else {
      setMailAddresses([...mailAddresses, mail]);
    }
    if (phoneNumbers.includes(phone)) {
      setPhoneNumbers(phoneNumbers.filter((p) => p !== phone));
    } else {
      setPhoneNumbers([...phoneNumbers, phone]);
    }
    if (profileIds.includes(profileId)) {
      setProfileIds(profileIds.filter((p) => p !== profileId));
    } else {
      setProfileIds([...profileIds, profileId]);
    }
  };

  const onAllMailSelect = () => {
    if (mailAddresses.length > 0) {
      setMailAddresses([]);
      setPhoneNumbers([]);
      setProfileIds([]);
    } else {
      setMailAddresses(applicants.map((applicant) => applicant.user.email));
      setPhoneNumbers(applicants.map((applicant) => applicant.user.phone));
      setProfileIds(applicants.map((applicant) => applicant.user._id));
    }
  };

  useEffect(() => {
    let month = year.getMonth(); // current month
    let trimester = '';
    if (month >= 0 && month <= 3) {
      trimester = 'Summer';
    } else if (month >= 4 && month <= 7) {
      trimester = 'Fall';
    } else if (month >= 8 && month <= 11) {
      trimester = 'Spring';
    }
    setFormData({ ...formData, trimester });
  }, []);

  useEffect(() => {
    dispatch(listPrograms());
    // eslint-disable-next-line
  }, [dispatch, listPrograms]);

  useEffect(() => {
    // Checking if it is the first request
    if (loadCount > 0) {
      dispatch(
        listApplicants({
          ...formData,
          year: year?.getFullYear(),
          paymentCompletionTime: paymentDate,
          selectedDate,
        })
      );
    }
    setLoadCount(loadCount + 1);
    // eslint-disable-next-line
  }, [
    year,
    paymentDate,
    selectedDate,
    formData.paymentStatus,
    formData.program,
    formData.trimester,
  ]);

  return loadingProgramList ? (
    <ThemeProvider theme={theme}>
      <Fragment>
        <div className="container">
          <Spinner />
        </div>
      </Fragment>
    </ThemeProvider>
  ) : (
    <ThemeProvider theme={theme}>
      <Fragment>
        <div className="container">
          <h1 className="large text-primary">Students</h1>
          <Divider /> <br />
          <form onSubmit={(e) => onSubmit(e)}>
            <Grid container spacing={3} alignItems="center">
              <Grid item xs={12} sm={8}>
                <TextField
                  size="small"
                  select
                  name="program"
                  label="Program"
                  value={program}
                  helperText={'Select Program'}
                  variant="outlined"
                  onChange={(e) => onChange(e)}
                  className={classes.textField}
                >
                  <MenuItem value={''}>* Select All Programs</MenuItem>
                  <MenuItem disabled> Undergraduate</MenuItem>
                  {programs && programs.length > 0
                    ? programs?.map((p) =>
                        p.programType === 'undergraduate' ? (
                          <MenuItem value={p._id} key={p._id}>
                            {p.programName} &nbsp;
                            <Chip
                              label={p.department.departmentName}
                              size="small"
                            ></Chip>
                          </MenuItem>
                        ) : null
                      )
                    : null}
                  <MenuItem disabled> Graduate</MenuItem>
                  {programs && programs.length > 0
                    ? programs?.map((p) =>
                        p.programType === 'graduate' ? (
                          <MenuItem value={p._id} key={p._id}>
                            {p.programName}
                            &nbsp;
                            <Chip
                              label={p.department.departmentName}
                              size="small"
                            ></Chip>
                          </MenuItem>
                        ) : null
                      )
                    : null}
                </TextField>
              </Grid>

              <Grid item xs={12} sm={6} md={2}>
                <TextField
                  size="small"
                  type="text"
                  name="batch"
                  label="Batch"
                  value={batch}
                  helperText={'Enter Batch'}
                  variant="outlined"
                  onChange={(e) => onChange(e)}
                  className={clsx(classes.margin, classes.textField)}
                ></TextField>
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <TextField
                  size="small"
                  type="text"
                  name="name"
                  label="Name"
                  value={name}
                  helperText={'Enter Name'}
                  variant="outlined"
                  onChange={(e) => onChange(e)}
                  className={clsx(classes.margin, classes.textField)}
                ></TextField>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  size="small"
                  type="text"
                  name="phone"
                  label="Phone"
                  value={phone}
                  helperText={'Enter Phone'}
                  variant="outlined"
                  onChange={(e) => onChange(e)}
                  className={clsx(classes.margin, classes.textField)}
                ></TextField>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  size="small"
                  type="text"
                  name="email"
                  label="Email"
                  value={email}
                  helperText={'Enter Email'}
                  variant="outlined"
                  onChange={(e) => onChange(e)}
                  className={clsx(classes.margin, classes.textField)}
                ></TextField>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  size="small"
                  type="text"
                  name="applicantId"
                  label="Applicant ID"
                  value={applicantId}
                  helperText={'Enter Applicant ID'}
                  variant="outlined"
                  onChange={(e) => onChange(e)}
                  className={clsx(classes.margin, classes.textField)}
                ></TextField>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  size="small"
                  select
                  name="trimester"
                  label="Trimester"
                  value={trimester}
                  helperText={'Select Trimester'}
                  variant="outlined"
                  onChange={(e) => onChange(e)}
                  className={clsx(classes.margin, classes.textField)}
                >
                  <MenuItem value={''}>* Select Trimester</MenuItem>
                  <MenuItem value={'Spring'}>Spring</MenuItem>
                  <MenuItem value={'Summer'}>Summer</MenuItem>
                  <MenuItem value={'Fall'}>Fall</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} name="year">
                  <DatePicker
                    name="year"
                    size="small"
                    inputVariant="outlined"
                    views={['year']}
                    helperText={'Select Exam Year'}
                    label="Year"
                    value={year}
                    onChange={setYear}
                    autoOk={true}
                  />
                  <i
                    className="fas fa-times"
                    style={{
                      color: 'red',
                      cursor: 'pointer',
                      display: year == null ? 'none' : '',
                      marginLeft: '5px',
                    }}
                    onClick={(e) => setYear(null)}
                  ></i>
                </MuiPickersUtilsProvider>
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  size="small"
                  select
                  name="paymentStatus"
                  label="Payment Status"
                  value={paymentStatus}
                  helperText={'Select payment status'}
                  variant="outlined"
                  onChange={(e) => onChange(e)}
                  className={clsx(classes.margin, classes.textField)}
                >
                  <MenuItem value={''}>All</MenuItem>
                  <MenuItem value={'PAID'}>Paid</MenuItem>
                  <MenuItem value={'UNPAID'}>Unpaid</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12} sm={6} md={3} align="center">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DateTimePicker
                    disabled={paymentStatus !== 'PAID' ? true : false}
                    value={paymentDate}
                    onChange={setPaymentDate}
                    helperText={'Paid after selected date.'}
                    label="Select Payment Date"
                  />
                  <i
                    className="fas fa-times"
                    style={{
                      color: 'red',
                      cursor: 'pointer',
                      display: paymentDate == null ? 'none' : '',
                    }}
                    onClick={(e) => setPaymentDate(null)}
                  ></i>
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={12} sm={6} md={3} align="center">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DateTimePicker
                    value={selectedDate}
                    onChange={setSelectedDate}
                    helperText={'Form fill-up after selected date.'}
                    label="Select Form Fill-Up Date"
                  />
                  <i
                    className="fas fa-times"
                    style={{
                      color: 'red',
                      cursor: 'pointer',
                      display: selectedDate == null ? 'none' : '',
                    }}
                    onClick={(e) => setSelectedDate(null)}
                  ></i>
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
            <br />
            <div className="form-group">
              <Link to={`/admin-dashboard`}>
                <Button
                  variant="contained"
                  color="default"
                  disableElevation
                  className={classes.btnSecondary}
                  size="small"
                >
                  <i className="fas fa-arrow-left"></i> &nbsp; Back
                </Button>
              </Link>
              <Button
                variant="contained"
                color="primary"
                disableElevation
                className={classes.btn}
                type="submit"
                size="small"
              >
                Search &nbsp;
                {loadingApplicantList ? (
                  <i className="fas fa-circle-notch fa-spin"></i>
                ) : (
                  <i className="fas fa-search"></i>
                )}
              </Button>
              <MailPopup
                email={mailAddresses}
                disabled={mailAddresses.length === 0 ? true : false}
              />
              <SmsMultiPopup
                phones={phoneNumbers}
                message={''}
                disabled={phoneNumbers.length === 0 ? true : false}
              />
              <Button
                onClick={(e) =>
                  dispatch(
                    generateMultiplePDFs(profileIds, formData.trimester, year)
                  )
                }
                aria-label="sms"
                variant="contained"
                color="primary"
                disableElevation
                size="small"
                style={{ color: 'white', marginRight: '10px' }}
                disabled={profileIds.length === 0 ? true : false}
              >
                PDFs &nbsp;
                {loadingProfilePDFMulti ? (
                  <i className="fas fa-circle-notch fa-spin loadingIndicator"></i>
                ) : (
                  <i className="fas fa-download"></i>
                )}
              </Button>
              {applicants && applicants.length > 0 ? (
                <DownloadXlsxMultiple profiles={applicants} />
              ) : null}
              &nbsp;
              {applicants && applicants.length > 0 ? (
                <DownloadInstitutions
                  institutions={institutionStats.sscInstitutions}
                  name={'SSC'}
                />
              ) : null}
              &nbsp;
              {applicants && applicants.length > 0 ? (
                <DownloadInstitutions
                  institutions={institutionStats.hscInstitutions}
                  name={'HSC'}
                />
              ) : null}
              {applicants && applicants.length > 0 ? (
                <small
                  style={{ marginLeft: '10px' }}
                >{`${count} applicants found`}</small>
              ) : null}
            </div>
          </form>
          <AlertComponent />
        </div>
        <div className="table-container">
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <StyledTableCell padding="checkbox">
                    <Checkbox
                      style={{ color: '#fff' }}
                      onChange={onAllMailSelect}
                      checked={mailAddresses.length}
                    />
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    className={classes.tableFontSize}
                  >
                    ID
                  </StyledTableCell>
                  <StyledTableCell
                    align="left"
                    className={classes.tableFontSize}
                  >
                    Name
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    className={classes.tableFontSize}
                  >
                    Phone
                  </StyledTableCell>
                  <StyledTableCell
                    align="left"
                    className={classes.tableFontSize}
                  >
                    Email
                  </StyledTableCell>
                  <StyledTableCell
                    align="left"
                    className={classes.tableFontSize}
                  >
                    Program
                  </StyledTableCell>

                  <StyledTableCell
                    align="left"
                    className={classes.tableFontSize}
                  >
                    Trimester
                  </StyledTableCell>
                  <StyledTableCell
                    align="right"
                    className={classes.tableFontSize}
                  >
                    Year
                  </StyledTableCell>
                  <StyledTableCell
                    align="right"
                    className={classes.tableFontSize}
                  >
                    Batch
                  </StyledTableCell>

                  <StyledTableCell
                    align="center"
                    className={classes.tableFontSize}
                  >
                    Payment
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    className={classes.tableFontSize}
                  >
                    View
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {applicants && applicants.length > 0
                  ? applicants.map((profile) => (
                      <StyledTableRow key={profile._id} hover>
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            onChange={(e) =>
                              onMailSelect(
                                e,
                                profile.user.email,
                                profile.user.phone,
                                profile.user._id
                              )
                            }
                            checked={mailAddresses.includes(profile.user.email)}
                          />
                        </TableCell>
                        <StyledTableCell
                          align="left"
                          className={classes.tableFontSize}
                        >
                          {profile.marks.applicantId}
                        </StyledTableCell>
                        <StyledTableCell
                          component="th"
                          scope="row"
                          className={classes.tableFontSize}
                        >
                          {profile.user.name}
                        </StyledTableCell>

                        <StyledTableCell
                          align="right"
                          className={classes.tableFontSize}
                        >
                          {profile.user.phone}
                        </StyledTableCell>
                        <StyledTableCell
                          align="left"
                          className={classes.tableFontSize}
                        >
                          {profile.user.email}
                        </StyledTableCell>

                        <StyledTableCell
                          align="left"
                          className={classes.tableFontSize}
                        >
                          {profile.marks?.examId.program.programName}
                        </StyledTableCell>

                        <StyledTableCell
                          align="left"
                          className={classes.tableFontSize}
                        >
                          {profile.marks.examId.trimester}
                        </StyledTableCell>
                        <StyledTableCell
                          align="right"
                          className={classes.tableFontSize}
                        >
                          {profile.marks.examId.year}
                        </StyledTableCell>
                        <StyledTableCell
                          align="center"
                          className={classes.tableFontSize}
                        >
                          {profile.marks.examId.batch}
                        </StyledTableCell>

                        <StyledTableCell
                          align="center"
                          className={classes.tableFontSize}
                        >
                          {profile.user.paymentInfo.status === 'PAID' ? (
                            <CheckIcon color="primary" size="small" />
                          ) : (
                            <ClearIcon color="secondary" size="small" />
                          )}
                        </StyledTableCell>
                        <StyledTableCell
                          align="center"
                          className={classes.tableFontSize}
                        >
                          <Link to={`/student/${profile.user._id}`}>
                            <IconButton
                              aria-label="view student profile"
                              color="primary"
                              size="small"
                            >
                              <ArrowForwardIcon />
                            </IconButton>
                          </Link>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))
                  : null}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </Fragment>
    </ThemeProvider>
  );
};

export default Students;
