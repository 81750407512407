import React, { useEffect, useState } from 'react';
import Spinner from '../layout/Spinner';
import Divider from '@material-ui/core/Divider';
import { format } from 'date-fns';

// import { getAvailableExams, deleteExam, editExam } from '../../actions/exam';
// import { calculatePlace } from '../../actions/profile';
import { Link } from 'react-router-dom';
import AlertComponent from '../layout/AlertComponent';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import { useDispatch, useSelector } from 'react-redux';
import { listExams, updateExam, deleteExam } from '../../actions/examActions';
import { listPrograms } from '../../actions/programActions';
import {
  Checkbox,
  createMuiTheme,
  IconButton,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ThemeProvider,
  withStyles,
  TextField,
  Chip,
} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import PersonIcon from '@material-ui/icons/Person';

import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#00acee',
    },
    // secondary: {
    //   main: '#a8dda8',
    // },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    // maxWidth: 500,
    backgroundColor: '#f4f4f4',
    padding: 20,
  },
  chip: {
    margin: theme.spacing(0.5),
    backgroundColor: '#b3e6fa',
    color: '#333333',
  },
  chipOutlined: {
    borderColor: '#00acee',
    color: '#00acee',
    marginTop: 10,
  },
  section1: {
    margin: theme.spacing(3, 2),
  },
  section2: {
    margin: theme.spacing(2),
  },
  section3: {
    margin: theme.spacing(3, 1, 1),
  },
  deleteButton: {
    marginLeft: 10,
  },
  switch: {
    color: '#00acee !important',
  },

  textField: {
    width: '100%',
  },
  btn: {
    color: '#FFF',
  },
  btnSecondary: {
    // color: '#00acee',
    marginRight: '10px',
  },
  my20: {
    display: 'block',
    marginBottom: '20px',
  },
}));

const Exams = () => {
  const dispatch = useDispatch();

  const [program, setCourse] = useState('');
  const [trimester, setTrimester] = useState('');
  const [year, setYear] = useState(null);
  const [courseType, setCourseType] = useState('');
  const [conducted, setConducted] = useState(false);

  const classes = useStyles();

  const examList = useSelector((state) => state.examList);
  const { loading: loadingList, exams, studentsInExam, error } = examList;

  const examUpdate = useSelector((state) => state.examUpdate);
  const { loading: loadingUpdate, success: successUpdate } = examUpdate;

  const programList = useSelector((state) => state.programList);
  const {
    loading: loadingProgramList,
    programs,
    errorProgramList,
  } = programList;

  useEffect(() => {
    dispatch(listExams(program, trimester, year, courseType, conducted));
    dispatch(listPrograms());
    // eslint-disable-next-line
  }, [
    listExams,
    dispatch,

    program,
    trimester,
    year,
    courseType,
    conducted,
    successUpdate,
  ]);

  const onDelete = (exam_id) => {
    if (window.confirm('Are you sure?')) {
      dispatch(deleteExam(exam_id));
    }
  };

  const onConducted = (e, exam, id) => {
    e.preventDefault();
    const formData = {
      ...exam,
      conducted: !exam.conducted,
    };
    dispatch(updateExam(formData));
  };

  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: '#00acee',
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);

  const StyledTableRow = withStyles((theme) => ({
    root: {
      // '&:nth-of-type(odd)': {
      //   backgroundColor: theme.palette.action.hover,
      // },
    },
  }))(TableRow);

  return (
    <ThemeProvider theme={theme}>
      <div className="container">
        <h1 className="large text-primary">Exams</h1>
        <Divider className={classes.my20} />
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={12}>
            <Link to={`/admin-dashboard`}>
              <Button
                variant="contained"
                size="small"
                color="default"
                disableElevation
                className={classes.btnSecondary}
              >
                <i className="fas fa-arrow-left"></i> &nbsp; Back
              </Button>
            </Link>
            <Link to={`/create-exam`}>
              <Button
                variant="contained"
                size="small"
                color="primary"
                disableElevation
                className={classes.btn}
              >
                Create &nbsp; <i className="fas fa-plus"></i>
              </Button>
            </Link>
          </Grid>
          <br /> <br />
          <Grid item xs={12} sm={12}>
            <TextField
              size="small"
              select
              name="program"
              label="Program"
              value={program}
              helperText={'Select Program'}
              variant="outlined"
              onChange={(e) => setCourse(e.target.value)}
              className={classes.textField}
            >
              <MenuItem value={''}>* Select All Programs</MenuItem>
              <MenuItem disabled> Undergraduate</MenuItem>
              {programs && programs.length > 0
                ? programs?.map((p) =>
                    p.programType === 'undergraduate' ? (
                      <MenuItem value={p._id} key={p._id}>
                        {p.programName} &nbsp;
                        <Chip
                          label={p.department.departmentName}
                          size="small"
                        ></Chip>
                      </MenuItem>
                    ) : null
                  )
                : null}
              <MenuItem disabled> Graduate</MenuItem>
              {programs && programs.length > 0
                ? programs?.map((p) =>
                    p.programType === 'graduate' ? (
                      <MenuItem value={p._id} key={p._id}>
                        {p.programName}
                        &nbsp;
                        <Chip
                          label={p.department.departmentName}
                          size="small"
                        ></Chip>
                      </MenuItem>
                    ) : null
                  )
                : null}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              select
              size="small"
              name="trimester"
              label="Trimester"
              value={trimester}
              helperText={'Select trimester'}
              variant="outlined"
              onChange={(e) => setTrimester(e.target.value)}
              className={classes.textField}
            >
              <MenuItem value={''}>* Select the trimester</MenuItem>
              <MenuItem value={'Spring'}>Spring</MenuItem>
              <MenuItem value={'Summer'}>Summer</MenuItem>
              <MenuItem value={'Fall'}>Fall</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} sm={3} align="center">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                size="small"
                inputVariant="outlined"
                views={['year']}
                helperText={'Select Exam Year'}
                label="Year"
                value={year}
                onChange={setYear}
                autoOk={true}
              />
              <i
                className="fas fa-times"
                style={{
                  color: 'red',
                  cursor: 'pointer',
                  display: year == null ? 'none' : '',
                  marginLeft: '5px',
                }}
                onClick={(e) => setYear(null)}
              ></i>
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              select
              size="small"
              name="courseType"
              label="Program Type"
              value={courseType}
              helperText={'Select Program Type'}
              variant="outlined"
              onChange={(e) => setCourseType(e.target.value)}
              className={classes.textField}
            >
              <MenuItem value={''}>* Select Program Type</MenuItem>
              <MenuItem value={'Undergraduate'}>Undergraduate</MenuItem>
              <MenuItem value={'Graduate'}>Graduate</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              select
              size="small"
              name="conducted"
              label="Conducted"
              value={conducted}
              helperText={'Select Exam Status'}
              variant="outlined"
              onChange={(e) => setConducted(e.target.value)}
              className={classes.textField}
            >
              <MenuItem value={'All'}>All</MenuItem>
              <MenuItem value={false}>Not Conducted</MenuItem>
              <MenuItem value={true}>Conducted</MenuItem>
            </TextField>
          </Grid>
        </Grid>
        <br />
        <AlertComponent />
        {loadingList && <Spinner />}
        <TableContainer component={Paper} className={classes.my}>
          <Table className={classes.table} aria-label="user table" size="small">
            <TableHead>
              <TableRow>
                <StyledTableCell align="left">Title</StyledTableCell>
                <StyledTableCell align="left">Trimester</StyledTableCell>
                <StyledTableCell align="left">Batch</StyledTableCell>
                <StyledTableCell align="left">Exam Date</StyledTableCell>
                <StyledTableCell align="center">Delete</StyledTableCell>
                <StyledTableCell align="center">Conducted</StyledTableCell>
                <StyledTableCell align="center">Applicants</StyledTableCell>
                <StyledTableCell align="center">View/Edit</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {exams && exams.length > 0 ? (
                exams.map((exam) => (
                  <StyledTableRow key={exam._id} hover>
                    <StyledTableCell align="left">{exam.title}</StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      {exam.trimester} - {exam.year}
                    </StyledTableCell>

                    <StyledTableCell align="left">{exam.batch}</StyledTableCell>
                    <StyledTableCell align="left">
                      {format(new Date(exam.examDate), 'PPPp')}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <IconButton
                        aria-label="delete-exam"
                        onClick={() => onDelete(exam._id)}
                        // className={classes.btn}
                        color="secondary"
                      >
                        <DeleteForeverIcon color="secondary" />
                      </IconButton>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Checkbox
                        checked={exam.conducted}
                        onChange={(e) => onConducted(e, exam)}
                        name="Conducted"
                        color="primary"
                      />
                    </StyledTableCell>

                    <StyledTableCell align="center">
                      <Link to={`/exam/students/${exam._id}`}>
                        <IconButton aria-label="exam-students" color="primary">
                          <Chip
                            label={
                              studentsInExam[exam._id]
                                ? studentsInExam[exam._id]
                                : 0
                            }
                            color="primary"
                            style={{ color: '#FFF', cursor: 'pointer' }}
                          />
                        </IconButton>
                      </Link>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Link to={`/edit-exam/${exam._id}`}>
                        <IconButton aria-label="delete-exam" color="primary">
                          <ArrowForwardIcon />
                        </IconButton>
                      </Link>
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              ) : (
                <small>
                  &nbsp;No active exams found, try changing the filter
                </small>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </ThemeProvider>
  );
};

export default Exams;
