import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { listExams } from '../../actions/examActions';
import {
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Typography,
} from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import AlertComponent from '../layout/AlertComponent';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import clsx from 'clsx';
import {
  makeStyles,
  createMuiTheme,
  ThemeProvider,
} from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import InsertInvitationIcon from '@material-ui/icons/InsertInvitation';

import Grid from '@material-ui/core/Grid';

import { countries } from './countries/countries';

import { Fragment } from 'react';

import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#00acee',
    },
    secondary: {
      main: '#fd3a69',
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    // display: 'flex',
    // flexWrap: 'wrap',
    justifyContent: 'center',
    marginTop: theme.spacing(3),
  },
  margin: {
    // margin: theme.spacing(1),
  },
  marginDate: {
    // marginTop: theme.spacing(1),
    // marginLeft: theme.spacing(1),
    // marginRight: theme.spacing(1),
    marginBottom: '-3px',
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: '100%',
  },
  dateOfBirthErrorClass: {
    borderColor: 'red !important',
  },
  nextStepErrorTextStyle: {
    color: 'red',
    display: 'block',
  },
  btn: {
    color: '#FFF',
    marginRight: '10px',
  },
  btnSecondary: {
    // color: '#00acee',
    marginRight: '10px',
  },
}));

const Step2Personal = ({
  onChange,
  setDateOfBirth,
  steps,
  nextStep,
  handleAddWorkExperience,
  handleChangeWorkExperience,
  handleRemoveWorkExperience,
  onOtherProgramSelect,
  handleShowOtherPrograms,
  formData: {
    religion,
    gender,
    dateOfBirth,
    nationality,

    presentAptNumber,
    permanentAptNumber,

    fathersName,
    fathersPhone,

    mothersName,
    mothersPhone,

    sscInstitutionName,
    sscGroup,
    sscPassingYear,
    sscGpa,
    sscSubjects,

    hscInstitutionName,
    hscGroup,
    hscPassingYear,
    hscGpa,
    hscSubjects,

    examCourseType,

    gradDegreeType,
    gradDegreeInstitutionName,
    gradDegreeCourseName,
    gradDegreeMajor,
    gradDegreeAcademicYearFrom,
    gradDegreeAcademicYearTo,
    gradDegreeCGpa,

    mastersDegreeType,
    mastersDegreeInstitutionName,
    mastersDegreeCourseName,
    mastersDegreeMajor,
    mastersDegreeAcademicYearFrom,
    mastersDegreeAcademicYearTo,
    mastersDegreeCGpa,

    workExperience,
    tillDateExists,

    examId,
    otherPrograms,
    showOtherPrograms,
  },
  prevStep,
  currentStepMobile,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const examList = useSelector((state) => state.examList);
  const { loading: loadingList, exams, error } = examList;

  useEffect(() => {
    window.scrollTo(0, 0);
    if (exams.length <= 0) {
      dispatch(listExams());
    }
    // eslint-disable-next-line
  }, [listExams, dispatch]);

  const [errorTexts, setErrorTexts] = useState({
    nextStepErrorText: '',
    religionError: false,
    religionText: 'Select your religion*',
    genderError: false,
    genderText: 'Select your gender*',
    dateOfBirthError: false,
    dateOfBirthText: 'Select your date of birth*',
    nationalityError: false,
    nationalityText: 'Select your nationality*',
    presentAddressError: false,
    presentAddressText: 'Example: 2/A Arambagh, Motijheel, Dhaka - 1000',
    permanentAddressError: false,
    permanentAddressText:
      'Example: 231/7, Chashara Hasnat Square, Narayanganj 1400',

    fathersNameError: false,
    fathersNameText: "Enter your father's name*",
    fathersPhoneError: false,
    fathersPhoneText: "Enter your father's phone number*, Example: 01708661555",

    mothersNameError: false,
    mothersNameText: "Enter your mother's name*",
    mothersPhoneError: false,
    mothersPhoneText: "Enter your mother's phone number*, Example: 01708661555",

    sscInstitutionNameError: false,
    sscInstitutionNameText: 'Enter your ssc institution name*',
    sscGroupError: false,
    sscGroupText: 'Select your ssc group*',
    sscPassingYearError: false,
    sscPassingYearText: 'Enter your ssc passing year*',
    sscGpaError: false,
    sscGpaText: 'SSC GPA (with optional subject)*',

    hscInstitutionNameError: false,
    hscInstitutionNameText: 'Enter your hsc institution name*',
    hscGroupError: false,
    hscGroupText: 'Select your hsc group*',
    hscPassingYearError: false,
    hscPassingYearText: 'Enter your hsc passing year*',
    hscGpaError: false,
    hscGpaText: 'HSC GPA (with optional subject)*',

    gradDegreeTypeError: false,
    gradDegreeTypeText: 'Degree Type*',
    gradDegreeInstitutionNameError: false,
    gradDegreeInstitutionNameText: 'Institution name*',
    gradDegreeCourseNameError: false,
    gradDegreeCourseNameText: 'Course name*',
    gradDegreeAcademicYearFromError: false,
    gradDegreeAcademicYearFromText: 'Staring year (academic)*',
    gradDegreeAcademicYearToError: false,
    gradDegreeAcademicYearToText: 'Ending year (academic)*',
    gradDegreeCGpaError: false,
    gradDegreeCGpaText: 'Enter your CGPA*',

    mastersDegreeCGpaError: false,
    mastersDegreeCGpaText: 'Enter your CGPA*',
  });

  const {
    nextStepErrorText,

    religionError,
    religionText,
    genderError,
    genderText,
    dateOfBirthError,
    dateOfBirthText,
    nationalityError,
    nationalityText,

    presentAddressText,
    presentAddressError,
    permanentAddressText,
    permanentAddressError,

    fathersNameError,
    fathersNameText,
    fathersPhoneError,
    fathersPhoneText,

    mothersNameError,
    mothersNameText,
    mothersPhoneError,
    mothersPhoneText,

    sscInstitutionNameError,
    sscInstitutionNameText,
    sscGroupError,
    sscGroupText,
    sscPassingYearError,
    sscPassingYearText,
    sscGpaError,
    sscGpaText,

    hscInstitutionNameError,
    hscInstitutionNameText,
    hscGroupError,
    hscGroupText,
    hscPassingYearError,
    hscPassingYearText,
    hscGpaError,
    hscGpaText,

    gradDegreeTypeError,
    gradDegreeTypeText,
    gradDegreeInstitutionNameError,
    gradDegreeInstitutionNameText,
    gradDegreeCourseNameError,
    gradDegreeCourseNameText,
    gradDegreeAcademicYearFromError,
    gradDegreeAcademicYearFromText,
    gradDegreeAcademicYearToError,
    gradDegreeAcademicYearToText,
    gradDegreeCGpaError,
    gradDegreeCGpaText,

    mastersDegreeCGpaError,
    mastersDegreeCGpaText,
  } = errorTexts;

  const validate = () => {
    let errors = {};

    if (religion === '') {
      errors.religionError = true;
      errors.religionText = 'You must select your religion';
    }

    if (gender === '') {
      errors.genderError = true;
      errors.genderText = 'You must select your gender';
    }

    if (dateOfBirth === '') {
      errors.dateOfBirthError = 'classes.dateOfBirthErrorClass';
      errors.dateOfBirthText = 'You must select your date of birth';
    }

    if (dateOfBirth === '') {
      errors.dateOfBirthError = 'red';
      errors.dateOfBirthText = 'You must select your date of birth';
    }

    if (nationality === '') {
      errors.nationalityError = true;
      errors.nationalityText = 'You must select your nationality';
    }

    if (presentAptNumber === '') {
      errors.presentAddressError = true;
      errors.presentAddressText = 'You must enter your present address';
    }

    if (permanentAptNumber === '') {
      errors.permanentAddressError = true;
      errors.permanentAddressText = 'You must enter your permanent address';
    }

    if (fathersName === '') {
      errors.fathersNameError = true;
      errors.fathersNameText = "You must enter your father's name";
    }

    if (fathersPhone === '') {
      errors.fathersPhoneError = true;
      errors.fathersPhoneText =
        "You must enter your father's phone number. Example: 01708661555";
    }

    if (!fathersPhone.match('^[0]{1}[1]{1}[0-9]{9}$')) {
      errors.fathersPhoneError = true;
      errors.fathersPhoneText =
        'Enter valid Bangladeshi phone number. Example: 01708661555';
    }

    if (mothersName === '') {
      errors.mothersNameError = true;
      errors.mothersNameText = "You must enter your mother's name";
    }

    if (mothersPhone === '') {
      errors.mothersPhoneError = true;
      errors.mothersPhoneText =
        "You must enter your mother's phone number. Example: 01708661555";
    }

    if (!mothersPhone.match('^[0]{1}[1]{1}[0-9]{9}$')) {
      errors.mothersPhoneError = true;
      errors.mothersPhoneText =
        'Enter valid Bangladeshi phone number. Example: 01708661555';
    }

    if (sscInstitutionName === '') {
      errors.sscInstitutionNameError = true;
      errors.sscInstitutionNameText =
        'You must enter your ssc institution name';
    }

    if (sscGroup === '') {
      errors.sscGroupError = true;
      errors.sscGroupText = 'You must select your ssc group';
    }

    if (sscPassingYear === '') {
      errors.sscPassingYearError = true;
      errors.sscPassingYearText = 'You must enter your ssc passing year';
    }

    if (sscGpa === '' || sscGpa === 0) {
      errors.sscGpaError = true;
      errors.sscGpaText = 'You must enter your ssc gpa';
    }

    if (sscGpa < 2.5 || sscGpa > 5) {
      errors.sscGpaError = true;
      errors.sscGpaText = 'Minimum GPA 2.5 on a scale of 5.00 in SSC.';
    }

    if (hscInstitutionName === '') {
      errors.hscInstitutionNameError = true;
      errors.hscInstitutionNameText =
        'You must enter your hsc institution name';
    }

    if (hscGroup === '') {
      errors.hscGroupError = true;
      errors.hscGroupText = 'You must select your hsc group';
    }

    if (hscPassingYear === '') {
      errors.hscPassingYearError = true;
      errors.hscPassingYearText = 'You must enter your hsc passing year';
    }

    // Get the current date
    const currentDate = new Date();

    // Get the current year
    const currentYear = currentDate.getFullYear();

    if (currentYear - Number(hscPassingYear) > 3) {
      errors.hscPassingYearError = true;
      errors.hscPassingYearText =
        'Admission not possible as you passed HSC 3 years ago';
    }

    if (hscGpa === '' || hscGpa === 0) {
      errors.hscGpaError = true;
      errors.hscGpaText = 'You must enter your hsc gpa';
    }

    if (hscGpa < 2.5 || hscGpa > 5) {
      errors.hscGpaError = true;
      errors.hscGpaText = 'Minimum GPA 2.5 on a scale of 5.00 in HSC.';
    }

    if (examCourseType === 'graduate') {
      if (gradDegreeType === '') {
        errors.gradDegreeTypeError = true;
        errors.gradDegreeTypeText = 'You must select your type';
      }

      if (gradDegreeInstitutionName === '') {
        errors.gradDegreeInstitutionNameError = true;
        errors.gradDegreeInstitutionNameText =
          'You must enter your institution name';
      }

      if (gradDegreeCourseName === '') {
        errors.gradDegreeCourseNameError = true;
        errors.gradDegreeCourseNameText = 'You must enter your course name';
      }

      if (gradDegreeAcademicYearFrom === '') {
        errors.gradDegreeAcademicYearFromError = true;
        errors.gradDegreeAcademicYearFromText =
          'You must enter your academic year';
      }

      if (gradDegreeAcademicYearTo === '') {
        errors.gradDegreeAcademicYearToError = true;
        errors.gradDegreeAcademicYearToText =
          'You must enter your academic year';
      }

      if (gradDegreeCGpa === '' || gradDegreeCGpa === 0) {
        errors.gradDegreeCGpaError = true;
        errors.gradDegreeCGpaText = 'You must enter your grad CGPA';
      }

      if (gradDegreeCGpa < 1 || gradDegreeCGpa > 4) {
        errors.gradDegreeCGpaError = true;
        errors.gradDegreeCGpaText = 'CGPA should be in between 1.00 to 4.00';
      }

      // if (mastersDegreeCGpa === '' || mastersDegreeCGpa === 0) {
      //   errors.mastersDegreeCGpaError = true;
      //   errors.mastersDegreeCGpaText = 'You must enter your grad CGPA';
      // }

      // if (mastersDegreeCGpa < 1 || mastersDegreeCGpa > 4) {
      //   errors.mastersDegreeCGpaError = true;
      //   errors.mastersDegreeCGpaText = 'CGPA should be in between 1.00 to 4.00';
      // }
    }

    setErrorTexts({
      ...errors,
      nextStepErrorText:
        'There are some error in your submission. Please scroll up and fix them.',
    });

    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const isValid = validate();

    Object.entries(isValid).length === 0 && nextStep(e);
  };

  return (
    <ThemeProvider theme={theme}>
      <div className="container">
        <h1 className="large text-primary">Information</h1>
        <p className="lead">
          <i className="far fa-file-alt"></i> Please fill up personal, academic
          & guardian's information
        </p>
        <div className="hide-sm">{steps}</div>
        <div className="hide-lg">{currentStepMobile}</div>
        <Divider /> <br />
        <form className={classes.root} onSubmit={(e) => handleSubmit(e)}>
          <Typography variant="button" component="h2" gutterBottom>
            Personal Details
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                error={religionError}
                select
                name="religion"
                label="Religion"
                value={religion}
                helperText={religionText}
                variant="outlined"
                onChange={(e) => onChange(e)}
                className={clsx(classes.margin, classes.textField)}
              >
                <MenuItem value={''}> Select</MenuItem>
                <MenuItem value={'Islam'}>Islam</MenuItem>
                <MenuItem value={'Christianity'}>Christianity</MenuItem>
                <MenuItem value={'Hinduism'}>Hinduism</MenuItem>
                <MenuItem value={'Buddhism'}>Buddhism</MenuItem>
                <MenuItem value={'Other'}>Other</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                error={genderError}
                select
                name="gender"
                label="Gender"
                value={gender}
                helperText={genderText}
                variant="outlined"
                onChange={(e) => onChange(e)}
                className={clsx(classes.margin, classes.textField)}
              >
                <MenuItem value={''}> Select</MenuItem>
                <MenuItem value={'Male'}>Male</MenuItem>
                <MenuItem value={'Female'}>Female</MenuItem>
                <MenuItem value={'Other'}>Other</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  className={clsx(classes.textField)}
                  value={dateOfBirth}
                  onChange={setDateOfBirth}
                  onError={console.log}
                  disableFuture
                  minDate={new Date('1970-01-01')}
                  inputVariant="outlined"
                  format="dd/MM/yyyy"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton>
                          <InsertInvitationIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  label="Select date of birth."
                />
              </MuiPickersUtilsProvider>
              <br />
              <label
                className="labelText"
                htmlFor="dateOfBirth"
                style={{ color: dateOfBirthError }}
              >
                {dateOfBirthText}
              </label>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                error={nationalityError}
                select
                name="nationality"
                label="Nationality"
                value={nationality}
                helperText={nationalityText}
                variant="outlined"
                onChange={(e) => onChange(e)}
                className={clsx(classes.margin, classes.textField)}
              >
                <MenuItem value={''}> Select</MenuItem>
                {countries.map((country) => (
                  <MenuItem key={country.num_code} value={country.nationality}>
                    {country.nationality}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
          <br />
          <Divider /> <br />
          <Typography variant="button" component="h2" gutterBottom>
            Address
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                error={presentAddressError}
                type="text"
                name="presentAptNumber"
                label="Present Address"
                value={presentAptNumber}
                helperText={presentAddressText}
                variant="outlined"
                onChange={(e) => onChange(e)}
                className={clsx(classes.margin, classes.textField)}
              ></TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                error={permanentAddressError}
                type="text"
                name="permanentAptNumber"
                label="Permanent Address"
                value={permanentAptNumber}
                helperText={permanentAddressText}
                variant="outlined"
                onChange={(e) => onChange(e)}
                className={clsx(classes.margin, classes.textField)}
              ></TextField>
            </Grid>
          </Grid>
          <br />
          <Divider /> <br />
          <Typography variant="button" component="h2" gutterBottom>
            Guardian's Info
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                error={fathersNameError}
                type="text"
                name="fathersName"
                label="Father's Name"
                value={fathersName}
                helperText={fathersNameText}
                variant="outlined"
                onChange={(e) => onChange(e)}
                className={clsx(classes.margin, classes.textField)}
              ></TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                error={fathersPhoneError}
                type="tel"
                name="fathersPhone"
                label="Father's Phone Number"
                value={fathersPhone}
                helperText={fathersPhoneText}
                variant="outlined"
                onChange={(e) => onChange(e)}
                className={clsx(classes.margin, classes.textField)}
              ></TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                error={mothersNameError}
                type="text"
                name="mothersName"
                label="Mother's Name"
                value={mothersName}
                helperText={mothersNameText}
                variant="outlined"
                onChange={(e) => onChange(e)}
                className={clsx(classes.margin, classes.textField)}
              ></TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                error={mothersPhoneError}
                type="tel"
                name="mothersPhone"
                label="Mother's Phone Number"
                value={mothersPhone}
                helperText={mothersPhoneText}
                variant="outlined"
                onChange={(e) => onChange(e)}
                className={clsx(classes.margin, classes.textField)}
              ></TextField>
            </Grid>
          </Grid>
          <br />
          <Divider /> <br />
          <Typography variant="button" component="h2" gutterBottom>
            SSC Information
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                error={sscInstitutionNameError}
                type="text"
                name="sscInstitutionName"
                label="Institution Name"
                value={sscInstitutionName}
                helperText={sscInstitutionNameText}
                variant="outlined"
                onChange={(e) => onChange(e)}
                className={clsx(classes.margin, classes.textField)}
              ></TextField>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                error={sscGroupError}
                select
                name="sscGroup"
                label="Group"
                value={sscGroup}
                helperText={sscGroupText}
                variant="outlined"
                onChange={(e) => onChange(e)}
                className={clsx(classes.margin, classes.textField)}
              >
                <MenuItem value={''}> Select</MenuItem>
                <MenuItem value={'Science'}>Science</MenuItem>
                <MenuItem value={'Commerce'}>Commerce</MenuItem>
                <MenuItem value={'Humanities'}>Humanities</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                error={sscPassingYearError}
                type="number"
                name="sscPassingYear"
                label="Passing Year"
                value={sscPassingYear}
                helperText={sscPassingYearText}
                variant="outlined"
                onChange={(e) => onChange(e)}
                className={clsx(classes.margin, classes.textField)}
              ></TextField>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                error={sscGpaError}
                type="number"
                name="sscGpa"
                label="Total GPA"
                value={sscGpa === 0 ? '' : sscGpa}
                helperText={sscGpaText}
                variant="outlined"
                onChange={(e) => onChange(e)}
                className={clsx(classes.margin, classes.textField)}
              ></TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                type="text"
                name="sscSubjects"
                label="Enter SSC Subjects (comma separated)"
                value={sscSubjects}
                helperText="Example: Higher Math, Physics, Chemistry"
                variant="outlined"
                onChange={(e) => onChange(e)}
                className={clsx(classes.margin, classes.textField)}
              ></TextField>
            </Grid>
            <br />
            <Divider />
            <br />
          </Grid>
          <br />
          <Divider />
          <br />
          <Typography variant="button" component="h2" gutterBottom>
            HSC Information
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                error={hscInstitutionNameError}
                type="text"
                name="hscInstitutionName"
                label="Institution Name"
                value={hscInstitutionName}
                helperText={hscInstitutionNameText}
                variant="outlined"
                onChange={(e) => onChange(e)}
                className={clsx(classes.margin, classes.textField)}
              ></TextField>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                error={hscGroupError}
                select
                name="hscGroup"
                label="Group"
                value={hscGroup}
                helperText={hscGroupText}
                variant="outlined"
                onChange={(e) => onChange(e)}
                className={clsx(classes.margin, classes.textField)}
              >
                <MenuItem value={''}>Select</MenuItem>
                <MenuItem value={'Science'}>Science</MenuItem>
                <MenuItem value={'Commerce'}>Commerce</MenuItem>
                <MenuItem value={'Humanities'}>Humanities</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                error={hscPassingYearError}
                type="number"
                name="hscPassingYear"
                label="Passing Year"
                value={hscPassingYear}
                helperText={hscPassingYearText}
                variant="outlined"
                onChange={(e) => onChange(e)}
                className={clsx(classes.margin, classes.textField)}
              ></TextField>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                error={hscGpaError}
                type="number"
                name="hscGpa"
                label="Total GPA"
                value={hscGpa === 0 ? '' : hscGpa}
                helperText={hscGpaText}
                variant="outlined"
                onChange={(e) => onChange(e)}
                className={clsx(classes.margin, classes.textField)}
              ></TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                type="text"
                name="hscSubjects"
                label="Enter HSC Subjects (comma separated)"
                value={hscSubjects}
                helperText="Example: Accounting, Statistics, Economics"
                variant="outlined"
                onChange={(e) => onChange(e)}
                className={clsx(classes.margin, classes.textField)}
              ></TextField>
            </Grid>
            <br />
            <Divider />
            <br />
          </Grid>
          {examCourseType === 'graduate' ? (
            <Fragment>
              <br />
              <Divider />
              <br />
              <Typography variant="button" component="h2" gutterBottom>
                Undergraduate Information
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    error={gradDegreeInstitutionNameError}
                    type="text"
                    name="gradDegreeInstitutionName"
                    label="Institution Name"
                    value={gradDegreeInstitutionName}
                    helperText={gradDegreeInstitutionNameText}
                    variant="outlined"
                    onChange={(e) => onChange(e)}
                    className={clsx(classes.margin, classes.textField)}
                  ></TextField>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    error={gradDegreeTypeError}
                    select
                    name="gradDegreeType"
                    label="Degree Type"
                    value={gradDegreeType}
                    helperText={gradDegreeTypeText}
                    variant="outlined"
                    onChange={(e) => onChange(e)}
                    className={clsx(classes.margin, classes.textField)}
                  >
                    <MenuItem value={''}>Select</MenuItem>
                    <MenuItem value={'Hons'}>Hons</MenuItem>
                    <MenuItem value={'Degree'}>Degree</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <TextField
                    error={gradDegreeCourseNameError}
                    type="text"
                    name="gradDegreeCourseName"
                    label="Course Name"
                    value={gradDegreeCourseName}
                    helperText={gradDegreeCourseNameText}
                    variant="outlined"
                    onChange={(e) => onChange(e)}
                    className={clsx(classes.margin, classes.textField)}
                  ></TextField>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    type="text"
                    name="gradDegreeMajor"
                    label="Major"
                    value={gradDegreeMajor}
                    helperText="Major name"
                    variant="outlined"
                    onChange={(e) => onChange(e)}
                    className={clsx(classes.margin, classes.textField)}
                  ></TextField>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    error={gradDegreeAcademicYearFromError}
                    type="number"
                    name="gradDegreeAcademicYearFrom"
                    label="Academic Year (Start)"
                    value={gradDegreeAcademicYearFrom}
                    helperText={gradDegreeAcademicYearFromText}
                    variant="outlined"
                    onChange={(e) => onChange(e)}
                    className={clsx(classes.margin, classes.textField)}
                  ></TextField>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    error={gradDegreeAcademicYearToError}
                    type="number"
                    name="gradDegreeAcademicYearTo"
                    label="Academic Year (End)"
                    value={gradDegreeAcademicYearTo}
                    helperText={gradDegreeAcademicYearToText}
                    variant="outlined"
                    onChange={(e) => onChange(e)}
                    className={clsx(classes.margin, classes.textField)}
                  ></TextField>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    error={gradDegreeCGpaError}
                    type="number"
                    name="gradDegreeCGpa"
                    label="CGPA"
                    value={gradDegreeCGpa === 0 ? '' : gradDegreeCGpa}
                    helperText={gradDegreeCGpaText}
                    variant="outlined"
                    onChange={(e) => onChange(e)}
                    className={clsx(classes.margin, classes.textField)}
                  ></TextField>
                </Grid>
                <br />
                <Divider />
                <br />
              </Grid>
            </Fragment>
          ) : null}
          {examCourseType === 'graduate' ? (
            <Fragment>
              <br />
              <Divider />
              <br />
              <Typography variant="button" component="h2">
                Graduate Information
              </Typography>
              <Typography
                variant="caption"
                display="block"
                gutterBottom
                style={{ marginBottom: '20px' }}
              >
                (If you have completed any master's degree, for example: MSc,
                MA, MBA etc, then enter here. Otherwise leave empty.)
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    type="text"
                    name="mastersDegreeInstitutionName"
                    label="Institution Name"
                    value={mastersDegreeInstitutionName}
                    helperText="Institution Name"
                    variant="outlined"
                    onChange={(e) => onChange(e)}
                    className={clsx(classes.margin, classes.textField)}
                  ></TextField>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <TextField
                    type="text"
                    name="mastersDegreeCourseName"
                    label="Course Name"
                    value={mastersDegreeCourseName}
                    helperText="Course Name"
                    variant="outlined"
                    onChange={(e) => onChange(e)}
                    className={clsx(classes.margin, classes.textField)}
                  ></TextField>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    type="text"
                    name="mastersDegreeMajor"
                    label="Major"
                    value={mastersDegreeMajor}
                    helperText="Major name"
                    variant="outlined"
                    onChange={(e) => onChange(e)}
                    className={clsx(classes.margin, classes.textField)}
                  ></TextField>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    type="number"
                    name="mastersDegreeAcademicYearFrom"
                    label="Academic Year (Start)"
                    value={mastersDegreeAcademicYearFrom}
                    helperText="Academic Year"
                    variant="outlined"
                    onChange={(e) => onChange(e)}
                    className={clsx(classes.margin, classes.textField)}
                  ></TextField>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    type="number"
                    name="mastersDegreeAcademicYearTo"
                    label="Academic Year (End)"
                    value={mastersDegreeAcademicYearTo}
                    helperText="Academic Year"
                    variant="outlined"
                    onChange={(e) => onChange(e)}
                    className={clsx(classes.margin, classes.textField)}
                  ></TextField>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    error={mastersDegreeCGpaError}
                    type="number"
                    name="mastersDegreeCGpa"
                    label="CGPA"
                    value={mastersDegreeCGpa}
                    helperText={mastersDegreeCGpaText}
                    variant="outlined"
                    onChange={(e) => onChange(e)}
                    className={clsx(classes.margin, classes.textField)}
                  ></TextField>
                </Grid>
                <br />
                <Divider />
                <br />
              </Grid>
              <br />
              <Divider />
              <br />
              <Typography variant="button" component="h2">
                Work Experience
              </Typography>
              <Typography
                variant="caption"
                display="block"
                gutterBottom
                style={{ marginBottom: '20px' }}
              >
                (If you have any work experience list them below. Otherwise
                leave empty.)
              </Typography>
              {workExperience.map((workExp) => (
                <Grid
                  container
                  spacing={3}
                  key={workExp.id}
                  className={clsx(classes.margin)}
                >
                  <Grid item xs={12} sm={3}>
                    <TextField
                      name="companyName"
                      label="Company Name"
                      variant="outlined"
                      value={workExp.companyName}
                      className={clsx(classes.margin, classes.textField)}
                      onChange={(e) =>
                        handleChangeWorkExperience(workExp.id, e)
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      name="position"
                      label="Position"
                      variant="outlined"
                      value={workExp.position}
                      className={clsx(classes.margin, classes.textField)}
                      onChange={(e) =>
                        handleChangeWorkExperience(workExp.id, e)
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <TextField
                      name="from"
                      label="From"
                      variant="outlined"
                      value={workExp.from}
                      className={clsx(classes.margin, classes.textField)}
                      onChange={(e) =>
                        handleChangeWorkExperience(workExp.id, e)
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <TextField
                      name="to"
                      label="To"
                      variant="outlined"
                      disabled={workExp.to === 'Till Date' && true}
                      value={workExp.to}
                      className={clsx(classes.margin, classes.textField)}
                      onChange={(e) =>
                        handleChangeWorkExperience(workExp.id, e)
                      }
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          size="small"
                          name="to"
                          disabled={
                            tillDateExists === ''
                              ? false
                              : tillDateExists === workExp.id
                              ? false
                              : true
                          }
                          value={workExp.to === 'Till Date' ? '' : 'Till Date'}
                          onClick={(e) =>
                            handleChangeWorkExperience(workExp.id, e)
                          }
                        />
                      }
                      label={<small>Till Date</small>}
                    />
                  </Grid>

                  <Grid item xs={12} sm={2} align="center">
                    <IconButton
                      onClick={handleAddWorkExperience}
                      color="primary"
                    >
                      <AddIcon />
                    </IconButton>
                    <IconButton
                      disabled={workExperience.length === 1}
                      onClick={() => handleRemoveWorkExperience(workExp.id)}
                      color="secondary"
                    >
                      <RemoveIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              ))}
            </Fragment>
          ) : null}
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    onClick={(e) => handleShowOtherPrograms(e)}
                    checked={showOtherPrograms}
                  />
                }
                label="Did you apply for any other programs?"
              />
            </Grid>
            {/* <Divider /> */}
            {showOtherPrograms &&
              exams
                .filter((exam) => exam._id !== examId)
                .map((exam) => (
                  <Grid item xs={12} key={exam._id}>
                    <Divider />
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          onClick={(e) => onOtherProgramSelect(e, exam)}
                          size="small"
                          checked={
                            otherPrograms.indexOf(exam._id) !== -1
                              ? true
                              : false
                          }
                        />
                      }
                      label={`${exam.title}`}
                      style={{ fontSize: '5px' }}
                    />
                  </Grid>
                ))}
          </Grid>
          <AlertComponent />
          <div className="form-group">
            {nextStepErrorText && (
              <small className={classes.nextStepErrorTextStyle}>
                <br />
                {nextStepErrorText}
              </small>
            )}
            <br />
            <Button
              variant="contained"
              color="default"
              disableElevation
              className={classes.btnSecondary}
              onClick={(e) => prevStep(e)}
            >
              <i className="fas fa-arrow-left"></i> &nbsp; Back
            </Button>
            <Button
              variant="contained"
              color="primary"
              disableElevation
              className={classes.btn}
              type="submit"
            >
              Next &nbsp;<i className="fas fa-arrow-right"></i>
            </Button>
          </div>
        </form>
      </div>
    </ThemeProvider>
  );
};

Step2Personal.propTypes = {
  onChange: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  prevStep: PropTypes.func.isRequired,
  formData: PropTypes.object.isRequired,
};

export default Step2Personal;
