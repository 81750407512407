import axios from 'axios';
import * as Sentry from '@sentry/react';
import {
  ADMIN_ADMIT_PDF_FAIL,
  ADMIN_ADMIT_PDF_REQUEST,
  ADMIN_ADMIT_PDF_RESET,
  ADMIN_ADMIT_PDF_SUCCESS,
} from '../constants/pdfConstants';
import { setAlert } from './alert';

// Get current users profile
export const downloadAdmitByAdmin = (user_id) => async (dispatch) => {
  try {
    dispatch({ type: ADMIN_ADMIT_PDF_REQUEST });
    const res = await axios.get(`/api/admit/download/${user_id}`);

    dispatch({
      type: ADMIN_ADMIT_PDF_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    Sentry.captureException(error);
    const errors = error.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
    }
    dispatch({
      type: ADMIN_ADMIT_PDF_FAIL,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};
