import {
  PROFILE_CREATE_FAIL,
  PROFILE_CREATE_REQUEST,
  PROFILE_CREATE_SUCCESS,
  PROFILE_DELETE_FAIL,
  PROFILE_DELETE_REQUEST,
  PROFILE_DELETE_SUCCESS,
  PROFILE_LIST_FAIL,
  PROFILE_LIST_REQUEST,
  PROFILE_LIST_SUCCESS,
  PROFILE_UPDATE_REQUEST,
  PROFILE_UPDATE_SUCCESS,
  PROFILE_UPDATE_FAIL,
  PROFILE_DETAILS_REQUEST,
  PROFILE_DETAILS_SUCCESS,
  PROFILE_DETAILS_FAIL,
  PROFILE_DETAILS_RESET,
  PROFILE_LIST_REMOVE_ITEM,
  PROFILE_MARK_ADD_REQUEST,
  PROFILE_MARK_ADD_SUCCESS,
  PROFILE_MARK_ADD_FAIL,
  PROFILE_PICTURE_UPLOAD_FAIL,
  PROFILE_PICTURE_UPLOAD_SUCCESS,
  PROFILE_PICTURE_UPLOAD_REQUEST,
  PROFILE_PICTURE_UPLOAD_RESET,
  PROFILE_SIGNATURE_UPLOAD_REQUEST,
  PROFILE_SIGNATURE_UPLOAD_SUCCESS,
  PROFILE_SIGNATURE_UPLOAD_FAIL,
  PROFILE_SIGNATURE_UPLOAD_RESET,
} from '../constants/profileConstants';

export const userListReducer = (state = { users: [] }, action) => {
  switch (action.type) {
    case PROFILE_LIST_REQUEST:
      return { loading: true, users: [] };
    case PROFILE_LIST_SUCCESS:
      return {
        loading: false,
        users: action.payload.users,
        pages: action.payload.pages,
        page: action.payload.page,
        count: action.payload.count,
      };
    case PROFILE_LIST_REMOVE_ITEM:
      return {
        ...state,
        users: state.users.filter((user) => user._id !== action.payload),
        count: state.count - 1,
      };
    case PROFILE_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const userDetailsReducer = (state = { user: {} }, action) => {
  switch (action.type) {
    case PROFILE_DETAILS_REQUEST:
      return { loading: true, users: {} };
    case PROFILE_DETAILS_SUCCESS:
      return {
        loading: false,
        user: action.payload.user,
      };
    case PROFILE_DETAILS_RESET:
      return {
        loading: false,
        user: {},
      };
    case PROFILE_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const profileCreateReducer = (state = { profile: {} }, action) => {
  switch (action.type) {
    case PROFILE_CREATE_REQUEST:
      return { loading: true, profile: {} };
    case PROFILE_CREATE_SUCCESS:
      return {
        loading: false,
        profile: action.payload,
      };
    case PROFILE_CREATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const profileAddMarkReducer = (state = { profile: {} }, action) => {
  switch (action.type) {
    case PROFILE_MARK_ADD_REQUEST:
      return { loading: true, profile: {} };
    case PROFILE_MARK_ADD_SUCCESS:
      return {
        loading: false,
        profile: action.payload,
        success: true,
      };
    case PROFILE_MARK_ADD_FAIL:
      return { loading: false, error: action.payload, success: false };
    default:
      return state;
  }
};

export const userUpdateReducer = (state = { user: {} }, action) => {
  switch (action.type) {
    case PROFILE_UPDATE_REQUEST:
      return { loading: true, user: {} };
    case PROFILE_UPDATE_SUCCESS:
      return {
        loading: false,
        user: action.payload.user,
      };
    case PROFILE_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// Delete User
export const userDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case PROFILE_DELETE_REQUEST:
      return { loading: true };
    case PROFILE_DELETE_SUCCESS:
      return { loading: false, success: true };
    case PROFILE_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// Upload Profile Picture
export const profileUpProPicReducer = (state = { profile: {} }, action) => {
  switch (action.type) {
    case PROFILE_PICTURE_UPLOAD_REQUEST:
      return { loading: true, profile: {} };
    case PROFILE_PICTURE_UPLOAD_SUCCESS:
      return {
        loading: false,
        profile: action.payload,
        success: true,
      };
    case PROFILE_PICTURE_UPLOAD_FAIL:
      return { loading: false, error: action.payload, success: false };
    case PROFILE_PICTURE_UPLOAD_RESET:
      return { profile: {} };
    default:
      return state;
  }
};

// Upload Profile Picture
export const profileSignatureReducer = (state = { profile: {} }, action) => {
  switch (action.type) {
    case PROFILE_SIGNATURE_UPLOAD_REQUEST:
      return { loading: true, profile: {} };
    case PROFILE_SIGNATURE_UPLOAD_SUCCESS:
      return {
        loading: false,
        profile: action.payload,
        success: true,
      };
    case PROFILE_SIGNATURE_UPLOAD_FAIL:
      return { loading: false, error: action.payload, success: false };
    case PROFILE_SIGNATURE_UPLOAD_RESET:
      return { profile: {} };
    default:
      return state;
  }
};
