/* eslint-disable import/no-anonymous-default-export */
import {
  GET_PROFILE,
  PROFILE_ERROR,
  CLEAR_PROFILE,
  SEARCH_PROFILE,
  SINGLE_STUDENT_PROFILE,
  ADD_MARKS,
  SINGLE_STUDENT_PROFILE_PDF,
  CLEAR_PROFILE_PDF,
  SINGLE_STUDENT_PROFILE_ADMIT,
  CLEAR_PROFILE_ADMIT,
  SET_LOADING,
} from '../actions/types';

const initialState = {
  profile: null,
  profilePDF: null,
  profileAdmit: null,
  profiles: [],
  loading: true,
  error: {},
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    case ADD_MARKS:
    case GET_PROFILE:
    case SINGLE_STUDENT_PROFILE:
      return {
        ...state,
        profile: payload,
        loading: false,
      };
    case SINGLE_STUDENT_PROFILE_PDF:
      return {
        ...state,
        profilePDF: payload,
        loading: false,
      };
    case SINGLE_STUDENT_PROFILE_ADMIT:
      return {
        ...state,
        profileAdmit: payload,
        loading: false,
      };
    case SEARCH_PROFILE:
      return {
        ...state,
        profiles: payload,
        loading: false,
      };
    case PROFILE_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case CLEAR_PROFILE:
    case CLEAR_PROFILE_PDF:
    case CLEAR_PROFILE_ADMIT:
      return {
        ...state,
        profile: null,
        profilePDF: null,
        profileAdmit: null,
        profiles: [],
        loading: false,
      };
    default:
      return state;
  }
}
