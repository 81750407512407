import {
  PROGRAM_CREATE_FAIL,
  PROGRAM_CREATE_REQUEST,
  PROGRAM_CREATE_RESET,
  PROGRAM_CREATE_SUCCESS,
  PROGRAM_DELETE_FAIL,
  PROGRAM_DELETE_REQUEST,
  PROGRAM_DELETE_RESET,
  PROGRAM_DELETE_SUCCESS,
  PROGRAM_DETAILS_FAIL,
  PROGRAM_DETAILS_REQUEST,
  PROGRAM_DETAILS_SUCCESS,
  PROGRAM_LIST_FAIL,
  PROGRAM_LIST_REMOVE_ITEM,
  PROGRAM_LIST_REQUEST,
  PROGRAM_LIST_SUCCESS,
  PROGRAM_UPDATE_FAIL,
  PROGRAM_UPDATE_REQUEST,
  PROGRAM_UPDATE_RESET,
  PROGRAM_UPDATE_SUCCESS,
} from '../constants/programConstants';

// Get Programs
export const programListReducer = (state = { programs: [] }, action) => {
  switch (action.type) {
    case PROGRAM_LIST_REQUEST:
      return { loading: true, programs: [] };
    case PROGRAM_LIST_SUCCESS:
      return {
        loading: false,
        programs: action.payload.programs,
        count: action.payload.count,
      };
    case PROGRAM_LIST_FAIL:
      return { loading: false, error: action.payload };
    case PROGRAM_LIST_REMOVE_ITEM:
      return {
        ...state,
        programs: state.programs.filter(
          (program) => program._id !== action.payload
        ),
        count: state.count - 1,
      };
    default:
      return state;
  }
};

// Get Single program
export const programDetailsReducer = (
  state = { exam: { loading: false } },
  action
) => {
  switch (action.type) {
    case PROGRAM_DETAILS_REQUEST:
      return { loading: true };
    case PROGRAM_DETAILS_SUCCESS:
      return {
        loading: false,
        program: action.payload,
      };
    case PROGRAM_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// Create program
export const programCreateReducer = (state = { loading: false }, action) => {
  switch (action.type) {
    case PROGRAM_CREATE_REQUEST:
      return { loading: true };
    case PROGRAM_CREATE_SUCCESS:
      return {
        loading: false,
        program: action.payload,
        success: true,
      };
    case PROGRAM_CREATE_FAIL:
      return { loading: false, error: action.payload, success: false };
    case PROGRAM_CREATE_RESET:
      return { loading: false, success: false };
    default:
      return state;
  }
};

// Update program
export const programUpdateReducer = (state = { loading: false }, action) => {
  switch (action.type) {
    case PROGRAM_UPDATE_REQUEST:
      return { loading: true };
    case PROGRAM_UPDATE_SUCCESS:
      return {
        loading: false,
        program: action.payload,
        success: true,
      };
    case PROGRAM_UPDATE_FAIL:
      return { loading: false, error: action.payload, success: false };
    case PROGRAM_UPDATE_RESET:
      return { loading: false, success: false };
    default:
      return state;
  }
};

// Delete program
export const programDeleteReducer = (
  state = { loading: false, success: false },
  action
) => {
  switch (action.type) {
    case PROGRAM_DELETE_REQUEST:
      return { loading: true };
    case PROGRAM_DELETE_SUCCESS:
      return { loading: false, success: true };
    case PROGRAM_DELETE_FAIL:
      return { loading: false, success: false, error: action.payload };
    case PROGRAM_DELETE_RESET:
      return { loading: false, success: false };
    default:
      return state;
  }
};
