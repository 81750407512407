import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Spinner from '../layout/Spinner';
import { getCurrentProfile } from '../../actions/profile';
import ProfileTop from './student-profile/ProfileTop';
import ProfileAbout from './student-profile/ProfileAbout';
import ProfileGuardiansInfo from './student-profile/ProfileGuardiansInfo';
import ProfileEducationInfo from './student-profile/ProfileEducationInfo';
import ProfileMarks from './student-profile/ProfileMarks';
import { Button } from '@material-ui/core';
// import AlertComponent from './AlertComponent';

const StudentViewOnly = ({
  getCurrentProfile,
  profile: { profile, loading },
  auth: { user },
}) => {
  useEffect(() => {
    if (!profile || profile?.user?._id != user?._id) {
      getCurrentProfile();
    }
  }, [getCurrentProfile, profile, user]);

  return (
    <div className="container">
      {profile === null && loading ? (
        <Spinner />
      ) : (
        <Fragment>
          {profile === null ? (
            <Spinner />
          ) : (
            <div className="profile-grid my-1">
              <ProfileTop profile={profile} />
              <ProfileAbout profile={profile} />
              <ProfileGuardiansInfo profile={profile} />
              <ProfileEducationInfo profile={profile} />
              {/* <ProfileMarks profile={profile} /> */}
            </div>
          )}
          <Link to={`/dashboard`}>
            <Button
              size="small"
              variant="contained"
              color="default"
              disableElevation
            >
              <i className="fas fa-arrow-left"></i> &nbsp; Back
            </Button>
          </Link>
        </Fragment>
      )}
    </div>
  );
};

StudentViewOnly.propTypes = {
  getCurrentProfile: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  profile: state.profile,
  auth: state.auth,
});

export default connect(mapStateToProps, { getCurrentProfile })(StudentViewOnly);
