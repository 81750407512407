import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { listNotices } from '../../actions/notice';
import Spinner from '../layout/Spinner';
import NoticeItem from './NoticeItem';
import AlertComponent from '../layout/AlertComponent';
import {
  NOTICE_CREATE_RESET,
  NOTICE_DELETE_RESET,
} from '../../constants/noticeConstants';
import { Link } from 'react-router-dom';
import {
  Button,
  createMuiTheme,
  makeStyles,
  ThemeProvider,
} from '@material-ui/core';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#00acee',
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
    marginTop: theme.spacing(3),
  },
  textField: {
    width: '100%',
  },
  btn: {
    color: '#FFF',
    marginRight: '10px',
  },
  btnSecondary: {
    // color: '#00acee',
    marginRight: '10px',
  },
  my20: {
    marginBottom: '20px',
  },
}));

const Notices = () => {
  const noticeDelete = useSelector((state) => state.noticeDelete);
  const {
    loading: loadingDelete,
    success: successDelete,
    notice: notice,
    error: errorDelete,
  } = noticeDelete;

  const noticeList = useSelector((state) => state.noticeList);
  const { loading, notices, error, page, pages } = noticeList;

  const auth = useSelector((state) => state.auth);
  const { loading: authLoading, role } = auth;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: NOTICE_DELETE_RESET });
    dispatch({ type: NOTICE_CREATE_RESET });

    dispatch(listNotices());
  }, [dispatch, successDelete]);

  const classes = useStyles();

  return loading ? (
    <div className="container">
      <Spinner />
    </div>
  ) : (
    <ThemeProvider theme={theme}>
      <div className="container">
        <h1 className="large text-primary">Notices</h1>
        <Link to={`/dashboard`}>
          <Button
            variant="contained"
            color="default"
            disableElevation
            className={classes.btnSecondary}
          >
            <i className="fas fa-arrow-left"></i> &nbsp; Back
          </Button>
        </Link>
        {!authLoading && role.includes('ADMIN') && (
          <Link to="/create-notice">
            <Button
              variant="contained"
              color="primary"
              disableElevation
              className={classes.btn}
            >
              Create Notice
            </Button>
          </Link>
        )}

        {/* <p className="lead">Welcome to notices</p> */}
        <AlertComponent />
        {notices && notices.length > 0 ? (
          <div>
            {notices.map((notice) => (
              <NoticeItem key={notice._id} notice={notice} />
            ))}
          </div>
        ) : (
          <div>
            <p className="lead">No notices available.</p>
          </div>
        )}
      </div>
    </ThemeProvider>
  );
};

Notices.propTypes = {
  getNotices: PropTypes.func.isRequired,
};

export default Notices;
