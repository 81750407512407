import './App.css';
import React, { Fragment, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import * as Sentry from '@sentry/react';

import MenuAppBar from './components/layout/MenuAppBar';
import Landing from './components/layout/Landing';
import Login from './components/auth/Login';
import Dashboard from './components/dashboard/Dashboard';
import DashboardAdmin from './components/dashboard/DashboardAdmin';
import CreateProfile from './components/profile-forms/CreateProfile';
import CreateExam from './components/exam-forms/CreateExam';
import EditExam from './components/exam-forms/EditExam';
// import EditProfile from './components/profile-forms/EditProfile';
import Upload from './components/profile-forms/Upload';
import UploadAlt from './components/profile-forms/UploadAlt';
import Exams from './components/layout/Exams';
import ExamStudents from './components/layout/ExamStudents';
import Students from './components/layout/Students';
import Student from './components/layout/Student';
import Payment from './components/layout/payment/Payment';
import PaymentBank from './components/layout/payment/PaymentBank';
import DownloadAdmit from './components/layout/admit/DownloadAdmit';
import NotFound from './components/layout/NotFound';
import StudentViewOnly from './components/layout/StudentViewOnly';
import PrivateRoute from './components/routing/PrivateRoute';
import AdminRoute from './components/routing/AdminRoute';
import SuperAdminRoute from './components/routing/SuperAdminRoute';
import CloseIcon from '@material-ui/icons/Close';

import Contact from './components/contact/Contact';
import Notices from './components/notice/Notices';
import Notice from './components/notice/Notice';
import CreateNotice from './components/notice-forms/CreateNotice';
import EditNotice from './components/notice-forms/EditNotice';

// import AlertComponent from './components/layout/AlertComponent';
// Redux
import { Provider } from 'react-redux';
import store from './store';
import { loadUser } from './actions/auth';
import { getCurrentProfile } from './actions/profile';
// import { getNotices, listNotices } from './actions/notice';
import setAuthToken from './utils/setAuthToken';
import PendingPayments from './components/layout/payment/PendingPayments';
import PendingPayment from './components/layout/payment/PendingPayment';

import DepartmentScreen from './screens/Department/DepartmentScreen';
import DepartmentCreateScreen from './screens/Department/DepartmentCreateScreen';
import DepartmentEditScreen from './screens/Department/DepartmentEditScreen';
import DepartmentViewScreen from './screens/Department/DepartmentViewScreen';

import ProgramScreen from './screens/Program/ProgramScreen';
import ProgramCreateScreen from './screens/Program/ProgramCreateScreen';
import ProgramEditScreen from './screens/Program/ProgramEditScreen';
import ProgramViewScreen from './screens/Program/ProgramViewScreen';

import Users from './components/users/Users';
import ProfileAll from './components/layout/student-profile/ProfileAll';
import RegisterScreenStep1 from './screens/Register/RegisterScreenStep1';
import RegisterScreenStep2 from './screens/Register/RegisterScreenStep2';

import ResetScreenStep1 from './screens/Reset/ResetScreenStep1';
import ResetScreenStep2 from './screens/Reset/ResetScreenStep2';
import ResetScreenStep3 from './screens/Reset/ResetScreenStep3';
import {
  // Button,
  // Dialog,
  // DialogActions,
  // DialogContent,
  // DialogContentText,
  // DialogTitle,
  // IconButton,
  ThemeProvider,
  // Typography,
} from '@material-ui/core';
import theme from './components/Theme';

if (localStorage.token) {
  setAuthToken(localStorage.token);
}

const App = () => {
  // const [popupDisplayed, setPopupDisplayed] = useState(false);

  // useEffect(() => {
  //   const lastPopupDate = localStorage.getItem('lastPopupDate');
  //   if (!lastPopupDate || Date.now() - lastPopupDate > 24 * 60 * 60 * 1000) {
  //     setPopupDisplayed(true);
  //     localStorage.setItem('lastPopupDate', Date.now());
  //   }
  // }, []);

  // const handleClose = () => {
  //   setPopupDisplayed(false);
  // };

  useEffect(() => {
    store.dispatch(loadUser());
    // store.dispatch(getCurrentProfile());
    // store.dispatch(listNotices());
  }, []);
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <Router>
          <Fragment>
            {/* <>
              {popupDisplayed && (
                <Dialog open={popupDisplayed} keepMounted onClose={handleClose}>
                  <DialogTitle
                    id="customized-dialog-title"
                    onClose={handleClose}
                    sx={{ m: 0, p: 2 }}
                  >
                    NOTRE DAME UNIVERSITY BANGLADESH ADMISSION OPEN: Spring 2024
                    {popupDisplayed ? (
                      <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                          position: 'absolute',
                          right: 8,
                          top: 8,
                          color: (theme) => theme.palette.grey[500],
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    ) : null}
                  </DialogTitle>
                  <DialogContent dividers>
                    <img
                      src="https://ndub.edu.bd/wp-content/uploads/2023/04/rsz_spring_2024_session.jpg"
                      alt=""
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                  </DialogActions>
                </Dialog>
              )}
            </> */}
            <MenuAppBar />
            <section>
              <Switch>
                <Route exact path="/" component={Landing} />
                <Route exact path="/contact" component={Contact} />
                <Route exact path="/register" component={RegisterScreenStep1} />
                <Route
                  path="/register/verify"
                  component={RegisterScreenStep2}
                />
                <Route
                  path="/register/verify?"
                  component={RegisterScreenStep2}
                />
                <Route exact path="/login" component={Login} />
                {/* <Route exact path="/reset" component={ResetPassword} /> */}
                <Route exact path="/reset" component={ResetScreenStep1} />
                <Route path="/reset/verify" component={ResetScreenStep2} />
                <Route path="/reset/verify?" component={ResetScreenStep2} />
                <Route
                  path="/reset/new-password"
                  component={ResetScreenStep3}
                />
                <Route
                  path="/reset/new-password?"
                  component={ResetScreenStep3}
                />
                <Route exact path="/upload-alt" component={UploadAlt} />
                <AdminRoute
                  exact
                  path="/admin-dashboard"
                  component={DashboardAdmin}
                />
                <SuperAdminRoute exact path="/users" component={Users} />

                <PrivateRoute exact path="/dashboard" component={Dashboard} />
                <PrivateRoute
                  exact
                  path="/create-profile"
                  component={CreateProfile}
                />
                <AdminRoute exact path="/create-exam" component={CreateExam} />
                <AdminRoute exact path="/exams" component={Exams} />
                <AdminRoute
                  exact
                  path="/exam/students/:id"
                  component={ExamStudents}
                />

                <AdminRoute exact path="/edit-exam/:id" component={EditExam} />
                <AdminRoute
                  exact
                  path="/departments"
                  component={DepartmentScreen}
                />
                <AdminRoute
                  exact
                  path="/departments/create"
                  component={DepartmentCreateScreen}
                />
                <AdminRoute
                  exact
                  path="/departments/edit/:id"
                  component={DepartmentEditScreen}
                />
                <AdminRoute
                  exact
                  path="/departments/view/:id"
                  component={DepartmentViewScreen}
                />
                <AdminRoute exact path="/programs" component={ProgramScreen} />
                <AdminRoute
                  exact
                  path="/programs/create"
                  component={ProgramCreateScreen}
                />
                <AdminRoute
                  exact
                  path="/programs/edit/:id"
                  component={ProgramEditScreen}
                />
                <AdminRoute
                  exact
                  path="/programs/view/:id"
                  component={ProgramViewScreen}
                />
                <PrivateRoute exact path="/upload" component={Upload} />
                <AdminRoute exact path="/students" component={Students} />
                <AdminRoute exact path="/student/:id" component={Student} />
                <PrivateRoute
                  exact
                  path="/profile"
                  component={StudentViewOnly}
                />
                <PrivateRoute
                  exact
                  path="/profile-student"
                  component={ProfileAll}
                />
                <PrivateRoute exact path="/notices" component={Notices} />
                <PrivateRoute exact path="/notices/:id" component={Notice} />
                <PrivateRoute exact path="/payment" component={Payment} />
                {/* <PrivateRoute
                  exact
                  path="/payment-bank"
                  component={PaymentBank}
                /> */}
                <PrivateRoute
                  exact
                  path="/download-admit"
                  component={DownloadAdmit}
                />
                {/* <AdminRoute
                exact
                path="/payment/pending"
                component={PendingPayments}
              /> */}
                <AdminRoute
                  exact
                  path="/payment/:id"
                  component={PendingPayment}
                />
                <AdminRoute
                  exact
                  path="/create-notice"
                  component={CreateNotice}
                />
                <AdminRoute
                  exact
                  path="/notices/edit/:id"
                  component={EditNotice}
                />
                <Route component={NotFound} />
              </Switch>
            </section>
          </Fragment>
        </Router>
      </Provider>
    </ThemeProvider>
  );
};

export default Sentry.withProfiler(App);

// export default App;
