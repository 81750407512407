import {
  Button,
  createMuiTheme,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  MenuItem,
  TextField,
  ThemeProvider,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { listUsers, deleteUser, getUser } from '../../actions/userActions';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import AlertComponent from '../layout/AlertComponent';
import Spinner from '../layout/Spinner';
import Pagination from '@material-ui/lab/Pagination';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import AdminUserModal from './AddUserModal';
import EditUserModal from './EditUserModal';
import { USER_DETAILS_RESET } from '../../constants/userConstants';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#00acee',
    },
    // secondary: {
    //   main: '#a8dda8',
    // },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
    marginTop: theme.spacing(3),
  },
  table: {
    minWidth: 700,
  },
  textField: {
    width: '100%',
  },
  btn: {
    color: '#FFF',
    marginRight: '10px',
  },
  btnSecondary: {
    color: '#00acee',
    marginRight: '10px',
  },
  paginationSection: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  my: {
    marginBottom: '10px',
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#00acee',
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    // '&:nth-of-type(odd)': {
    //   backgroundColor: theme.palette.action.hover,
    // },
  },
}))(TableRow);

const Users = () => {
  const [role, setRole] = useState('ADMIN');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [name, setName] = useState('');
  const [paymentStatus, setPaymentStatus] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('');
  const [trxID, setTrxID] = useState('');
  const [bankSlipNumber, setBankSlipNumber] = useState('');
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState('desc');

  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [editUserID, setEditUserID] = useState('');

  const handlePageChange = (value) => {
    setPage(value);
  };

  const handleSort = () => {
    setSort(sort === 'desc' ? 'asc' : 'desc');
  };

  // const page = match.params.page || 1;

  const dispatch = useDispatch();

  const userList = useSelector((state) => state.userList);
  const { loading, error, users, page: pageNumber, pages, count } = userList;

  const userDelete = useSelector((state) => state.userDelete);
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = userDelete;

  useEffect(() => {
    dispatch(
      listUsers(
        role,
        email,
        phone,
        name,
        paymentStatus,
        paymentMethod,
        trxID,
        bankSlipNumber,
        page,
        sort
      )
    );
  }, [page, sort, open, openEdit]);

  const onSubmit = (e) => {
    e.preventDefault();

    dispatch(
      listUsers(
        role,
        email,
        phone,
        name,
        paymentStatus,
        paymentMethod,
        trxID,
        bankSlipNumber,
        page,
        sort
      )
    );
  };

  const onDelete = (id) => {
    if (window.confirm('Are you sure?')) {
      dispatch(deleteUser(id));
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const userDetails = useSelector((state) => state.userDetails);
  const { user } = userDetails;

  const handleEditOpen = (id) => {
    setEditUserID(id);
    dispatch(getUser(id));
    setOpenEdit(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleEditClose = (id) => {
    setOpenEdit(false);
    dispatch({ type: USER_DETAILS_RESET });
  };

  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <div className="container">
        <h1 className="large text-primary">Users</h1> <Divider /> <br />
        {/* <p className="lead">
          <i className="fas fa-user"></i> Search admin or student users
        </p> */}
        <AdminUserModal open={open} handleClose={handleClose} />
        <EditUserModal
          open={openEdit}
          handleClose={handleEditClose}
          id={editUserID}
        />
        <form onSubmit={(e) => onSubmit(e)} style={{ marginBottom: '20px' }}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} sm={6} md={2}>
              <TextField
                size="small"
                select
                name="role"
                label="Role"
                value={role}
                // helperText={'Select role'}
                variant="outlined"
                onChange={(e) => setRole(e.target.value)}
                className={classes.textField}
              >
                <MenuItem value={'ADMIN'}>Admin</MenuItem>
                <MenuItem value={'SUPERADMIN'}>Super Admin</MenuItem>
                <MenuItem value={'STUDENT'}>Student</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={5}>
              <TextField
                size="small"
                type="text"
                name="email"
                label="Email"
                value={email}
                // helperText={'Enter Email'}
                variant="outlined"
                onChange={(e) => setEmail(e.target.value)}
                className={classes.textField}
              ></TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={5}>
              <TextField
                size="small"
                type="text"
                name="phone"
                label="Phone"
                value={phone}
                // helperText={'Enter Phone'}
                variant="outlined"
                onChange={(e) => setPhone(e.target.value)}
                className={classes.textField}
              ></TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                size="small"
                type="text"
                name="name"
                label="Name"
                value={name}
                // helperText={'Enter Name'}
                variant="outlined"
                onChange={(e) => setName(e.target.value)}
                className={classes.textField}
              ></TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                size="small"
                select
                name="paymentStatus"
                label="Payment Status"
                value={paymentStatus}
                // helperText={'Select payment status'}
                variant="outlined"
                onChange={(e) => setPaymentStatus(e.target.value)}
                className={classes.textField}
              >
                <MenuItem value={'ALL'}>All</MenuItem>
                <MenuItem value={'PAID'}>Paid</MenuItem>
                <MenuItem value={'PENDING'}>Pending</MenuItem>
                <MenuItem value={'UNPAID'}>Unpaid</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                size="small"
                select
                name="paymentStatus"
                label="Payment Method"
                value={paymentMethod}
                // helperText={'Select payment method'}
                variant="outlined"
                onChange={(e) => {
                  setPaymentMethod(e.target.value);
                  setTrxID('');
                  setBankSlipNumber('');
                }}
                className={classes.textField}
              >
                <MenuItem value={'ALL'}>All</MenuItem>
                <MenuItem value={'BKASH'}>Bkash</MenuItem>
                <MenuItem value={'BANK'}>Bank</MenuItem>
              </TextField>
            </Grid>
            {paymentMethod === 'BKASH' ? (
              <Grid item xs={12} sm={3}>
                <TextField
                  size="small"
                  type="text"
                  name="trxID"
                  label="Transaction ID"
                  value={trxID}
                  variant="outlined"
                  // helperText={'Enter bKash transaction ID'}
                  onChange={(e) => setTrxID(e.target.value)}
                  className={classes.textField}
                ></TextField>
              </Grid>
            ) : paymentMethod === 'BANK' ? (
              <Grid item xs={12} sm={3}>
                <TextField
                  size="small"
                  type="text"
                  name="bankSlipNumber"
                  label="Bank Slip Number"
                  value={bankSlipNumber}
                  // helperText={'Enter bank slip number'}
                  variant="outlined"
                  onChange={(e) => setBankSlipNumber(e.target.value)}
                  className={classes.textField}
                ></TextField>
              </Grid>
            ) : (
              <Grid item xs={12} sm={3}>
                <TextField
                  size="small"
                  type="text"
                  name="bankSlipNumber"
                  label="Transaction ID/Slip No."
                  value={bankSlipNumber}
                  // helperText={'Enter bank slip number'}
                  variant="outlined"
                  disabled
                  className={classes.textField}
                ></TextField>
              </Grid>
            )}
          </Grid>
          <br />
          <div className="form-group">
            <Button
              variant="contained"
              href={`/admin-dashboard/`}
              disableElevation
              className={classes.btnSecondary}
              type="submit"
            >
              <i
                className="fa fa-arrow-left text-primary"
                aria-hidden="true"
              ></i>
              &nbsp; Back
            </Button>
            <Button
              variant="contained"
              color="primary"
              disableElevation
              className={classes.btn}
              type="submit"
            >
              Search &nbsp;
              {loading ? (
                <i className="fas fa-circle-notch fa-spin"></i>
              ) : (
                <i className="fas fa-search"></i>
              )}
            </Button>
            <Button
              variant="contained"
              color="primary"
              disableElevation
              className={classes.btn}
              onClick={handleClickOpen}
            >
              Add User &nbsp;
              <i className="fas fa-plus"></i>
            </Button>
          </div>
        </form>
        {!(open || openEdit) && <AlertComponent />}
        {loading && <Spinner />}
        <TableContainer component={Paper} className={classes.my}>
          <Table className={classes.table} aria-label="user table" size="small">
            <TableHead>
              <TableRow>
                <StyledTableCell align="left">Role</StyledTableCell>
                <StyledTableCell align="left">Name</StyledTableCell>
                <StyledTableCell align="left">Phone</StyledTableCell>
                <StyledTableCell align="left">Email</StyledTableCell>
                <StyledTableCell align="center">Delete</StyledTableCell>
                <StyledTableCell align="center">
                  <Tooltip
                    title="Change Sorting"
                    enterDelay={300}
                    leaveDelay={200}
                  >
                    {sort === 'desc' ? (
                      <IconButton
                        aria-label="order by descending"
                        onClick={handleSort}
                        className={classes.btn}
                      >
                        <ArrowDownwardIcon />
                      </IconButton>
                    ) : (
                      <IconButton
                        aria-label="order by ascending"
                        onClick={handleSort}
                        className={classes.btn}
                      >
                        <ArrowUpwardIcon />
                      </IconButton>
                    )}
                  </Tooltip>
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users && users.length > 0
                ? users.map((user) => (
                    <StyledTableRow key={user._id} hover>
                      <StyledTableCell align="left">
                        {user.role}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {user.name}
                      </StyledTableCell>

                      <StyledTableCell align="left">
                        {user.phone}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {user.email}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <IconButton
                          aria-label="delete-user"
                          onClick={() => onDelete(user._id)}
                          // className={classes.btn}
                          color="secondary"
                        >
                          <DeleteForeverIcon color="secondary" />
                        </IconButton>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Button
                          variant="contained"
                          color="primary"
                          disableElevation
                          onClick={() => handleEditOpen(user._id)}
                          className={classes.btn}
                        >
                          Edit
                        </Button>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))
                : null}
            </TableBody>
          </Table>
        </TableContainer>
        {count && count >= 0 ? (
          <div className={classes.paginationSection}>
            <Pagination
              page={page}
              count={pages}
              onChange={handlePageChange}
              color="primary"
            />
            <Typography
              variant="overline"
              display="block"
              gutterBottom
            >{`${count} ${count > 1 ? 'Users' : 'User'} Found`}</Typography>
          </div>
        ) : count === 0 ? (
          <Typography
            variant="overline"
            display="block"
            gutterBottom
          >{`0 User Found`}</Typography>
        ) : (
          !loading && (
            <Typography
              variant="overline"
              display="block"
              gutterBottom
            >{`Enter Search Query`}</Typography>
          )
        )}
      </div>
    </ThemeProvider>
  );
};

export default Users;
