import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Divider from '@material-ui/core/Divider';
import { deleteNotice, editNotice, getNotice } from '../../actions/notice';
import AlertComponent from '../layout/AlertComponent';
import {
  Button,
  createMuiTheme,
  Grid,
  makeStyles,
  TextField,
  ThemeProvider,
} from '@material-ui/core';
import ReactQuill from 'react-quill';
import Spinner from '../layout/Spinner';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#00acee',
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
    marginTop: theme.spacing(3),
  },
  textField: {
    width: '100%',
  },
  btn: {
    color: '#FFF',
    marginRight: '10px',
  },
  btnSecondary: {
    // color: '#00acee',
    marginRight: '10px',
  },
  my20: {
    marginBottom: '20px',
  },
}));

const EditNotice = ({ match, history }) => {
  const [title, setTitle] = useState('');
  const [bodyText, setBodyText] = useState('');

  const noticeDetails = useSelector((state) => state.noticeDetails);
  const { loading, notice, error } = noticeDetails;

  const noticeUpdate = useSelector((state) => state.noticeUpdate);
  const { loading: loadingUpdate, success: successUpdate } = noticeUpdate;

  const noticeDelete = useSelector((state) => state.noticeDelete);
  const { loading: loadingDelete, success: successDelete } = noticeDelete;

  const auth = useSelector((state) => state.auth);
  const { loading: authLoading, role } = auth;

  const dispatch = useDispatch();

  useEffect(() => {
    if (successDelete) {
      history.push('/notices');
    } else {
      if (!notice || !notice.title || notice._id !== match.params.id) {
        dispatch(getNotice(match.params.id));
      } else {
        setTitle(notice.title);
        setBodyText(notice.bodyText);
      }
    }
  }, [dispatch, match.params.id, notice, successDelete, history]);

  const onSubmit = (e) => {
    e.preventDefault();
    const formData = {
      title,
      bodyText,
    };
    dispatch(editNotice(formData, match.params.id));
  };

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
      ],
      ['link', 'image'],
      ['clean'],
    ],
  };

  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
  ];

  const handleChange = (value) => {
    setBodyText(value);
  };

  const classes = useStyles();

  const onDelete = (id) => {
    if (window.confirm('Are you sure?')) {
      dispatch(deleteNotice(id));
    }
  };

  return loading ? (
    <div className="container">
      <Spinner />
    </div>
  ) : (
    <ThemeProvider theme={theme}>
      <div className="container">
        <h1 className="large text-primary">Edit Notice</h1>
        {/* <p className="lead">
        <i className="far fa-newspaper"></i> &nbsp; Please fill up the form and
        create a new notice.
      </p> */}
        <Divider /> <br />
        <form onSubmit={(e) => onSubmit(e)}>
          <Grid>
            <Grid item xs={12} className={classes.my20}>
              <TextField
                // error={phoneError}
                type="text"
                name="title"
                label="Notice Title"
                value={title}
                helperText={'Main title of the notice'}
                variant="outlined"
                onChange={(e) => setTitle(e.target.value)}
                className={classes.textField}
              ></TextField>
            </Grid>
            <Grid item xs={12} className={classes.my20}>
              <ReactQuill
                theme="snow"
                value={bodyText}
                onChange={handleChange}
                modules={modules}
                formats={formats}
              />
              <small className="form-text">Notice Description.</small>
            </Grid>
            <Grid item xs={12} className={classes.my20}>
              {!authLoading && (
                <Link to={`/notices`}>
                  <Button
                    variant="contained"
                    color="default"
                    disableElevation
                    className={classes.btnSecondary}
                  >
                    <i className="fas fa-arrow-left"></i> &nbsp; Back
                  </Button>
                </Link>
              )}

              {!authLoading && role.includes('ADMIN') && (
                <Button
                  variant="contained"
                  color="primary"
                  disableElevation
                  className={classes.btn}
                  type="submit"
                >
                  Update
                  {loadingUpdate ? (
                    <>
                      &nbsp;<i className="fas fa-circle-notch fa-spin"></i>
                    </>
                  ) : (
                    <>
                      &nbsp;<i className="fas fa-check"></i>
                    </>
                  )}
                </Button>
              )}
              {!auth.loading && auth.role.includes('ADMIN') && (
                <Button
                  variant="contained"
                  color="secondary"
                  disableElevation
                  onClick={() => onDelete(notice._id)}
                  className={classes.btn}
                >
                  Delete{' '}
                  {loadingDelete ? (
                    <>
                      &nbsp;<i className="fas fa-circle-notch fa-spin"></i>
                    </>
                  ) : (
                    <>
                      &nbsp;<i className="fas fa-trash"></i>
                    </>
                  )}
                </Button>
              )}
            </Grid>
          </Grid>
          <AlertComponent />
        </form>
      </div>
    </ThemeProvider>
  );
};

export default EditNotice;
