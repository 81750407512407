import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Grid,
  MenuItem,
  TextField,
  Checkbox,
  FormControlLabel,
  Divider,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { getUser, updateUser } from '../../actions/userActions';
import AlertComponent from '../layout/AlertComponent';

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
    marginTop: theme.spacing(3),
  },
  table: {
    minWidth: 700,
  },
  textField: {
    width: '100%',
  },
  btn: {
    color: '#FFF',
  },
  optionText: {
    color: '#B7B7B7',
    textTransform: 'uppercase',
    fontSize: '10px',
  },
  btnSecondary: {
    color: '#00acee',
  },
  paginationSection: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  my: {
    marginBottom: '10px',
  },
}));

const EditUserModal = ({ open, handleClose, id }) => {
  const [role, setRole] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState('password');
  const [editPayment, setEditPayment] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('');
  const [trxID, setTrxID] = useState('');
  const [bankSlipNumber, setBankSlipNumber] = useState('');

  const dispatch = useDispatch();

  const userDetails = useSelector((state) => state.userDetails);
  const { loading, error, user } = userDetails;

  const userUpdate = useSelector((state) => state.userUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    user: updatedUser,
  } = userUpdate;

  // useEffect(() => {
  //   if (!user || user._id !== id) {
  //     dispatch(getUser(id));
  //   } else {
  //     setName(user.name);
  //     setEmail(user.email);
  //     setPhone(user.phone);
  //     setRole(user.role);
  //   }
  // }, [id, user]);
  useEffect(() => {
    if (user && user.name) {
      setName(user.name);
      setEmail(user.email);
      setPhone(user.phone);
      setRole(String(user.role));
      setPassword('');
      setPaymentStatus(String(user?.paymentInfo?.status));
      setPaymentMethod(String(user?.paymentInfo?.paymentMethod));
      user?.paymentInfo?.paymentMethod === 'BKASH'
        ? setTrxID(String(user?.paymentInfo?.bkashInfo?.trxID))
        : setTrxID('');
      user?.paymentInfo?.paymentMethod === 'BANK'
        ? setBankSlipNumber(String(user?.paymentInfo?.bankSlipNumber))
        : setBankSlipNumber('');

      setEditPayment(false);
      setShowPassword('password');
    }
  }, [user, id]);

  // if (user) {
  //   setName(user.name);
  //   setEmail(user.email);
  //   setPhone(user.phone);
  //   setRole(user.role);
  // }

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(
      updateUser(
        id,
        role,
        email,
        phone,
        name,
        password,
        paymentStatus,
        paymentMethod,
        trxID,
        bankSlipNumber
      )
    );
  };

  const togglePass = (e) => {
    showPassword === 'password'
      ? setShowPassword('text')
      : setShowPassword('password');
  };

  const toggleEditPayment = (e) => {
    editPayment ? setEditPayment(false) : setEditPayment(true);
    editPayment && setPaymentStatus(String(user?.paymentInfo?.status));
    editPayment && setPaymentMethod(String(user?.paymentInfo?.paymentMethod));
    editPayment && user?.paymentInfo?.paymentMethod === 'BKASH'
      ? setTrxID(String(user?.paymentInfo?.bkashInfo?.trxID))
      : setBankSlipNumber(String(user?.paymentInfo?.bankSlipNumber));
  };

  const classes = useStyles();

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        Update User
      </DialogTitle>
      <DialogContent dividers>
        {/* <Typography gutterBottom>
          Only JPG/JPEG Images are allowed. Please choose the allowed image
          format.
        </Typography> */}
        <form onSubmit={(e) => onSubmit(e)}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} sm={6}>
              <TextField
                type="text"
                name="name"
                label="Name"
                value={loading ? 'Loading...' : name}
                helperText={'Enter Name'}
                variant="outlined"
                onChange={(e) => setName(e.target.value)}
                className={classes.textField}
              ></TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                type="text"
                name="phone"
                label="Phone"
                value={loading ? 'Loading...' : phone}
                helperText={'Enter Phone'}
                variant="outlined"
                onChange={(e) => setPhone(e.target.value)}
                className={classes.textField}
              ></TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                type="text"
                name="email"
                label="Email"
                value={loading ? 'Loading...' : email}
                helperText={'Enter Email'}
                variant="outlined"
                onChange={(e) => setEmail(e.target.value)}
                className={classes.textField}
              ></TextField>
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                select
                name="role"
                label="Role"
                value={role}
                helperText={'Select role'}
                variant="outlined"
                onChange={(e) => setRole(e.target.value)}
                className={classes.textField}
              >
                <MenuItem value={'ADMIN'}>Admin</MenuItem>
                <MenuItem value={'SUPERADMIN'}>Super Admin</MenuItem>
                <MenuItem value={'STUDENT'}>Student</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                select
                name="paymentStatus"
                label="Payment Status"
                value={paymentStatus}
                variant="outlined"
                onChange={(e) => setPaymentStatus(e.target.value)}
                className={classes.textField}
                disabled={!editPayment}
              >
                <MenuItem value={'UNPAID'}>Unpaid</MenuItem>
                <MenuItem value={'PENDING'}>Pending</MenuItem>
                <MenuItem value={'PAID'}>Paid</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                select
                name="paymentStatus"
                label="Payment Method"
                value={paymentMethod}
                variant="outlined"
                onChange={(e) => {
                  setPaymentMethod(e.target.value);
                  setTrxID('');
                  setBankSlipNumber('');
                }}
                className={classes.textField}
                disabled={!editPayment}
              >
                <MenuItem value={'NOT YET SUBMITTED'}>N/A</MenuItem>
                <MenuItem value={'BANK'}>Bank</MenuItem>
                <MenuItem value={'BKASH'}>Bkash</MenuItem>
              </TextField>
            </Grid>
            {paymentMethod === 'BKASH' ? (
              <Grid item xs={12} sm={4}>
                <TextField
                  type="text"
                  name="trxID"
                  label="Transaction ID"
                  value={trxID}
                  variant="outlined"
                  onChange={(e) => setTrxID(e.target.value)}
                  className={classes.textField}
                  disabled={!editPayment}
                ></TextField>
              </Grid>
            ) : paymentMethod === 'BANK' ? (
              <Grid item xs={12} sm={4}>
                <TextField
                  type="text"
                  name="bankSlipNumber"
                  label="Bank Slip Number"
                  value={bankSlipNumber}
                  // helperText={'Enter Phone'}
                  variant="outlined"
                  onChange={(e) => setBankSlipNumber(e.target.value)}
                  className={classes.textField}
                  disabled={!editPayment}
                ></TextField>
              </Grid>
            ) : (
              <Grid item xs={12} sm={4}>
                <Typography
                  variant="caption"
                  display="block"
                  className={classes.optionText}
                >
                  Option will appear here after payment method selection
                </Typography>
              </Grid>
            )}

            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={editPayment ? true : false}
                    onChange={toggleEditPayment}
                    name="editPayment"
                    color="primary"
                    size="small"
                  />
                }
                label="Edit payment info?"
              />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <TextField
                type={showPassword}
                name="password"
                label="Password"
                value={password}
                helperText="Use 6 or more characters with a mix of letters, numbers & symbols."
                variant="outlined"
                onChange={(e) => setPassword(e.target.value)}
                className={classes.textField}
              ></TextField>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showPassword === 'password' ? false : true}
                    onChange={togglePass}
                    name="showPassword"
                    color="primary"
                    size="small"
                  />
                }
                label="Show Password"
              />
            </Grid>

            <Grid item xs={12}>
              <AlertComponent />
              <Button
                variant="contained"
                color="primary"
                disableElevation
                className={classes.btn}
                type="submit"
              >
                Update
                {loadingUpdate ? (
                  <i
                    className="fas fa-circle-notch fa-spin"
                    style={{ marginLeft: '10px' }}
                  ></i>
                ) : null}
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditUserModal;
