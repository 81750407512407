import {
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  registerUserStep1,
  resetPasswordStep1,
  verifyCode,
} from '../../actions/userActions';
import AlertComponent from '../../components/layout/AlertComponent';
import { checkEmail } from '../../utils/checkEmail';
import { Link, Redirect } from 'react-router-dom';
import { useQuery } from '../../utils/useQuery';
import {
  USER_RESET_STEP1_RESET,
  USER_RESET_STEP2_RESET,
  USER_VERIFY_PHONE_RESET,
} from '../../constants/userConstants';
import Logo from '../../img/logo.png';
// import AlertComponent from '../layout/AlertComponent';

const RegisterScreen = ({ history }) => {
  const dispatch = useDispatch();

  const [phone, setPhone] = useState('');

  const userResetStep1 = useSelector((state) => state.userResetStep1);
  const {
    loading: loadingUserResetStep1,
    _id,
    token,
    success,
  } = userResetStep1;

  useEffect(() => {
    if (success) {
      history.push(`/reset/verify?token=${token}&phone=${phone}`);
      dispatch({ type: USER_RESET_STEP1_RESET });
      dispatch({ type: USER_VERIFY_PHONE_RESET });
      dispatch({ type: USER_RESET_STEP2_RESET });
    }
  }, [success]);

  // reset pass step 1
  const onSubmit = (e) => {
    e.preventDefault();
    if (phone.length === 0) {
      let resetPhone = document.getElementById('resetPhone');
      resetPhone.focus();
      window.alert('Please type in your phone number');
    } else dispatch(resetPasswordStep1(phone));
  };

  return (
    <div className="container">
      <Grid
        container
        alignItems="center"
        justify="center"
        style={{ minHeight: '80vh' }}
      >
        <Grid item xs={12} sm={6} md={6}>
          <Card variant="outlined">
            <CardContent>
              <form onSubmit={(e) => onSubmit(e)}>
                <Grid container spacing={2}>
                  <Grid item xs={12} align="center">
                    <img src={Logo} className="logo-100" alt="Logo" />
                    <Typography variant="h3">Reset Password</Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      size="small"
                      type="number"
                      name="phone"
                      label="Phone"
                      id="resetPhone"
                      value={phone}
                      helperText={
                        'Enter your phone number, a verification code will be sent to it.'
                      }
                      variant="outlined"
                      onChange={(e) => setPhone(e.target.value)}
                      fullWidth
                    ></TextField>
                  </Grid>
                  <Grid item xs={12} variant={'contained'}>
                    <AlertComponent />
                    <Button
                      variant="contained"
                      color="primary"
                      disableElevation
                      type="submit"
                    >
                      <span>Next</span>
                      {loadingUserResetStep1 ? (
                        <>
                          &nbsp;
                          <i className="fas fa-circle-notch fa-spin loadingIndicator"></i>
                        </>
                      ) : null}
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <small>
                      Already have an account? <Link to="/login">Sign In</Link>{' '}
                      | Don't have an account?{' '}
                      <Link to="/register">Register</Link>
                    </small>
                  </Grid>
                </Grid>
              </form>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default RegisterScreen;
