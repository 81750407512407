import axios from 'axios';
import * as Sentry from '@sentry/react';
import {
  PROGRAM_DETAILS_FAIL,
  PROGRAM_DETAILS_REQUEST,
  PROGRAM_DETAILS_SUCCESS,
  PROGRAM_UPDATE_REQUEST,
  PROGRAM_UPDATE_SUCCESS,
  PROGRAM_UPDATE_FAIL,
  PROGRAM_LIST_REQUEST,
  PROGRAM_LIST_SUCCESS,
  PROGRAM_LIST_FAIL,
  PROGRAM_DELETE_REQUEST,
  PROGRAM_DELETE_SUCCESS,
  PROGRAM_DELETE_FAIL,
  PROGRAM_LIST_REMOVE_ITEM,
  PROGRAM_CREATE_REQUEST,
  PROGRAM_CREATE_FAIL,
  PROGRAM_CREATE_SUCCESS,
} from '../constants/programConstants';
import { setAlert } from './alert';

// Get all programs
export const listPrograms = () => async (dispatch) => {
  try {
    dispatch({ type: PROGRAM_LIST_REQUEST });

    const { data } = await axios.get(`/api/programs`);

    dispatch({
      type: PROGRAM_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    Sentry.captureException(error);
    const errors = error.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
    }

    dispatch({
      type: PROGRAM_LIST_FAIL,
      payload: { msg: errors, status: error.response.status },
    });
  }
};

// Get single program
export const getProgram = (program_id) => async (dispatch) => {
  try {
    dispatch({ type: PROGRAM_DETAILS_REQUEST });
    const { data } = await axios.get(`/api/programs/${program_id}`);
    dispatch({
      type: PROGRAM_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (err) {
    console.log(err.response);
    dispatch({
      type: PROGRAM_DETAILS_FAIL,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Create Program
export const createProgram = (formData) => async (dispatch) => {
  try {
    dispatch({ type: PROGRAM_CREATE_REQUEST });
    const { data } = await axios.post(`/api/programs/`, formData);

    dispatch({
      type: PROGRAM_CREATE_SUCCESS,
      payload: data,
    });
    dispatch(setAlert('Program Created', 'success'));
  } catch (err) {
    console.log(err);
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
    }
    dispatch({
      type: PROGRAM_CREATE_FAIL,
      payload: { msg: errors, status: err.response.status },
    });
  }
};

// Update Program
export const updateProgram = (formData, program_id) => async (dispatch) => {
  console.log(program_id);
  try {
    dispatch({ type: PROGRAM_UPDATE_REQUEST });
    const { data } = await axios.put(`/api/programs/${program_id}`, formData);

    dispatch({
      type: PROGRAM_UPDATE_SUCCESS,
      payload: data,
    });
    dispatch({
      type: PROGRAM_DETAILS_SUCCESS,
      payload: data,
    });
    dispatch(setAlert('Program Updated', 'success'));
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
    }
    dispatch({
      type: PROGRAM_UPDATE_FAIL,
      payload: { msg: errors, status: err.response.status },
    });
  }
};

// Delete a program
export const deleteProgram = (program_id) => async (dispatch) => {
  try {
    dispatch({ type: PROGRAM_DELETE_REQUEST });
    const { data } = await axios.delete(`/api/programs/${program_id}`);
    dispatch({ type: PROGRAM_DELETE_SUCCESS });
    dispatch({
      type: PROGRAM_LIST_REMOVE_ITEM,
      payload: program_id,
    });
    dispatch(setAlert('Program Deleted', 'success'));
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
    }
    dispatch({
      type: PROGRAM_DELETE_FAIL,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};
