import React from 'react';

const BreakButton = () => {
  return (
    <button
      onClick={() => {
        throw new Error(
          'Sentry is connected and running as intended on NDUB Admission Frontend'
        );
      }}
    >
      Break the world
    </button>
  );
};

export default BreakButton;
