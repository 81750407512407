import axios from 'axios';
import * as Sentry from '@sentry/react';
import {
  MAIL_SEND_FAIL,
  MAIL_SEND_REQUEST,
  MAIL_SEND_SUCCESS,
} from '../constants/mailConstants';
import { setAlert } from './alert';

export const sendMail = (formData) => async (dispatch) => {
  try {
    dispatch({ type: MAIL_SEND_REQUEST });

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const { data } = await axios.post('/api/mail', formData, config);

    dispatch({
      type: MAIL_SEND_SUCCESS,
      payload: data,
    });
    dispatch(setAlert(`Mail sent`, 'success'));
  } catch (error) {
    Sentry.captureException(error);
    dispatch({
      type: MAIL_SEND_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
