export const USER_LIST_REQUEST = 'USER_LIST_REQUEST';
export const USER_LIST_SUCCESS = 'USER_LIST_SUCCESS';
export const USER_LIST_REMOVE_ITEM = 'USER_LIST_REMOVE_ITEM';
export const USER_LIST_FAIL = 'USER_LIST_FAIL';

export const USER_DETAILS_REQUEST = 'USER_DETAILS_REQUEST';
export const USER_DETAILS_SUCCESS = 'USER_DETAILS_SUCCESS';
export const USER_DETAILS_FAIL = 'USER_DETAILS_FAIL';
export const USER_DETAILS_RESET = 'USER_DETAILS_RESET';

export const USER_DELETE_REQUEST = 'USER_DELETE_REQUEST';
export const USER_DELETE_SUCCESS = 'USER_DELETE_SUCCESS';
export const USER_DELETE_FAIL = 'USER_DELETE_FAIL';

export const USER_CREATE_REQUEST = 'USER_CREATE_REQUEST';
export const USER_CREATE_SUCCESS = 'USER_CREATE_SUCCESS';
export const USER_CREATE_FAIL = 'USER_CREATE_FAIL';
export const USER_CREATE_RESET = 'USER_CREATE_RESET';

export const USER_UPDATE_REQUEST = 'USER_UPDATE_REQUEST';
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS';
export const USER_UPDATE_FAIL = 'USER_UPDATE_FAIL';
export const USER_UPDATE_RESET = 'NOTICE_UPDATE_RESET';

export const USER_REGISTER_STEP1_REQUEST = 'USER_REGISTER_STEP1_REQUEST';
export const USER_REGISTER_STEP1_SUCCESS = 'USER_REGISTER_STEP1_SUCCESS';
export const USER_REGISTER_STEP1_FAIL = 'USER_REGISTER_STEP1_FAIL';
export const USER_REGISTER_STEP1_RESET = 'USER_REGISTER_STEP1_RESET';

export const USER_VERIFY_PHONE_REQUEST = 'USER_VERIFY_PHONE_REQUEST';
export const USER_VERIFY_PHONE_SUCCESS = 'USER_VERIFY_PHONE_SUCCESS';
export const USER_VERIFY_PHONE_FAIL = 'USER_VERIFY_PHONE_FAIL';
export const USER_VERIFY_PHONE_RESET = 'USER_VERIFY_PHONE_RESET';

export const USER_REGISTER_STEP3_REQUEST = 'USER_REGISTER_STEP3_REQUEST';
export const USER_REGISTER_STEP3_SUCCESS = 'USER_REGISTER_STEP3_SUCCESS';
export const USER_REGISTER_STEP3_FAIL = 'USER_REGISTER_STEP3_FAIL';
export const USER_REGISTER_STEP3_RESET = 'USER_REGISTER_STEP3_RESET';

export const USER_RESET_STEP1_REQUEST = 'USER_RESET_STEP1_REQUEST';
export const USER_RESET_STEP1_SUCCESS = 'USER_RESET_STEP1_SUCCESS';
export const USER_RESET_STEP1_FAIL = 'USER_RESET_STEP1_FAIL';
export const USER_RESET_STEP1_RESET = 'USER_RESET_STEP1_RESET';

export const USER_RESET_STEP2_REQUEST = 'USER_RESET_STEP2_REQUEST';
export const USER_RESET_STEP2_SUCCESS = 'USER_RESET_STEP2_SUCCESS';
export const USER_RESET_STEP2_FAIL = 'USER_RESET_STEP2_FAIL';
export const USER_RESET_STEP2_RESET = 'USER_RESET_STEP2_RESET';
