import React, { useState, useEffect, Fragment } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect, useDispatch, useSelector } from 'react-redux';
import Divider from '@material-ui/core/Divider';
import { getCurrentProfile } from '../../actions/profile';

import { upProPic, upSignature } from '../../actions/profileActions';
import AlertComponent from '../layout/AlertComponent';
import Spinner from '../layout/Spinner';

import Grid from '@material-ui/core/Grid';

import Paper from '@material-ui/core/Paper';

import {
  makeStyles,
  createMuiTheme,
  ThemeProvider,
} from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#00acee',
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    // display: 'flex',
    // flexWrap: 'wrap',
    justifyContent: 'center',
    marginTop: theme.spacing(3),
  },
  margin: {
    // margin: theme.spacing(1),
  },
  marginDate: {
    marginBottom: '-3px',
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: '100%',
  },
  dateOfBirthErrorClass: {
    borderColor: 'red !important',
  },
  paper: {
    padding: theme.spacing(3),
  },
  chipContainer: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    '& .MuiChip-root': {
      margin: theme.spacing(0.5),
      color: '#FFF',
      overflow: 'hidden',
    },
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  btn: {
    color: '#FFF',
    marginRight: '10px',
  },
}));

const Upload = ({ profile: { profile, loading } }) => {
  const dispatch = useDispatch();

  const profileUpProPic = useSelector((state) => state.profileUpProPic);
  const {
    loading: loadingProfilePicUpload,
    profile: profileProfilePicUpload,
    success: successProfilePicUpload,
    error: errorProfilePicUpload,
  } = profileUpProPic;

  const profileSignature = useSelector((state) => state.profileSignature);
  const {
    loading: loadingSignature,
    profile: profileUpdatedSignature,
    success: successSignature,
    error: errorSignature,
  } = profileSignature;

  const classes = useStyles();

  const [profilePic, setProfilePic] = useState(null);
  const [profilePicInputState, setProfilePicInputState] = useState('');
  const [profilePicPreview, setProfilePicPreview] = useState(null);

  const [signature, setSignature] = useState(null);
  const [signatureInputState, setSignatureInputState] = useState('');
  const [signaturePreview, setSignaturePreview] = useState(null);

  const refreshPage = () => {
    window.location.reload();
  };

  useEffect(() => {
    dispatch(getCurrentProfile());
  }, [dispatch]);

  useEffect(() => {
    setProfilePic(null);
    setProfilePicInputState('');
    setProfilePicPreview(null);
  }, [
    successProfilePicUpload,
    setProfilePicPreview,
    setProfilePicInputState,
    setProfilePic,
  ]);

  useEffect(() => {
    setSignature(null);
    setSignatureInputState('');
    setSignaturePreview(null);
  }, [
    successSignature,
    setSignaturePreview,
    setSignatureInputState,
    setSignature,
  ]);

  const previewProfilePicSet = (file) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onloadend = () => {
      setProfilePicPreview(fileReader.result);
    };
  };

  const onChange = (e) => {
    const file = e.target.files[0];
    setProfilePicInputState(e.target.value);
    const fileReader = new FileReader();
    fileReader.onloadend = function (e) {
      if (e.target.readyState === FileReader.DONE) {
        const uint = new Uint8Array(e.target.result);
        let bytes = [];
        uint.forEach((byte) => {
          bytes.push(byte.toString(16));
        });
        const hex = bytes.join('').toUpperCase();
        if (getMimetype(hex)) {
          setProfilePic(file);
          previewProfilePicSet(file);
        } else {
          handleClickOpen();
          setProfilePic(null);
          setProfilePicInputState('');
          setProfilePicPreview(null);
        }
      }
    };

    if (file) {
      const blob = file.slice(0, 4);
      fileReader.readAsArrayBuffer(blob);
    }

    const getMimetype = (signature) => {
      switch (signature) {
        case 'FFD8FFDB':
        case 'FFD8FFE0':
        case 'FFD8FFE1':
          return true;
        default:
          return false;
      }
    };
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (profilePic !== null) {
      dispatch(upProPic(profilePic, refreshPage));
    } else {
      alert('Please choose a file before clicking on upload!');
    }
  };

  const previewSignatureSet = (file) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onloadend = () => {
      setSignaturePreview(fileReader.result);
    };
  };

  const onChangeSignature = (e) => {
    const file = e.target.files[0];
    setSignatureInputState(e.target.value);
    const fileReader = new FileReader();
    fileReader.onloadend = function (e) {
      if (e.target.readyState === FileReader.DONE) {
        const uint = new Uint8Array(e.target.result);
        let bytes = [];
        uint.forEach((byte) => {
          bytes.push(byte.toString(16));
        });
        const hex = bytes.join('').toUpperCase();
        if (getMimetype(hex)) {
          setSignature(file);
          previewSignatureSet(file);
        } else {
          handleClickOpen();
          setSignature(null);
          setSignatureInputState('');
          setSignaturePreview(null);
        }
      }
    };

    if (file) {
      const blob = file.slice(0, 4);
      fileReader.readAsArrayBuffer(blob);
    }

    const getMimetype = (signature) => {
      switch (signature) {
        case 'FFD8FFDB':
        case 'FFD8FFE0':
        case 'FFD8FFE1':
          return true;
        default:
          return false;
      }
    };
  };

  const onSubmitSignature = (e) => {
    e.preventDefault();
    if (signature !== null) {
      dispatch(upSignature({ signature }, refreshPage));
    } else {
      alert('Please choose a file before clicking on upload!');
    }
  };

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const modal = (
    <Dialog
      onClose={handleClose}
      aria-labelledby="image-not-supported"
      open={open}
    >
      <DialogTitle id="image-not-supported" onClose={handleClose}>
        <Typography variant="h6">Image format not supported</Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Typography gutterBottom>
          Only JPG/JPEG Images are allowed. Please choose the allowed image
          format.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <ThemeProvider theme={theme}>
      <div className="container">
        <h1 className="large text-primary">
          Upload Profile Picture & Signature
        </h1>
        <Divider /> <br />
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Paper variant="outlined" className={classes.paper}>
              <p className="lead">Profile Picture</p> <Divider />
              {modal}
              <br />
              {!loading ? (
                profile?.profilePic ? (
                  <Fragment>
                    <p>
                      <i className="far fa-image"></i> Current Picture:
                    </p>
                    <img
                      src={'/uploads/profile-pic/' + profile.profilePic}
                      alt="Please upload profile pic"
                      className="profilePic-200"
                    />

                    {profilePicPreview && (
                      <div style={{ marginTop: '10px' }}>
                        <p>
                          <i className="far fa-image"></i> Selected Picture:
                        </p>
                        <img
                          src={profilePicPreview}
                          alt="Chosen signature"
                          style={{ width: '200px' }}
                        />
                      </div>
                    )}
                  </Fragment>
                ) : (
                  <Fragment>
                    {profilePicPreview ? (
                      <div style={{ marginTop: '10px' }}>
                        <p>
                          <i className="far fa-image"></i> Selected Picture:
                        </p>
                        <img
                          src={profilePicPreview}
                          alt="Chosen signature"
                          style={{ width: '200px' }}
                        />
                      </div>
                    ) : (
                      <p>Please upload a profile picture below</p>
                    )}
                  </Fragment>
                )
              ) : (
                <Spinner />
              )}
              <form className="form" onSubmit={(e) => onSubmit(e)}>
                <div className="form-group">
                  <input
                    type="file"
                    name="profilePic"
                    value={profilePicInputState}
                    accept="image/jpeg,image/jpg"
                    onChange={(e) => onChange(e)}
                  />
                  <small className="form-text">
                    - Only JPG/JPEG images are allowed. <br /> - Max file size
                    2MB. <br />- Maximum dimensions: Height 1000px, Width
                    1000px.
                  </small>
                </div>

                <div className="form-group">
                  <Button
                    variant="contained"
                    color="primary"
                    disableElevation
                    disabled={profilePic === null ? true : false}
                    className={classes.btn}
                    type="submit"
                  >
                    Upload
                    {loadingProfilePicUpload ? (
                      <>
                        &nbsp;<i className="fas fa-circle-notch fa-spin"></i>
                      </>
                    ) : (
                      <>
                        &nbsp;<i className="fas fa-upload"></i>
                      </>
                    )}
                  </Button>
                </div>
              </form>{' '}
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper variant="outlined" className={classes.paper}>
              <p className="lead">Signature</p> <Divider /> <br />
              {!loading ? (
                profile?.signature ? (
                  <Fragment>
                    <p>
                      <i className="far fa-image"></i> Current Signature:
                    </p>
                    <br />
                    <img
                      src={'/uploads/signature/' + profile.signature}
                      alt="Please upload Signature"
                      className="signature-200"
                    />
                    {signaturePreview && (
                      <div style={{ marginTop: '10px' }}>
                        <p>
                          <i className="far fa-image"></i> Selected Signature:
                        </p>
                        <img
                          src={signaturePreview}
                          alt="Chosen signature"
                          className="signature-200"
                        />
                      </div>
                    )}
                  </Fragment>
                ) : (
                  <Fragment>
                    {signaturePreview ? (
                      <div>
                        <p>
                          <i className="far fa-image"></i> Selected Signature:
                        </p>
                        <img
                          src={signaturePreview}
                          alt="Chosen signature"
                          className="signature-200"
                        />
                      </div>
                    ) : (
                      <p>Please upload signature below</p>
                    )}
                  </Fragment>
                )
              ) : (
                <Spinner />
              )}
              <form className="form" onSubmit={(e) => onSubmitSignature(e)}>
                <div className="form-group">
                  <input
                    type="file"
                    name="signature"
                    accept="image/jpeg,image/jpg"
                    value={signatureInputState}
                    onChange={(e) => onChangeSignature(e)}
                  />
                  <small className="form-text">
                    - Only JPG/JPEG images are allowed. <br /> - Max file size
                    2MB. <br />- Maximum dimensions: Height 350px, Width 1000px
                  </small>
                </div>
                <div className="form-group">
                  <Button
                    variant="contained"
                    color="primary"
                    disableElevation
                    disabled={signature === null ? true : false}
                    className={classes.btn}
                    type="submit"
                  >
                    Upload
                    {loadingSignature ? (
                      <>
                        &nbsp;<i className="fas fa-circle-notch fa-spin"></i>
                      </>
                    ) : (
                      <>
                        &nbsp;<i className="fas fa-upload"></i>
                      </>
                    )}
                  </Button>
                </div>
              </form>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Link to="/dashboard" className="btn btn-light">
              <i className="fas fa-arrow-left text-primary"></i> &nbsp; Back
            </Link>
          </Grid>
        </Grid>
        <AlertComponent />
        <br />
      </div>
    </ThemeProvider>
  );
};

Upload.propTypes = {
  profile: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  profile: state.profile,
});

export default connect(mapStateToProps, {})(Upload);
