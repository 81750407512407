import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Divider from '@material-ui/core/Divider';
import AlertComponent from '../../components/layout/AlertComponent';
import { useDispatch, useSelector } from 'react-redux';
import { listDepartments } from '../../actions/departmentActions';
import { createProgram } from '../../actions/programActions';

import {
  Grid,
  makeStyles,
  Button,
  TextField,
  createMuiTheme,
  ThemeProvider,
  MenuItem,
} from '@material-ui/core';

import clsx from 'clsx';
import Spinner from '../../components/layout/Spinner';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#00acee',
    },
    secondary: {
      main: '#a8dda8',
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
    marginTop: theme.spacing(3),
  },
  textField: {
    width: '100%',
  },
  btnStyle: {
    color: '#FFF',
  },
  btn: {
    color: '#FFF',
    marginRight: '10px',
  },
  btnSecondary: {
    // color: '#00acee',
    marginRight: '10px',
  },
  my20: {
    marginBottom: '20px',
  },
}));

const ProgramCreateScreen = ({ history }) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const programCreate = useSelector((state) => state.programCreate);
  const { loading: loadingCreate, success: successCreate } = programCreate;

  const auth = useSelector((state) => state.auth);
  const { loading: authLoading, role } = auth;

  const departmentList = useSelector((state) => state.departmentList);
  const { loading: loadingList, departments, error } = departmentList;

  const [programName, setProgramName] = useState('');
  const [programShortCode, setProgramShortCode] = useState('');
  const [credit, setCredit] = useState('');
  const [programType, setProgramType] = useState('');
  const [department, setDepartment] = useState('');
  const [programUrl, setProgramUrl] = useState('');
  const [description, setDescription] = useState('');

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(
      createProgram({
        programName,
        programShortCode,
        credit,
        programType,
        department,
        programUrl,
        description,
      })
    );
    //
  };

  useEffect(() => {
    dispatch(listDepartments());
  }, [listDepartments, dispatch]);

  useEffect(() => {
    if (successCreate) {
      setTimeout(() => {
        history.push(`/programs`);
      }, 1000);
    }
  }, [successCreate, history]);

  return (
    <ThemeProvider theme={theme}>
      <div className="container">
        <h1 className="large text-primary">Create Program</h1>
        <p className="lead">
          <i className="fa fa-check"></i> Please fill up the form and create a
          new program.
        </p>
        <small>* marked are required field</small>
        <Divider /> <br />
        <form onSubmit={(e) => onSubmit(e)}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <TextField
                type="text"
                name="programName"
                label="Name of the program*"
                value={programName}
                helperText={'Enter the name of the program'}
                variant="outlined"
                onChange={(e) => setProgramName(e.target.value)}
                className={clsx(classes.textField)}
              ></TextField>
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                type="text"
                name="programShortCode"
                label="Short Code*"
                value={programShortCode}
                helperText={'Enter the short code of the program'}
                variant="outlined"
                onChange={(e) => setProgramShortCode(e.target.value)}
                className={clsx(classes.textField)}
              ></TextField>
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                type="number"
                name="credit"
                label="Credit*"
                value={credit}
                helperText={'Enter the credit of the program'}
                variant="outlined"
                onChange={(e) => setCredit(e.target.value)}
                className={clsx(classes.textField)}
              ></TextField>
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                select
                name="department"
                label="Program Type*"
                value={programType}
                helperText={'Select Program Type'}
                variant="outlined"
                onChange={(e) => setProgramType(e.target.value)}
                className={clsx(classes.textField)}
              >
                <MenuItem value={''}>* Select Department</MenuItem>
                <MenuItem value={'undergraduate'}>Undergraduate</MenuItem>
                <MenuItem value={'graduate'}>Graduate</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                select
                name="department"
                label="Department*"
                value={department}
                helperText={'Select Department'}
                variant="outlined"
                onChange={(e) => setDepartment(e.target.value)}
                className={clsx(classes.textField)}
              >
                <MenuItem value={''}>* Select Department</MenuItem>
                {departments.map((department) => (
                  <MenuItem value={department._id} key={department._id}>
                    {department.departmentName}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                type="text"
                name="programUrl"
                label="Link"
                value={programUrl}
                helperText={'Enter NDUB website link of the program'}
                variant="outlined"
                onChange={(e) => setProgramUrl(e.target.value)}
                className={clsx(classes.textField)}
              ></TextField>
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                type="text"
                multiline
                rows={5}
                name="description"
                label="Description"
                value={description}
                helperText={'Enter the description of the program'}
                variant="outlined"
                onChange={(e) => setDescription(e.target.value)}
                className={clsx(classes.textField)}
              ></TextField>
            </Grid>

            <Grid item xs={12}>
              {!authLoading && (
                <Link to={`/programs`}>
                  <Button
                    variant="contained"
                    color="default"
                    disableElevation
                    className={classes.btnSecondary}
                  >
                    <i className="fas fa-arrow-left"></i> &nbsp; Back
                  </Button>
                </Link>
              )}

              {!authLoading && role.includes('ADMIN') && (
                <Button
                  variant="contained"
                  color="primary"
                  disableElevation
                  className={classes.btn}
                  type="submit"
                >
                  Create
                  {loadingCreate ? (
                    <>
                      &nbsp;<i className="fas fa-circle-notch fa-spin"></i>
                    </>
                  ) : (
                    <>
                      &nbsp;<i className="fas fa-check"></i>
                    </>
                  )}
                </Button>
              )}
            </Grid>
          </Grid>
          <AlertComponent />
        </form>
      </div>
    </ThemeProvider>
  );
};

export default ProgramCreateScreen;
