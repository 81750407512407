import {
  USER_CREATE_FAIL,
  USER_CREATE_REQUEST,
  USER_CREATE_SUCCESS,
  USER_DELETE_FAIL,
  USER_DELETE_REQUEST,
  USER_DELETE_SUCCESS,
  USER_LIST_FAIL,
  USER_LIST_REQUEST,
  USER_LIST_SUCCESS,
  USER_UPDATE_REQUEST,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAIL,
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,
  USER_DETAILS_FAIL,
  USER_DETAILS_RESET,
  USER_LIST_REMOVE_ITEM,
  USER_REGISTER_STEP1_REQUEST,
  USER_REGISTER_STEP1_SUCCESS,
  USER_REGISTER_STEP1_FAIL,
  USER_REGISTER_STEP1_RESET,
  USER_VERIFY_PHONE_REQUEST,
  USER_VERIFY_PHONE_SUCCESS,
  USER_VERIFY_PHONE_FAIL,
  USER_VERIFY_PHONE_RESET,
  USER_RESET_STEP1_REQUEST,
  USER_RESET_STEP1_SUCCESS,
  USER_RESET_STEP1_FAIL,
  USER_RESET_STEP1_RESET,
  USER_RESET_STEP2_REQUEST,
  USER_RESET_STEP2_SUCCESS,
  USER_RESET_STEP2_FAIL,
  USER_RESET_STEP2_RESET,
} from '../constants/userConstants';

export const userListReducer = (state = { users: [] }, action) => {
  switch (action.type) {
    case USER_LIST_REQUEST:
      return { loading: true, users: [] };
    case USER_LIST_SUCCESS:
      return {
        loading: false,
        users: action.payload.users,
        pages: action.payload.pages,
        page: action.payload.page,
        count: action.payload.count,
      };
    case USER_LIST_REMOVE_ITEM:
      return {
        ...state,
        users: state.users.filter((user) => user._id !== action.payload),
        count: state.count - 1,
      };
    case USER_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const userDetailsReducer = (state = { user: {} }, action) => {
  switch (action.type) {
    case USER_DETAILS_REQUEST:
      return { loading: true, users: {} };
    case USER_DETAILS_SUCCESS:
      return {
        loading: false,
        user: action.payload.user,
      };
    case USER_DETAILS_RESET:
      return {
        loading: false,
        user: {},
      };
    case USER_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const userCreateReducer = (state = { user: {} }, action) => {
  switch (action.type) {
    case USER_CREATE_REQUEST:
      return { loading: true, user: {} };
    case USER_CREATE_SUCCESS:
      return {
        loading: false,
        user: action.payload.user,
        msg: action.payload.msg,
      };
    case USER_CREATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// new user reg system
export const userRegisterStep1Reducer = (state = {}, action) => {
  switch (action.type) {
    case USER_REGISTER_STEP1_REQUEST:
      return { loading: true };
    case USER_REGISTER_STEP1_SUCCESS:
      return {
        loading: false,
        _id: action.payload._id,
        token: action.payload.token,
        success: true,
      };
    case USER_REGISTER_STEP1_FAIL:
      return { loading: false, error: action.payload, success: false };
    case USER_REGISTER_STEP1_RESET:
      return {};
    default:
      return state;
  }
};

export const userVerifyPhoneReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_VERIFY_PHONE_REQUEST:
      return { loading: true };
    case USER_VERIFY_PHONE_SUCCESS:
      return {
        loading: false,
        _id: action.payload._id,
        token: action.payload.token,
        success: true,
      };
    case USER_VERIFY_PHONE_FAIL:
      return { loading: false, error: action.payload, success: false };
    case USER_VERIFY_PHONE_RESET:
      return {};
    default:
      return state;
  }
};

// new user reg system
export const userResetStep1Reducer = (state = {}, action) => {
  switch (action.type) {
    case USER_RESET_STEP1_REQUEST:
      return { loading: true };
    case USER_RESET_STEP1_SUCCESS:
      return {
        loading: false,
        _id: action.payload._id,
        token: action.payload.token,
        success: true,
      };
    case USER_RESET_STEP1_FAIL:
      return { loading: false, error: action.payload, success: false };
    case USER_RESET_STEP1_RESET:
      return {};
    default:
      return state;
  }
};

// new user reg system
export const userResetStep2Reducer = (state = {}, action) => {
  switch (action.type) {
    case USER_RESET_STEP2_REQUEST:
      return { loading: true };
    case USER_RESET_STEP2_SUCCESS:
      return {
        loading: false,
        data: action.payload,
        success: true,
      };
    case USER_RESET_STEP2_FAIL:
      return { loading: false, error: action.payload, success: false };
    case USER_RESET_STEP2_RESET:
      return {};
    default:
      return state;
  }
};

export const userUpdateReducer = (state = { user: {} }, action) => {
  switch (action.type) {
    case USER_UPDATE_REQUEST:
      return { loading: true, user: {} };
    case USER_UPDATE_SUCCESS:
      return {
        loading: false,
        user: action.payload.user,
      };
    case USER_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// Delete User
export const userDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_DELETE_REQUEST:
      return { loading: true };
    case USER_DELETE_SUCCESS:
      return { loading: false, success: true };
    case USER_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
