export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const GET_PROFILE = 'GET_PROFILE';
export const CLEAR_PROFILE = 'CLEAR_PROFILE';
export const CLEAR_PROFILE_PDF = 'CLEAR_PROFILE_PDF';
export const PROFILE_ERROR = 'PROFILE_ERROR';
export const GET_EXAM = 'GET_EXAM';
export const EXAM_ERROR = 'EXAM_ERROR';
export const ADD_EXAM = 'ADD_EXAM';
export const EDIT_EXAM = 'EDIT_EXAM';
export const SEARCH_PROFILE = 'SEARCH_PROFILE';
export const SINGLE_STUDENT_PROFILE = 'SINGLE_STUDENT_PROFILE';
export const SINGLE_STUDENT_PROFILE_PDF = 'SINGLE_STUDENT_PROFILE_PDF';
export const ADD_MARKS = 'ADD_MARKS';
export const GET_NOTICES = 'GET_NOTICES';
export const GET_NOTICE = 'GET_NOTICE';
export const NOTICE_ERROR = 'NOTICE_ERROR';
export const ADD_NOTICE = 'ADD_NOTICE';
export const EDIT_NOTICE = 'EDIT_NOTICE';
export const DELETE_NOTICE = 'DELETE_NOTICE';
export const SINGLE_STUDENT_PROFILE_ADMIT = 'SINGLE_STUDENT_PROFILE_ADMIT';
export const CLEAR_PROFILE_ADMIT = 'CLEAR_PROFILE_ADMIT';
export const SET_LOADING = 'SET_LOADING';
