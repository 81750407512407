import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { logout } from '../../actions/auth';

const MenuAppBar = ({ auth: { isAuthenticated, loading, role }, logout }) => {
  const authLinks = (
    <ul>
      <li>
        <Link to="/notices">
          <i className="far fa-newspaper"></i>{' '}
          <span className="hide-sm">Notices</span>
        </Link>
      </li>
      <li>
        <Link to="/contact">
          <i className="fas fa-phone-alt"></i>{' '}
          <span className="hide-sm">Contact</span>
        </Link>
      </li>
      <li>
        <Link to="/dashboard">
          <i className="fas fa-user"></i>{' '}
          <span className="hide-sm">Dashboard</span>
        </Link>
      </li>
      <li>
        <Link onClick={logout} to="/">
          <i className="fas fa-sign-out-alt"></i>{' '}
          <span className="hide-sm">Logout</span>
        </Link>
      </li>
    </ul>
  );
  const adminAuthLinks = (
    <ul>
      <li>
        <Link to="/admin-dashboard">
          <i className="fas fa-user"></i>{' '}
          <span className="hide-sm">Dashboard</span>
        </Link>
        {'  '}
      </li>
      <li>
        <Link onClick={logout} to="/">
          <i className="fas fa-sign-out-alt"></i>{' '}
          <span className="hide-sm">Logout</span>
        </Link>
      </li>
    </ul>
  );

  const guestLinks = (
    <ul>
      <li>
        <Link to="/notices">
          <i className="far fa-newspaper"></i>{' '}
          <span className="hide-sm">Notices</span>
        </Link>
      </li>
      <li>
        <Link to="/contact">
          <i className="fas fa-phone-alt"></i>{' '}
          <span className="hide-sm">Contact</span>
        </Link>
      </li>
      <li>
        <Link to="/register">
          <i className="fas fa-file-signature"></i>{' '}
          <span className="hide-sm">Register</span>
        </Link>
      </li>
      <li>
        <Link to="/login">
          <i className="fas fa-sign-in-alt"></i>{' '}
          <span className="hide-sm">Login</span>
        </Link>
      </li>
    </ul>
  );
  return (
    <Fragment>
      <nav className="navbar bg-dark">
        <h2>
          <Link to="/">NDUB Admission</Link>
        </h2>
        {!loading && (
          <Fragment>
            {isAuthenticated && role
              ? role.includes('ADMIN')
                ? adminAuthLinks
                : authLinks
              : guestLinks}
          </Fragment>
        )}
      </nav>
    </Fragment>
  );
};

MenuAppBar.propTypes = {
  logout: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logout })(MenuAppBar);
