import React from 'react';
import PropTypes from 'prop-types';

const ProfileMarks = ({
  profile: {
    marks: { applicantId, written, viva, total, result, place, remarks },
    ssc: { sscConverted },
    hsc: { hscConverted },
  },
}) => {
  return (
    <div className="profile-marks bg-white p-2">
      <h2 className="text-primary">Admission Info</h2>
      <div>
        <p>
          <strong>Applicant ID: </strong>
          {applicantId && <span>{applicantId}</span>}
        </p>
      </div>
      {/* style={{ textAlign: 'right' }} */}
      <div>
        <p>
          <strong>SSC Converted: </strong>
          {sscConverted}
        </p>
        <p>
          <strong>HSC Converted: </strong>
          {hscConverted}
        </p>
        <p>
          <strong>Written: </strong>
          {written}
        </p>
        <p>
          <strong>Viva: </strong>
          {viva}
        </p>{' '}
      </div>{' '}
      <div>
        <p>
          <strong>Total: </strong>
          {total}
        </p>
      </div>
      <div>
        <p>
          <strong>Result: </strong>
          {result}
        </p>
        <p>
          <strong>Place: </strong>
          {place}
        </p>
        <p>
          <strong>Remarks: </strong>
          {remarks}
        </p>
      </div>
    </div>
  );
};

ProfileMarks.propTypes = {
  profile: PropTypes.object.isRequired,
};

export default ProfileMarks;
