import axios from 'axios';
import * as Sentry from '@sentry/react';
import {
  DEPARTMENT_DETAILS_FAIL,
  DEPARTMENT_DETAILS_REQUEST,
  DEPARTMENT_DETAILS_SUCCESS,
  DEPARTMENT_UPDATE_REQUEST,
  DEPARTMENT_UPDATE_SUCCESS,
  DEPARTMENT_UPDATE_FAIL,
  DEPARTMENT_LIST_REQUEST,
  DEPARTMENT_LIST_SUCCESS,
  DEPARTMENT_LIST_FAIL,
  DEPARTMENT_DELETE_REQUEST,
  DEPARTMENT_DELETE_SUCCESS,
  DEPARTMENT_DELETE_FAIL,
  DEPARTMENT_LIST_REMOVE_ITEM,
  DEPARTMENT_CREATE_REQUEST,
  DEPARTMENT_CREATE_FAIL,
  DEPARTMENT_CREATE_SUCCESS,
} from '../constants/departmentConstants';
import { setAlert } from './alert';

// Get all departments
export const listDepartments = () => async (dispatch) => {
  try {
    dispatch({ type: DEPARTMENT_LIST_REQUEST });

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const { data } = await axios.get(`/api/departments`, config);

    dispatch({
      type: DEPARTMENT_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    Sentry.captureException(error);
    dispatch({
      type: DEPARTMENT_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// Get single department
export const getDepartment = (department_id) => async (dispatch) => {
  try {
    dispatch({ type: DEPARTMENT_DETAILS_REQUEST });
    const { data } = await axios.get(`/api/departments/${department_id}`);
    dispatch({
      type: DEPARTMENT_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    Sentry.captureException(error);
    dispatch({
      type: DEPARTMENT_DETAILS_FAIL,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};

// Create Department
export const createDepartment = (formData) => async (dispatch) => {
  try {
    dispatch({ type: DEPARTMENT_CREATE_REQUEST });
    const { data } = await axios.post(`/api/departments/`, formData);

    dispatch({
      type: DEPARTMENT_CREATE_SUCCESS,
      payload: data,
    });
    dispatch(setAlert('Department Created', 'success'));
  } catch (error) {
    Sentry.captureException(error);
    const errors = error.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
    }
    dispatch({
      type: DEPARTMENT_CREATE_FAIL,
      payload: { msg: errors, status: error.response.status },
    });
  }
};

// Update Department
export const updateDepartment =
  (formData, department_id) => async (dispatch) => {
    try {
      dispatch({ type: DEPARTMENT_UPDATE_REQUEST });
      const { data } = await axios.put(
        `/api/departments/${department_id}`,
        formData
      );

      dispatch({
        type: DEPARTMENT_UPDATE_SUCCESS,
        payload: data,
      });
      dispatch({
        type: DEPARTMENT_DETAILS_SUCCESS,
        payload: data,
      });
      dispatch(setAlert('Department Updated', 'success'));
    } catch (error) {
      Sentry.captureException(error);
      const errors = error.response.data.errors;
      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
      }
      dispatch({
        type: DEPARTMENT_UPDATE_FAIL,
        payload: { msg: errors, status: error.response.status },
      });
    }
  };

// Delete a department
export const deleteDepartment = (department_id) => async (dispatch) => {
  try {
    dispatch({ type: DEPARTMENT_DELETE_REQUEST });
    const { data } = await axios.delete(`/api/departments/${department_id}`);
    dispatch({ type: DEPARTMENT_DELETE_SUCCESS });
    dispatch({
      type: DEPARTMENT_LIST_REMOVE_ITEM,
      payload: department_id,
    });
    dispatch(setAlert('Department Deleted', 'success'));
  } catch (error) {
    Sentry.captureException(error);
    const errors = error.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
    }
    dispatch({
      type: DEPARTMENT_DELETE_FAIL,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};
