import axios from 'axios';
import * as Sentry from '@sentry/react';
import {
  USER_CREATE_FAIL,
  USER_CREATE_REQUEST,
  USER_CREATE_SUCCESS,
  USER_DELETE_FAIL,
  USER_DELETE_REQUEST,
  USER_DELETE_SUCCESS,
  USER_DETAILS_FAIL,
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,
  USER_LIST_FAIL,
  USER_LIST_REMOVE_ITEM,
  USER_LIST_REQUEST,
  USER_LIST_SUCCESS,
  USER_REGISTER_STEP1_FAIL,
  USER_REGISTER_STEP1_REQUEST,
  USER_REGISTER_STEP1_SUCCESS,
  USER_VERIFY_PHONE_FAIL,
  USER_VERIFY_PHONE_REQUEST,
  USER_VERIFY_PHONE_SUCCESS,
  USER_RESET_STEP1_FAIL,
  USER_RESET_STEP1_REQUEST,
  USER_RESET_STEP1_SUCCESS,
  USER_UPDATE_FAIL,
  USER_UPDATE_REQUEST,
  USER_UPDATE_SUCCESS,
  USER_RESET_STEP2_SUCCESS,
  USER_RESET_STEP2_FAIL,
  USER_RESET_STEP2_REQUEST,
} from '../constants/userConstants';
import { setAlert } from './alert';
import { LOGIN_SUCCESS } from './types';

// Get users
export const listUsers =
  (
    role = 'ADMIN',
    email = '',
    phone = '',
    name = '',
    paymentStatus = '',
    paymentMethod = '',
    trxID = '',
    bankSlipNumber = '',
    page = '',
    sort = ''
  ) =>
  async (dispatch) => {
    try {
      dispatch({ type: USER_LIST_REQUEST });

      const { data } = await axios.get(
        `/api/users/admin-users?role=${role}&email=${email}&phone=${phone}&name=${name}&page=${page}&sort=${sort}&paymentStatus=${paymentStatus}&paymentMethod=${paymentMethod}&trxID=${trxID}&bankSlipNumber=${bankSlipNumber}`
      );

      dispatch({
        type: USER_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      Sentry.captureException(error);

      const errors = error.response.data.errors;

      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
      }
      dispatch({
        type: USER_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

// Get single user
export const getUser = (id) => async (dispatch) => {
  try {
    dispatch({ type: USER_DETAILS_REQUEST });

    const { data } = await axios.get(`/api/users/admin-users/${id}`);

    dispatch({
      type: USER_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    Sentry.captureException(error);

    const errors = error.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
    }
    dispatch({
      type: USER_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// Create User
export const createUser =
  (role, email, phone, name, password) => async (dispatch, getState) => {
    try {
      dispatch({ type: USER_CREATE_REQUEST });

      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      const { data } = await axios.post(
        `/api/users/admin-users/`,
        { role, email, phone, name, password },
        config
      );

      dispatch({
        type: USER_CREATE_SUCCESS,
        payload: data,
      });
      dispatch(setAlert(data.msg));
    } catch (error) {
      Sentry.captureException(error);

      const errors = error.response.data.errors;

      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
      }
      dispatch({
        type: USER_CREATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

// Student registration step 1
export const registerUserStep1 =
  (name, email, phone, password, confirmPassword) => async (dispatch) => {
    try {
      dispatch({ type: USER_REGISTER_STEP1_REQUEST });

      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      const { data } = await axios.post(
        `/api/users/register-step-1/v1`,
        { name, email, phone, password, confirmPassword },
        config
      );

      dispatch({
        type: USER_REGISTER_STEP1_SUCCESS,
        payload: data,
      });
      dispatch(
        setAlert(
          'Registration successful. A verification code was sent to your phone number.'
        )
      );
    } catch (error) {
      Sentry.captureException(error);
      const errors = error.response.data.errors;

      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
      }
      dispatch({
        type: USER_REGISTER_STEP1_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

// Student registration step 2
// Verify phone number with token and verification code
export const verifyCode =
  (token, verificationCode, reset) => async (dispatch) => {
    try {
      dispatch({ type: USER_VERIFY_PHONE_REQUEST });

      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      const { data } = await axios.post(
        `/api/users/verify/v1`,
        { token, verificationCode },
        config
      );

      data.role = 'STUDENT';

      dispatch({
        type: USER_VERIFY_PHONE_SUCCESS,
        payload: data,
      });

      if (reset === false) {
        dispatch({
          type: LOGIN_SUCCESS,
          payload: data,
        });
        dispatch(
          setAlert(
            'Verification successful, you will be logged in automatically'
          )
        );
      } else {
        dispatch(setAlert('Verification successful'));
      }
    } catch (error) {
      Sentry.captureException(error);
      const errors = error.response.data.errors;

      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
      }
      dispatch({
        type: USER_VERIFY_PHONE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

// Password reset step 1
export const resetPasswordStep1 = (phone) => async (dispatch) => {
  try {
    dispatch({ type: USER_RESET_STEP1_REQUEST });

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const { data } = await axios.post(`/api/users/reset/v1`, { phone }, config);

    dispatch({
      type: USER_RESET_STEP1_SUCCESS,
      payload: data,
    });
    dispatch(setAlert('A verification code was sent to your phone number.'));
  } catch (error) {
    Sentry.captureException(error);
    const errors = error.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
    }
    dispatch({
      type: USER_RESET_STEP1_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// Password reset step 2
export const resetPasswordStep2 =
  (token, password, confirmPassword) => async (dispatch) => {
    try {
      dispatch({ type: USER_RESET_STEP2_REQUEST });

      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      const { data } = await axios.post(
        `/api/users/reset-password/`,
        { token, password, confirmPassword },
        config
      );

      dispatch({
        type: USER_RESET_STEP2_SUCCESS,
        payload: data,
      });
      dispatch(
        setAlert(
          'Password reset successful. Please login with your new password.'
        )
      );
    } catch (error) {
      Sentry.captureException(error);
      const errors = error.response.data.errors;

      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
      }
      dispatch({
        type: USER_RESET_STEP2_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

// Update User
export const updateUser =
  (
    id,
    role,
    email,
    phone,
    name,
    password,
    paymentStatus,
    paymentMethod,
    trxID,
    bankSlipNumber
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: USER_UPDATE_REQUEST });

      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      const { data } = await axios.put(
        `/api/users/admin-users/`,
        {
          id,
          role,
          email,
          phone,
          name,
          password,
          paymentStatus,
          paymentMethod,
          trxID,
          bankSlipNumber,
        },
        config
      );

      dispatch({
        type: USER_UPDATE_SUCCESS,
        payload: data,
      });
      dispatch(setAlert(data.msg));
    } catch (error) {
      Sentry.captureException(error);
      const errors = error.response.data.errors;

      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
      }
      dispatch({
        type: USER_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

// Delete User
export const deleteUser = (_id) => async (dispatch, getState) => {
  try {
    dispatch({ type: USER_DELETE_REQUEST });

    const { data } = await axios.delete(`/api/users/admin-users/${_id}`);

    dispatch({
      type: USER_DELETE_SUCCESS,
    });
    dispatch({
      type: USER_LIST_REMOVE_ITEM,
      payload: _id,
    });
    dispatch(setAlert(data.msg));
  } catch (error) {
    Sentry.captureException(error);
    const errors = error.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
    }
    dispatch({
      type: USER_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
