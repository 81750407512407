import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';

import Alert from '@material-ui/lab/Alert';
import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
} from '@material-ui/core';

import BkashLogo from '../../../img/illustrations/BKash-Icon2-Logo.wine.svg';
import BankIllustration from '../../../img/illustrations/bank-solid.svg';

const Payment = ({ user }) => {
  return (
    <Fragment>
      <div className="container">
        <h1 className="large text-primary">Payment</h1>
        <Divider />
        {user !== null ? (
          <Alert
            severity={
              user.paymentInfo.status === 'UNPAID'
                ? 'error'
                : user.paymentInfo.status === 'PENDING'
                ? 'warning'
                : 'success'
            }
          >
            Payment Status: {user.paymentInfo.status}
          </Alert>
        ) : null}
        {user && user?.paymentInfo?.status === 'PAID' ? (
          <p style={{ marginBottom: '15px' }}>
            Payment Method:{' '}
            <code
              style={{
                backgroundColor: '#EDF7ED',
                padding: '5px',
                borderRadius: '5px',
              }}
            >
              {user?.paymentInfo?.paymentMethod}
            </code>{' '}
            | Transaction ID:{' '}
            <code
              style={{
                backgroundColor: '#EDF7ED',
                padding: '5px',
                borderRadius: '5px',
              }}
            >
              {user?.paymentInfo?.bkashInfo?.trxID}
            </code>
          </p>
        ) : (
          <div>'Make your payment online through bKash or Bank'</div>
        )}
        {user !== null && user.paymentInfo.status !== 'PAID' ? (
          <div>
            <p className="lead">Choose your payment method:</p>
            <Grid container spacing={3}>
              {/* <Grid item xs={12} sm={6} md={3}>
                <Card variant="outlined">
                  <Link to="/payment-bank" style={{ color: '#000' }}>
                    <CardActionArea>
                      <CardMedia
                        style={{ height: '140px' }}
                        image={BankIllustration}
                        title="Bank Payment"
                      />
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                          Bank Payment
                        </Typography>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          component="p"
                        >
                          Make BDT 1000 payment to Mercantile Bank through
                          payment slip
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Link>
                </Card>
              </Grid> */}
              <Grid item xs={12} sm={6} md={3}>
                <Card variant="outlined">
                  <a
                    href={`https://www.admission.ndub.edu.bd/pay-with-bKash/index.html?phone=${user.phone}`}
                    style={{ color: '#000' }}
                  >
                    <CardActionArea>
                      <CardMedia
                        style={{ height: '140px' }}
                        image={BkashLogo}
                        title="bKash Payment"
                      />
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                          bKash Payment
                        </Typography>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          component="p"
                        >
                          Make BDT 1016 payment through bKash <br /> <br />
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </a>
                </Card>
              </Grid>
            </Grid>
            <br />
            <div>
              <Link className="btn btn-light" to="/dashboard">
                <i className="fas fa-arrow-left"></i> &nbsp; Back
              </Link>
            </div>
          </div>
        ) : (
          <div>
            <Link to={`/dashboard`}>
              <Button
                size="small"
                variant="contained"
                color="default"
                disableElevation
              >
                <i className="fas fa-arrow-left"></i> &nbsp; Back
              </Button>
            </Link>
          </div>
        )}
      </div>
    </Fragment>
  );
};

Payment.propTypes = {
  isAuthenticated: PropTypes.bool,
  role: PropTypes.string,
};

const mapStateToProps = (state) => ({
  exam: state.exam,
  isAuthenticated: state.auth.isAuthenticated,
  role: state.auth.role,
  user: state.auth.user,
});

export default connect(mapStateToProps, {})(Payment);
