import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Divider from '@material-ui/core/Divider';
import AlertComponent from '../../components/layout/AlertComponent';
import { useDispatch, useSelector } from 'react-redux';
import { createDepartment } from '../../actions/departmentActions';

import {
  Grid,
  makeStyles,
  Button,
  TextField,
  createMuiTheme,
  ThemeProvider,
} from '@material-ui/core';

import clsx from 'clsx';
import Spinner from '../../components/layout/Spinner';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#00acee',
    },
    secondary: {
      main: '#a8dda8',
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
    marginTop: theme.spacing(3),
  },
  textField: {
    width: '100%',
  },
  btnStyle: {
    color: '#FFF',
  },
  btn: {
    color: '#FFF',
    marginRight: '10px',
  },
  btnSecondary: {
    // color: '#00acee',
    marginRight: '10px',
  },
  my20: {
    marginBottom: '20px',
  },
}));

const DepartmentCreateScreen = ({ history }) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const departmentCreate = useSelector((state) => state.departmentCreate);
  const { loading: loadingCreate, success: successCreate } = departmentCreate;

  const auth = useSelector((state) => state.auth);
  const { loading: authLoading, role } = auth;

  const [departmentName, setDepartmentName] = useState('');
  const [deptShortCode, setDeptShortCode] = useState('');
  const [deptUrl, setDeptUrl] = useState('');
  const [details, setDetails] = useState('');

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(
      createDepartment({ departmentName, deptShortCode, details, deptUrl })
    );
    //
  };

  useEffect(() => {
    if (successCreate) {
      setTimeout(() => {
        history.push(`/departments`);
      }, 1000);
    }
  }, [successCreate, history]);

  return (
    <ThemeProvider theme={theme}>
      <div className="container">
        <h1 className="large text-primary">Create Department</h1>
        <p className="lead">
          <i className="fa fa-check"></i> Please fill up the form and create a
          new department.
        </p>
        <small>* marked are required field</small>
        <Divider /> <br />
        <form onSubmit={(e) => onSubmit(e)}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <TextField
                type="text"
                name="departmentName"
                label="Name of the department*"
                value={departmentName}
                helperText={'Please enter the name of the department'}
                variant="outlined"
                onChange={(e) => setDepartmentName(e.target.value)}
                className={clsx(classes.textField)}
              ></TextField>
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                type="text"
                name="shortCode"
                label="Short Code*"
                value={deptShortCode}
                helperText={'Please enter the short code of the department'}
                variant="outlined"
                onChange={(e) => setDeptShortCode(e.target.value)}
                className={clsx(classes.textField)}
              ></TextField>
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                type="text"
                name="deptUrl"
                label="Link"
                value={deptUrl}
                helperText={
                  'Please enter the NDUB website link of the department'
                }
                variant="outlined"
                onChange={(e) => setDeptUrl(e.target.value)}
                className={clsx(classes.textField)}
              ></TextField>
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                type="text"
                multiline
                rows={5}
                name="details"
                label="Details"
                value={details}
                helperText={'Please enter the details of the department'}
                variant="outlined"
                onChange={(e) => setDetails(e.target.value)}
                className={clsx(classes.textField)}
              ></TextField>
            </Grid>

            <Grid item xs={12}>
              {!authLoading && (
                <Link to={`/departments`}>
                  <Button
                    variant="contained"
                    color="default"
                    disableElevation
                    className={classes.btnSecondary}
                  >
                    <i className="fas fa-arrow-left"></i> &nbsp; Back
                  </Button>
                </Link>
              )}

              {!authLoading && role.includes('ADMIN') && (
                <Button
                  variant="contained"
                  color="primary"
                  disableElevation
                  className={classes.btn}
                  type="submit"
                >
                  Create
                  {loadingCreate ? (
                    <>
                      &nbsp;<i className="fas fa-circle-notch fa-spin"></i>
                    </>
                  ) : (
                    <>
                      &nbsp;<i className="fas fa-check"></i>
                    </>
                  )}
                </Button>
              )}
            </Grid>
          </Grid>
          <AlertComponent />
        </form>
      </div>
    </ThemeProvider>
  );
};

export default DepartmentCreateScreen;
