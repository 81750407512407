import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Divider from '@material-ui/core/Divider';
import AlertComponent from '../layout/AlertComponent';
import { useDispatch, useSelector } from 'react-redux';
import { updateExam, getExam } from '../../actions/examActions';

import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import {
  DatePicker,
  DateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import InsertInvitationIcon from '@material-ui/icons/InsertInvitation';
import {
  Button,
  Grid,
  makeStyles,
  MenuItem,
  TextField,
  IconButton,
  InputAdornment,
  createMuiTheme,
  ThemeProvider,
  Chip,
} from '@material-ui/core';

import clsx from 'clsx';
import Spinner from '../layout/Spinner';
import { listPrograms } from '../../actions/programActions';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#00acee',
    },
    secondary: {
      main: '#a8dda8',
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
    marginTop: theme.spacing(3),
  },
  textField: {
    width: '100%',
  },
  btnStyle: {
    color: '#FFF',
  },
  btn: {
    color: '#FFF',
    marginRight: '10px',
  },
  btnSecondary: {
    // color: '#00acee',
    marginRight: '10px',
  },
  my20: {
    marginBottom: '20px',
  },
}));

const EditExam = ({ match, history }) => {
  // if (editExamId === null) {
  //   return <Redirect to="/exams" />;
  // }
  const classes = useStyles();

  const dispatch = useDispatch();

  const programList = useSelector((state) => state.programList);
  const {
    loading: loadingProgramList,
    programs,
    errorProgramList,
  } = programList;

  const examDetails = useSelector((state) => state.examDetails);
  const { loading: loadingDetails, exam, error } = examDetails;

  const examUpdate = useSelector((state) => state.examUpdate);
  const { loading: loadingUpdate, success: successUpdate } = examUpdate;

  const auth = useSelector((state) => state.auth);
  const { loading: authLoading, role } = auth;

  const [formData, setFormData] = useState({
    _id: '',
    title: '',
    description: '',
    program: '',
    batch: '',
    trimester: '',
    conducted: false,
  });
  const [examDate, setExamDate] = useState(new Date());
  const [year, setYear] = useState(null);

  useEffect(() => {
    if (!exam || !exam.title || exam._id !== match.params.id) {
      console.log('Requested');
      dispatch(getExam(match.params.id));
      dispatch(listPrograms());
    } else {
      setFormData({ ...exam, program: exam.program._id });
      setExamDate(new Date(exam.examDate));
      setYear(new Date(exam.year));
    }
  }, [dispatch, exam, match.params.id]);

  const {
    title,
    description,
    program,

    batch,
    trimester,
    conducted,
  } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(
      updateExam({ ...formData, examDate, year: year.getFullYear() }, history)
    );
  };

  return loadingDetails ? (
    <div className="container">
      <Spinner />
    </div>
  ) : (
    <ThemeProvider theme={theme}>
      <div className="container">
        <h1 className="large text-primary">Edit Exam</h1>
        <Divider /> <br />
        <form onSubmit={(e) => onSubmit(e)}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <TextField
                size="small"
                type="text"
                name="title"
                label="Title of the exam"
                value={title}
                helperText={'Please enter the main title of the exam'}
                variant="outlined"
                onChange={(e) => onChange(e)}
                className={clsx(classes.textField)}
              ></TextField>
            </Grid>

            <Grid item xs={12} sm={12}>
              <TextField
                size="small"
                label="Description"
                multiline
                rows={2}
                variant="outlined"
                className={clsx(classes.textField)}
                type="text"
                name="description"
                helperText={'A small description about the exam'}
                value={description}
                onChange={(e) => onChange(e)}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <TextField
                size="small"
                select
                name="program"
                label="Program*"
                value={program}
                helperText={'Select Program'}
                variant="outlined"
                onChange={(e) => onChange(e)}
                className={clsx(classes.textField)}
              >
                <MenuItem disabled> Undergraduate</MenuItem>
                {programs && programs.length > 0
                  ? programs?.map((p) =>
                      p.programType === 'undergraduate' ? (
                        <MenuItem value={p._id} key={p._id}>
                          {p.programName} &nbsp;
                          <Chip
                            label={p.department.departmentName}
                            size="small"
                          ></Chip>
                        </MenuItem>
                      ) : null
                    )
                  : null}
                <MenuItem disabled> Graduate</MenuItem>
                {programs && programs.length > 0
                  ? programs?.map((p) =>
                      p.programType === 'graduate' ? (
                        <MenuItem value={p._id} key={p._id}>
                          {p.programName}
                          &nbsp;
                          <Chip
                            label={p.department.departmentName}
                            size="small"
                          ></Chip>
                        </MenuItem>
                      ) : null
                    )
                  : null}
              </TextField>
            </Grid>

            <Grid item xs={12} sm={4}>
              <TextField
                size="small"
                type="text"
                name="batch"
                label="Batch"
                value={batch}
                helperText={'Ongoing batch for this department'}
                variant="outlined"
                onChange={(e) => onChange(e)}
                className={clsx(classes.textField)}
              ></TextField>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                size="small"
                select
                name="trimester"
                label="Trimester"
                value={trimester}
                helperText={'Select trimester'}
                variant="outlined"
                onChange={(e) => onChange(e)}
                className={clsx(classes.textField)}
              >
                <MenuItem value={''}>* Select the trimester</MenuItem>
                <MenuItem value={'Spring'}>Spring</MenuItem>
                <MenuItem value={'Summer'}>Summer</MenuItem>
                <MenuItem value={'Fall'}>Fall</MenuItem>
              </TextField>
            </Grid>
            {/* <Grid item xs={12} sm={4}>
              <TextField
                size="small"
                type="number"
                name="year"
                label="Year"
                value={year}
                helperText={'Type in the year'}
                variant="outlined"
                onChange={(e) => setYear(e)}
                className={clsx(classes.textField)}
              ></TextField>
            </Grid> */}
            <Grid item xs={12} sm={3} align="center">
              <MuiPickersUtilsProvider utils={DateFnsUtils} name="year">
                <DatePicker
                  name="year"
                  size="small"
                  inputVariant="outlined"
                  views={['year']}
                  helperText={'Select Exam Year'}
                  label="Year"
                  value={year}
                  onChange={setYear}
                  autoOk={true}
                />
                <i
                  className="fas fa-times"
                  style={{
                    color: 'red',
                    cursor: 'pointer',
                    display: year == null ? 'none' : '',
                    marginLeft: '5px',
                  }}
                  onClick={(e) => setYear(null)}
                ></i>
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                size="small"
                select
                name="conducted"
                label="Conducted?"
                value={conducted}
                helperText={'Has the exam taken place?'}
                variant="outlined"
                onChange={(e) => onChange(e)}
                className={clsx(classes.textField)}
              >
                <MenuItem value={false}>No</MenuItem>
                <MenuItem value={true}>Yes</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} sm={4}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DateTimePicker
                  className={clsx(classes.textField)}
                  value={examDate}
                  onChange={setExamDate}
                  // onError={console.log}
                  inputVariant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton>
                          <InsertInvitationIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  label="Select date of exam."
                />
              </MuiPickersUtilsProvider>
            </Grid>

            <Grid item xs={12}>
              {!authLoading && (
                <Link to={`/exams`}>
                  <Button
                    variant="contained"
                    color="default"
                    disableElevation
                    className={classes.btnSecondary}
                  >
                    <i className="fas fa-arrow-left"></i> &nbsp; Back
                  </Button>
                </Link>
              )}

              {!authLoading && role.includes('ADMIN') && (
                <Button
                  variant="contained"
                  color="primary"
                  disableElevation
                  className={classes.btn}
                  type="submit"
                >
                  Update
                  {loadingUpdate ? (
                    <>
                      &nbsp;<i className="fas fa-circle-notch fa-spin"></i>
                    </>
                  ) : (
                    <>
                      &nbsp;<i className="fas fa-check"></i>
                    </>
                  )}
                </Button>
              )}
            </Grid>
          </Grid>
          <AlertComponent />
        </form>
      </div>
    </ThemeProvider>
  );
};

export default EditExam;
