import React from 'react';
import { Link } from 'react-router-dom';
import Divider from '@material-ui/core/Divider';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Button } from '@material-ui/core';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  mrTop: {
    marginTop: '1rem',
  },
});

const Contact = () => {
  const classes = useStyles();
  return (
    <div className="container">
      <h1 className="large-alt text-primary">Contact</h1>
      <small>
        Our Address: 2/A, Arambagh, Motijheel, GPO Box-7, DHAKA-1000, BANGLADESH
      </small>
      <Divider />
      <br />
      <p className="lead-alt">General Inquiries</p>
      <small>
        For admission inquiries, university inquiries, general info and
        financial inquiries.
      </small>
      <TableContainer component={Paper} variant="outlined">
        <Table size="small" aria-label="table" sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell>Department</TableCell>
              <TableCell align="left">Phone</TableCell>
              <TableCell align="left">Email</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                Information
              </TableCell>
              <TableCell align="left">
                +8802-41070719, +8802-41070720, +8801781-910129
              </TableCell>
              <TableCell align="left">info@ndub.edu.bd</TableCell>
            </TableRow>
          </TableBody>
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                Admission
              </TableCell>
              <TableCell align="left">+8801708-661555</TableCell>
              <TableCell align="left">admissions@ndub.edu.bd</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <br /> <br /> <Divider />
      <br />
      <p className="lead-alt">Technical Support</p>
      <small>
        For technical support for NDUB Online Admission Account. Example:
        Payment Issues, Account Issues, Admit Card Issues etc.
      </small>
      <TableContainer component={Paper} variant="outlined">
        <Table size="small" aria-label="table" sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align="left">Designation</TableCell>
              <TableCell align="left">Email</TableCell>
              <TableCell align="left">Phone</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                Ricky Rozario
              </TableCell>
              <TableCell align="left">Lead Software Developer</TableCell>
              <TableCell align="left">ricky@ndub.edu.bd</TableCell>
              <TableCell align="left">
                +8802-41070719, +8802-41070720 (Extension: 107)
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell component="th" scope="row">
                Joydhon Chakma
              </TableCell>
              <TableCell align="left">Software Developer</TableCell>
              <TableCell align="left">joydhon@ndub.edu.bd</TableCell>
              <TableCell align="left">
                +8802-41070719, +8802-41070720 (Extension: 124)
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Md. Harun Aur Rashid Khan
              </TableCell>
              <TableCell align="left">Jr. Programmer</TableCell>
              <TableCell align="left">ishan@ndub.edu.bd</TableCell>
              <TableCell align="left">
                +8802-41070719, +8802-41070720 (Extension: 102)
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <br />
      <Link to={`/`}>
        <Button
          variant="contained"
          size="small"
          color="default"
          disableElevation
        >
          <i className="fas fa-arrow-left"></i> &nbsp; Back
        </Button>
      </Link>
    </div>
  );
};

export default Contact;
