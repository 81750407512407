import React from 'react';
import PropTypes from 'prop-types';
import xlsx from 'json-as-xlsx';
import { format } from 'date-fns';
import { Button, Chip } from '@material-ui/core';

const DownloadInstitutions = ({ institutions, name, type }) => {
  var columns = [
    {
      label: 'Name',
      value: 'name',
    },
    {
      label: 'Percentage',
      value: 'percentage',
    },
  ];

  var settings = {
    sheetName: `${name}`, // The name of the sheet
    fileName: `${name} Institutions - ${format(new Date(), 'PPPPp')}`, // The name of the spreadsheet
    extraLength: 3, // A bigger number means that columns should be wider
    writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
  };

  var download = true; // If true will download the xlsx file, otherwise will return a buffer

  const onDownload = (e) => {
    e.preventDefault();
    xlsx(columns, institutions, settings, download); // Will download the excel file
  };

  if (type === 'chip') {
    return (
      <Chip
        label={`${name} Institute`}
        onDelete={(e) => onDownload(e)}
        deleteIcon={
          <i
            className="fas fa-download"
            style={{ color: '#FFF', paddingTop: '3px' }}
          ></i>
        }
        color="primary"
        onClick={(e) => onDownload(e)}
      />
    );
  }
  //   Download Institutions
  return (
    <Button
      variant="contained"
      color="primary"
      size="small"
      disableElevation
      type="submit"
      style={{ color: '#FFF' }}
      onClick={(e) => onDownload(e)}
    >
      {name} Institute &nbsp; <i className="fas fa-download"></i>
    </Button>
  );
};

export default DownloadInstitutions;
