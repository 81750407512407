import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';

import Spinner from '../layout/Spinner';
import AlertComponent from '../layout/AlertComponent';
import ProgramItem from './ProgramItem';

import { useDispatch, useSelector } from 'react-redux';
import { listExams } from '../../actions/examActions';
import { Button, Chip, createMuiTheme, Typography } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#00acee',
    },
    // secondary: {
    //   main: '#FFF',
    // },
  },
});

const useStyles = makeStyles((theme) => ({
  // root: {
  //   maxWidth: '1100px',
  //   margin: 'auto',
  //   padding: '0 2rem',
  //   marginTop: '4rem',
  //   marginBottom: '2rem',
  // },
  divider: {
    marginTop: '0.5rem',
  },
  btn: {
    color: '#FFF',
    // marginRight: '10px',
  },
}));

const Step1Program = ({
  nextStep,
  formData: { examId },
  onProgramSelect,
  steps,
  currentStepMobile,
}) => {
  const dispatch = useDispatch();

  const examList = useSelector((state) => state.examList);
  const { loading: loadingList, exams, error } = examList;

  useEffect(() => {
    if (exams.length <= 0) {
      dispatch(listExams());
    }
    // eslint-disable-next-line
  }, [listExams, dispatch]);

  const classes = useStyles();

  let selectedExam = {};

  if (exams) {
    selectedExam = exams.filter((exm) => exm._id === examId)[0];
  }

  const clearSelect = (e) => {
    e.preventDefault();
    onProgramSelect('', '', '', '', '', '', '', '', '');
  };

  const onSelect = (e) => {
    e.preventDefault();
    if (examId !== '') {
      nextStep();
    }
  };

  return (
    <div className={'container'}>
      <h1 className="large text-primary">Programs</h1>
      <p className="lead-alt">Select your desired program.</p>
      <div className="hide-sm">{steps}</div>
      <div className="hide-lg">{currentStepMobile}</div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <Typography variant="button" display="block" gutterBottom>
            Selected Program:{' '}
            {/* {selectedExam ? selectedExam.title : 'None'}{' '} */}
            {selectedExam && examId ? (
              <Chip
                size="small"
                label={selectedExam.title}
                onDelete={clearSelect}
                deleteIcon={<CancelIcon style={{ color: '#DC004E' }} />}
                color="primary"
                variant="outlined"
              />
            ) : (
              'None'
            )}
          </Typography>
        </div>
        <div>
          <Button
            variant="contained"
            color="primary"
            disableElevation
            disabled={selectedExam && examId ? false : true}
            className={classes.btn}
            onClick={(e) => onSelect(e)}
            size="small"
          >
            Next &nbsp; <i className="fas fa-arrow-right"></i>
          </Button>
        </div>
      </div>
      <small className="p-subtext">
        Click to expand and see details on the program.
      </small>{' '}
      <br />
      <Divider className={classes.divider} />
      <br />
      <AlertComponent />
      {loadingList ? (
        <Spinner />
      ) : (
        exams !== null &&
        exams.length > 0 &&
        exams.map((exm) => (
          <ProgramItem
            exam={exm}
            nextStep={nextStep}
            onProgramSelect={onProgramSelect}
            selectedExamId={examId}
            examId={examId}
            key={exm._id}
          />
        ))
      )}
    </div>
  );
};

Step1Program.propTypes = {
  getAvailableExams: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  onProgramSelect: PropTypes.func.isRequired,
};

export default Step1Program;
